import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import Button from "@amzn/meridian/button";
import Divider from "@amzn/meridian/divider";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import SearchField from "@amzn/meridian/search-field";
import syncIcon from "@amzn/meridian-tokens/base/icon/sync";

import SearchFooter from "componentsV2/HomePage/ActionBar/Search/SearchFooter";
import SearchSuggestions from "componentsV2/HomePage/ActionBar/Search/SearchSuggestions";
import { clearYardConfigData, fetchSearchSuggestions, fetchYardIdentifiers } from "store";
import { keys } from "i18n";
import {
  AMAZON_ACCOUNT_NAME,
  SEARCH_DEBOUNCE_WAIT,
  SEARCH_SCOPE_YARD_AND_BUILDING,
  YARD_DESIGNER_ACCOUNT_NAME,
} from "app-constants";

export default function Search() {
  const [searchString, setSearchString] = useState("");
  const [searchScope, setSearchScope] = useState(SEARCH_SCOPE_YARD_AND_BUILDING);

  const { activeAccountName } = useSelector((state) => state.accountInfo);
  const { fetchingRefresh } = useSelector((state) => state.yardIdentifiersData);

  const dispatch = useDispatch();

  const searchFieldRef = useRef(null);

  useEffect(() => {
    debouncedFetchSearchResults(searchString, searchScope);
  }, [searchString, searchScope]);

  const { t } = useTranslation();

  const fetchSearchResults = (searchString, searchScope) => {
    dispatch(fetchSearchSuggestions({ searchString, searchScope }));
  };

  const debouncedFetchSearchResults = useCallback(
    debounce(fetchSearchResults, SEARCH_DEBOUNCE_WAIT),
    []
  );

  const submitSearch = () => {
    dispatch(
      fetchYardIdentifiers({
        refresh: true,
        searchString,
        searchScope,
      })
    );
  };

  const refresh = () => {
    dispatch(
      fetchYardIdentifiers({
        refresh: true,
        searchString,
        searchScope,
      })
    );

    dispatch(clearYardConfigData());
  };

  const blurSearchField = () => searchFieldRef.current.blur();

  const isSearchEligibleAccount =
    activeAccountName === YARD_DESIGNER_ACCOUNT_NAME || activeAccountName === AMAZON_ACCOUNT_NAME;

  return (
    <Row spacing="small">
      <SearchField
        ref={searchFieldRef}
        data-testid="searchField"
        width={440}
        placeholder={t(keys.SEARCH_FOR_YARD_OR_BUILDING)}
        searchButton={true}
        clearButton={false}
        value={searchString}
        disabled={fetchingRefresh || !isSearchEligibleAccount}
        onChange={setSearchString}
        onSubmit={submitSearch}
      >
        <SearchSuggestions searchString={searchString} blurSearchField={blurSearchField} />
        <Divider />
        <SearchFooter searchString={searchString} searchScope={searchScope} />
      </SearchField>

      <Button
        data-testid="refreshButton"
        type="icon"
        label={t(keys.REFRESH)}
        disabled={fetchingRefresh}
        onClick={refresh}
      >
        <Icon tokens={syncIcon} />
      </Button>
    </Row>
  );
}

Search.propTypes = {};
