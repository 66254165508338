import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";

import YardLinks from "componentsV2/HomePage/YardLinks";
import { keys } from "i18n";
import { SUPPORT_OFFSITE_MODELING } from "app-constants";

export default function YardIdentifierTable() {
  const featureFlags = useSelector((state) => state.featureFlags);
  const { fetchingRefresh, fetchingContinue, yardIdentifiers } = useSelector(
    (state) => state.yardIdentifiersData
  );

  const { t } = useTranslation();

  const getOffsiteYardLabelsText = (offsiteYardIdentifiers) =>
    offsiteYardIdentifiers?.map(({ yardLabel }) => yardLabel).join(", ");
  const getBuildingCodesText = (buildingCodes) => buildingCodes?.join(", ");

  if (fetchingRefresh) {
    return (
      <Row alignmentHorizontal="center">
        <Loader data-testid="initialLoader" />
      </Row>
    );
  }

  return (
    <>
      {!!yardIdentifiers.length && (
        <Table headerRows={1} showDividers={true}>
          <TableRow>
            <TableCell>{t(keys.YARD)}</TableCell>
            {featureFlags[SUPPORT_OFFSITE_MODELING]?.enabled && (
              <TableCell>{t(keys.OFFSITE_YARDS)}</TableCell>
            )}
            <TableCell>{t(keys.BUILDINGS)}</TableCell>
            <TableCell>{t(keys.LINKS)}</TableCell>
          </TableRow>

          {yardIdentifiers.map(({ yardId, yardLabel, offsiteYardIdentifiers, buildingCodes }) => (
            <TableRow key={yardId} highlightOnHover={true}>
              <TableCell data-testid={yardLabel}>{yardLabel}</TableCell>

              {featureFlags[SUPPORT_OFFSITE_MODELING]?.enabled && (
                <TableCell data-testid={getOffsiteYardLabelsText(offsiteYardIdentifiers)}>
                  {getOffsiteYardLabelsText(offsiteYardIdentifiers)}
                </TableCell>
              )}

              <TableCell data-testid={getBuildingCodesText(buildingCodes)}>
                {getBuildingCodesText(buildingCodes)}
              </TableCell>

              <TableCell>
                <YardLinks yardId={yardId} />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      )}

      {fetchingContinue && (
        <Row alignmentHorizontal="center">
          <Loader data-testid="subsequentLoader" />
        </Row>
      )}
    </>
  );
}

YardIdentifierTable.propTypes = {};
