import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";

import { keys } from "i18n";
import { STATUS_TYPES } from "app-constants";

export default function DialogFooter({
  commitButtonText,
  inProgress,
  error,
  disabled,
  commit,
  dismiss,
  children,
}) {
  const { t } = useTranslation();

  return (
    <Column>
      <Row alignmentHorizontal="end">
        <Button
          data-testid="dismissButton"
          type="secondary"
          disabled={inProgress}
          onClick={dismiss}
        >
          {t(keys.CANCEL)}
        </Button>

        {children && children}

        {!inProgress && (
          <Button data-testid="commitButton" type="primary" disabled={disabled} onClick={commit}>
            {commitButtonText || t(keys.SAVE)}
          </Button>
        )}
        {inProgress && <Loader data-testid="loader" size="medium" />}
      </Row>

      {error && (
        <Alert data-testid="errorMessage" type={STATUS_TYPES.ERROR}>
          {error}
        </Alert>
      )}
    </Column>
  );
}

DialogFooter.propTypes = {
  commitButtonText: PropTypes.string,
  inProgress: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  commit: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
};
