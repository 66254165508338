import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SaveChangesDialog from "component/Common/SaveChangesDialog";

import { clearErrorSavingSubscriptions, saveSubscriptions } from "store";

class SaveSubscriptionsDialog extends React.PureComponent {
  saveChanges = () => {
    const { yardId, buildingCode, subscriptions, originalSubscriptions, dismiss } = this.props;
    this.props.saveSubscriptions(
      {
        yardId,
        buildingCode,
        subscriptions,
        originalSubscriptions,
      },
      dismiss
    );
  };

  dismiss = () => {
    this.props.clearErrorSavingSubscriptions(this.props.dismiss);
  };

  render() {
    const { savingSubscriptions, errorSavingSubscriptions } = this.props.subscriptionsData;

    return (
      <SaveChangesDialog
        title={"SAVE_SUBSCRIPTIONS"}
        saveChanges={this.saveChanges}
        dismiss={this.dismiss}
        savingChanges={savingSubscriptions}
        genericSavingErrorMessage={"SAVE_SUBSCRIPTIONS_ERROR_MESSAGE"}
        serverErrorMessage={errorSavingSubscriptions}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo,
  subscriptionsData: state.subscriptionsData,
});

const mapDispatchToProps = (dispatch) => ({
  saveSubscriptions: (payload, callback) => {
    dispatch(saveSubscriptions(payload, callback));
  },
  clearErrorSavingSubscriptions: (callback) => {
    dispatch(clearErrorSavingSubscriptions(callback));
  },
});

export { SaveSubscriptionsDialog };

export default connect(mapStateToProps, mapDispatchToProps)(SaveSubscriptionsDialog);

SaveSubscriptionsDialog.propTypes = {
  yardId: PropTypes.string.isRequired,
  buildingCode: PropTypes.string.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  originalSubscriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  dismiss: PropTypes.func.isRequired,
};
