import PropTypes from "prop-types";

import Row from "@amzn/meridian/row";
import { useSelector } from "react-redux";

import { getApplicableLanesForMasking } from "helpers";
import Select, { SelectOption } from "@amzn/meridian/select";
import { useYardConfigInit } from "hooks";
import { useEffect, useState } from "react";
import { keys } from "i18n";
import { useTranslation } from "react-i18next";
import { callAPI } from "store";
import { APIs } from "app-constants";
import { CAMERA_TYPES } from "app-constants";
import { STREAM_QUALITY } from "app-constants";

export default function ActionBar({
  yardId,
  gateId,
  setChannels,
  isLoadingChannels,
  setIsLoadingChannels,
}) {
  const { gates } = useSelector((state) => state.yardConfigData)[yardId];
  const [purposeLane, setPurposeLane] = useState(null);
  const { t } = useTranslation();
  const accountInfo = useSelector((state) => state.accountInfo);
  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];
  const { label } = yardConfig || {};
  const buildingCodes = yardConfig.buildings.map((buildingConfig) => buildingConfig.buildingCode);

  const applicableLanes = getApplicableLanesForMasking(gates, gateId);

  useYardConfigInit(yardId);

  const updatePurposeLane = (laneKey) => {
    const lane = applicableLanes.get(laneKey);
    setPurposeLane(lane);
  };

  const getSignalingChannelMapByBuildingCode = async (buildingCode) => {
    let responseJson;
    try {
      responseJson = await callAPI(accountInfo, APIs.LIST_SIGNALLING_CHANNELS_BY_GATE_LANE, {
        gateLaneInfo: {
          site: buildingCode,
          gateId: gateId,
          purpose: purposeLane.purpose,
          laneId: purposeLane.laneNumber,
        },
      });
    } catch (error) {
      if (error.message.includes("InvalidArgumentException")) {
        console.log(error);
        console.log("KVS query argument is invalid, skipping...");
        return new Map();
      }
      throw error;
    }

    const filteredSignalingChannels = responseJson?.signalingChannels?.reduce((map, channel) => {
      const nameParams = channel.channelName.split("-");
      if (nameParams.length !== 7) {
        // Name should be in the format SITE-GATE-PURPOSE-LANE-CAMERA_TYPE-CAM_NUM-QUALITY
        return map;
      }

      const transformedChannel = {
        ...channel,
        siteId: nameParams[0],
        gateId: nameParams[1],
        purpose: nameParams[2],
        laneNumber: nameParams[3],
        cameraType: nameParams[4],
        cameraNumber: nameParams[5],
        cameraQuality: nameParams[6],
      };
      if (
        transformedChannel.cameraQuality === STREAM_QUALITY.LO &&
        CAMERA_TYPES.includes(transformedChannel.cameraType)
      ) {
        // Only map relevant streams
        map.set(transformedChannel.channelName, transformedChannel);
      }
      return map;
    }, new Map());

    return filteredSignalingChannels;
  };

  useEffect(() => {
    const fetchChannels = async () => {
      if (!purposeLane) {
        return;
      }
      setIsLoadingChannels(true);

      // iterate through the building codes to find the KVS channels
      let filteredSignalingChannels = new Map();
      let i = 0;
      do {
        let buildingCode = buildingCodes[i];
        filteredSignalingChannels = await getSignalingChannelMapByBuildingCode(buildingCode);
        i += 1;
      } while (i < buildingCodes.length && filteredSignalingChannels.size == 0);

      setChannels(filteredSignalingChannels);
      setIsLoadingChannels(false);
    };
    fetchChannels();
  }, [label, gateId, purposeLane]);

  return (
    <Row alignmentVertical="center">
      <Select
        label={t(keys.SELECT_LANE)}
        value={!purposeLane ? null : purposeLane.key}
        onChange={updatePurposeLane}
        disabled={isLoadingChannels}
        placeholder={applicableLanes.size ? t(keys.SELECT_LANE) : t(keys.NO_LANES_FOUND)}
        width={300}
        size="medium"
        prefix=""
        helperText=""
        data-testid="selectLaneParent"
      >
        {Array.from(applicableLanes.keys()).map((key) => (
          <SelectOption value={key} label={key} key={key} />
        ))}
      </Select>
    </Row>
  );
}

ActionBar.propTypes = {
  yardId: PropTypes.string.isRequired,
  gateId: PropTypes.string.isRequired,
  setChannels: PropTypes.func.isRequired,
  isLoadingChannels: PropTypes.bool.isRequired,
  setIsLoadingChannels: PropTypes.func.isRequired,
};
