import PropTypes from "prop-types";

export const yardIdentifier = PropTypes.shape({
  yardId: PropTypes.string.isRequired,
  yardLabel: PropTypes.string.isRequired,
  buildingCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const yardIdentifiersData = PropTypes.shape({
  fetchingRefresh: PropTypes.bool.isRequired,
  fetchingContinue: PropTypes.bool.isRequired,
  yardGraphVersion: PropTypes.number,
  lastUpdate: PropTypes.instanceOf(Date),
  allMatchedYardIdentifiersCount: PropTypes.number.isRequired,
  yardIdentifiers: PropTypes.arrayOf(yardIdentifier),
  exclusiveStartKey: PropTypes.string,
  error: PropTypes.string,
});
