import { callAPI } from "store";
import { APIs } from "app-constants";

export default class YardConsoleRequestSigner {
  constructor(accountInfo) {
    this.accountInfo = accountInfo;
  }

  async getSignedURL(endpoint, queryParams) {
    // Validate and parse endpoint
    const protocol = "wss";
    const urlProtocol = `${protocol}://`;
    if (!endpoint.startsWith(urlProtocol)) {
      throw new Error(
        // eslint-disable-next-line max-len
        `Endpoint '${endpoint}' is not a secure WebSocket endpoint. It should start with '${urlProtocol}'.`
      );
    }
    const queryParamsStart = "?";
    if (endpoint.includes(queryParamsStart)) {
      throw new Error(`Endpoint '${endpoint}' should not contain any query parameters.`);
    }

    const transformedParams = {};
    Object.entries(queryParams).forEach((entry) => (transformedParams[entry[0]] = [entry[1]]));

    const responseJson = await callAPI(this.accountInfo, APIs.GET_KVS_PRESIGNED_URL, {
      channelEndpoint: endpoint,
      parameters: transformedParams,
    });

    return responseJson.presignedUrl;
  }
}
