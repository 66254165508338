import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";

import Box from "@amzn/meridian/box";

import ControlledExpander from "componentsV2/Common/ControlledExpander";
import GateDisplayer from "component/GateDisplayer";
import { keys } from "i18n";
import { extractGateLanes } from "helpers";
import { ENABLE_MULTILANE_GATES_MANAGEMENT, GATE_TABLE } from "app-constants";
import Column from "@amzn/meridian/column";
import TopActionBar from "componentsV2/ConfigPage/YardEntity/YardInfo/GateTable/TopActionBar";
import TableContent from "componentsV2/ConfigPage/YardEntity/YardInfo/GateTable/TableContent";
import ActionBar from "componentsV2/ConfigPage/YardEntity/YardInfo/GateTable/ActionBar";
import NewGateAction from "componentsV2/ConfigPage/YardEntity/YardInfo/GateTable/NewGateAction";

export default function GateTable({ yardId }) {
  const { permissions } = useSelector((state) => state.permissionsData);
  const featureFlags = useSelector((state) => state.featureFlags);
  const { gates, version } = useSelector((state) => state.yardConfigData)[yardId];

  const { t } = useTranslation();
  const gateLanes = extractGateLanes(gates);
  const canEdit = !!permissions.canEdit;

  if (!featureFlags[ENABLE_MULTILANE_GATES_MANAGEMENT]?.enabled) {
    // GateDisplayer used here is under component V1. When we remove ENABLE_MULTILANE_GATES_MANAGEMENT feature flag,
    // we can remove all components under component/GateDisplayer.
    return (
      <ControlledExpander title={t(keys.GATES)} type="inline" openOnMount={true}>
        <Box type="fill" spacingInset="medium">
          <GateDisplayer
            canEdit={canEdit}
            yardId={yardId}
            gateLanes={gateLanes}
            version={version}
          />
        </Box>
      </ControlledExpander>
    );
  }

  // component state to store all gates, used for creating new gate (guard shack).
  const [gatesInState, setGatesInState] = useState(gates);
  // component state to store gateLanes extracted from gates in redux store.
  const [gateLanesInState, setGateLanesInState] = useState(gateLanes);
  // The following component states are used in gate content table and remove/edit/restore action bar.
  const [sortColumn, setSortColumn] = useState(GATE_TABLE.keys[1]); // table sort by lane purpose
  const [sortDirection, setSortDirection] = useState("ascending");
  const [selectedIds, setSelectedIds] = useState([]);
  const [page, setPage] = useState(1);

  // When there is an update to gates in store, gateLanes in component state are refreshed with the new values from store.
  useEffect(() => {
    if (!isEqual(gateLanesInState, gateLanes)) {
      setGateLanesInState(gateLanes);
    }
  }, [gates]);

  // Logical to display gates configured through legacy gate configuration.
  if (gates && gates.length && gates[0].gateIdentifier == null) {
    const gate = gates[0];
    return (
      <ControlledExpander title={t(keys.GATES)} type="inline" openOnMount={true}>
        <Column>
          <NewGateAction yardId={yardId} setGates={setGatesInState} />
          <Box type="fill" spacingInset="medium">
            <Column>
              <TopActionBar
                yardId={yardId}
                gateLanes={gateLanesInState}
                currentGate={gate}
                setGateLanes={setGateLanesInState}
              />

              <ActionBar
                yardId={yardId}
                gateLanes={gateLanesInState}
                currentGate={gate}
                selectedIds={selectedIds}
                setGateLanes={setGateLanesInState}
                setSelectedIds={setSelectedIds}
              />

              <TableContent
                yardId={yardId}
                gateLanes={gateLanesInState}
                currentGate={gate}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                selectedIds={selectedIds}
                page={page}
                setSortColumn={setSortColumn}
                setSortDirection={setSortDirection}
                setSelectedIds={setSelectedIds}
                setPage={setPage}
              />
            </Column>
          </Box>
        </Column>
      </ControlledExpander>
    );
  }

  return (
    <ControlledExpander title={t(keys.GATES)} type="inline" openOnMount={true}>
      <Column>
        <NewGateAction yardId={yardId} setGates={setGatesInState} />
        {gatesInState?.map((gate) => (
          <Box key={gate.id} type="fill" spacingInset="medium">
            <Column>
              <TopActionBar
                yardId={yardId}
                gateLanes={gateLanesInState}
                currentGate={gate}
                setGateLanes={setGateLanesInState}
              />

              <ActionBar
                yardId={yardId}
                gateLanes={gateLanesInState}
                currentGate={gate}
                selectedIds={selectedIds}
                setGateLanes={setGateLanesInState}
                setSelectedIds={setSelectedIds}
              />

              <TableContent
                yardId={yardId}
                gateLanes={gateLanesInState}
                currentGate={gate}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                selectedIds={selectedIds}
                page={page}
                setSortColumn={setSortColumn}
                setSortDirection={setSortDirection}
                setSelectedIds={setSelectedIds}
                setPage={setPage}
              />
            </Column>
          </Box>
        ))}
      </Column>
    </ControlledExpander>
  );
}

GateTable.propTypes = {
  yardId: PropTypes.string.isRequired,
};
