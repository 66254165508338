import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import Alert from "@amzn/meridian/alert";
import Loader from "@amzn/meridian/loader";

import { callAPI } from "store";
import { equalsIgnoreCase } from "helpers";
import { keys } from "i18n";
import {
  APIs,
  SEARCH_SCOPE_YARD,
  STATUS_TYPES,
  YARD_LABEL_AVAILABILITY_CHECK_DEBOUNCE_WAIT,
} from "app-constants";

export default function YardLabelAvailabilityCheck({ label, setSubmittable }) {
  const accountInfo = useSelector((state) => state.accountInfo);

  const [preCheckingAvailability, setPreCheckingAvailability] = useState(false);
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const [isLabelUsed, setIsLabelUsed] = useState(false);
  const [errorCheckingAvailability, setErrorCheckingAvailability] = useState(null);

  useEffect(() => {
    if (
      !label ||
      preCheckingAvailability ||
      checkingAvailability ||
      isLabelUsed ||
      errorCheckingAvailability
    ) {
      setSubmittable((submittable) => ({ ...submittable, isLabelValid: false }));
    } else {
      setSubmittable((submittable) => ({ ...submittable, isLabelValid: true }));
    }
  }, [
    label,
    preCheckingAvailability,
    checkingAvailability,
    isLabelUsed,
    errorCheckingAvailability,
  ]);

  useEffect(() => {
    if (label) {
      setPreCheckingAvailability(true);
      debouncedCheckAvailability(label);
    }
  }, [label]);

  const checkAvailability = async (label) => {
    setPreCheckingAvailability(false);
    setCheckingAvailability(true);
    setIsLabelUsed(false);
    setErrorCheckingAvailability(null);

    try {
      const { yardIdentifiers } = await callAPI(accountInfo, APIs.LIST_SUPPORTED_YARD_IDENTIFIERS, {
        searchString: label,
        searchScope: SEARCH_SCOPE_YARD,
      });

      const isLabelUsed = yardIdentifiers.some(
        ({ yardLabel, offsiteYardIdentifiers }) =>
          equalsIgnoreCase(yardLabel, label) ||
          offsiteYardIdentifiers.some(({ yardLabel }) => equalsIgnoreCase(yardLabel, label))
      );
      setIsLabelUsed(isLabelUsed);
    } catch (e) {
      setErrorCheckingAvailability(e.message);
      console.error(e.message);
    }

    setCheckingAvailability(false);
  };

  const debouncedCheckAvailability = useCallback(
    debounce(checkAvailability, YARD_LABEL_AVAILABILITY_CHECK_DEBOUNCE_WAIT),
    []
  );

  const { t } = useTranslation();

  if (!label || preCheckingAvailability) {
    return null;
  }

  if (checkingAvailability) {
    return <Loader data-testid="loader" size="medium" />;
  }

  if (isLabelUsed) {
    return (
      <Alert data-testid="labelUsedAlert" size="small" type={STATUS_TYPES.ERROR}>
        {t(keys.LABEL_USED)}
      </Alert>
    );
  }

  if (errorCheckingAvailability) {
    return (
      <Alert data-testid="checkAvailabilityErrorAlert" size="small" type={STATUS_TYPES.ERROR}>
        {t(keys.CHECK_YARD_LABEL_AVAILABILITY_ERROR_MESSAGE)}
      </Alert>
    );
  }

  return (
    <Alert data-testid="labelAvailableAlert" size="small" type={STATUS_TYPES.SUCCESS}>
      {t(keys.LABEL_AVAILABLE)}
    </Alert>
  );
}

YardLabelAvailabilityCheck.propTypes = {
  label: PropTypes.string,
  setSubmittable: PropTypes.func.isRequired,
};
