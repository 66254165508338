import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Button from "@amzn/meridian/button";

import GateTable from "component/GateDisplayer/GateTable";
import GateDialog from "component/GateDisplayer/GateDialog";
import SaveGatesDialog from "component/GateDisplayer/SaveGatesDialog";
import { keys } from "i18n";
import { doListsContainSameElements, convertToMapById } from "helpers";
import { gateLane } from "types";

class GateDisplayer extends React.PureComponent {
  state = {
    showGateDialog: false,
    showSaveGatesDialog: false,
    gateLaneToEdit: null,
    gateLanes: this.props.gateLanes,
    currentPage: 1,

    // the key is used to refresh the state of the table in case the list of
    // gates is updated
    tableKey: 1,
  };

  componentDidUpdate(prevProps) {
    if (!doListsContainSameElements(this.props.gateLanes, prevProps.gateLanes)) {
      this.setState({
        gateLanes: this.props.gateLanes,
        tableKey: this.state.tableKey + 1,
      });
    }
  }

  setPageNumber = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  openSaveGatesDialog = () => {
    this.setState({ showSaveGatesDialog: true });
  };

  closeSaveGatesDialog = () => this.setState({ showSaveGatesDialog: false });

  addNewGate = (values) => {
    const { gateLanes } = this.state;
    this.setState({
      showGateDialog: false,
      gateLanes: [...gateLanes, values],
      tableKey: this.state.tableKey + 1,
    });
  };

  showEditDialog = ([gateLaneToEdit]) => this.setState({ showGateDialog: true, gateLaneToEdit });

  editGate = (values) => {
    const { gateLanes } = this.state;
    this.setState({
      showGateDialog: false,
      gateLanes: gateLanes.map((gateLane) => (gateLane.id === values.id ? values : gateLane)),
      tableKey: this.state.tableKey + 1,
    });
  };

  removeGate = (gateLanesToRemove) => {
    const { gateLanes } = this.state;
    this.setState({
      gateLanes: gateLanes.filter(
        (gatelane) =>
          !gateLanesToRemove.some((gateLaneToRemove) => gateLaneToRemove.id === gatelane.id)
      ),
      tableKey: this.state.tableKey + 1,
    });
  };

  restoreGate = ([gateLaneToRestore]) => {
    const { gateLanes } = this.state;
    const idToOriginalGateLaneMap = convertToMapById(this.props.gateLanes);
    const originalGateLane = idToOriginalGateLaneMap[gateLaneToRestore.id];
    this.setState({
      gateLanes: gateLanes.map((gateLane) =>
        gateLane.id === gateLaneToRestore.id ? originalGateLane : gateLane
      ),
      tableKey: this.state.tableKey + 1,
    });
  };

  areGatesChanged = () => {
    const { gateLanes: originalGateLanes } = this.props;
    const { gateLanes } = this.state;
    return !doListsContainSameElements(originalGateLanes, gateLanes);
  };

  render() {
    const { t, canEdit, version, yardId, gateLanes: originalGateLanes } = this.props;
    const { showGateDialog, showSaveGatesDialog, gateLaneToEdit, gateLanes, tableKey } = this.state;
    const idToOriginalGateLaneMap = convertToMapById(originalGateLanes);
    const areGatesChanged = this.areGatesChanged();
    return (
      <Column spacing="medium">
        <Row alignmentHorizontal="justify">
          <Heading level={6} color="secondary">
            {t(keys.GATES)}
          </Heading>
          <Row>
            <Button
              type="tertiary"
              disabled={!canEdit}
              onClick={() =>
                this.setState({
                  showGateDialog: true,
                  gateLaneToEdit: null,
                })
              }
            >
              {t(keys.NEW_GATE)}
            </Button>
            <Button
              type="tertiary"
              disabled={!canEdit || !areGatesChanged}
              onClick={this.openSaveGatesDialog}
            >
              {t(keys.SAVE)}
            </Button>
          </Row>
        </Row>

        <GateTable
          key={tableKey}
          canEdit={canEdit}
          gateLanes={gateLanes}
          idToOriginalGateLaneMap={idToOriginalGateLaneMap}
          onEdit={this.showEditDialog}
          onRemove={this.removeGate}
          onRestore={this.restoreGate}
          currentPage={this.state.currentPage}
          setPageNumber={(pageNumber) => this.setPageNumber(pageNumber)}
        />

        {showGateDialog && (
          <GateDialog
            gateLane={gateLaneToEdit}
            gateLanes={gateLanes}
            add={this.addNewGate}
            edit={this.editGate}
            dismiss={() => this.setState({ showGateDialog: false })}
          />
        )}

        {showSaveGatesDialog && (
          <SaveGatesDialog
            version={version}
            yardId={yardId}
            gateLanes={gateLanes}
            dismiss={this.closeSaveGatesDialog}
          />
        )}
      </Column>
    );
  }
}

export default withTranslation()(GateDisplayer);

GateDisplayer.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  version: PropTypes.number.isRequired,
  yardId: PropTypes.string.isRequired,
  gateLanes: PropTypes.arrayOf(gateLane).isRequired,
};
