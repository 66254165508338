import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";

import BuildingDialog from "component/BuildingDialogs/BuildingDialog";
import ControlledExpander from "componentsV2/Common/ControlledExpander";
import { keys } from "i18n";
import {
  ENABLE_BUILDING_FEATURES_MANAGEMENT,
  YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH,
} from "app-constants";

export default function BuildingPropsTable({ yardId, buildingCode }) {
  const [shouldShowEditBuildingDialog, setShouldShowEditBuildingDialog] = useState(false);

  const { permissions } = useSelector((state) => state.permissionsData);
  const { buildings, version } = useSelector((state) => state.yardConfigData)[yardId];
  const featureFlags = useSelector((state) => state.featureFlags);

  const { t } = useTranslation();

  const openEditBuildingDialog = () => setShouldShowEditBuildingDialog(true);
  const closeEditBuildingDialog = () => setShouldShowEditBuildingDialog(false);

  const building = buildings.find((building) => buildingCode === building.buildingCode);
  const { description, express, inboundExpress, enableSSP, enableInboundSSP, buildingFeatures } =
    building;

  const canEdit = !!permissions.canEdit;

  return (
    <>
      <ControlledExpander title={t(keys.PROPERTIES)} type="inline" openOnMount={true}>
        <Box type="fill" spacingInset="medium">
          <Column>
            <Row alignmentHorizontal="justify">
              <Heading level={6} color="secondary">
                {t(keys.PROPERTIES)}
              </Heading>
              <Button
                data-testid="editButton"
                type="tertiary"
                disabled={!canEdit}
                onClick={openEditBuildingDialog}
              >
                {t(keys.EDIT)}
              </Button>
            </Row>

            <Table showDividers={true} spacing="small">
              <TableRow highlightOnHover={true}>
                <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                  {t(keys.BUILDING_CODE)}
                </TableCell>
                <TableCell data-testid={buildingCode}>{buildingCode}</TableCell>
              </TableRow>

              <TableRow highlightOnHover={true}>
                <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                  {t(keys.BUILDING_DESCRIPTION)}
                </TableCell>
                <TableCell data-testid={description}>{description}</TableCell>
              </TableRow>

              <TableRow highlightOnHover={true}>
                <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                  {t(keys.OB_EXPRESS)}
                </TableCell>
                <TableCell>
                  <Checkbox
                    data-testid={`obExpress-${express}`}
                    disabled
                    checked={express}
                    onChange={() => {}}
                  />
                </TableCell>
              </TableRow>

              <TableRow highlightOnHover={true}>
                <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                  {t(keys.IB_EXPRESS)}
                </TableCell>
                <TableCell>
                  <Checkbox
                    data-testid={`ibExpress-${inboundExpress}`}
                    disabled
                    checked={inboundExpress}
                    onChange={() => {}}
                  />
                </TableCell>
              </TableRow>

              <TableRow highlightOnHover={true}>
                <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                  {t(keys.SSP_OB)}
                </TableCell>
                <TableCell>
                  <Checkbox
                    data-testid={`ob-${enableSSP}`}
                    disabled
                    checked={enableSSP}
                    onChange={() => {}}
                  />
                </TableCell>
              </TableRow>

              <TableRow highlightOnHover={true}>
                <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                  {t(keys.SSP_IB)}
                </TableCell>
                <TableCell>
                  <Checkbox
                    data-testid={`ib-${enableInboundSSP}`}
                    disabled
                    checked={enableInboundSSP}
                    onChange={() => {}}
                  />
                </TableCell>
              </TableRow>

              {featureFlags[ENABLE_BUILDING_FEATURES_MANAGEMENT]?.enabled && (
                <TableRow highlightOnHover={true}>
                  <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                    {t(keys.BUILDING_FEATURES)}
                  </TableCell>
                  <TableCell data-testid={buildingFeatures?.join(",")}>
                    {buildingFeatures?.map((buildingFeature) => t(buildingFeature)).join(", ")}
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </Column>
        </Box>
      </ControlledExpander>

      {shouldShowEditBuildingDialog && (
        <BuildingDialog
          yardId={yardId}
          building={building}
          version={version}
          dismiss={closeEditBuildingDialog}
        />
      )}
    </>
  );
}

BuildingPropsTable.propTypes = {
  yardId: PropTypes.string.isRequired,
  buildingCode: PropTypes.string.isRequired,
};
