import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Tab, { TabGroup } from "@amzn/meridian/tab";

import { keys } from "i18n";
import { SUPPORT_OFFSITE_MODELING } from "app-constants";

export default function YardEntityTabs({ yardId, tab, setTab }) {
  const featureFlags = useSelector((state) => state.featureFlags);
  const { label, offsiteYards, buildings } = useSelector((state) => state.yardConfigData)[yardId];

  const { t } = useTranslation();

  return (
    <TabGroup fill="line" value={tab} onChange={setTab}>
      <Tab data-testid="yardTab" value={yardId}>
        {t(keys.YARD)} <strong>{label}</strong>
      </Tab>

      {featureFlags[SUPPORT_OFFSITE_MODELING]?.enabled &&
        offsiteYards.map(({ yardId, label }) => (
          <Tab data-testid={`offsiteYardTab-${label}`} key={label} value={yardId}>
            {t(keys.OFFSITE)} <strong>{label}</strong>
          </Tab>
        ))}

      {buildings.map(({ buildingCode }) => (
        <Tab data-testid={`buildingTab-${buildingCode}`} key={buildingCode} value={buildingCode}>
          {t(keys.BUILDING)} <strong>{buildingCode}</strong>
        </Tab>
      ))}
    </TabGroup>
  );
}

YardEntityTabs.propTypes = {
  yardId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
};
