import { FETCH_YARD_FEATURES_SUCCESS } from "./yardFeaturesActionTypes";

export const INITIAL_YARD_FEATURES = {};

export const yardFeaturesReducer = (state = INITIAL_YARD_FEATURES, action) => {
  switch (action.type) {
    case FETCH_YARD_FEATURES_SUCCESS:
      const { yardFeatures } = action.payload;
      return {
        ...state,
        yardFeatures,
      };

    default:
      return state;
  }
};
