import { useState } from "react";
import { useSelector } from "react-redux";

import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";

import { TwoDimensionalImage } from "react-annotation-tool";
import { keys } from "i18n";
import { useTranslation } from "react-i18next";
import { uploadMaskImageToS3 } from "helpers/helpers";

export default function AnnotationPanel({
  cameraInfo,
  imageUrl,
  setChannels,
  setShowAnnotationPanel,
}) {
  const [maskImageUrl, setMaskImageUrl] = useState("");
  const [openCompleteModal, setOpenCompleteModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [warningModalMessage, setWarningModalMessage] = useState("");
  const [openFailureModal, setOpenFailureModal] = useState(false);
  const [failureModalMessage, setFailureModalMessage] = useState("");

  const accountInfo = useSelector((state) => state.accountInfo);
  const { t } = useTranslation();

  const LANE_OPTIONS = {
    LANE: t(keys.LANE),
    LANE_TO_THE_LEFT: t(keys.LANE_TO_THE_LEFT),
    LANE_TO_THE_RIGHT: t(keys.LANE_TO_THE_RIGHT),
  };

  const LANE_COLOR_MAP = new Map([
    [LANE_OPTIONS.LANE, "#2ca02c"],
    [LANE_OPTIONS.LANE_TO_THE_LEFT, "#1f77b4"],
    [LANE_OPTIONS.LANE_TO_THE_RIGHT, "#ff7f0e"],
  ]);

  const ANNOTATION_OPTIONS = {
    id: "0",
    value: "root",
    children: [
      { id: "1", value: LANE_OPTIONS.LANE, children: [] },
      { id: "2", value: LANE_OPTIONS.LANE_TO_THE_LEFT, children: [] },
      { id: "3", value: LANE_OPTIONS.LANE_TO_THE_RIGHT, children: [] },
    ],
  };

  const WARNING_MESSAGE = {
    unclosedSegmentation: t(keys.ANNOTATION_UNCLOSED_SEGMENTATION_WARNING),
    unlabeledSegmentation: t(keys.ANNOTATION_UNLABELED_SEGMENTATION_WARNING),
    missingMainLane: `${t(keys.ANNOTATION_MISSING_LANE_SEGMENTATION_WARNING_PREFIX)} "${
      LANE_OPTIONS.LANE
    }."`,
  };

  const MASK_PNG_DIMENSION = { width: 1280, height: 720 };

  const validateAndExtractPolygons = (annotatedItems) => {
    const polygons = [];
    let unclosedPolygonDetected = false;
    let mainLaneLabelDetected = false;
    let unlabeledPolygonDetected = false;

    annotatedItems.forEach((polygon) => {
      const selectedOption = polygon.selectedOptions.slice(-1)[0];
      if (!selectedOption) {
        unlabeledPolygonDetected = true;
        return;
      }
      unclosedPolygonDetected = unclosedPolygonDetected || !polygon.isClosed;
      mainLaneLabelDetected = mainLaneLabelDetected || selectedOption.value == LANE_OPTIONS.LANE;
      polygons.push({
        vertices: polygon.vertices,
        color: LANE_COLOR_MAP.get(selectedOption.value),
      });
    });

    if (unclosedPolygonDetected) {
      setWarningModalMessage(WARNING_MESSAGE.unclosedSegmentation);
      setOpenWarningModal(true);
      return null;
    }
    if (!mainLaneLabelDetected) {
      setWarningModalMessage(WARNING_MESSAGE.missingMainLane);
      setOpenWarningModal(true);
      return null;
    }
    if (unlabeledPolygonDetected) {
      setWarningModalMessage(WARNING_MESSAGE.unlabeledSegmentation);
      setOpenWarningModal(true);
      return null;
    }
    return polygons;
  };

  const generateMaskPng = (polygons) => {
    const canvas = document.createElement("canvas");
    canvas.width = MASK_PNG_DIMENSION.width;
    canvas.height = MASK_PNG_DIMENSION.height;
    const ctx = canvas.getContext("2d");

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, MASK_PNG_DIMENSION.width, MASK_PNG_DIMENSION.height);

    polygons.forEach((polygon) => {
      ctx.fillStyle = polygon.color;
      ctx.beginPath();
      polygon.vertices.forEach((vertex) => {
        ctx.lineTo(vertex.x, vertex.y);
      });
      ctx.closePath();
      ctx.fill();
    });
    return canvas.toDataURL("image/png");
  };

  const uploadMaskImage = async (imageDataUrl) => {
    try {
      const response = await uploadMaskImageToS3(accountInfo, imageDataUrl, cameraInfo);
      if (response.ok) {
        setOpenCompleteModal(true);
      } else {
        setFailureModalMessage(response.statusText);
        setOpenFailureModal(true);
      }
    } catch (error) {
      setFailureModalMessage(error);
      setOpenFailureModal(true);
    }
  };

  const onSubmit = async (event) => {
    const annotatedItems = event.annotations;
    const polygons = validateAndExtractPolygons(annotatedItems);
    if (!polygons) {
      return;
    }
    const dataUrl = generateMaskPng(polygons);
    setMaskImageUrl(dataUrl);
    await uploadMaskImage(dataUrl);
  };

  const closeAnnotationPanel = () => {
    setOpenCompleteModal(false);
    setChannels(null);
    setShowAnnotationPanel(false);
  };

  const formatedCameraName = `${cameraInfo.gateLaneInfo.site}-${cameraInfo.gateLaneInfo.gateId}-${cameraInfo.gateLaneInfo.purpose}-LANE${cameraInfo.gateLaneInfo.laneId}-${cameraInfo.cameraLocation}-${cameraInfo.cameraId}`;

  return (
    <Column>
      <Text type="h500" className="text-aligned-center" data-testid="cameraMaskTitle">
        {`${t(keys.ANNOTATION_PANEL_TITLE_PREFIX)} ${formatedCameraName}`}
      </Text>

      <Modal
        title={t(keys.ANNOTATION_INVALID_ANNOTATION_WARNING_TITLE)}
        open={openWarningModal}
        scrollContainer="viewport"
        aria-describedby="modal-description"
      >
        <Text id="modal-description">{warningModalMessage}</Text>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button
              type="primary"
              onClick={() => {
                setOpenWarningModal(false);
              }}
            >
              Ok
            </Button>
          </Row>
        </ModalFooter>
      </Modal>

      <Modal
        title={t(keys.ANNOTATION_UPLOAD_COMPLETED_TITLE)}
        open={openCompleteModal}
        scrollContainer="viewport"
        aria-describedby="modal-description"
        data-testid="completeModal"
      >
        <Text id="modal-description">{t(keys.ANNOTATION_UPLOAD_COMPLETED_MESSAGE)}</Text>
        <img src={maskImageUrl} alt="Mask Image" />
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="primary" onClick={closeAnnotationPanel}>
              Ok
            </Button>
          </Row>
        </ModalFooter>
      </Modal>

      <Modal
        title={t(keys.ANNOTATION_UPLOAD_FAILED_TITLE)}
        open={openFailureModal}
        scrollContainer="viewport"
        aria-describedby="modal-description"
      >
        <Text id="modal-description">
          {t(keys.ANNOTATION_UPLOAD_FAILED_MESSAGE_PREFIX) + failureModalMessage}
        </Text>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button
              type="primary"
              onClick={() => {
                setOpenFailureModal(false);
              }}
            >
              Ok
            </Button>
          </Row>
        </ModalFooter>
      </Modal>

      <TwoDimensionalImage
        imageWidth={MASK_PNG_DIMENSION.width}
        url={imageUrl}
        options={ANNOTATION_OPTIONS}
        isLabelOn={true}
        hasNextButton={true}
        onNextClick={onSubmit}
      />
    </Column>
  );
}
