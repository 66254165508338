import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BreadcrumbMeridian, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";

import { yardLink } from "types";
import { keys } from "i18n";

export default function Breadcrumb({ yardId, yardLabel, yardLink }) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <BreadcrumbGroup>
      <BreadcrumbMeridian data-testid="homeLink" href="/" onClick={() => navigate("/")}>
        {t(keys.HOME)}
      </BreadcrumbMeridian>
      <BreadcrumbMeridian data-testid="yardLink" href={`/yards/${yardId}/${yardLink.path}`}>
        {t(keys.YARD)} {yardLabel} {t(yardLink.text)}
      </BreadcrumbMeridian>
    </BreadcrumbGroup>
  );
}

Breadcrumb.propTypes = {
  yardId: PropTypes.string.isRequired,
  yardLabel: PropTypes.string,
  yardLink: yardLink.isRequired,
};
