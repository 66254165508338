import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Menu, { MenuItem } from "@amzn/meridian/menu";
import helpIcon from "@amzn/meridian-tokens/base/icon/help";

import { keys } from "i18n";
import { REQUEST_CHANGE_LINK } from "app-constants";

export default function HelpMenu() {
  const [shouldOpenHelpMenu, setShouldOpenHelpMenu] = useState(false);

  const buttonRef = useRef();

  const { t } = useTranslation();

  const openHelpMenu = () => setShouldOpenHelpMenu(true);
  const closeHelpMenu = () => setShouldOpenHelpMenu(false);

  return (
    <>
      <Button data-testid="helpMenuButton" ref={buttonRef} type="icon" onClick={openHelpMenu}>
        <Icon tokens={helpIcon} />
      </Button>

      <Menu
        anchorNode={buttonRef.current}
        position="bottom"
        open={shouldOpenHelpMenu}
        onClose={closeHelpMenu}
      >
        <MenuItem
          data-testid="requestChangeMenuItem"
          href={REQUEST_CHANGE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(keys.REQUEST_CHANGE)}
        </MenuItem>
      </Menu>
    </>
  );
}

HelpMenu.propTypes = {};
