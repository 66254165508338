import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import alertTokens from "@amzn/meridian-tokens/base/icon/alert";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";

import { keys } from "i18n";

export default function FileDownload({ fetching, error, url }) {
  const downloadYardDocument = (url) => window.open(url, "_blank", "noopener,noreferrer");

  const { t } = useTranslation();

  if (fetching) {
    return <Loader data-testid="loader" size="medium" />;
  }

  if (error) {
    return (
      <Row spacingInset="none none none 400">
        <Icon tokens={alertTokens} />
        <Text data-testid="errorMessage">{t(keys.SOMETHING_WENT_WRONG)}</Text>
      </Row>
    );
  }

  if (url) {
    return (
      <Button data-testid="downloadButton" type="link" onClick={() => downloadYardDocument(url)}>
        <Icon tokens={downloadLargeTokens} />
        {t(keys.DOWNLOAD_FILE)}
      </Button>
    );
  }

  return (
    <Row spacingInset="none none none 400">
      <Icon tokens={alertTokens} />
      <Text data-testid="notFoundMessage">{t(keys.NOT_FOUND)}</Text>
    </Row>
  );
}

FileDownload.propTypes = {
  fetching: PropTypes.bool,
  error: PropTypes.string,
  url: PropTypes.string,
};
