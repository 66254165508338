import { callAPI } from "store";
import { FETCH_YARD_FEATURES_SUCCESS } from "./yardFeaturesActionTypes";
import { APIs } from "app-constants";

export const fetchYardFeatures = ({ failureCallback }) => {
  return async (dispatch, getState) => {
    try {
      const responseJson = await callAPI(getState().accountInfo, APIs.LIST_SUPPORTED_YARD_FEATURES);

      dispatch(fetchYardFeaturesSuccess(responseJson));
    } catch (exception) {
      failureCallback(exception.message);
    }
  };
};

export const fetchYardFeaturesSuccess = (payload) => {
  return {
    type: FETCH_YARD_FEATURES_SUCCESS,
    payload,
  };
};
