import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Column from "@amzn/meridian/column";
import Modal from "@amzn/meridian/modal";

import AssetTypeSelect from "componentsV2/Common/Dialogs/YardDialog/AssetTypeSelect";
import DialogFooter from "componentsV2/Common/Dialogs/DialogFooter";
import HostlerSettings from "componentsV2/Common/Dialogs/YardDialog/HostlerSettings";
import LanguageSelect from "componentsV2/Common/Dialogs/YardDialog/LanguageSelect";
import YardFeatureSelect from "componentsV2/Common/Dialogs/YardDialog/YardFeatureSelect";
import YardLabelInput from "componentsV2/Common/Dialogs/YardDialog/YardLabelInput";
import YardTypeSelect from "componentsV2/Common/Dialogs/YardDialog/YardTypeSelect";
import { generateErrorMessage } from "helpers";
import { ENABLE_YARD_FEATURES_MANAGEMENT, SHOW_YARD_TYPE } from "app-constants";

export default function YardDialog({ title, genericErrorMessage, yardId, submit, dismiss }) {
  const featureFlags = useSelector((state) => state.featureFlags);
  const yardConfigInStore = useSelector((state) => state.yardConfigData)[yardId];

  const [yardConfig, setYardConfig] = useState(yardConfigInStore || {});
  const [submittable, setSubmittable] = useState({});
  const [savingYardConfig, setSavingYardConfig] = useState(false);
  const [errorSavingYardConfig, setErrorSavingYardConfig] = useState(null);

  const handleSubmit = () => {
    setSavingYardConfig(true);
    setErrorSavingYardConfig(null);

    submit({
      yardConfig,
      successCallback: dismiss,
      failureCallback: (errorMessage) => {
        setSavingYardConfig(false);
        setErrorSavingYardConfig(errorMessage);
      },
    });
  };

  const submittableConsolidated = Object.values(submittable).reduce(
    (accumulator, current) => accumulator && current,
    true
  );
  const error =
    errorSavingYardConfig && generateErrorMessage(genericErrorMessage, errorSavingYardConfig);

  return (
    <Modal width="400px" title={title} open={true}>
      <Column spacing="500">
        <Column>
          <YardLabelInput
            yardId={yardId}
            yardConfig={yardConfig}
            setYardConfig={setYardConfig}
            setSubmittable={setSubmittable}
          />

          {featureFlags[SHOW_YARD_TYPE]?.enabled && (
            <YardTypeSelect
              yardConfig={yardConfig}
              setYardConfig={setYardConfig}
              setSubmittable={setSubmittable}
            />
          )}

          <AssetTypeSelect yardId={yardId} yardConfig={yardConfig} setYardConfig={setYardConfig} />

          <LanguageSelect
            yardConfig={yardConfig}
            setYardConfig={setYardConfig}
            setSubmittable={setSubmittable}
          />

          {featureFlags[ENABLE_YARD_FEATURES_MANAGEMENT]?.enabled && (
            <YardFeatureSelect yardConfig={yardConfig} setYardConfig={setYardConfig} />
          )}

          <HostlerSettings yardConfig={yardConfig} setYardConfig={setYardConfig} />
        </Column>

        <DialogFooter
          inProgress={savingYardConfig}
          error={error}
          disabled={!submittableConsolidated}
          commit={handleSubmit}
          dismiss={dismiss}
        />
      </Column>
    </Modal>
  );
}

YardDialog.propTypes = {
  title: PropTypes.string.isRequired,
  genericErrorMessage: PropTypes.string.isRequired,
  yardId: PropTypes.string,
  submit: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
};
