import {
  EDIT_BUILDING,
  EDIT_BUILDING_SUCCESS,
  EDIT_BUILDING_FAILURE,
  CLEAR_ERROR_EDITING_BUILDING,
  CREATE_BUILDING,
  CREATE_BUILDING_SUCCESS,
  CREATE_BUILDING_FAILURE,
  CLEAR_ERROR_CREATING_BUILDING,
  DELETE_BUILDING,
  DELETE_BUILDING_SUCCESS,
  DELETE_BUILDING_FAILURE,
  CLEAR_DELETE_BUILDING_DIALOG,
} from "./editBuildingActionTypes";

export const createBuildingRequest = (yardId) => ({
  type: CREATE_BUILDING,
  payload: yardId,
});

// payload includes yardId and yardConfig
export const createBuildingSuccess = (payload) => ({
  type: CREATE_BUILDING_SUCCESS,
  payload,
});

// payload includes yardId and error
export const createBuildingFailure = (payload) => ({
  type: CREATE_BUILDING_FAILURE,
  payload,
});

export const clearErrorCreatingBuilding = (yardId) => ({
  type: CLEAR_ERROR_CREATING_BUILDING,
  payload: yardId,
});

export const editBuildingRequest = (yardId) => ({
  type: EDIT_BUILDING,
  payload: yardId,
});

// payload includes yardId and yardConfig
export const editBuildingSuccess = (payload) => ({
  type: EDIT_BUILDING_SUCCESS,
  payload,
});

// payload includes yardId and error
export const editBuildingFailure = (payload) => ({
  type: EDIT_BUILDING_FAILURE,
  payload,
});

export const clearErrorEditingBuilding = (yardId) => ({
  type: CLEAR_ERROR_EDITING_BUILDING,
  payload: yardId,
});

export const deleteBuildingRequest = (yardId) => ({
  type: DELETE_BUILDING,
  payload: yardId,
});

export const deleteBuildingSuccess = (payload) => ({
  type: DELETE_BUILDING_SUCCESS,
  payload,
});

export const deleteBuildingFailure = (payload) => ({
  type: DELETE_BUILDING_FAILURE,
  payload,
});

export const clearErrorDeletingBuilding = (payload) => ({
  type: CLEAR_DELETE_BUILDING_DIALOG,
  payload,
});
