import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { useCallback, useEffect, useRef, useState } from "react";
import KVSCamera from "./KVSCamera";
import Select, { SelectOption } from "@amzn/meridian/select";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Toggle from "@amzn/meridian/toggle";
import { keys } from "i18n";
import { useTranslation } from "react-i18next";
import { generateClientId } from "helpers";

export default function CommunicationPanel({
  yardId,
  channels,
  setshowAnnotationPanel,
  setCameraInfo,
  setAnnotationImageUrl,
}) {
  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];

  const [cameraFeed, setCameraFeed] = useState(null);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [displayMask, setDisplayMask] = useState(false);
  const autoReloadTimeout = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedCamera(null);
    setCameraFeed(null);
  }, [channels]);

  const setCameraStreamByCameraType = useCallback((cameraStream) => {
    setCameraFeed((prevCameraFeed) => {
      return {
        ...prevCameraFeed,
        cameraStream,
      };
    });
  }, []);

  const createRefreshedCameraFeed = useCallback(() => {
    if (!selectedCamera) {
      return null;
    }

    return {
      cameraStream: undefined,
      cameraType: selectedCamera.cameraType,
      configuration: {
        channelName: selectedCamera.channelName,
        channelARN: selectedCamera.channelARN,
        channelEndpoint: selectedCamera.channelEndpoint,
        iceServers: selectedCamera.iceServers,
        region: selectedCamera.channelARN.split(":")?.[3],
        siteId: selectedCamera.siteId,
        gateId: selectedCamera.gateId,
        purpose: selectedCamera.purpose,
        laneNumber: selectedCamera.laneNumber,
        cameraType: selectedCamera.cameraType,
        cameraNumber: selectedCamera.cameraNumber,
        clientId: generateClientId(),
      },
    };
  }, [selectedCamera]);

  const manuallyReloadCamera = useCallback(async () => {
    const refreshedCameraFeed = await createRefreshedCameraFeed();
    setCameraFeed(refreshedCameraFeed);
  }, [selectedCamera]);

  const autoReloadCamera = useCallback(() => {
    if (!autoReloadTimeout.current) {
      autoReloadTimeout.current = window.setTimeout(async () => {
        autoReloadTimeout.current = undefined;
        const refreshedCameraFeed = await createRefreshedCameraFeed();
        setCameraFeed(refreshedCameraFeed);
      }, 1000);
    }
  }, []);

  return (
    <div>
      {channels && (
        <>
          <Row alignmentVertical="center">
            <Select
              label={t(keys.SELECT_A_CAMERA)}
              value={!selectedCamera ? null : selectedCamera.channelName}
              onChange={(channelName) => setSelectedCamera(channels.get(channelName))}
              placeholder={channels.size ? t(keys.SELECT_A_CAMERA) : t(keys.NO_CAMERAS_FOUND)}
              width={300}
              size="medium"
              prefix=""
              helperText=""
              data-testid="selectCameraParent"
            >
              {Array.from(channels.keys()).map((key) => (
                <SelectOption value={key} label={channels.get(key).cameraType} key={key} />
              ))}
            </Select>
            <Button
              type="tertiary"
              onClick={() => manuallyReloadCamera()}
              data-testid="viewCameraButton"
            >
              {t(keys.VIEW_CAMERA)}
            </Button>
            <Toggle checked={displayMask} onChange={setDisplayMask} data-testid="toggleCamera">
              {t(keys.DISPLAY_AND_OVERLAY_MASK)}
            </Toggle>
          </Row>

          <Row
            className="box communication-panel-live-camera-section"
            alignmentHorizontal="center"
            width="100%"
            height="100%"
            spacing="300"
          >
            {cameraFeed && (
              <Column spacing="300" alignmentHorizontal="center">
                <Text type="h500" className="text-aligned-center">
                  {cameraFeed.cameraType}
                </Text>
                <KVSCamera
                  configuration={cameraFeed.configuration}
                  cameraType={cameraFeed.cameraType}
                  isThumbnail
                  onRemoteStream={setCameraStreamByCameraType}
                  showReload={true}
                  manuallyReloadKVSVideo={manuallyReloadCamera}
                  autoReloadKVSVideo={autoReloadCamera}
                  siteId={cameraFeed.configuration.siteId}
                  displayMask={displayMask}
                  setDisplayMask={setDisplayMask}
                  setshowAnnotationPanel={setshowAnnotationPanel}
                  setCameraInfo={setCameraInfo}
                  setAnnotationImageUrl={setAnnotationImageUrl}
                />
              </Column>
            )}
          </Row>
        </>
      )}
    </div>
  );
}

CommunicationPanel.propTypes = {
  yardId: PropTypes.string.isRequired,
  channels: PropTypes.object.isRequired,
};
