import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";

import HelpMenu from "componentsV2/Common/Headers/HelpMenu";
import LanguagePicker from "componentsV2/Common/Headers/LanguagePicker";
import { APP_NAME } from "app-constants";

export default function Headers({ children }) {
  return (
    <Column>
      <Row alignmentVertical="bottom" alignmentHorizontal="justify">
        <Heading data-testid="appNameHeading" level={2}>
          {APP_NAME}
        </Heading>

        <Row spacing="200" alignmentHorizontal="right">
          <HelpMenu />
          <LanguagePicker />
        </Row>
      </Row>

      {children && children}
    </Column>
  );
}

Headers.propTypes = {};
