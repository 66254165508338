import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Alert from "@amzn/meridian/alert";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";

import ItemList from "componentsV2/Common/ItemList";
import { fetchYardFeatures } from "store";
import { keys } from "i18n";
import { STATUS_TYPES } from "app-constants";

export default function YardFeatureSelect({ yardConfig, setYardConfig }) {
  const yardFeatures = yardConfig.yardFeatures || [];

  const { yardFeatures: eligibleYardFeatures } = useSelector((state) => state.yardFeaturesData);

  const [errorFetchingEligibleYardFeatures, setErrorFetchingEligibleYardFeatures] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!eligibleYardFeatures) {
      dispatch(fetchYardFeatures({ failureCallback: setErrorFetchingEligibleYardFeatures }));
    }
  }, [eligibleYardFeatures]);

  const selectYardFeature = (yardFeature) =>
    setYardConfig({ ...yardConfig, yardFeatures: [...yardFeatures, yardFeature] });
  const removeYardFeature = (yardFeatureToRemove) =>
    setYardConfig({
      ...yardConfig,
      yardFeatures: yardFeatures.filter((yardFeature) => yardFeature !== yardFeatureToRemove),
    });

  const { t } = useTranslation();

  return (
    <Column>
      <Select
        data-testid="yardFeatureSelect"
        label={t(keys.YARD_FEATURE)}
        placeholder={t(keys.SELECT_YARD_FEATURE)}
        value={""}
        disabled={!!errorFetchingEligibleYardFeatures}
        onChange={selectYardFeature}
      >
        {!eligibleYardFeatures && (
          <Row spacingInset="medium" alignmentHorizontal="center">
            <Loader />
          </Row>
        )}
        {eligibleYardFeatures
          ?.filter((yardFeature) => !yardFeatures.includes(yardFeature))
          .map((yardFeature) => (
            <SelectOption
              data-testid={yardFeature}
              key={yardFeature}
              value={yardFeature}
              label={t(yardFeature)}
            />
          ))}
      </Select>

      <ItemList items={yardFeatures} formatItem={t} removeItem={removeYardFeature} />

      {errorFetchingEligibleYardFeatures && (
        <Alert size="small" type={STATUS_TYPES.ERROR}>
          {t(keys.FETCH_YARD_FEATURES_ERROR_MESSAGE)}
        </Alert>
      )}
    </Column>
  );
}

YardFeatureSelect.propTypes = {
  yardConfig: PropTypes.object.isRequired,
  setYardConfig: PropTypes.func.isRequired,
};
