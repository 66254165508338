import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";

import SelfRemoveBuildingDialog from "component/BuildingDialogs/SelfRemoveBuildingDialog";
import { keys } from "i18n";

export default function RemoveBuildingAction({ yardId, buildingCode }) {
  const [shouldShowSelfRemoveBuildingDialog, setShouldShowSelfRemoveBuildingDialog] =
    useState(false);

  const { permissions } = useSelector((state) => state.permissionsData);
  const { version } = useSelector((state) => state.yardConfigData)[yardId];

  const { t } = useTranslation();

  const openSelfRemoveBuildingDialog = () => setShouldShowSelfRemoveBuildingDialog(true);
  const closeSelfRemoveBuildingDialog = () => setShouldShowSelfRemoveBuildingDialog(false);

  const canEdit = !!permissions.canEdit;

  return (
    <>
      <Row alignmentHorizontal="end">
        <Button
          data-testid="removeBuildingButton"
          type="secondary"
          disabled={!canEdit}
          onClick={openSelfRemoveBuildingDialog}
        >
          {t(keys.REMOVE_BUILDING)}
        </Button>
      </Row>

      {shouldShowSelfRemoveBuildingDialog && (
        <SelfRemoveBuildingDialog
          yardId={yardId}
          buildingCode={buildingCode}
          version={version}
          dismiss={closeSelfRemoveBuildingDialog}
        />
      )}
    </>
  );
}

RemoveBuildingAction.propTypes = {
  yardId: PropTypes.string.isRequired,
  buildingCode: PropTypes.string.isRequired,
};
