import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Alert from "@amzn/meridian/alert";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";

import ItemList from "componentsV2/Common/ItemList";
import { fetchBuildingFeatures } from "store";
import { keys } from "i18n";
import { STATUS_TYPES } from "app-constants";

export default function BuildingFeatureSelect({ buildingFeatures, setBuildingFeatures }) {
  const { buildingFeatures: eligibleBuildingFeatures } = useSelector(
    (state) => state.buildingFeaturesData
  );

  const [errorFetchingEligibleBuildingFeatures, setErrorFetchingEligibleBuildingFeatures] =
    useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!eligibleBuildingFeatures) {
      dispatch(
        fetchBuildingFeatures({ failureCallback: setErrorFetchingEligibleBuildingFeatures })
      );
    }
  }, [eligibleBuildingFeatures]);

  const selectBuildingFeature = (buildingFeature) =>
    setBuildingFeatures([...buildingFeatures, buildingFeature]);
  const removeBuildingFeature = (buildingFeatureToRemove) =>
    setBuildingFeatures(
      buildingFeatures.filter((buildingFeature) => buildingFeature !== buildingFeatureToRemove)
    );

  const { t } = useTranslation();

  return (
    <Column>
      <Select
        data-testid="buildingFeatureSelect"
        label={t(keys.BUILDING_FEATURE)}
        placeholder={t(keys.SELECT_BUILDING_FEATURE)}
        value={""}
        disabled={!!errorFetchingEligibleBuildingFeatures}
        onChange={selectBuildingFeature}
      >
        {!eligibleBuildingFeatures && (
          <Row spacingInset="medium" alignmentHorizontal="center">
            <Loader />
          </Row>
        )}
        {eligibleBuildingFeatures
          ?.filter((buildingFeature) => !buildingFeatures.includes(buildingFeature))
          .map((buildingFeature) => (
            <SelectOption
              data-testid={buildingFeature}
              key={buildingFeature}
              value={buildingFeature}
              label={t(buildingFeature)}
            />
          ))}
      </Select>

      <ItemList items={buildingFeatures} formatItem={t} removeItem={removeBuildingFeature} />

      {errorFetchingEligibleBuildingFeatures && (
        <Alert size="small" type={STATUS_TYPES.ERROR}>
          {t(keys.FETCH_BUILDING_FEATURES_ERROR_MESSAGE)}
        </Alert>
      )}
    </Column>
  );
}

BuildingFeatureSelect.propTypes = {
  buildingFeatures: PropTypes.object.isRequired,
  setBuildingFeatures: PropTypes.func.isRequired,
};
