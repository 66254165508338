import { callAPI } from "store";
import {
  FETCH_YARD_DEVICES_SUCCESS,
  ADD_YARD_DEVICE_SUCCESS,
  MOVE_YARD_DEVICE_SUCCESS,
  REMOVE_YARD_DEVICE_SUCCESS,
} from "./devicesActionTypes";
import { APIs } from "app-constants";

export const fetchYardDevices = (
  { yardIdentifier: { yardId, buildingCodes } },
  successCallback,
  failureCallback
) => {
  return async (dispatch, getState) => {
    const accountInfo = getState().accountInfo;

    try {
      const responseJson = await callAPI(accountInfo, APIs.LIST_SUPPORTED_DEVICES, {
        yardId: { yardId, buildingCode: buildingCodes[0] },
      });

      dispatch(fetchYardDevicesSuccess({ yardId, ...responseJson }));
      successCallback && successCallback();
    } catch (exception) {
      failureCallback && failureCallback(exception.message);
    }
  };
};

export const addYardDevice = (
  { yardIdentifier: { yardId, buildingCodes }, imei },
  successCallback,
  failureCallback
) => {
  return async (dispatch, getState) => {
    const accountInfo = getState().accountInfo;

    try {
      const { yardLabel } = await callAPI(accountInfo, APIs.ADD_DEVICE, {
        yardId: { yardId, buildingCode: buildingCodes[0] },
        device: { imei },
      });

      if (yardLabel) {
        throw new Error(`Device ${imei} already exists in ${yardLabel}.`);
      }

      dispatch(addYardDeviceSuccess({ yardId, imei }));
      successCallback && successCallback();
    } catch (exception) {
      failureCallback && failureCallback(exception.message);
    }
  };
};

export const moveYardDevice = (
  { yardIdentifier: { yardId, buildingCodes }, targetYardIdentifier, imei },
  successCallback,
  failureCallback
) => {
  return async (dispatch, getState) => {
    const accountInfo = getState().accountInfo;

    try {
      if (yardId === targetYardIdentifier.yardId) {
        throw new Error("Target yard is the same as current yard.");
      }

      await callAPI(accountInfo, APIs.UPDATE_DEVICE, {
        yardId: { yardId, buildingCode: buildingCodes[0] },
        targetYardCode: targetYardIdentifier.buildingCodes[0],
        device: { imei },
      });

      dispatch(moveYardDeviceSuccess({ yardId, targetYardId: targetYardIdentifier.yardId, imei }));
      successCallback && successCallback();
    } catch (exception) {
      failureCallback && failureCallback(exception.message);
    }
  };
};

export const removeYardDevice = (
  { yardIdentifier: { yardId, buildingCodes }, imei },
  successCallback,
  failureCallback
) => {
  return async (dispatch, getState) => {
    const accountInfo = getState().accountInfo;

    try {
      await callAPI(accountInfo, APIs.DELETE_DEVICE, {
        yardId: { yardId, buildingCode: buildingCodes[0] },
        device: { imei },
      });

      dispatch(removeYardDeviceSuccess({ yardId, imei }));
      successCallback && successCallback();
    } catch (exception) {
      failureCallback && failureCallback(exception.message);
    }
  };
};

export const fetchYardDevicesSuccess = (payload) => {
  return {
    type: FETCH_YARD_DEVICES_SUCCESS,
    payload,
  };
};

export const addYardDeviceSuccess = (payload) => {
  return {
    type: ADD_YARD_DEVICE_SUCCESS,
    payload,
  };
};

export const moveYardDeviceSuccess = (payload) => {
  return {
    type: MOVE_YARD_DEVICE_SUCCESS,
    payload,
  };
};

export const removeYardDeviceSuccess = (payload) => {
  return {
    type: REMOVE_YARD_DEVICE_SUCCESS,
    payload,
  };
};
