import React from "react";
import PropTypes from "prop-types";
import { compose } from "lodash/fp";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Modal from "@amzn/meridian/modal";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import Loader from "@amzn/meridian/loader";
import Alert from "@amzn/meridian/alert";

import {
  deleteBuildingRequest,
  deleteBuildingSuccess,
  deleteBuildingFailure,
  clearErrorDeletingBuilding,
  callAPI,
} from "store";
import { APIs } from "app-constants";
import { keys } from "i18n";

class SelfRemoveBuildingDialog extends React.PureComponent {
  state = {
    devicesRemovedCheck: false,
    equipmentsRemovedCheck: false,
    appointmentsRemovedCheck: false,
    recreate: false,
    disableAll: false,
    isLastBuilding: false,
  };

  componentDidMount() {
    const { yardId, yardConfigData } = this.props;
    this.setState({
      isLastBuilding: yardConfigData[yardId].buildings.length === 1,
    });
  }

  componentWillUnmount() {
    const { yardId, clearErrorDeletingBuilding } = this.props;
    clearErrorDeletingBuilding({ yardId });
  }

  submit = async () => {
    const {
      accountInfo,
      version,
      yardId,
      buildingCode,
      deleteBuildingRequest,
      deleteBuildingSuccess,
      deleteBuildingFailure,
    } = this.props;
    const { recreate } = this.state;

    deleteBuildingRequest({ yardId });
    this.setState({ disableAll: true });

    try {
      const responseJson = await callAPI(accountInfo, APIs.DELETE_BUILDING, {
        version,
        buildingCode,
        recreate,
      });

      this.setState({ disableAll: false });
      deleteBuildingSuccess({ yardId, deletingBuildingResponse: responseJson });
    } catch (exception) {
      let errorDeletingBuilding = "";
      try {
        errorDeletingBuilding = JSON.parse(exception.message).message;
      } catch (e) {
        errorDeletingBuilding = exception.message;
      } finally {
        deleteBuildingFailure({ yardId, errorDeletingBuilding });
      }
    }
  };

  render() {
    const { t, yardId, yardConfigData, dismiss } = this.props;
    const {
      devicesRemovedCheck,
      equipmentsRemovedCheck,
      appointmentsRemovedCheck,
      recreate,
      disableAll,
      isLastBuilding,
    } = this.state;

    const deletingBuilding =
      yardId && yardConfigData[yardId] && yardConfigData[yardId].deletingBuilding;
    const successDelete =
      yardId && yardConfigData[yardId] && yardConfigData[yardId].successDeletingBuilding;
    const errorDelete =
      yardId && yardConfigData[yardId] && yardConfigData[yardId].errorDeletingBuilding;
    const disableRemoveButton = isLastBuilding
      ? deletingBuilding ||
        !devicesRemovedCheck ||
        !equipmentsRemovedCheck ||
        (!appointmentsRemovedCheck && !recreate)
      : deletingBuilding || (!appointmentsRemovedCheck && !recreate);

    return (
      <Modal width="650px" bodySpacingInset="medium" title={t(keys.REMOVE_BUILDING)} open={true}>
        <Column heights={"fit"} alignmentHorizontal="left" spacing="medium">
          {successDelete && (
            <Row>
              <Alert size="medium" type="success" width="570px">
                {t(keys.REMOVE_BUILDING_SUCCESS_MESSAGE)}
              </Alert>
            </Row>
          )}
          {errorDelete && (
            <Row>
              <Alert size="medium" type="error" width="570px">
                {[
                  t(keys.REMOVE_BUILDING_ERROR_MESSAGE),
                  yardConfigData[yardId].errorDeletingBuilding,
                ].join(" ")}
              </Alert>
            </Row>
          )}
          <Column spacing="300">
            <Row>
              <Text type="b300">
                {isLastBuilding &&
                  `${t(keys.REMOVE_BUILDING_SELF_INSTRUCTION_ONE_PREFIX)} ${t(
                    keys.REMOVE_BUILDING_SELF_INSTRUCTION
                  )}`}
              </Text>
            </Row>
            <Row spacingInset="none medium">
              <Checkbox
                checked={appointmentsRemovedCheck}
                disabled={recreate || disableAll}
                onChange={() =>
                  this.setState({
                    appointmentsRemovedCheck: !appointmentsRemovedCheck,
                  })
                }
              >
                {t(keys.REMOVE_BUILDING_SELF_INSTRUCTION_APPOINTMENTS)}
              </Checkbox>
            </Row>
            <Row spacingInset="none large">
              <Checkbox
                checked={recreate}
                disabled={disableAll}
                onChange={() => this.setState({ recreate: !recreate })}
              >
                {t(keys.REMOVE_BUILDING_SELF_INSTRUCTION_RECREATE)}
              </Checkbox>
            </Row>
            {isLastBuilding && (
              <Row spacingInset="none medium">
                <Checkbox
                  checked={devicesRemovedCheck}
                  disabled={disableAll}
                  onChange={() => this.setState({ devicesRemovedCheck: !devicesRemovedCheck })}
                >
                  {t(keys.REMOVE_BUILDING_SELF_INSTRUCTION_DEVICES)}
                </Checkbox>
              </Row>
            )}
            {isLastBuilding && (
              <Row spacingInset="none medium">
                <Checkbox
                  checked={equipmentsRemovedCheck}
                  disabled={disableAll}
                  onChange={() =>
                    this.setState({
                      equipmentsRemovedCheck: !equipmentsRemovedCheck,
                    })
                  }
                >
                  {t(keys.REMOVE_BUILDING_SELF_INSTRUCTION_EQUIPMENT)}
                </Checkbox>
              </Row>
            )}
          </Column>
          <Row>
            <Text type="b300">{t(keys.REMOVE_BUILDING_VERIFICATION)}</Text>
          </Row>
        </Column>
        {deletingBuilding && (
          <Row alignmentHorizontal="right" spacingInset="large none none none">
            <Loader size="medium" />
          </Row>
        )}
        {!deletingBuilding && (
          <Row alignmentHorizontal="right" spacingInset="large none none none">
            <Button type="secondary" minWidth="90px" onClick={dismiss}>
              {t(keys.CLOSE)}
            </Button>
            <Button
              type="primary"
              minWidth="90px"
              disabled={disableRemoveButton || errorDelete || successDelete}
              onClick={this.submit}
            >
              {t(keys.REMOVE_BUILDING)}
            </Button>
          </Row>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo,
  yardConfigData: state.yardConfigData,
});

const mapDispatchToProps = (dispatch) => ({
  deleteBuildingRequest: (payload) => {
    dispatch(deleteBuildingRequest(payload));
  },
  deleteBuildingSuccess: (payload) => {
    dispatch(deleteBuildingSuccess(payload));
  },
  deleteBuildingFailure: (payload) => {
    dispatch(deleteBuildingFailure(payload));
  },
  clearErrorDeletingBuilding: (payload) => {
    dispatch(clearErrorDeletingBuilding(payload));
  },
});

const withTranslationsAndMapStateAndDispatchToProps = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
);

export default withTranslationsAndMapStateAndDispatchToProps(SelfRemoveBuildingDialog);

SelfRemoveBuildingDialog.propTypes = {
  version: PropTypes.number,
  yardId: PropTypes.string,
  buildingCode: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
};
