import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { withTranslation } from "react-i18next";

import Modal from "@amzn/meridian/modal";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Checkbox from "@amzn/meridian/checkbox";
import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Select, { SelectOption } from "@amzn/meridian/select";
import plusTokens from "@amzn/meridian-tokens/base/icon/plus";
import Icon from "@amzn/meridian/icon";
import minusTokens from "@amzn/meridian-tokens/base/icon/minus";

import {
  LOCATION_TABLE,
  SHOW_AUDIT_MODE_SEARCH_FEATURE,
  ENABLE_MULTIZONE_MAPPING,
  SHOW_OFFSITE_BUILDING_CODE,
  STATUS_TYPES,
} from "app-constants";
import {
  equalsIgnoreCase,
  zipWithFlattenedKeys,
  getUniqueZoneSet,
  getNewZones,
  getZoneList,
  getTempId,
} from "helpers";
import { keys } from "i18n";
import Tag from "@amzn/meridian/tag";

const prepareFields = (featureFlags) => {
  // Nested fields are automatically handled by Formik, uses lodash path syntax
  // Can add any desired field name for convenience when referencing
  const additionalFields = {};

  // TODO: When auditModeSearch is enabled in all regions, move auditModeSearch directly into additionalFields
  //       e.g. additionalFields = { auditModeSearch: "auditFeatures.auditModeSearch" }
  if (featureFlags[SHOW_AUDIT_MODE_SEARCH_FEATURE]?.enabled) {
    additionalFields["auditModeSearch"] = "auditFeatures.auditModeSearch";
  }

  if (featureFlags[SHOW_OFFSITE_BUILDING_CODE]?.enabled) {
    additionalFields["offsiteBuildingCode"] = "offsiteBuildingCode";
  }

  return Object.assign(zipWithFlattenedKeys(LOCATION_TABLE.keys), additionalFields);
};

class LocationDialog extends React.PureComponent {
  state = {
    isLocationLabelUsed: false,
    fields: prepareFields(this.props.featureFlags),
    showInputZoneField: false,
    zoneList: getZoneList(this.props.location),
    inputZoneList: [""],
    useDefaultAuditModeSearchValue: !this.props.location,
    isValidOffsiteBuildingCode: true,
  };

  getInitialValues = () => ({
    // Give temp id for locations added directly with the UI (https://sim.amazon.com/issues/Yard-3453)
    id: getTempId(),
    label: "",
    hasDockDoor: false,
    inbound: false,
    outbound: false,
    offsiteLocation: false,
    screenOrder: "",
    auditOrder: "",
    zone: "",
    nonInventory: false,
    sortable: false,
    capacity: 1,
    multiCapacity: false,
    auditFeatures: {
      auditModeSearch: null,
    },
    zoneList: [],
    offsiteBuildingCode: "",
    disabled: false,
  });

  validate = (values) => {
    const { t } = this.props;
    const labelError = !values.label;
    const screenOrderError = !values.screenOrder;
    const auditOrderError = !values.auditOrder;
    const capacityError =
      parseInt(values.capacity) < 0 || (parseInt(values.capacity) > 1 && !values.multiCapacity);
    return {
      ...(labelError && { label: t(keys.REQUIRED_ERROR_MESSAGE) }),
      ...(screenOrderError && {
        screenOrder: t(keys.REQUIRED_ERROR_MESSAGE),
      }),
      ...(auditOrderError && {
        auditOrder: t(keys.REQUIRED_ERROR_MESSAGE),
      }),
      ...(capacityError && {
        capacity: t(keys.CAPACITY_MULTI_CAPACITY_MISMATCH_ERROR_MESSAGE),
      }),
    };
  };

  handleChange = (setFieldValue, fieldName) => (fieldValue) => {
    setFieldValue(fieldName, fieldValue);
    const { locations, buildings } = this.props;

    if (fieldName === "label" && fieldValue) {
      const isLocationLabelUsed = locations.some((location) =>
        equalsIgnoreCase(location.label, fieldValue)
      );
      this.setState({ isLocationLabelUsed });
    }

    // validate offsite building code
    if (fieldName === "offsiteBuildingCode" && typeof fieldValue === "string") {
      const isValidOffsiteBuildingCode =
        buildings.some((building) => equalsIgnoreCase(building.buildingCode, fieldValue)) ||
        isEmpty(fieldValue);
      this.setState({ isValidOffsiteBuildingCode });
    }

    if (fieldName === "auditFeatures.auditModeSearch" && fieldValue) {
      if (this.state.useDefaultAuditModeSearchValue) {
        this.setState({ useDefaultAuditModeSearchValue: false });
      }
    }
  };

  format = (values) => ({
    ...values,
    // when capacity is number string -> parseInt
    // when it's empty string -> null
    capacity: values.capacity ? parseInt(values.capacity) : null,
    zoneList: [...this.state.zoneList, ...getNewZones(this.state.inputZoneList)],
    offsiteBuildingCode: isEmpty(values.offsiteBuildingCode) ? null : values.offsiteBuildingCode,
  });

  renderLocationLabelAvailabilityCheck = (currentLabel) => {
    const { t } = this.props;
    const { isLocationLabelUsed } = this.state;

    const previousLabel = this.props.location?.label;
    const isLocationLabelUnchanged = previousLabel && equalsIgnoreCase(currentLabel, previousLabel);

    if (!currentLabel || isLocationLabelUnchanged) {
      return null;
    }

    return isLocationLabelUsed ? (
      <Alert size="small" type="error">
        {t(keys.LABEL_USED)}
      </Alert>
    ) : (
      <Alert size="small" type="success">
        {t(keys.LABEL_AVAILABLE)}
      </Alert>
    );
  };

  renderIsValidOffsiteBuildingCodeCheck = (currentOffsiteBuildingCode) => {
    const { t } = this.props;
    const { isValidOffsiteBuildingCode } = this.state;

    const previousOffsiteBuildingCode = this.props.location?.offsiteBuildingCode;

    const isOffsiteBuildingCodeUnchanged =
      previousOffsiteBuildingCode &&
      equalsIgnoreCase(currentOffsiteBuildingCode, previousOffsiteBuildingCode);

    if (!currentOffsiteBuildingCode || isOffsiteBuildingCodeUnchanged) {
      return null;
    }

    return isValidOffsiteBuildingCode ? (
      <Alert size="small" type="success">
        {t(keys.OFFSITE_BUILDING_CODE_VALID)}
      </Alert>
    ) : (
      <Alert size="small" type="error">
        {t(keys.OFFSITE_BUILDING_CODE_INVALID)}
      </Alert>
    );
  };

  handleChangeEventForInputZoneField = (zoneValue, index) => {
    const list = [...this.state.inputZoneList];
    list[index] = zoneValue;
    this.setState({ inputZoneList: list });
  };

  handleRemoveZoneButtonClick = (index) => {
    const list = [...this.state.inputZoneList];
    list.splice(index, 1);
    if (this.state.inputZoneList.length === 1) {
      this.setState({
        showInputZoneField: !this.state.showInputZoneField,
        inputZoneList: [""],
      });
    } else {
      this.setState({ inputZoneList: list });
    }
  };

  handleAddZoneButtonClick = () => {
    this.setState({
      inputZoneList: [...this.state.inputZoneList, ""],
    });
  };

  toggleInputShowFlags = () => {
    this.setState({ showInputZoneField: !this.state.showInputZoneField });
  };

  prepareAuditModeSearchValue = (
    values,
    useDefault = this.state.useDefaultAuditModeSearchValue
  ) => {
    const currentValue = values.auditFeatures.auditModeSearch;

    const isMultiCapacity = values.multiCapacity;
    const isOffsiteLocation = values.offsiteLocation;

    // Set to multiCapacity and offsite default if value was not yet touched, remove value if no longer appropriate to default
    if (useDefault) {
      if (isMultiCapacity && isOffsiteLocation) {
        return true;
      }
      if (currentValue) {
        return null;
      }
    }

    // Set to false if value has been touched and multiCapacity is no longer enabled (field is disabled)
    if (!useDefault && !isMultiCapacity) {
      return false;
    }

    return currentValue;
  };

  prepareAuditSearchModeDependentFieldChange = (values, fieldName, fieldValue) => {
    const { featureFlags } = this.props;
    const valuesWithModifiedField = { ...values, [fieldName]: fieldValue };

    // Remove this check when auditModeSearch has been enabled for all regions
    if (!featureFlags[SHOW_AUDIT_MODE_SEARCH_FEATURE]?.enabled) {
      return valuesWithModifiedField;
    }

    return {
      ...valuesWithModifiedField,
      auditFeatures: {
        auditModeSearch: this.prepareAuditModeSearchValue(valuesWithModifiedField),
      },
    };
  };

  render() {
    const { t, location, add, edit, dismiss, featureFlags } = this.props;
    const { fields } = this.state;
    const UNIQUE_ZONES = getUniqueZoneSet(this.props.locations);
    const enableMultiZone = featureFlags[ENABLE_MULTIZONE_MAPPING]?.enabled;
    const showOffsiteBuildingCode = featureFlags[SHOW_OFFSITE_BUILDING_CODE]?.enabled;

    return (
      <Modal
        width="400px"
        bodySpacingInset="medium"
        title={location ? t(keys.EDIT_LOCATION) : t(keys.ADD_LOCATION)}
        open={true}
      >
        <Formik
          initialValues={location || this.getInitialValues()}
          // invalidate form on initialization for adding location
          initialErrors={!location && this.validate(this.getInitialValues())}
          validate={this.validate}
          onSubmit={(values) => {
            location ? edit(this.format(values)) : add(this.format(values));
            dismiss();
          }}
        >
          {({ values, errors, isValid, setFieldValue, setValues, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Column spacing="small" spacingInset="none none small none">
                {values.disabled && (
                  <Row>
                    <Tag data-testid="disabledTag" type={STATUS_TYPES.ERROR}>
                      {t(keys.DISABLED)}
                    </Tag>
                  </Row>
                )}
                <Row>
                  <Input
                    width="69%"
                    name={fields.label}
                    type="text"
                    label={`${t(keys.LOCATION_LABEL)}*`}
                    value={values.label}
                    onChange={this.handleChange(setFieldValue, fields.label)}
                  />
                  {this.renderLocationLabelAvailabilityCheck(values.label)}
                </Row>
                <Row>
                  <Input
                    name={fields.screenOrder}
                    type="text"
                    label={`${t(keys.LOCATION_SCREEN_ORDER)}*`}
                    value={values.screenOrder}
                    onChange={this.handleChange(setFieldValue, fields.screenOrder)}
                  />
                  <Input
                    name={fields.auditOrder}
                    type="text"
                    label={`${t(keys.LOCATION_AUDIT_ORDER)}*`}
                    value={values.auditOrder}
                    onChange={this.handleChange(setFieldValue, fields.auditOrder)}
                  />
                </Row>
                {!enableMultiZone && (
                  <Input
                    name={fields.zone}
                    type="text"
                    label={t(keys.LOCATION_ZONE)}
                    value={values.zone}
                    onChange={this.handleChange(setFieldValue, fields.zone)}
                  />
                )}
                {enableMultiZone && (
                  <>
                    <Row widths={["88%", "12%"]}>
                      <Select
                        value={this.state.zoneList}
                        onChange={(value) => {
                          this.setState({ zoneList: value });
                          //resetting the zone value to null when zonelist has no value selected.
                          if (value.length < 1) {
                            setFieldValue(fields.zone, "");
                          }
                        }}
                        placeholder="Select zones..."
                        size="large"
                        label={`${t(keys.LOCATION_ZONES)}`}
                      >
                        {[...UNIQUE_ZONES].map((zone) => (
                          <SelectOption key={zone} value={zone} label={zone} />
                        ))}
                      </Select>
                      {!this.state.showInputZoneField && (
                        <Button onClick={() => this.toggleInputShowFlags()} type="link">
                          <Icon tokens={plusTokens} />
                        </Button>
                      )}
                    </Row>

                    {this.state.showInputZoneField &&
                      this.state.inputZoneList.map((zone, i) => {
                        return (
                          <Row key={i} widths={["76%", "12%", "12%"]}>
                            <Input
                              type="text"
                              name="zone"
                              placeholder="Enter Zone"
                              value={zone}
                              onChange={(zoneValue) =>
                                this.handleChangeEventForInputZoneField(zoneValue, i)
                              }
                            />
                            {this.state.inputZoneList.length - 1 === i && (
                              <Button onClick={() => this.handleAddZoneButtonClick()} type="link">
                                <Icon tokens={plusTokens} />
                              </Button>
                            )}
                            <Button onClick={() => this.handleRemoveZoneButtonClick(i)} type="link">
                              <Icon tokens={minusTokens} />
                            </Button>
                          </Row>
                        );
                      })}
                  </>
                )}
                <Row widths={["88%"]}>
                  {showOffsiteBuildingCode && values.offsiteLocation && (
                    <Input
                      name={fields.offsiteBuildingCode}
                      type="text"
                      label={t(keys.LOCATION_OFFSITE_BUILDING_CODE)}
                      value={values.offsiteBuildingCode}
                      onChange={this.handleChange(setFieldValue, fields.offsiteBuildingCode)}
                      disabled={!values.offsiteLocation}
                    />
                  )}
                </Row>
                <Row>
                  {showOffsiteBuildingCode &&
                    this.renderIsValidOffsiteBuildingCodeCheck(values.offsiteBuildingCode)}
                </Row>
                <Row widths={["50%", "50%"]}>
                  <Checkbox
                    name={fields.hasDockDoor}
                    checked={values.hasDockDoor}
                    onChange={this.handleChange(setFieldValue, fields.hasDockDoor)}
                  >
                    {t(keys.LOCATION_DOCK_DOOR)}
                  </Checkbox>
                  <Checkbox
                    name={fields.offsiteLocation}
                    checked={values.offsiteLocation}
                    onChange={(fieldValue) => {
                      setValues(
                        this.prepareAuditSearchModeDependentFieldChange(
                          values,
                          fields.offsiteLocation,
                          fieldValue
                        )
                      );
                    }}
                  >
                    {t(keys.LOCATION_OFFSITE)}
                  </Checkbox>
                </Row>

                <Row widths={["50%", "50%"]}>
                  <Checkbox
                    name={fields.inbound}
                    checked={values.inbound}
                    onChange={this.handleChange(setFieldValue, fields.inbound)}
                  >
                    {t(keys.LOCATION_INBOUND)}
                  </Checkbox>
                  <Checkbox
                    name={fields.outbound}
                    checked={values.outbound}
                    onChange={this.handleChange(setFieldValue, fields.outbound)}
                  >
                    {t(keys.LOCATION_OUTBOUND)}
                  </Checkbox>
                </Row>

                <Row widths={["50%", "50%"]}>
                  <Checkbox
                    name={fields.nonInventory}
                    checked={values.nonInventory}
                    onChange={this.handleChange(setFieldValue, fields.nonInventory)}
                  >
                    {t(keys.LOCATION_NON_INVENTORY)}
                  </Checkbox>
                  <Checkbox
                    name={fields.sortable}
                    checked={values.sortable}
                    onChange={this.handleChange(setFieldValue, fields.sortable)}
                  >
                    {t(keys.LOCATION_SORTABLE)}
                  </Checkbox>
                </Row>

                {featureFlags[SHOW_AUDIT_MODE_SEARCH_FEATURE]?.enabled && (
                  <Row>
                    <Checkbox
                      name={fields.auditModeSearch}
                      checked={values.auditFeatures?.auditModeSearch}
                      onChange={this.handleChange(setFieldValue, fields.auditModeSearch)}
                      disabled={!values.multiCapacity}
                    >
                      {t(keys.AUDIT_MODE_SEARCH)}
                    </Checkbox>
                  </Row>
                )}

                <Row widths={["50%", "50%"]}>
                  <Input
                    name={fields.capacity}
                    pattern={/^\d+$/}
                    label={t(keys.LOCATION_CAPACITY)}
                    value={values.capacity}
                    onChange={(fieldValue) => {
                      setValues(
                        this.prepareAuditSearchModeDependentFieldChange(
                          values,
                          fields.capacity,
                          fieldValue
                        )
                      );
                    }}
                    error={!!errors.capacity}
                  />
                  <Checkbox
                    name={fields.multiCapacity}
                    checked={values.multiCapacity}
                    onChange={(fieldValue) => {
                      setValues(
                        this.prepareAuditSearchModeDependentFieldChange(
                          values,
                          fields.multiCapacity,
                          fieldValue
                        )
                      );
                    }}
                  >
                    {t(keys.LOCATION_MULTI_CAPACITY)}
                  </Checkbox>
                </Row>
                {errors.capacity && (
                  <Alert size="small" type="error">
                    {errors.capacity}
                  </Alert>
                )}
              </Column>

              <Row alignmentHorizontal="right">
                <Button type="secondary" minWidth="80px" onClick={dismiss}>
                  {t(keys.CANCEL)}
                </Button>
                <Button
                  type="primary"
                  minWidth="80px"
                  disabled={
                    !isValid ||
                    this.state.isLocationLabelUsed ||
                    !this.state.isValidOffsiteBuildingCode
                  }
                  onClick={handleSubmit}
                >
                  {location ? t(keys.CLOSE) : t(keys.ADD)}
                </Button>
              </Row>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default withTranslation()(LocationDialog);

LocationDialog.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.object, // not required as is null when adding location
  featureFlags: PropTypes.object.isRequired,
  add: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
};
