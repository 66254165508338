import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { fetchYardConfig } from "store";
import { FEATURES } from "app-constants";

export function useYardConfigInit(yardId) {
  const dispatch = useDispatch();

  const { activeAccountName } = useSelector((state) => state.accountInfo);
  const featureFlags = useSelector((state) => state.featureFlags);
  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];

  const fetchingFeatureFlags = FEATURES.some(
    (feature) => isEmpty(featureFlags[feature]) || featureFlags[feature].fetching
  );
  const { version } = yardConfig || {};

  useEffect(() => {
    if (activeAccountName && !fetchingFeatureFlags && !version) {
      dispatch(fetchYardConfig({ yardId }));
    }
  }, [activeAccountName, fetchingFeatureFlags]);
}
