import {
  FETCH_YARD_CONFIG_INITIALIZATION,
  FETCH_YARD_CONFIG_SUCCESS,
  FETCH_YARD_CONFIG_FAILURE,
  CLEAR_YARD_CONFIG_DATA,
} from "./yardConfigDataActionTypes";
import { CREATE_YARD_SUCCESS, EDIT_YARD_SUCCESS, DELETE_YARD_SUCCESS } from "./editYardActionTypes";
import {
  CREATE_BUILDING,
  CREATE_BUILDING_SUCCESS,
  CREATE_BUILDING_FAILURE,
  CLEAR_ERROR_CREATING_BUILDING,
  EDIT_BUILDING,
  EDIT_BUILDING_SUCCESS,
  EDIT_BUILDING_FAILURE,
  CLEAR_ERROR_EDITING_BUILDING,
  DELETE_BUILDING,
  DELETE_BUILDING_SUCCESS,
  DELETE_BUILDING_FAILURE,
  CLEAR_DELETE_BUILDING_DIALOG,
} from "./editBuildingActionTypes";
import {
  CLEAR_ERROR_SAVING_GATES,
  SAVE_GATES,
  SAVE_GATES_FAILURE,
  SAVE_GATES_SUCCESS,
} from "./saveGatesActionTypes";
import {
  SAVE_LOCATIONS,
  SAVE_LOCATIONS_SUCCESS,
  SAVE_LOCATIONS_FAILURE,
  CLEAR_ERROR_SAVING_LOCATIONS,
  MOVE_LOCATIONS_SUCCESS,
} from "./saveLocationsActionTypes";
import { SAVE_LOCALES_SUCCESS } from "../documents/documentsActionTypes";

const INITIAL_YARD_CONFIG_DATA = {};

const updateSingleBuildingInYard = (buildings, updatedBuilding) => {
  return buildings.map((b) =>
    b.buildingCode === updatedBuilding.buildingCode ? updatedBuilding : b
  );
};

export const yardConfigDataReducer = (state = INITIAL_YARD_CONFIG_DATA, action) => {
  switch (action.type) {
    // ---------------------------------------------------
    //                 Fetch yard config
    // ---------------------------------------------------

    case FETCH_YARD_CONFIG_INITIALIZATION: {
      const yardId = action.payload;
      return {
        ...state,
        [yardId]: {
          fetching: true,
          error: null,
        },
      };
    }

    case FETCH_YARD_CONFIG_SUCCESS: {
      const { yardId, yardConfig } = action.payload;
      const { version, yards, buildings } = yardConfig;
      return {
        ...state,
        [yardId]: {
          fetching: false,
          version,
          // With new UX, users can access config page of a yard directly with the yard
          // ID in URL path, but then yard ID can be wrong, and backend will return empty
          // yards array, so need to check if array is not empty first
          ...(yards.length && extractMainYardProps(yardId, yards)),
          offsiteYards: extractOffsiteYardIdentifiers(yardId, yards),
          buildings,
          error: null,
        },
        ...extractOffsiteYards(yardId, yards, version),
      };
    }

    case FETCH_YARD_CONFIG_FAILURE: {
      const { yardId, error } = action.payload;
      return {
        ...state,
        [yardId]: {
          fetching: false,
          error,
        },
      };
    }

    case CLEAR_YARD_CONFIG_DATA:
      return INITIAL_YARD_CONFIG_DATA;

    // ---------------------------------------------------
    //                    Create yard
    // ---------------------------------------------------

    case CREATE_YARD_SUCCESS: {
      const { mainYardId, version, yard } = action.payload;
      return {
        ...state,
        ...(mainYardId && {
          [mainYardId]: {
            ...state[mainYardId],
            version,
            offsiteYards: [
              ...state[mainYardId].offsiteYards,
              { yardId: yard.yardId, label: yard.label },
            ],
          },
        }),
        [yard.yardId]: { ...extractYardProps(yard), offsiteYards: [], buildings: [], version },
      };
    }

    // ---------------------------------------------------
    //                     Edit yard
    // ---------------------------------------------------

    case EDIT_YARD_SUCCESS: {
      const { yardId, yard, version } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          version,
          ...extractYardProps(yard),
        },
      };
    }

    // ---------------------------------------------------
    //                    Delete yard
    // ---------------------------------------------------

    case DELETE_YARD_SUCCESS: {
      const { mainYardId, yardId, version } = action.payload;
      delete state[yardId];
      return {
        ...state,
        ...(mainYardId && {
          [mainYardId]: {
            ...state[mainYardId],
            offsiteYards: state[mainYardId].offsiteYards.filter(
              (offsite) => offsite.yardId !== yardId
            ),
            version,
          },
        }),
      };
    }

    // ---------------------------------------------------
    //                   Create building
    // ---------------------------------------------------

    case CREATE_BUILDING: {
      const yardId = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          creatingBuilding: true,
          errorCreatingBuilding: null,
        },
      };
    }

    case CREATE_BUILDING_SUCCESS: {
      const { yardId, createBuildingResponse } = action.payload;
      const { version, building } = createBuildingResponse;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          buildings: state[yardId].buildings.concat(building),
          creatingBuilding: false,
          version,
          errorCreatingBuilding: null,
        },
      };
    }

    case CREATE_BUILDING_FAILURE: {
      const { yardId, error } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          creatingBuilding: false,
          errorCreatingBuilding: error,
        },
      };
    }

    case CLEAR_ERROR_CREATING_BUILDING: {
      const yardId = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          creatingBuilding: false,
          errorCreatingBuilding: null,
        },
      };
    }

    // ---------------------------------------------------
    //                   Edit building
    // ---------------------------------------------------

    case EDIT_BUILDING: {
      const yardId = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          editingBuilding: true,
          errorEditingBuilding: null,
        },
      };
    }

    case EDIT_BUILDING_SUCCESS: {
      const { yardId, yardConfig } = action.payload;
      const { version, building } = yardConfig;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          editingBuilding: false,
          version,
          buildings: updateSingleBuildingInYard(state[yardId].buildings, building),
          building,
          errorEditingBuilding: null,
        },
      };
    }

    case EDIT_BUILDING_FAILURE: {
      const { yardId, error } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          editingBuilding: false,
          errorEditingBuilding: error,
        },
      };
    }

    case CLEAR_ERROR_EDITING_BUILDING: {
      const yardId = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          editingBuilding: false,
          errorEditingBuilding: null,
        },
      };
    }

    // ---------------------------------------------------
    //                   Delete building
    // ---------------------------------------------------

    case DELETE_BUILDING: {
      const { yardId } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          deletingBuilding: true,
          successDeletingBuilding: null,
          errorDeletingBuilding: null,
        },
      };
    }

    case DELETE_BUILDING_SUCCESS: {
      const { yardId, deletingBuildingResponse } = action.payload;
      const { version, building } = deletingBuildingResponse;
      const buildingCode = building.buildingCode;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          version,
          buildings: state[yardId].buildings.filter(
            (building) => building.buildingCode !== buildingCode
          ),
          deletingBuilding: false,
          successDeletingBuilding: true,
          errorDeletingBuilding: null,
        },
      };
    }

    case DELETE_BUILDING_FAILURE: {
      const { yardId, errorDeletingBuilding } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          deletingBuilding: false,
          successDeletingBuilding: false,
          errorDeletingBuilding,
        },
      };
    }

    case CLEAR_DELETE_BUILDING_DIALOG: {
      const { yardId } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          deletingBuilding: false,
          successDeletingBuilding: null,
          errorDeletingBuilding: null,
        },
      };
    }

    // ---------------------------------------------------
    //                   Save locations
    // ---------------------------------------------------

    case SAVE_LOCATIONS: {
      const yardId = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          savingLocations: true,
          errorSavingLocations: null,
        },
      };
    }

    case SAVE_LOCATIONS_SUCCESS: {
      const { yardId, yardConfig } = action.payload;
      const { version, yard } = yardConfig;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          savingLocations: false,
          version,
          ...extractYardProps(yard),
          errorSavingLocations: null,
        },
      };
    }

    case SAVE_LOCATIONS_FAILURE: {
      const { yardId, error } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          savingLocations: false,
          errorSavingLocations: error,
        },
      };
    }

    case CLEAR_ERROR_SAVING_LOCATIONS: {
      const yardId = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          savingLocations: false,
          errorSavingLocations: null,
        },
      };
    }

    case MOVE_LOCATIONS_SUCCESS: {
      const { sourceYard, destinationYard, version } = action.payload;
      return {
        ...state,
        [sourceYard.yardId]: {
          ...state[sourceYard.yardId],
          ...extractYardProps(sourceYard),
          version,
        },
        [destinationYard.yardId]: {
          ...state[destinationYard.yardId],
          ...extractYardProps(destinationYard),
          version,
        },
      };
    }

    // ---------------------------------------------------
    //                   Save gates
    // ---------------------------------------------------

    case SAVE_GATES: {
      const yardId = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          savingGates: true,
          errorSavingGates: null,
        },
      };
    }

    case SAVE_GATES_SUCCESS: {
      const { yardId, yardConfig } = action.payload;
      const { version, yard } = yardConfig;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          savingGates: false,
          version,
          ...extractYardProps(yard),
          errorSavingGates: null,
        },
      };
    }

    case SAVE_GATES_FAILURE: {
      const { yardId, error } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          savingGates: false,
          errorSavingGates: error,
        },
      };
    }

    case CLEAR_ERROR_SAVING_GATES: {
      const yardId = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          savingGates: false,
          errorSavingGates: null,
        },
      };
    }

    // ---------------------------------------------------
    //                   Save locales
    // ---------------------------------------------------

    case SAVE_LOCALES_SUCCESS: {
      const { yardId, yard, version } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          ...extractYardProps(yard),
          version,
        },
      };
    }

    // ---------------------------------------------------
    //                     Default
    // ---------------------------------------------------

    default:
      return state;
  }
};

const extractMainYardProps = (mainYardId, yards) =>
  extractYardProps(yards.find(({ yardId }) => yardId === mainYardId));

const extractOffsiteYardIdentifiers = (mainYardId, yards) =>
  yards
    .filter(({ yardId }) => yardId !== mainYardId)
    .map((yard) => ({ yardId: yard.yardId, label: yard.label }));

const extractOffsiteYards = (mainYardId, yards, version) =>
  yards
    .filter(({ yardId }) => yardId !== mainYardId)
    .reduce(
      (accumulator, yard) => ({
        ...accumulator,
        [yard.yardId]: { ...extractYardProps(yard), buildings: [], version },
      }),
      {}
    );

const extractYardProps = ({
  label,
  type,
  assetTypes,
  languages,
  yardFeatures,
  enableHostler,
  parkingLocations,
  gates,
}) => ({
  label,
  type,
  assetTypes,
  languages,
  yardFeatures,
  enableHostler,
  parkingLocations,
  gates,
});
