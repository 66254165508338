import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Map,
  LayersControl,
  ZoomControl,
  TileLayer,
  Polygon,
  Marker,
  Tooltip,
} from "react-leaflet";

import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Icon from "@amzn/meridian/icon";
import alertErrorLargeTokens from "@amzn/meridian-tokens/base/icon/alert-error-large";
import {
  colorBlue400,
  colorPurple200,
  colorOrange400,
  colorGray400,
} from "@amzn/meridian-tokens/base/color";

import BingTileLayer from "component/YardMapDialog/BingTileLayer";
import OffsetPopup from "component/YardMapDialog/YardMapOffsetPopup";
import { getLatLonFromCartesianDisplacement } from "helpers";
import { EDIT_YARD_MAP_TYPES, OFFSET_UNIT } from "app-constants";

const HEREStyleSatellite = "satellite.day";
const HEREStyleStreet = "normal.day";

export default class YardMap extends React.PureComponent {
  state = { yardMap: null, center: null, offsetKeysEnabled: false };

  componentDidMount() {
    this.initState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.yardMapData.yardMap !== prevProps.yardMapData.yardMap) {
      this.initState();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  initState = () => {
    const { gpsPosition } = this.props.yardMapData.yardMap;
    if (!this.state.center) {
      this.setState({
        center: [gpsPosition.latitude, gpsPosition.longitude],
      });
    }
    this.initStateGpsPosition();
  };

  toggleOffsetKeysEnabled = () => {
    const { offsetKeysEnabled } = this.state;

    if (offsetKeysEnabled) {
      document.removeEventListener("keydown", this.handleKeyDown);
    } else {
      document.addEventListener("keydown", this.handleKeyDown);
    }

    this.setState({ offsetKeysEnabled: !offsetKeysEnabled });
  };

  handleKeyDown = (e) => {
    // This is so that the up/down arrow key presses will not move the background behind the
    // YardMap modal (i.e. YardMapDialog component) up/down accordingly, which confuses user
    // and drives their attention away from the desired behavior of shifting the markers/polygons.
    // Previous solution considered was wrapping the entire YardDesigner component in Meridian's <AppLayout>,
    // but this interferes with the scroll event listener in YardDesigner that handles the infinite
    // scrolling of the YardCard list
    e.preventDefault();

    const { yardMap } = this.state;
    const gpsPosition = { ...yardMap.gpsPosition };

    switch (e.code) {
      case "KeyW":
      case "ArrowUp":
        gpsPosition.latitude += OFFSET_UNIT;
        break;
      case "KeyS":
      case "ArrowDown":
        gpsPosition.latitude -= OFFSET_UNIT;
        break;
      case "KeyA":
      case "ArrowLeft":
        gpsPosition.longitude -= OFFSET_UNIT;
        break;
      case "KeyD":
      case "ArrowRight":
        gpsPosition.longitude += OFFSET_UNIT;
    }

    this.setState({ yardMap: { ...yardMap, gpsPosition } });
  };

  saveOffset = () => {
    const { yardMapData, editYardMap } = this.props;
    const { gpsPosition: stateGpsPosition } = this.state.yardMap;
    const { yardId, version, gpsPosition } = yardMapData.yardMap;
    const offset = {
      latitudeOffset: stateGpsPosition.latitude - gpsPosition.latitude,
      longitudeOffset: stateGpsPosition.longitude - gpsPosition.longitude,
    };

    editYardMap({
      editType: EDIT_YARD_MAP_TYPES.SAVE_OFFSET,
      yardId,
      version,
      offset,
    });
  };

  cancelOffset = () => this.initStateGpsPosition();

  initStateGpsPosition = () => {
    const { yardMap } = this.props.yardMapData;
    const { latitude, longitude } = yardMap.gpsPosition;
    const gpsPosition = {
      latitude: latitude + (yardMap.offset?.latitudeOffset || 0),
      longitude: longitude + (yardMap.offset?.longitudeOffset || 0),
    };
    this.setState({ yardMap: { ...yardMap, gpsPosition } });
  };

  getGpsPosition = (yardMap) => [yardMap.gpsPosition?.latitude, yardMap.gpsPosition?.longitude];

  getYardPolygon = (yardMap) => this.getPolygon(yardMap.gpsPosition, yardMap.polygon);

  getLocationPolygons = (yardMap) =>
    yardMap.locations.map(({ polygon, ...rest }) => ({
      ydlrPolygon: polygon, // preserve back-end Catersian coordinates, for use to call EditYardMap API
      polygon: this.getPolygon(yardMap.gpsPosition, polygon),
      ...rest,
    }));

  getPolygon = (gpsPosition, xys) =>
    xys.map(({ x, y }) => {
      const { latitude, longitude } = getLatLonFromCartesianDisplacement(gpsPosition, x, y);
      return [latitude, longitude];
    });

  handleViewportChange = ({ center, zoom }) => this.setState({ center, zoom });

  hasGeoFencePolygon = (yardmap) => yardmap && yardmap.polygon;

  categorizeLocationPolygonsForRendering = () => {
    const { yardMapData, selectedLocation } = this.props;
    const { yardMap } = this.state;
    const nonselectedLocationPolygons = [];
    const nonselectedNeedsRemappingLocationPolygons = [];
    const selectedLocationPolygons = [];

    if (!isEmpty(yardMapData.yardMap)) {
      this.getLocationPolygons(yardMap || yardMapData.yardMap).forEach((polygon) => {
        if (polygon.locationCode === selectedLocation?.locationCode) {
          selectedLocationPolygons.push(polygon);
        } else if (polygon.needsRemapping) {
          nonselectedNeedsRemappingLocationPolygons.push(polygon);
        } else {
          nonselectedLocationPolygons.push(polygon);
        }
      });
    }

    return {
      nonselectedLocationPolygons,
      nonselectedNeedsRemappingLocationPolygons,
      selectedLocationPolygons,
    };
  };

  renderLocationPolygon = (polygon, selected) => (
    <Polygon
      key={polygon.locationCode}
      color={selected ? colorOrange400 : polygon.needsRemapping ? colorGray400 : colorPurple200}
      fillOpacity={0.5}
      positions={polygon.polygon}
      onClick={() => this.props.selectLocation(polygon)}
    >
      <Tooltip sticky>{polygon.locationCode}</Tooltip>
    </Polygon>
  );

  render() {
    const { secrets, dialogHeight, yardLabel, yardMapData } = this.props;
    const { yardMap, center, offsetKeysEnabled } = this.state;
    const {
      nonselectedLocationPolygons,
      nonselectedNeedsRemappingLocationPolygons,
      selectedLocationPolygons,
    } = this.categorizeLocationPolygonsForRendering();
    return (
      <Column height={dialogHeight} alignmentHorizontal="center" alignmentVertical="center">
        {yardMapData.fetchingYardMap ? (
          <Loader />
        ) : yardMapData.errorFetchingYardMap ? (
          <Icon tokens={alertErrorLargeTokens} />
        ) : (
          <Map center={center} zoom={18} keyboard={false} zoomControl={false}>
            <LayersControl position="topright">
              <LayersControl.BaseLayer name="HERE (satellite view)">
                <TileLayer
                  url={`https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${HEREStyleSatellite}/{z}/{x}/{y}/256/png?apiKey=${secrets.hereApiKey}`}
                  maxZoom={20}
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="HERE (street view)">
                <TileLayer
                  url={`https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${HEREStyleStreet}/{z}/{x}/{y}/256/png?apiKey=${secrets.hereApiKey}`}
                  maxZoom={20}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="mapbox (satellite view - Raster Tiles API)">
                <TileLayer
                  url={`https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.jpg90?access_token=${secrets.mapboxApiKey}`}
                  attribution='© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  maxZoom={22}
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="mapbox (satellite view - Static Tiles API)">
                <TileLayer
                  url={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${secrets.mapboxApiKey}`}
                  attribution='© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  maxZoom={22}
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="mapbox (street view - Raster Tiles API)">
                <TileLayer
                  url={`https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/{z}/{x}/{y}.jpg90?access_token=${secrets.mapboxApiKey}`}
                  attribution='© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  maxZoom={22}
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="mapbox (street view - Static Tiles API)">
                <TileLayer
                  url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${secrets.mapboxApiKey}`}
                  attribution='© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  maxZoom={22}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Bing (aerial view)">
                <BingTileLayer bingKey={secrets.bingApiKey} imagerySet="Aerial" maxZoom={20} />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Bing (street view)">
                <BingTileLayer
                  bingKey={secrets.bingApiKey}
                  imagerySet="RoadOnDemand"
                  maxZoom={20}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer checked name="OSM (street view)">
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  maxZoom={19}
                />
              </LayersControl.BaseLayer>
            </LayersControl>

            <ZoomControl position="bottomright" />

            {this.hasGeoFencePolygon(yardMap || yardMapData.yardMap) && (
              <Polygon
                color={colorBlue400}
                fillOpacity={0.5}
                positions={this.getYardPolygon(yardMap || yardMapData.yardMap)}
              >
                <Tooltip sticky>Yard {yardLabel}</Tooltip>
              </Polygon>
            )}

            {/* Draw non-selected locations first */}
            {nonselectedLocationPolygons.map((polygon) =>
              this.renderLocationPolygon(polygon, false)
            )}
            {/* Then, draw non-selected locations that need re-mapping */}
            {nonselectedNeedsRemappingLocationPolygons.map((polygon) =>
              this.renderLocationPolygon(polygon, false)
            )}
            {/* Lastly, draw selected location */}
            {selectedLocationPolygons.map((polygon) => this.renderLocationPolygon(polygon, true))}

            <Marker position={this.getGpsPosition(yardMap || yardMapData.yardMap)}>
              <OffsetPopup
                yardMapData={yardMapData}
                offsetKeysEnabled={offsetKeysEnabled}
                toggleOffsetKeysEnabled={this.toggleOffsetKeysEnabled}
                saveOffset={this.saveOffset}
                cancelOffset={this.cancelOffset}
              />
            </Marker>
          </Map>
        )}
      </Column>
    );
  }
}

YardMap.propTypes = {
  secrets: PropTypes.object.isRequired,
  dialogHeight: PropTypes.number.isRequired,
  yardLabel: PropTypes.string.isRequired,
  yardMapData: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object,
  selectLocation: PropTypes.func.isRequired,
  editYardMap: PropTypes.func.isRequired,
};
