import React from "react";
import Papa from "papaparse"; // CSV parser
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { compose } from "lodash/fp";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { withTranslation } from "react-i18next";

import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import FileInput from "@amzn/meridian/file-input";
import Icon from "@amzn/meridian/icon";
import Modal from "@amzn/meridian/modal";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";

import YardLabelTable from "component/BatchActionDialogs/BatchCreate3PYardsDialog/YardLabelTable";
import { batchCreate3PYards, callAPI } from "store";
import { accountInfo } from "types";
import { keys } from "i18n";
import {
  APIs,
  BATCH_ACTION_FILE_SUPPORTED_EXTENSIONS,
  BATCH_CREATE_3P_YARDS_LIMIT,
} from "app-constants";

class BatchCreate3PYardsDialog extends React.PureComponent {
  csvLink = React.createRef();

  state = {
    fetchingYardIdentifiers: false,
    yardIdentifiers: [],
    yardLabelsWithStatus: null,
    csvUploadInfoMessage: null,
    csvUploadErrorMessage: null,
    batchCreating3PYards: false,
    batchCreateSuccessMessage: null,
    batchCreateErrorMessage: null,
  };

  componentDidMount() {
    // Fetch all yard identifiers, to validate yard labels uploaded
    this.setState({ fetchingYardIdentifiers: true }, this.fetchYardIdentifiers);
  }

  fetchYardIdentifiers = async () => {
    const { accountInfo } = this.props;

    try {
      const { yardIdentifiers } = await callAPI(accountInfo, APIs.LIST_SUPPORTED_YARD_IDENTIFIERS);

      this.setState({ fetchingYardIdentifiers: false, yardIdentifiers });
    } catch (e) {
      // TODO: Display some error so users know what's going on, and what to do
      // Right now, spinner will just keep spinning forever
      console.error(e);
    }
  };

  downloadCSV = () => {
    this.csvLink.current.link.click();
  };

  handleCSVUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const [fileExtension] = file.name.split(".").slice(-1);

    if (BATCH_ACTION_FILE_SUPPORTED_EXTENSIONS.includes(fileExtension)) {
      const reader = new FileReader();
      reader.onload = () => {
        const yardLabelsWithStatus = this.extractYardLabelsFromCSV(reader.result);
        if (yardLabelsWithStatus?.length) {
          this.setState({ yardLabelsWithStatus });
        }
      };

      this.setState({
        yardLabelsWithStatus: null,
        csvUploadInfoMessage: null,
        csvUploadErrorMessage: null,
        batchCreateSuccessMessage: null,
        batchCreateErrorMessage: null,
      });
      reader.readAsText(file);
    } else {
      this.setState({
        csvUploadErrorMessage: this.props.t(keys.FILE_EXTENSION_NOT_SUPPORTED_MESSAGE),
      });
    }
  };

  extractYardLabelsFromCSV = (csvString) => {
    const { t } = this.props;
    const { yardIdentifiers } = this.state;

    const parsedCSV = Papa.parse(csvString, {
      header: true,
      skipEmptyLines: true,
      delimiter: ",",
    });

    if (parsedCSV.errors?.length) {
      this.setState({
        csvUploadErrorMessage:
          // prettier-ignore
          `${t(keys.CSV_PARSE_FAILURE)} ${JSON.stringify(parsedCSV.errors)}`,
      });
      return null;
    }

    // Check if header of first column is correct
    const headers = parsedCSV.meta.fields;
    if (headers[0] !== "Yard label") {
      this.setState({
        csvUploadErrorMessage: t(keys.CSV_YARD_LABELS_WRONG_HEADER),
      });
      return null;
    }

    // Check if there are yard labels at all to work with
    const yardLabels = parsedCSV.data
      .map((row) => row[headers[0]].trim())
      .filter((yardLabel) => !!yardLabel);
    if (!yardLabels.length) {
      this.setState({
        csvUploadInfoMessage: t(keys.CSV_YARD_LABELS_NONE_FOUND),
      });
      return null;
    }

    // Check if batch size is within limit
    const uniqueYardLabels = new Set(yardLabels.map((yardLabel) => yardLabel.toUpperCase()));
    if (uniqueYardLabels.size > BATCH_CREATE_3P_YARDS_LIMIT) {
      this.setState({
        // prettier-ignore
        csvUploadErrorMessage: `${t(keys.CSV_YARD_LABELS_LIMIT_EXCEEDED)} ${BATCH_CREATE_3P_YARDS_LIMIT}.`,
      });
      return null;
    }

    // Check if there are invalid yard labels (duplicate or already used)
    const uniqueYardLabelSeen = Object.fromEntries(
      [...uniqueYardLabels].map((uniqueYardLabel) => [uniqueYardLabel, false])
    );
    const existingYardLabels = new Set(
      yardIdentifiers.map(({ yardLabel }) => yardLabel.toUpperCase())
    );
    const exisitngBuildingCodes = new Set(
      yardIdentifiers
        .flatMap(({ buildingCodes }) => buildingCodes)
        .map((buildingCode) => buildingCode.toUpperCase())
    );
    const yardLabelsWithStatus = yardLabels
      .map((yardLabel) => {
        if (!uniqueYardLabelSeen[yardLabel.toUpperCase()]) {
          uniqueYardLabelSeen[yardLabel.toUpperCase()] = true;
          return { yardLabel, errorsFrontEnd: [] };
        }
        return {
          yardLabel,
          errorsFrontEnd: [t(keys.CSV_YARD_LABELS_DUPLICATE)],
        };
      })
      .map(({ yardLabel, errorsFrontEnd }) => {
        if (existingYardLabels.has(yardLabel.toUpperCase())) {
          errorsFrontEnd.push(t(keys.CSV_YARD_LABELS_YARD_ALREADY_EXISTS));
        }
        if (exisitngBuildingCodes.has(yardLabel.toUpperCase())) {
          errorsFrontEnd.push(t(keys.CSV_YARD_LABELS_BUILDING_ALREADY_EXISTS));
        }
        return { yardLabel, errorsFrontEnd };
      });
    if (yardLabelsWithStatus.some(({ errorsFrontEnd }) => !isEmpty(errorsFrontEnd))) {
      this.setState({
        csvUploadErrorMessage: t(keys.CSV_YARD_LABELS_INVALID),
      });
    }

    return yardLabelsWithStatus;
  };

  submit = () => {
    this.setState({
      batchCreating3PYards: true,
      batchCreateErrorMessage: null,
    });

    this.props.batchCreate3PYards({
      yardLabels: this.state.yardLabelsWithStatus.map(({ yardLabel }) => yardLabel),
      onSuccess: this.handleSuccess,
      onFailure: this.handleFailure,
    });
  };

  handleSuccess = () => {
    const { t } = this.props;
    this.setState(({ yardLabelsWithStatus }) => ({
      yardLabelsWithStatus: yardLabelsWithStatus.map(({ yardLabel }) => ({
        yardLabel,
        successMessage: t(keys.THIRD_PARTY_YARD_ADDED),
      })),
      batchCreating3PYards: false,
      batchCreateSuccessMessage: t(keys.ALL_3P_YARDS_ADDED),
    }));
  };

  handleFailure = ({ message, errorMap }) => {
    this.setState(({ yardLabelsWithStatus }) => ({
      yardLabelsWithStatus: yardLabelsWithStatus.map(({ yardLabel }) => ({
        yardLabel,
        errorsBackEnd: errorMap[yardLabel],
      })),
      batchCreating3PYards: false,
      batchCreateErrorMessage: message,
    }));
  };

  render() {
    const { t, dismiss } = this.props;
    const {
      fetchingYardIdentifiers,
      yardLabelsWithStatus,
      csvUploadInfoMessage,
      csvUploadErrorMessage,
      batchCreating3PYards,
      batchCreateSuccessMessage,
      batchCreateErrorMessage,
    } = this.state;
    const isSubmittable = yardLabelsWithStatus?.length && !csvUploadErrorMessage;
    return (
      <Modal bodySpacingInset="medium" title={t(keys.BATCH_ADD_3P_YARDS)} open={true}>
        <Column>
          <Row spacing="small">
            <Button type="link" onClick={this.downloadCSV}>
              <Icon tokens={downloadLargeTokens} />
              {t(keys.DOWNLOAD_CSV_TEMPLATE)}
            </Button>
            <CSVLink
              data={[]}
              headers={["Yard label"]}
              filename="3p-yards.csv"
              enclosingCharacter=""
              ref={this.csvLink}
              target="_blank"
            />

            <FileInput
              data-testid="csvUpload"
              uploadButtonLabel={t(keys.UPLOAD_CSV)}
              uploadButtonDisabled={
                fetchingYardIdentifiers || batchCreating3PYards || !!batchCreateSuccessMessage
              }
              uploadButtonType="link"
              onFileAttached={this.handleCSVUpload}
            />
          </Row>

          {fetchingYardIdentifiers && (
            <Row alignmentHorizontal="center">
              <Loader data-testid="fetchingYardIdentifiersLoader" />
            </Row>
          )}

          <YardLabelTable
            yardLabelsWithStatus={yardLabelsWithStatus}
            batchCreateSuccessMessage={batchCreateSuccessMessage}
          />

          {csvUploadInfoMessage && (
            <Alert data-testid="csvUploadInfoMessage">{csvUploadInfoMessage}</Alert>
          )}
          {csvUploadErrorMessage && (
            <Alert data-testid="csvUploadErrorMessage" type="error">
              {csvUploadErrorMessage}
            </Alert>
          )}

          {batchCreateErrorMessage && <Alert type="error">{batchCreateErrorMessage}</Alert>}

          {batchCreating3PYards && (
            <Row alignmentHorizontal="right">
              <Loader data-testid="batchCreating3PYardsLoader" size="medium" />
            </Row>
          )}
          {batchCreateSuccessMessage && (
            <Row alignmentHorizontal="right">
              <Button type="secondary" minWidth="80px" onClick={dismiss}>
                {t(keys.CLOSE)}
              </Button>
            </Row>
          )}
          {!fetchingYardIdentifiers && !batchCreating3PYards && !batchCreateSuccessMessage && (
            <Row alignmentHorizontal="right">
              <Button type="secondary" minWidth="80px" onClick={dismiss}>
                {t(keys.CANCEL)}
              </Button>

              <Button
                data-testid="submitButton"
                type="primary"
                minWidth="80px"
                disabled={!isSubmittable}
                onClick={this.submit}
              >
                {t(keys.ADD)}
              </Button>
            </Row>
          )}
        </Column>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  batchCreate3PYards: (payload) => {
    dispatch(batchCreate3PYards(payload));
  },
});

const withTranslationsAndMapDispatchToProps = compose(
  withTranslation(),
  connect(null, mapDispatchToProps)
);

export { BatchCreate3PYardsDialog };
export default withTranslationsAndMapDispatchToProps(BatchCreate3PYardsDialog);

BatchCreate3PYardsDialog.propTypes = {
  accountInfo: accountInfo.isRequired,
  dismiss: PropTypes.func.isRequired,
};
