import { FETCH_BUILDING_FEATURES_SUCCESS } from "./buildingFeaturesActionTypes";

export const INITIAL_BUILDING_FEATURES = {};

export const buildingFeaturesReducer = (state = INITIAL_BUILDING_FEATURES, action) => {
  switch (action.type) {
    case FETCH_BUILDING_FEATURES_SUCCESS:
      const { buildingFeatures } = action.payload;
      return {
        ...state,
        buildingFeatures,
      };

    default:
      return state;
  }
};
