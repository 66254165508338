import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import Modal from "@amzn/meridian/modal";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Loader from "@amzn/meridian/loader";
import Alert from "@amzn/meridian/alert";
import Column from "@amzn/meridian/column";

import { keys } from "i18n";
import { generateErrorMessage } from "helpers";

/**
 * A component for displaying a dialog with a request to confirm saving changes.
 */
class SaveChangesDialog extends React.PureComponent {
  renderWarningMessage = () => {
    if (!this.props.warningMessage) {
      return null;
    }

    return (
      <Alert size="small" type="error">
        {this.props.warningMessage}
      </Alert>
    );
  };

  render() {
    const {
      t,
      title,
      saveChanges,
      dismiss,
      savingChanges,
      genericSavingErrorMessage,
      serverErrorMessage,
    } = this.props;
    return (
      <Modal
        width="400px"
        bodySpacingInset="medium"
        title={t(title)}
        open={true}
        data-testid="modalInSaveChangesDialog"
      >
        <Column spacing="400">
          {this.renderWarningMessage()}
          <Text>{t(keys.SAVE_CHANGES_TEXT)}</Text>
        </Column>

        <Row alignmentHorizontal="right" spacingInset="large none none none">
          <Button
            type="secondary"
            minWidth="80px"
            disabled={savingChanges}
            onClick={dismiss}
            data-testid="cancelButtonInSaveChangesDialog"
          >
            {t(keys.CANCEL)}
          </Button>

          {savingChanges ? (
            <Loader size="medium" />
          ) : (
            <Button
              type="primary"
              minWidth="90px"
              onClick={saveChanges}
              data-testid="saveButtonInSaveChangesDialog"
            >
              {t(keys.SAVE)}
            </Button>
          )}
        </Row>

        {serverErrorMessage && (
          <Row alignmentHorizontal="right" spacingInset="medium none">
            <Alert size="small" type="error">
              {generateErrorMessage(t(genericSavingErrorMessage), serverErrorMessage)}
            </Alert>
          </Row>
        )}
      </Modal>
    );
  }
}

export default withTranslation()(SaveChangesDialog);

SaveChangesDialog.propTypes = {
  /**
   * The title of the modal. This will be shown in the modal header.
   */
  title: PropTypes.string.isRequired,

  /**
   * This callback will be fired when user clicks `Save`.
   */
  saveChanges: PropTypes.func.isRequired,

  /**
   * This callback will be fired when user tries to close the dialog.
   */
  dismiss: PropTypes.func.isRequired,

  /**
   * Is the process of saving changes in progress?
   */
  savingChanges: PropTypes.bool,

  /**
   * A warning message about the changes that should be displayed.
   */
  warningMessage: PropTypes.string,

  /**
   * A message that should be displayed in case of an error when saving changes.
   */
  genericSavingErrorMessage: PropTypes.string.isRequired,

  /**
   * An error message that was returned from the server in the response.
   */
  serverErrorMessage: PropTypes.string,
};
