import { callAPI } from "store";
import {
  SAVE_LOCATIONS,
  SAVE_LOCATIONS_SUCCESS,
  SAVE_LOCATIONS_FAILURE,
  CLEAR_ERROR_SAVING_LOCATIONS,
  MOVE_LOCATIONS_SUCCESS,
} from "./saveLocationsActionTypes";
import { APIs } from "app-constants";

export const moveLocations = ({
  sourceYardId,
  destinationYardId,
  locationIdsToMove,
  version,
  successCallback,
  failureCallback,
}) => {
  return async (dispatch, getState) => {
    try {
      const responseJson = await callAPI(
        getState().accountInfo,
        APIs.MOVE_LOCATIONS_TO_RELATED_YARD,
        {
          sourceYardId,
          destinationYardId,
          locationsToMove: locationIdsToMove,
          version,
        }
      );

      dispatch(moveLocationsSuccess(responseJson));
      successCallback();
    } catch (e) {
      failureCallback(e.message);
    }
  };
};

export const saveLocationsRequest = (yardId) => {
  return {
    type: SAVE_LOCATIONS,
    payload: yardId,
  };
};

// payload includes yardId and yardConfig
export const saveLocationsSuccess = (payload) => {
  return {
    type: SAVE_LOCATIONS_SUCCESS,
    payload,
  };
};

// payload includes yardId and error
export const saveLocationsFailure = (payload) => {
  return {
    type: SAVE_LOCATIONS_FAILURE,
    payload,
  };
};

export const clearErrorSavingLocations = (yardId) => {
  return {
    type: CLEAR_ERROR_SAVING_LOCATIONS,
    payload: yardId,
  };
};

export const moveLocationsSuccess = (payload) => {
  return {
    type: MOVE_LOCATIONS_SUCCESS,
    payload,
  };
};
