import { groupBy } from "lodash";
import {
  CLEAR_ERROR_SAVING_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  SAVE_SUBSCRIPTIONS,
  SAVE_SUBSCRIPTIONS_FAILURE,
  SAVE_SUBSCRIPTIONS_SUCCESS,
} from "./subscriptionsActionTypes";

export const INITIAL_SUBSCRIPTIONS = {
  fetchingSubscriptions: false,
  errorFetchingSubscriptions: null,
  savingSubscriptions: false,
  errorSavingSubscriptions: null,
};

export const subscriptionsReducer = (state = INITIAL_SUBSCRIPTIONS, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS: {
      return {
        ...state,
        fetchingSubscriptions: true,
        errorFetchingSubscriptions: null,
      };
    }

    case FETCH_SUBSCRIPTIONS_SUCCESS: {
      const { yardId, subscriptions } = action.payload;
      return {
        ...state,
        fetchingSubscriptions: false,
        errorFetchingSubscriptions: null,
        [yardId]: groupBy(subscriptions, (s) => s.buildingCode),
      };
    }

    case FETCH_SUBSCRIPTIONS_FAILURE: {
      const error = action.payload;
      return {
        ...state,
        fetchingSubscriptions: false,
        errorFetchingSubscriptions: error,
      };
    }

    case SAVE_SUBSCRIPTIONS: {
      return {
        ...state,
        savingSubscriptions: true,
        errorSavingSubscriptions: null,
      };
    }

    case SAVE_SUBSCRIPTIONS_SUCCESS: {
      const { yardId, buildingCode, subscriptions } = action.payload;
      return {
        ...state,
        savingSubscriptions: false,
        errorSavingSubscriptions: null,
        [yardId]: {
          ...state[yardId],
          [buildingCode]: subscriptions,
        },
      };
    }

    case SAVE_SUBSCRIPTIONS_FAILURE: {
      const error = action.payload;
      return {
        ...state,
        savingSubscriptions: false,
        errorSavingSubscriptions: error,
      };
    }

    case CLEAR_ERROR_SAVING_SUBSCRIPTIONS: {
      return {
        ...state,
        savingSubscriptions: false,
        errorSavingSubscriptions: null,
      };
    }

    default:
      return state;
  }
};
