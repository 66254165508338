import { callAPI } from "store";
import {
  FETCH_YARD_IDENTIFIERS_REFRESH,
  FETCH_YARD_IDENTIFIERS_CONTINUE,
  FETCH_YARD_IDENTIFIERS_SUCCESS,
  FETCH_YARD_IDENTIFIERS_FAILURE,
  SET_YARD_IDENTIFIERS,
} from "./yardIdentifiersActionTypes";
import { APIs, DEFAULT_NUM_YARD_IDENTIFIERS_PER_PAGE } from "app-constants";

/**
 * There are two mode of fetch, controlled by `refresh` flag:
 * - "Refresh" fetch: fetch identifiers and replace those in store with new ones
 * - "Continue" fetch: fetch identifiers and append to those in store
 *
 * There's also another dimension, controlled by `searchString` and `searchScope`:
 * - If searchString and searchScope are provided, they are used
 * - If not, the current values in store will be used
 *
 * - When it's refresh fetch, searchString and searchScope will be reset to:
 *   - The values provided
 *   - If not provided, default values of null
 */
export const fetchYardIdentifiers = (searchInfo) => {
  return async (dispatch, getState) => {
    const {
      refresh,
      searchString = null,
      searchScope = null,
      pageSize = DEFAULT_NUM_YARD_IDENTIFIERS_PER_PAGE,
    } = searchInfo;

    if (refresh) {
      dispatch(fetchYardIdentifiersRefresh({ searchString, searchScope }));
    } else {
      dispatch(fetchYardIdentifiersContinue());
    }

    try {
      const { searchString: currentSearchString, searchScope: currentSearchScope } =
        getState().yardIdentifiersData;

      const responseJson = await callAPI(
        getState().accountInfo,
        APIs.LIST_SUPPORTED_YARD_IDENTIFIERS,
        {
          searchString: searchString || currentSearchString,
          searchScope: searchScope || currentSearchScope,
          exclusiveStartKey: getState().yardIdentifiersData.exclusiveStartKey,
          pageSize,
        }
      );

      dispatch(fetchYardIdentifiersSuccess(responseJson));
    } catch (exception) {
      dispatch(fetchYardIdentifiersFailure(exception.message));
    }
  };
};

export const setYardIdentifiers = (yardIdentifiers) => {
  return {
    type: SET_YARD_IDENTIFIERS,
    payload: yardIdentifiers,
  };
};

const fetchYardIdentifiersRefresh = (payload) => {
  return {
    type: FETCH_YARD_IDENTIFIERS_REFRESH,
    payload,
  };
};

const fetchYardIdentifiersContinue = () => {
  return {
    type: FETCH_YARD_IDENTIFIERS_CONTINUE,
  };
};

const fetchYardIdentifiersSuccess = (yardIdentifiers) => {
  return {
    type: FETCH_YARD_IDENTIFIERS_SUCCESS,
    payload: yardIdentifiers,
  };
};

const fetchYardIdentifiersFailure = (error) => {
  return {
    type: FETCH_YARD_IDENTIFIERS_FAILURE,
    payload: error,
  };
};
