import {
  FETCH_YARD_DEVICES_SUCCESS,
  ADD_YARD_DEVICE_SUCCESS,
  MOVE_YARD_DEVICE_SUCCESS,
  REMOVE_YARD_DEVICE_SUCCESS,
} from "./devicesActionTypes";

export const INITIAL_YARD_DEVICES = {};

export const yardDevicesReducer = (state = INITIAL_YARD_DEVICES, action) => {
  switch (action.type) {
    case FETCH_YARD_DEVICES_SUCCESS: {
      const { yardId, devices } = action.payload;
      return {
        ...state,
        [yardId]: {
          devices,
        },
      };
    }

    case ADD_YARD_DEVICE_SUCCESS: {
      const { yardId, imei } = action.payload;
      return {
        ...state,
        [yardId]: {
          devices: [...state[yardId].devices, { imei }],
        },
      };
    }

    case MOVE_YARD_DEVICE_SUCCESS: {
      const { yardId, targetYardId, imei: imeiToMove } = action.payload;
      return {
        ...state,
        [yardId]: {
          devices: state[yardId].devices.filter(({ imei }) => imei !== imeiToMove),
        },
        ...(state[targetYardId] && {
          [targetYardId]: {
            devices: [...state[targetYardId].devices, { imei: imeiToMove }],
          },
        }),
      };
    }

    case REMOVE_YARD_DEVICE_SUCCESS: {
      const { yardId, imei: imeiToRemove } = action.payload;
      return {
        ...state,
        [yardId]: {
          devices: state[yardId].devices.filter(({ imei }) => imei !== imeiToRemove),
        },
      };
    }

    default:
      return state;
  }
};
