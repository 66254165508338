import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";

import ControlledExpander from "componentsV2/Common/ControlledExpander";
import EditYardDialog from "componentsV2/Common/Dialogs/EditYardDialog";
import { keys } from "i18n";
import {
  ENABLE_YARD_FEATURES_MANAGEMENT,
  SHOW_YARD_TYPE,
  SUPPORTED_LANGUAGES,
  YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH,
} from "app-constants";

export default function YardPropsTable({ yardId }) {
  const [shouldShowEditYardDialog, setShouldShowEditYardDialog] = useState(false);

  const { permissions } = useSelector((state) => state.permissionsData);
  const featureFlags = useSelector((state) => state.featureFlags);
  const { label, type, assetTypes, languages, yardFeatures, enableHostler } = useSelector(
    (state) => state.yardConfigData
  )[yardId];

  const { t } = useTranslation();

  const openEditYardDialog = () => setShouldShowEditYardDialog(true);
  const closeEditYardDialog = () => setShouldShowEditYardDialog(false);

  const canEdit = !!permissions.canEdit;

  return (
    <>
      <ControlledExpander title={t(keys.PROPERTIES)} type="inline" openOnMount={true}>
        <Box type="fill" spacingInset="medium">
          <Column>
            <Row alignmentHorizontal="justify">
              <Heading level={6} color="secondary">
                {t(keys.PROPERTIES)}
              </Heading>
              <Button
                data-testid="editButton"
                type="tertiary"
                disabled={!canEdit}
                onClick={openEditYardDialog}
              >
                {t(keys.EDIT)}
              </Button>
            </Row>

            <Table showDividers={true} spacing="small">
              <TableRow highlightOnHover={true}>
                <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                  {t(keys.YARD_LABEL)}
                </TableCell>
                <TableCell data-testid={label}>{label}</TableCell>
              </TableRow>

              {featureFlags[SHOW_YARD_TYPE]?.enabled && (
                <TableRow highlightOnHover={true}>
                  <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                    {t(keys.YARD_TYPE)}
                  </TableCell>
                  <TableCell data-testid={type}>{t(type)}</TableCell>
                </TableRow>
              )}

              <TableRow highlightOnHover={true}>
                <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                  {t(keys.ASSET_TYPES)}
                </TableCell>
                <TableCell data-testid={assetTypes.join(",")}>
                  {assetTypes.map((assetType) => t(assetType)).join(", ")}
                </TableCell>
              </TableRow>

              <TableRow highlightOnHover={true}>
                <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                  {t(keys.LANGUAGES)}
                </TableCell>
                <TableCell data-testid={languages.join(",")}>
                  {languages.map((language) => SUPPORTED_LANGUAGES[language]).join(", ")}
                </TableCell>
              </TableRow>

              {featureFlags[ENABLE_YARD_FEATURES_MANAGEMENT]?.enabled && (
                <TableRow highlightOnHover={true}>
                  <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                    {t(keys.YARD_FEATURES)}
                  </TableCell>
                  <TableCell data-testid={yardFeatures.join(",")}>
                    {yardFeatures.map((yardFeature) => t(yardFeature)).join(", ")}
                  </TableCell>
                </TableRow>
              )}

              <TableRow highlightOnHover={true}>
                <TableCell width={YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH}>
                  {t(keys.HOSTLER)}
                </TableCell>
                <TableCell>
                  <Checkbox
                    data-testid={`hostler-${enableHostler}`}
                    disabled
                    checked={enableHostler}
                    onChange={() => {}}
                  />
                </TableCell>
              </TableRow>
            </Table>
          </Column>
        </Box>
      </ControlledExpander>

      {shouldShowEditYardDialog && <EditYardDialog yardId={yardId} dismiss={closeEditYardDialog} />}
    </>
  );
}

YardPropsTable.propTypes = {
  yardId: PropTypes.string.isRequired,
};
