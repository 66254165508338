import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";

import ActionBar from "componentsV2/ConfigPage/YardEntity/YardInfo/LocationTable/ActionBar";
import ControlledExpander from "componentsV2/Common/ControlledExpander";
import MoveLocationsAction from "componentsV2/ConfigPage/YardEntity/YardInfo/LocationTable/MoveLocationsAction";
import TableContent from "componentsV2/ConfigPage/YardEntity/YardInfo/LocationTable/TableContent";
import TopActionBar from "componentsV2/ConfigPage/YardEntity/YardInfo/LocationTable/TopActionBar";
import { keys } from "i18n";
import { LOCATION_TABLE, SUPPORT_OFFSITE_MODELING } from "app-constants";

export default function LocationTable({ mainYardId, yardId }) {
  const featureFlags = useSelector((state) => state.featureFlags);
  const { parkingLocations } = useSelector((state) => state.yardConfigData)[yardId];

  const [locations, setLocations] = useState(parkingLocations);
  const [filterString, setFilterString] = useState("");
  const [sortColumn, setSortColumn] = useState(LOCATION_TABLE.keys[0]);
  const [sortDirection, setSortDirection] = useState("ascending");
  const [selectedIds, setSelectedIds] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (locations !== parkingLocations) {
      setLocations(parkingLocations);
    }
  }, [parkingLocations]);

  const { t } = useTranslation();

  return (
    <ControlledExpander title={t(keys.LOCATIONS)} type="inline" openOnMount={true}>
      <Column>
        {featureFlags[SUPPORT_OFFSITE_MODELING]?.enabled && (
          <MoveLocationsAction mainYardId={mainYardId} yardId={yardId} />
        )}

        <Box type="fill" spacingInset="medium">
          <Column>
            <TopActionBar yardId={yardId} locations={locations} setLocations={setLocations} />

            <ActionBar
              yardId={yardId}
              locations={locations}
              filterString={filterString}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              selectedIds={selectedIds}
              setLocations={setLocations}
              setFilterString={setFilterString}
              setSelectedIds={setSelectedIds}
              setPage={setPage}
            />

            <TableContent
              yardId={yardId}
              locations={locations}
              filterString={filterString}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              selectedIds={selectedIds}
              page={page}
              setSortColumn={setSortColumn}
              setSortDirection={setSortDirection}
              setSelectedIds={setSelectedIds}
              setPage={setPage}
            />
          </Column>
        </Box>
      </Column>
    </ControlledExpander>
  );
}

LocationTable.propTypes = {
  mainYardId: PropTypes.string,
  yardId: PropTypes.string.isRequired,
};
