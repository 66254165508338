import React from "react";
import PropTypes from "prop-types";
import { compose } from "lodash/fp";
import { connect } from "react-redux";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import { zipObject, debounce } from "lodash";
import { featureFlags } from "types";

import Modal from "@amzn/meridian/modal";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Checkbox from "@amzn/meridian/checkbox";
import Button from "@amzn/meridian/button";
import Loader from "@amzn/meridian/loader";
import Alert from "@amzn/meridian/alert";

import {
  clearErrorCreatingBuilding,
  clearErrorEditingBuilding,
  createBuildingFailure,
  createBuildingRequest,
  createBuildingSuccess,
  editBuildingFailure,
  editBuildingRequest,
  editBuildingSuccess,
  callAPI,
} from "store";
import { equalsIgnoreCase, generateErrorMessage, includesIgnoreCase } from "helpers";
import {
  ADJACENT,
  APIs,
  BUILDING_CODE_AVAILABILITY_CHECK_DEBOUNCE_WAIT,
  BUILDING_PROPERTY_KEYS,
  ENABLE_BUILDING_FEATURES_MANAGEMENT,
  SEARCH_SCOPE_BUILDING,
} from "app-constants";
import { building } from "types";
import { keys } from "i18n";
import BuildingFeatureSelect from "./BuildingFeatureSelect";

const fields = zipObject(BUILDING_PROPERTY_KEYS, BUILDING_PROPERTY_KEYS);

class BuildingDialog extends React.PureComponent {
  state = {
    delayingCheckingBuildingCodeAvailability: false,
    checkingBuildingCodeAvailability: false,
    buildingCodeUsed: null,
    errorCheckingBuildingCodeAvailability: null,
  };

  getInitialValues = () => ({
    buildingCode: "",
    description: "",
    express: false,
    inboundExpress: false,
    enableSSP: false,
    enableInboundSSP: false,
    buildingFeatures: [],
  });

  validate = (values) => {
    const { t } = this.props;
    return {
      ...(!values.buildingCode && {
        buildingCode: t(keys.REQUIRED_ERROR_MESSAGE),
      }),
      ...(!values.description && {
        description: t(keys.REQUIRED_ERROR_MESSAGE),
      }),
    };
  };

  submit = async (values) => {
    const {
      accountInfo,
      version,
      yardId,
      building,
      editBuildingRequest,
      editBuildingSuccess,
      editBuildingFailure,
      createBuildingRequest,
      createBuildingSuccess,
      createBuildingFailure,
      dismiss,
    } = this.props;

    if (building) {
      // Edit existing building
      editBuildingRequest(yardId);

      try {
        const responseJson = await callAPI(accountInfo, APIs.EDIT_BUILDING_LAYOUT, {
          version,
          ...values,
        });

        editBuildingSuccess({ yardId, yardConfig: responseJson });
        dismiss();
      } catch (exception) {
        editBuildingFailure({ yardId, error: exception.message });
      }
    } else {
      // Create new building
      createBuildingRequest(yardId);

      try {
        const responseJson = await callAPI(accountInfo, APIs.CREATE_BUILDING, {
          version,
          ...values,
          yardBuildingRelations: [{ relationType: ADJACENT, yardId }],
        });

        createBuildingSuccess({ yardId, createBuildingResponse: responseJson });
        dismiss();
      } catch (exception) {
        createBuildingFailure({ yardId, error: exception.message });
      }
    }
  };

  isBuildingCodeSubmittable = (buildingCode) => {
    const {
      delayingCheckingBuildingCodeAvailability,
      checkingBuildingCodeAvailability,
      buildingCodeUsed,
      errorCheckingBuildingCodeAvailability,
    } = this.state;
    return (
      (this.props.building && equalsIgnoreCase(buildingCode, this.props.building.buildingCode)) ||
      (!delayingCheckingBuildingCodeAvailability &&
        !checkingBuildingCodeAvailability &&
        !buildingCodeUsed &&
        !errorCheckingBuildingCodeAvailability)
    );
  };

  buildingCodeOnChange = (setFieldValue) => (buildingCode) => {
    this.setState(
      {
        delayingCheckingBuildingCodeAvailability: true,
        errorCheckingBuildingCodeAvailability: null,
      },
      () => this.checkBuildingCodeAvailability(buildingCode)
    );
    return setFieldValue(fields.buildingCode, buildingCode);
  };

  checkBuildingCodeAvailability = debounce(async (buildingCode) => {
    const { accountInfo } = this.props;

    if (
      !buildingCode ||
      (this.props.building && equalsIgnoreCase(buildingCode, this.props.building.buildingCode))
    ) {
      this.setState({
        delayingCheckingBuildingCodeAvailability: false,
        buildingCodeUsed: null,
      });
      return;
    }

    this.setState({
      delayingCheckingBuildingCodeAvailability: false,
      checkingBuildingCodeAvailability: true,
    });

    try {
      const { yardIdentifiers } = await callAPI(accountInfo, APIs.LIST_SUPPORTED_YARD_IDENTIFIERS, {
        searchString: buildingCode,
        searchScope: SEARCH_SCOPE_BUILDING,
      });

      const buildingCodeUsed = yardIdentifiers.some((yardIdentifier) =>
        includesIgnoreCase(yardIdentifier.buildingCodes, buildingCode)
      );
      this.setState({
        checkingBuildingCodeAvailability: false,
        buildingCodeUsed,
      });
    } catch (exception) {
      this.setState({
        checkingBuildingCodeAvailability: false,
        buildingCodeUsed: null,
        errorCheckingBuildingCodeAvailability: exception.message,
      });
    }
  }, BUILDING_CODE_AVAILABILITY_CHECK_DEBOUNCE_WAIT);

  handleChange = (setFieldValue, field) => (val) => setFieldValue(field, val);

  renderBuildingCodeAvailabilityCheck = (buildingCode) => {
    const { t } = this.props;
    const {
      delayingCheckingBuildingCodeAvailability,
      checkingBuildingCodeAvailability,
      buildingCodeUsed,
      errorCheckingBuildingCodeAvailability,
    } = this.state;
    if (
      !buildingCode ||
      (this.props.building && equalsIgnoreCase(buildingCode, this.props.building.buildingCode)) ||
      delayingCheckingBuildingCodeAvailability ||
      errorCheckingBuildingCodeAvailability
    )
      return null;
    if (checkingBuildingCodeAvailability) return <Loader size="medium" />;
    return buildingCodeUsed ? (
      <Alert size="small" type="error">
        {t(keys.BUILDING_CODE_USED)}
      </Alert>
    ) : (
      <Alert size="small" type="success">
        {t(keys.BUILDING_CODE_AVAILABLE)}
      </Alert>
    );
  };

  render() {
    const { t, yardConfigData, yardId, building, dismiss, featureFlags } = this.props;
    const { errorCheckingBuildingCodeAvailability } = this.state;
    const { creatingBuilding, errorCreatingBuilding, editingBuilding, errorEditingBuilding } =
      yardConfigData[yardId];
    return (
      <Modal
        width="400px"
        bodySpacingInset="medium"
        title={building ? t(keys.EDIT_BUILDING) : t(keys.ADD_BUILDING)}
        open={true}
      >
        <Formik
          initialValues={building || this.getInitialValues()}
          // invalidate form on initialization for adding building
          // also invalidate for editing building, which is unintended, but nice to have
          initialErrors={this.validate(this.getInitialValues())}
          validate={this.validate}
          onSubmit={this.submit}
        >
          {({ values, isValid, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Column spacing="medium" spacingInset="none none large none">
                <Row>
                  <Input
                    width="250px"
                    name={fields.buildingCode}
                    type="text"
                    label={`${t(keys.BUILDING_CODE)}*`}
                    value={values.buildingCode}
                    disabled={!!building}
                    onChange={this.buildingCodeOnChange(setFieldValue)}
                  />
                  {this.renderBuildingCodeAvailabilityCheck(values.buildingCode)}
                </Row>
                {errorCheckingBuildingCodeAvailability && (
                  <Alert size="small" type="error">
                    {t(keys.CHECK_BUILDING_CODE_AVAILABILITY_ERROR_MESSAGE)}
                  </Alert>
                )}

                <Input
                  width="250px"
                  name={fields.description}
                  type="text"
                  label={`${t(keys.BUILDING_DESCRIPTION)}*`}
                  value={values.description}
                  onChange={this.handleChange(setFieldValue, fields.description)}
                />

                <Row widths={["120px"]}>
                  <Checkbox
                    name={fields.express}
                    checked={values.express}
                    onChange={this.handleChange(setFieldValue, fields.express)}
                  >
                    {t(keys.OB_EXPRESS)}
                  </Checkbox>
                  <Checkbox
                    name={fields.enableSSP}
                    checked={values.enableSSP}
                    onChange={this.handleChange(setFieldValue, fields.enableSSP)}
                  >
                    {t(keys.SSP_OB)}
                  </Checkbox>
                </Row>

                <Row widths={["120px"]}>
                  <Checkbox
                    name={fields.inboundExpress}
                    checked={values.inboundExpress}
                    onChange={this.handleChange(setFieldValue, fields.inboundExpress)}
                  >
                    {t(keys.IB_EXPRESS)}
                  </Checkbox>
                  <Checkbox
                    name={fields.enableInboundSSP}
                    checked={values.enableInboundSSP}
                    onChange={this.handleChange(setFieldValue, fields.enableInboundSSP)}
                  >
                    {t(keys.SSP_IB)}
                  </Checkbox>
                </Row>

                {featureFlags[ENABLE_BUILDING_FEATURES_MANAGEMENT]?.enabled && (
                  <BuildingFeatureSelect
                    buildingFeatures={values.buildingFeatures}
                    setBuildingFeatures={this.handleChange(setFieldValue, fields.buildingFeatures)}
                  />
                )}
              </Column>

              <Row alignmentHorizontal="right">
                <Button
                  type="secondary"
                  minWidth="80px"
                  onClick={() => {
                    if (building) {
                      this.props.clearErrorEditingBuilding(yardId);
                    } else {
                      this.props.clearErrorCreatingBuilding(yardId);
                    }
                    dismiss();
                  }}
                >
                  {t(keys.CANCEL)}
                </Button>
                {creatingBuilding || editingBuilding ? (
                  <Loader size="medium" />
                ) : (
                  <Button
                    type="primary"
                    minWidth="80px"
                    disabled={!isValid || !this.isBuildingCodeSubmittable(values.buildingCode)}
                    onClick={handleSubmit}
                  >
                    {building ? t(keys.EDIT) : t(keys.ADD)}
                  </Button>
                )}
              </Row>

              {errorCreatingBuilding && (
                <Row alignmentHorizontal="right" spacingInset="medium none">
                  <Alert size="small" type="error">
                    {generateErrorMessage(
                      t(keys.CREATE_BUILDING_ERROR_MESSAGE),
                      errorCreatingBuilding
                    )}
                  </Alert>
                </Row>
              )}

              {errorEditingBuilding && (
                <Row alignmentHorizontal="right" spacingInset="medium none">
                  <Alert size="small" type="error">
                    {generateErrorMessage(
                      t(keys.EDIT_BUILDING_ERROR_MESSAGE),
                      errorEditingBuilding
                    )}
                  </Alert>
                </Row>
              )}
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo,
  yardConfigData: state.yardConfigData,
  featureFlags: state.featureFlags,
});

const mapDispatchToProps = (dispatch) => ({
  createBuildingRequest: (yardId) => {
    dispatch(createBuildingRequest(yardId));
  },
  createBuildingSuccess: (payload) => {
    dispatch(createBuildingSuccess(payload));
  },
  createBuildingFailure: (payload) => {
    dispatch(createBuildingFailure(payload));
  },
  editBuildingRequest: (yardId) => {
    dispatch(editBuildingRequest(yardId));
  },
  editBuildingSuccess: (payload) => {
    dispatch(editBuildingSuccess(payload));
  },
  editBuildingFailure: (payload) => {
    dispatch(editBuildingFailure(payload));
  },
  clearErrorCreatingBuilding: (yardId) => {
    dispatch(clearErrorCreatingBuilding(yardId));
  },
  clearErrorEditingBuilding: (yardId) => {
    dispatch(clearErrorEditingBuilding(yardId));
  },
});

const withTranslationsAndMapStateAndDispatchToProps = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
);

export { BuildingDialog };
export default withTranslationsAndMapStateAndDispatchToProps(BuildingDialog);

BuildingDialog.propTypes = {
  version: PropTypes.number.isRequired,
  yardId: PropTypes.string.isRequired,
  building: building,
  dismiss: PropTypes.func.isRequired,
};
