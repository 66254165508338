import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";

import Column from "@amzn/meridian/column";
import Tag from "@amzn/meridian/tag";

import { keys } from "i18n";
import { STATUS_TYPES } from "app-constants";

export default function TableCellContent({
  columnKey,
  item,
  originalItem,
  formatTableCellContent,
}) {
  const featureFlags = useSelector((state) => state.featureFlags);

  const { t } = useTranslation();

  const isItemNew = !originalItem;
  const isItemModified = !isItemNew && !isEqual(item, originalItem);
  const isValueModified = isItemModified && !isEqual(item[columnKey], originalItem[columnKey]);

  const className = isItemNew || isValueModified ? "base-table-cell-new-modified" : "";

  return (
    <Column spacing="none" alignmentHorizontal="start">
      <span data-testid="tableCellContent" className={className}>
        {formatTableCellContent(columnKey, item, featureFlags, t)}
      </span>

      {columnKey === "label" && isItemNew && (
        <Tag data-testid="newTag" type={STATUS_TYPES.SUCCESS}>
          {t(keys.NEW)}
        </Tag>
      )}
      {columnKey === "label" && isItemModified && (
        <Tag data-testid="changedTag" type={STATUS_TYPES.WARNING}>
          {t(keys.CHANGED)}
        </Tag>
      )}
    </Column>
  );
}

TableCellContent.propTypes = {
  columnKey: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  originalItem: PropTypes.object,
  formatTableCellContent: PropTypes.func.isRequired,
};
