import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Column from "@amzn/meridian/column";

import Breadcrumb from "componentsV2/Common/Headers/Breadcrumb";
import MapPageBody from "componentsV2/MapPage/MapPageBody";
import Headers from "componentsV2/Common/Headers";
import PageBody from "componentsV2/Common/PageBody";
import { useYardConfigInit } from "hooks";
import { fetchYardMap } from "store";
import { YARD_LINKS } from "app-constants";

export default function MapPage() {
  const { yardId } = useParams();

  const dispatch = useDispatch();

  const { activeAccountName } = useSelector((state) => state.accountInfo);
  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];

  const { label } = yardConfig || {};

  useEffect(() => {
    if (activeAccountName) {
      dispatch(fetchYardMap(yardId));
    }
  }, [activeAccountName]);

  useYardConfigInit(yardId);

  return (
    <Column spacing="large" spacingInset="large">
      <Headers>
        <Breadcrumb yardId={yardId} yardLabel={label} yardLink={YARD_LINKS.map} />
      </Headers>

      <PageBody yardId={yardId}>
        <MapPageBody yardId={yardId} />
      </PageBody>
    </Column>
  );
}

MapPage.propTypes = {};
