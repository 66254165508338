import { SAVE_ACCOUNT_INFO } from "./accountInfoActionTypes";

const INITIAL_ACCOUNT_INFO = {
  activeAccountName: null,
  realm: null,
  domain: null,
};

export const accountInfoReducer = (state = INITIAL_ACCOUNT_INFO, action) => {
  switch (action.type) {
    case SAVE_ACCOUNT_INFO:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};
