import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Button from "@amzn/meridian/button";

import SubscriptionDialog from "component/SubscriptionDisplayer/SubscriptionDialog";
import SaveSubscriptionsDialog from "component/SubscriptionDisplayer/SaveSubscriptionsDialog";
import SubscriptionTable from "component/SubscriptionDisplayer/SubscriptionTable";
import { keys } from "i18n";
import { convertToMapById, doListsContainSameElements } from "helpers";

class SubscriptionDisplayer extends React.PureComponent {
  state = {
    showSubscriptionDialog: false,
    showSaveSubscriptionsDialog: false,
    subscriptionToEdit: null,
    subscriptions: this.props.subscriptions,
    currentPage: 1,
    tableKey: 1,
  };

  componentDidUpdate(prevProps) {
    if (!doListsContainSameElements(this.props.subscriptions, prevProps.subscriptions)) {
      this.setState({
        subscriptions: this.props.subscriptions,
        tableKey: this.state.tableKey + 1,
      });
    }
  }

  setPageNumber = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  openSaveSubscriptionsDialog = () => {
    this.setState({ showSaveSubscriptionsDialog: true });
  };

  openEditSubscriptionDialog = () => {
    this.setState({
      showSubscriptionDialog: true,
      subscriptionToEdit: null,
    });
  };

  closeEditSubscriptionDialog = () => {
    this.setState({ showSubscriptionDialog: false });
  };

  closeSaveSubscriptionsDialog = () => {
    this.setState({ showSaveSubscriptionsDialog: false });
  };

  addNewSubscription = (values) => {
    const { subscriptions } = this.state;
    this.setState({
      showSubscriptionDialog: false,
      subscriptions: [...subscriptions, values],
      tableKey: this.state.tableKey + 1,
    });
  };

  showEditDialog = ([subscriptionToEdit]) => {
    this.setState({ showSubscriptionDialog: true, subscriptionToEdit });
  };

  editSubscription = (values) => {
    const { subscriptions } = this.state;
    this.setState({
      showSubscriptionDialog: false,
      subscriptions: subscriptions.map((subscription) =>
        subscription.id === values.id ? values : subscription
      ),
      tableKey: this.state.tableKey + 1,
    });
  };

  removeSubscription = (subscriptionsToRemove) => {
    const { subscriptions } = this.state;
    this.setState({
      subscriptions: subscriptions.filter(
        (subscription) =>
          !subscriptionsToRemove.some(
            (subscriptionToRemove) => subscriptionToRemove.id === subscription.id
          )
      ),
      tableKey: this.state.tableKey + 1,
    });
  };

  restoreSubscription = ([subscriptionToRestore]) => {
    const { subscriptions } = this.state;
    const idToOriginalSubscriptionMap = convertToMapById(this.props.subscriptions);
    const originalSubscription = idToOriginalSubscriptionMap[subscriptionToRestore.id];
    this.setState({
      subscriptions: subscriptions.map((subscription) =>
        subscription.id === subscriptionToRestore.id ? originalSubscription : subscription
      ),
      tableKey: this.state.tableKey + 1,
    });
  };

  areSubscriptionsChanged = () => {
    const { subscriptions: originalSubscriptions } = this.props;
    const { subscriptions } = this.state;
    return !doListsContainSameElements(originalSubscriptions, subscriptions);
  };

  render() {
    const { t, canEdit, yardId, buildingCode, subscriptions: originalSubscriptions } = this.props;
    const {
      showSubscriptionDialog,
      showSaveSubscriptionsDialog,
      subscriptionToEdit,
      subscriptions,
      tableKey,
    } = this.state;
    const idToOriginalSubscriptionMap = convertToMapById(originalSubscriptions);
    const areSubscriptionsChanged = this.areSubscriptionsChanged();

    return (
      <Column spacing="medium" data-testid="subscriptionDisplayerContainer">
        <Row alignmentHorizontal="justify">
          <Heading level={6} color="secondary">
            {t(keys.SUBSCRIPTIONS)}
          </Heading>
          <Row>
            <Button
              type="tertiary"
              disabled={!canEdit}
              onClick={this.openEditSubscriptionDialog}
              data-testid="newSubscriptionButton"
            >
              {t(keys.NEW_SUBSCRIPTION)}
            </Button>
            <Button
              type="tertiary"
              disabled={!canEdit || !areSubscriptionsChanged}
              onClick={this.openSaveSubscriptionsDialog}
              data-testid="saveSubscriptionButton"
            >
              {t(keys.SAVE)}
            </Button>
          </Row>
        </Row>

        <SubscriptionTable
          key={tableKey}
          canEdit={canEdit}
          subscriptions={subscriptions}
          idToOriginalSubscriptionMap={idToOriginalSubscriptionMap}
          onEdit={this.showEditDialog}
          onRemove={this.removeSubscription}
          onRestore={this.restoreSubscription}
          currentPage={this.state.currentPage}
          setPageNumber={this.setPageNumber}
        />

        {showSubscriptionDialog && (
          <SubscriptionDialog
            yardId={yardId}
            buildingCode={buildingCode}
            subscription={subscriptionToEdit}
            subscriptions={subscriptions}
            add={this.addNewSubscription}
            edit={this.editSubscription}
            dismiss={this.closeEditSubscriptionDialog}
          />
        )}

        {showSaveSubscriptionsDialog && (
          <SaveSubscriptionsDialog
            yardId={yardId}
            buildingCode={buildingCode}
            subscriptions={subscriptions}
            originalSubscriptions={originalSubscriptions}
            dismiss={this.closeSaveSubscriptionsDialog}
          />
        )}
      </Column>
    );
  }
}

export { SubscriptionDisplayer };

export default withTranslation()(SubscriptionDisplayer);

SubscriptionDisplayer.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  yardId: PropTypes.string.isRequired,
  buildingCode: PropTypes.string.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
