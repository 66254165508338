import {
  CLEAR_ERROR_SAVING_GATES,
  SAVE_GATES,
  SAVE_GATES_FAILURE,
  SAVE_GATES_SUCCESS,
} from "./saveGatesActionTypes";

export const saveGatesRequest = (yardId) => {
  return {
    type: SAVE_GATES,
    payload: yardId,
  };
};

// payload includes yardId and yardConfig
export const saveGatesSuccess = (payload) => {
  return {
    type: SAVE_GATES_SUCCESS,
    payload,
  };
};

// payload includes yardId and error
export const saveGatesFailure = (payload) => {
  return {
    type: SAVE_GATES_FAILURE,
    payload,
  };
};

export const clearErrorSavingGates = (yardId) => {
  return {
    type: CLEAR_ERROR_SAVING_GATES,
    payload: yardId,
  };
};
