import { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "@amzn/meridian-tokens/base/font/amazon-ember.css";
import "styles/leaflet.scss";

// Fix for issue where leaflet marker and marker shadow icons are not displayed
// https://github.com/Leaflet/Leaflet/issues/4968#issuecomment-399857656
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";
import "leaflet-defaulticon-compatibility";

import "styles/styles.scss";

import { store } from "store/store";
import { storeDev } from "store/store.dev";

import App from "componentsV2/App";

ReactDOM.render(
  <Provider store={process.env.NODE_ENV === "development" ? storeDev : store}>
    <BrowserRouter basename="/yms/yardConfig">
      <Suspense fallback="Loading...">
        <App />
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById("app")
);
