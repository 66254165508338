import { generateRandomFloat } from "./commonHelpers";
import {
  ENABLE_DDU_SUPPORT,
  ENABLE_DSP_SUPPORT,
  ENABLE_GATE_BADGE_SCANNER_MANAGEMENT,
} from "app-constants";

export const extractGateLanes = (gates) => {
  const gateLanes = [];
  gates?.forEach((gate) =>
    gate.lanes?.forEach((lane) => {
      let gateLane = {
        id: lane.id,
        laneIdentifier: lane.laneIdentifier ?? "LANE1", // empty laneIdentifier is configured before multi-lane
        gateId: gate.id,
        gateIdentifier: gate.gateIdentifier ?? "GATE1", // empty gateIdentifier is configured before multi-lane
        label: lane.label,
        purpose: lane.purpose,
        r4gMode: lane.r4gMode,
        kfbAccessPointId: lane.kfbAccessPointId,
        intercomPhoneNumber: lane.intercomPhoneNumber,
        badgeScannerInfo: lane.badgeScannerInfo,
      };
      gateLanes.push(gateLane);
    })
  );
  return gateLanes;
};

export const getGateIdentifierFromGate = (gate) => {
  // Single lane gate configured through legacy gate config UI has gateIdentifier as null. UI will assign a default value GATE1.
  if (gate.gateIdentifier == null) {
    return "GATE1";
  }
  return gate.gateIdentifier;
};

export const getGateIdentifierNumberFromGate = (gate) => {
  return getGateIdentifierFromGate(gate).charAt(4);
};

export const getNewGateIdentifier = (gates) => {
  const gateIdentifierNumberArray = gates.map((gate) => getGateIdentifierNumberFromGate(gate));
  const newGateIdentifierNumber =
    gateIdentifierNumberArray.length === 0 ? 1 : Math.max(...gateIdentifierNumberArray) + 1;
  return "GATE" + newGateIdentifierNumber;
};

export const getApplicableLanesForMasking = (gates, gateId) => {
  return extractGateLanes(gates)
    .filter((gateLane) => gateLane.gateIdentifier === gateId)
    .reduce((map, gateLane) => {
      const transformedLane = {
        purpose: gateLane.purpose,
        laneId: gateLane.laneIdentifier,
        laneNumber: gateLane.laneIdentifier?.replace(/^(LANE)/, ""),
        key: `${gateLane.purpose}-${gateLane.laneIdentifier}`,
      };
      map.set(transformedLane.key, transformedLane);
      return map;
    }, new Map());
};

export const generateClientId = () => {
  // We are generating the same clientID as R4G. This means we could have collisions with R4G users,
  // but we are deliberately choosing to do this because of previous API Gateway LSE experience
  // more details on https://quip-amazon.com/jitmAxmuotcY/R4G-KVS-Sev-2-mitigation-on-client-ID-not-disconnecting-due-to-API-gateway-LSE
  const index = Math.floor(generateRandomFloat() * 10);
  return `r4g-kvs-client-id-${index}`;
};

export const shouldShowGateBadgeScannerInfoForYard = (featureFlags, yardData) => {
  if (featureFlags[ENABLE_GATE_BADGE_SCANNER_MANAGEMENT]?.enabled) {
    const { buildings, yardFeatures } = yardData;
    const isDspSupportEnabled = yardFeatures?.includes(ENABLE_DSP_SUPPORT);
    const isDduSupportEnabledForAnyBuilding = buildings?.some((building) =>
      building.buildingFeatures?.includes(ENABLE_DDU_SUPPORT)
    );
    return isDspSupportEnabled || isDduSupportEnabledForAnyBuilding;
  }
  return false;
};
