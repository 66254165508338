import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { zipObject } from "lodash";

import BaseTable from "componentsV2/Common/BaseTable";
import { formatTableCellContent, prepareTableColumns } from "helpers";
import { location } from "types";
import { keys } from "i18n";

export default function TableContent({
  yardId,
  locations,
  filterString,
  sortColumn,
  sortDirection,
  selectedIds,
  page,
  setSortColumn,
  setSortDirection,
  setSelectedIds,
  setPage,
}) {
  const featureFlags = useSelector((state) => state.featureFlags);
  const { parkingLocations } = useSelector((state) => state.yardConfigData)[yardId];

  const handleSort = ({ sortColumn, sortDirection }) => {
    setSortColumn(sortColumn);
    setSortDirection(sortDirection);
  };

  const tableColumns = prepareTableColumns(featureFlags);
  const idToOriginalLocationMap = zipObject(
    parkingLocations.map(({ id }) => id),
    parkingLocations
  );

  return (
    <BaseTable
      tableColumns={tableColumns}
      idToOriginalItemMap={idToOriginalLocationMap}
      itemsLabelLowerCase={keys.LOCATIONS_LOWER_CASE}
      items={locations}
      filterString={filterString}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      selectedIds={selectedIds}
      page={page}
      handleSort={handleSort}
      setSelectedIds={setSelectedIds}
      formatTableCellContent={formatTableCellContent}
      setPage={setPage}
    />
  );
}

TableContent.propTypes = {
  yardId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(location).isRequired,
  filterString: PropTypes.string.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  page: PropTypes.number.isRequired,
  setSortColumn: PropTypes.func.isRequired,
  setSortDirection: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};
