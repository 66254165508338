import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Column from "@amzn/meridian/column";

import ActionBar from "componentsV2/ConfigPage/ActionBar";
import Breadcrumb from "componentsV2/Common/Headers/Breadcrumb";
import Headers from "componentsV2/Common/Headers";
import PageBody from "componentsV2/Common/PageBody";
import YardEntity from "componentsV2/ConfigPage/YardEntity";
import { useYardConfigInit } from "hooks";
import { YARD_LINKS } from "app-constants";

export default function ConfigPage() {
  const { yardId } = useParams();

  const [tab, setTab] = useState(yardId);

  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];

  const { label } = yardConfig || {};

  useYardConfigInit(yardId);

  return (
    <Column spacing="large" spacingInset="large">
      <Headers>
        <Breadcrumb yardId={yardId} yardLabel={label} yardLink={YARD_LINKS.config} />
      </Headers>

      <PageBody yardId={yardId}>
        <Column>
          <ActionBar yardId={yardId} tab={tab} setTab={setTab} />
          <YardEntity yardId={yardId} tab={tab} setTab={setTab} />
        </Column>
      </PageBody>
    </Column>
  );
}

ConfigPage.propTypes = {};
