import { useRef } from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";

import { formatLocationsForCSV, prepareCSVColumns } from "helpers";
import { location } from "types";
import { keys } from "i18n";

export default function LocationCSVDownload({
  buttonType,
  locations,
  sortColumn,
  sortDirection,
  downloadCallback,
}) {
  const featureFlags = useSelector((state) => state.featureFlags);

  const csvLinkRef = useRef();

  const { t } = useTranslation();

  const downloadCSV = () => {
    // https://github.com/react-csv/react-csv/issues/72#issuecomment-421160842
    csvLinkRef.current.link.click();

    downloadCallback && downloadCallback();
  };

  const csvColumns = prepareCSVColumns(featureFlags);
  const headers = csvColumns.labels.map((label, index) => ({ label, key: csvColumns.keys[index] }));
  const data = formatLocationsForCSV(locations, sortColumn, sortDirection, t);

  return (
    <Row spacing="none">
      <Button data-testid="downloadCSVButton" type={buttonType || "link"} onClick={downloadCSV}>
        <Icon tokens={downloadLargeTokens} />
        {t(keys.DOWNLOAD_CSV)}
      </Button>

      <CSVLink
        data-testid="csvLink"
        ref={csvLinkRef}
        headers={headers}
        data={data}
        filename="locations.csv"
        enclosingCharacter='"'
        target="_blank"
      />
    </Row>
  );
}

LocationCSVDownload.propTypes = {
  buttonType: PropTypes.string,
  locations: PropTypes.arrayOf(location).isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  downloadCallback: PropTypes.func,
};
