import config from "app-config";
import { POST } from "app-constants";

export const callAPI = async (accountInfo, API, body) => {
  const response = await fetch(getApiEndpoint(accountInfo, config[API].resource), {
    method: POST,
    headers: {
      token: window.securityToken,
      api: config[API].name,
      method: POST,
    },
    ...(body && { body: JSON.stringify(body) }),
  });

  if (response.ok) {
    return await response.json();
  } else {
    const message = await response.text();
    throw Error(message);
  }
};

export const getApiEndpoint = (accountInfo, resource) =>
  `${config[accountInfo.realm][accountInfo.domain]}${resource}`;
