import PropTypes from "prop-types";

import Button from "@amzn/meridian/button";
import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import closeSmallTokens from "@amzn/meridian-tokens/base/icon/close-small";

export default function ItemList({ items, formatItem, removeItem }) {
  return (
    <Column spacing="200">
      {items.map((item) => (
        <Card key={item} spacingInset="200 400" onClick={() => {}}>
          <Row alignmentHorizontal="justify">
            <Text data-testid={item}>{formatItem ? formatItem(item) : item}</Text>

            <Button
              data-testid={`closeButton-${item}`}
              type="icon"
              size="small"
              onClick={() => removeItem(item)}
            >
              <Icon tokens={closeSmallTokens}>Close</Icon>
            </Button>
          </Row>
        </Card>
      ))}
    </Column>
  );
}

ItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  formatItem: PropTypes.func,
  removeItem: PropTypes.func.isRequired,
};
