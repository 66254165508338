import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Select, { SelectOption } from "@amzn/meridian/select";

import { keys } from "i18n";
import { YARD_TYPE_LIST } from "app-constants";

export default function YardTypeSelect({ yardConfig, setYardConfig, setSubmittable }) {
  const { type } = yardConfig;

  useEffect(() => {
    setSubmittable((submittable) => ({ ...submittable, isTypeValid: !!type }));
  }, [type]);

  const handleChange = (type) => setYardConfig({ ...yardConfig, type });

  const { t } = useTranslation();

  return (
    <Select
      data-testid="yardTypeSelect"
      label={t(keys.YARD_TYPE)}
      placeholder={t(keys.SELECT_YARD_TYPE)}
      value={type}
      onChange={handleChange}
    >
      {YARD_TYPE_LIST.map((type) => (
        <SelectOption data-testid={type} key={type} value={type} label={t(type)} />
      ))}
    </Select>
  );
}

YardTypeSelect.propTypes = {
  yardConfig: PropTypes.object.isRequired,
  setYardConfig: PropTypes.func.isRequired,
  setSubmittable: PropTypes.func.isRequired,
};
