import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Alert from "@amzn/meridian/alert";
import FileInput from "@amzn/meridian/file-input";
import Toaster from "@amzn/meridian/toaster";

import {
  doListsContainSameElements,
  extractLocationsFromCSV,
  prepareCSVColumns,
  replaceUnknownIdsByTempIds,
} from "helpers";
import { location } from "types";
import { keys } from "i18n";
import { LOCATION_FILE_SUPPORTED_EXTENSIONS, STATUS_TYPES } from "app-constants";

export default function CSVUpload({ locations, setLocations }) {
  const [toasts, setToasts] = useState([]);

  const { permissions } = useSelector((state) => state.permissionsData);
  const featureFlags = useSelector((state) => state.featureFlags);

  const { t } = useTranslation();

  const expectedColumns = prepareCSVColumns(featureFlags);

  const saveExtractedLocations = (extractedLocations) => {
    if (!doListsContainSameElements(locations, extractedLocations)) {
      setLocations(replaceUnknownIdsByTempIds(locations, extractedLocations));
    }
  };

  const handleFileAttached = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const [extension] = file.name.split(".").slice(-1);
    if (LOCATION_FILE_SUPPORTED_EXTENSIONS.includes(extension)) {
      const reader = new FileReader();

      reader.onload = () => {
        let extractedLocations;
        try {
          extractedLocations = extractLocationsFromCSV(reader.result, expectedColumns, t);
        } catch (e) {
          handleError(e, e.cause);
        }

        extractedLocations && saveExtractedLocations(extractedLocations);
      };

      reader.readAsText(file);
    } else {
      handleError(null, "file-extension-not-supported");
    }
  };

  const handleError = (e, cause) => {
    let message;

    switch (cause) {
      case "file-extension-not-supported": {
        message = t(keys.FILE_EXTENSION_NOT_SUPPORTED_MESSAGE);
        break;
      }
      case "invalid-csv": {
        message = t(keys.CSV_PARSE_FAILURE);
        break;
      }
      case "csv-invalid-header": {
        message = t(keys.LOCATION_FILE_INVALID_HEADER);
        break;
      }
      default: {
        message = t(keys.CSV_UPLOAD_FAILURE);
      }
    }

    setToasts(
      toasts.concat({
        id: cause || "unhandled-error",
        type: STATUS_TYPES.ERROR,
        message,
      })
    );
    e && console.error(e.message);
  };

  const closeToast = (id) => {
    setToasts(toasts.filter((toast) => toast.id !== id));
  };

  const canEdit = !!permissions.canEdit;

  if (!canEdit) {
    return null;
  }

  return (
    <>
      <FileInput
        data-testid="csvUpload"
        uploadButtonLabel={t(keys.UPLOAD_CSV)}
        uploadButtonType="link"
        onFileAttached={handleFileAttached}
      />

      <Toaster toasts={toasts} onCloseToast={closeToast}>
        {({ id, type, message, onClose }) => (
          <Alert data-testid={id} toast={true} type={type} onClose={onClose}>
            {message}
          </Alert>
        )}
      </Toaster>
    </>
  );
}

CSVUpload.propTypes = {
  locations: PropTypes.arrayOf(location).isRequired,
  setLocations: PropTypes.func.isRequired,
};
