import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Column from "@amzn/meridian/column";

import ActionBar from "componentsV2/CameraMaskingPage/ActionBar";
import PageBody from "componentsV2/Common/PageBody";
import Headers from "componentsV2/Common/Headers";
import { useYardConfigInit } from "hooks";
import CommunicationPanel from "./CommunicationPanel";
import { keys } from "i18n";
import { useTranslation } from "react-i18next";
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";
import { useSelector } from "react-redux";
import Loader from "@amzn/meridian/loader";
import AnnotationPanel from "./AnnotationPanel";

export default function CameraMaskingPage() {
  const { yardId, gateId } = useParams();
  const [isLoadingChannels, setIsLoadingChannels] = useState(false);
  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];
  const { label } = yardConfig || {};
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [channels, setChannels] = useState(null);
  const [showAnnotationPanel, setshowAnnotationPanel] = useState(false);
  const [cameraInfo, setCameraInfo] = useState(null);
  const [annotationImageUrl, setAnnotationImageUrl] = useState("");

  useYardConfigInit(yardId);

  return (
    <Column spacing="large" spacingInset="large">
      <Headers>
        <BreadcrumbGroup>
          <Breadcrumb data-testid="homeLink" href="/" onClick={() => navigate("/")}>
            {t(keys.HOME)}
          </Breadcrumb>
          <Breadcrumb
            data-testid="yardLink-config"
            href={`/yards/${yardId}/config`}
            onClick={() => navigate(`/yards/${yardId}/config`)}
          >
            {t(keys.YARD)} {label} {t(keys.CONFIG)}
          </Breadcrumb>
          <Breadcrumb data-testid="cameraMasksLink" href={`/yards/${yardId}/config/cameraMasking`}>
            {`${gateId} ${t(keys.CAMERA_MASKS)}`}
          </Breadcrumb>
        </BreadcrumbGroup>
      </Headers>

      <PageBody yardId={yardId}>
        <Column width="100%">
          {!showAnnotationPanel && (
            <Column>
              <ActionBar
                yardId={yardId}
                gateId={gateId}
                setChannels={setChannels}
                isLoadingChannels={isLoadingChannels}
                setIsLoadingChannels={setIsLoadingChannels}
              />
              {!isLoadingChannels && channels && (
                <CommunicationPanel
                  gateId={gateId}
                  yardId={yardId}
                  channels={channels}
                  setshowAnnotationPanel={setshowAnnotationPanel}
                  setCameraInfo={setCameraInfo}
                  setAnnotationImageUrl={setAnnotationImageUrl}
                />
              )}
              {isLoadingChannels && <Loader />}
            </Column>
          )}

          {showAnnotationPanel && (
            <AnnotationPanel
              cameraInfo={cameraInfo}
              imageUrl={annotationImageUrl}
              setChannels={setChannels}
              setShowAnnotationPanel={setshowAnnotationPanel}
            />
          )}
        </Column>
      </PageBody>
    </Column>
  );
}

CameraMaskingPage.propTypes = {};
