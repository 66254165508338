import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import YardDialog from "componentsV2/Common/Dialogs/YardDialog";
import { createYard } from "store";
import { keys } from "i18n";

export default function AddOffsiteYardDialog({ mainYardId, dismiss }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const submit = ({ yardConfig, successCallback, failureCallback }) =>
    dispatch(createYard({ mainYardId, yardConfig, successCallback, failureCallback }));

  return (
    <YardDialog
      title={t(keys.ADD_OFFSITE_YARD)}
      genericErrorMessage={t(keys.CREATE_OFFSITE_YARD_ERROR_MESSAGE)}
      submit={submit}
      dismiss={dismiss}
    />
  );
}

AddOffsiteYardDialog.propTypes = {
  mainYardId: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
};
