import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import Alert from "@amzn/meridian/alert";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import SearchField, { SearchSuggestion } from "@amzn/meridian/search-field";

import { callAPI } from "store";
import { keys } from "i18n";
import { APIs, SEARCH_DEBOUNCE_WAIT, SEARCH_SCOPE_BUILDING, STATUS_TYPES } from "app-constants";

export default function BuildingCodeSearch({
  searchString,
  setSearchString,
  setSelectedBuildingCode,
  setSelectedYardIdentifier,
}) {
  const [matchingYardIdentifiers, setMatchingYardIdentifiers] = useState([]);
  const [searchingBuildingCode, setSearchingBuildingCode] = useState(false);
  const [errorSearchingBuildingCode, setErrorSearchingBuildingCode] = useState(null);

  const accountInfo = useSelector((state) => state.accountInfo);

  useEffect(() => {
    debouncedFetchSearchResults(searchString);
  }, [searchString]);

  const { t } = useTranslation();

  const fetchSearchResults = async (searchString) => {
    if (!searchString) {
      setMatchingYardIdentifiers([]);
      setSelectedYardIdentifier(null);
      setErrorSearchingBuildingCode(null);
      return;
    }

    try {
      setSearchingBuildingCode(true);
      setErrorSearchingBuildingCode(null);

      const { yardIdentifiers } = await callAPI(accountInfo, APIs.LIST_SUPPORTED_YARD_IDENTIFIERS, {
        searchString,
        searchScope: SEARCH_SCOPE_BUILDING,
      });

      setMatchingYardIdentifiers(yardIdentifiers);
    } catch (exception) {
      setErrorSearchingBuildingCode(exception.message);
    } finally {
      setSearchingBuildingCode(false);
    }
  };

  const debouncedFetchSearchResults = useCallback(
    debounce(fetchSearchResults, SEARCH_DEBOUNCE_WAIT),
    []
  );

  return (
    <SearchField
      data-testid="buildingCodeSearchField"
      placeholder={t(keys.SEARCH_FOR_BUILDING)}
      clearButton={false}
      value={searchString}
      onChange={setSearchString}
      onSubmit={() => {}}
    >
      {searchingBuildingCode && (
        <Row
          data-testid="fetchBuildingCodesLoader"
          spacingInset="medium"
          alignmentHorizontal="center"
        >
          <Loader />
        </Row>
      )}

      {errorSearchingBuildingCode && (
        <Alert data-testid="fetchBuildingCodesErrorMessage" type={STATUS_TYPES.ERROR}>
          {t(keys.FETCH_BUILDING_CODES_ERROR_MESSAGE)}
        </Alert>
      )}

      {matchingYardIdentifiers
        .flatMap((yardIdentifier) =>
          yardIdentifier.buildingCodes.map((buildingCode) => ({ yardIdentifier, buildingCode }))
        )
        .map(({ yardIdentifier, buildingCode }) => (
          <SearchSuggestion
            key={buildingCode}
            onClick={() => {
              setSearchString(buildingCode);
              setSelectedBuildingCode && setSelectedBuildingCode(buildingCode);
              setSelectedYardIdentifier(yardIdentifier);
            }}
          >
            {buildingCode}
          </SearchSuggestion>
        ))}
    </SearchField>
  );
}

BuildingCodeSearch.propTypes = {
  searchString: PropTypes.string.isRequired,
  setSearchString: PropTypes.func.isRequired,
  setSelectedBuildingCode: PropTypes.func,
  setSelectedYardIdentifier: PropTypes.func.isRequired,
};
