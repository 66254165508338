import { callAPI } from "store";
import {
  FETCH_SEARCH_SUGGESTIONS,
  FETCH_SEARCH_SUGGESTIONS_SUCCESS,
  FETCH_SEARCH_SUGGESTIONS_FAILURE,
  CLEAR_SEARCH_SUGGESTIONS,
} from "./searchSuggestionsActionTypes";
import { APIs, MAX_SEARCH_SUGGESTIONS } from "app-constants";

export const fetchSearchSuggestions = (searchInfo) => {
  return async (dispatch, getState) => {
    const {
      searchString = null,
      searchScope = null,
      exclusiveStartKey = null,
      pageSize = MAX_SEARCH_SUGGESTIONS,
    } = searchInfo;

    dispatch(fetchSearchSuggestionsRequest());

    try {
      const responseJson = await callAPI(
        getState().accountInfo,
        APIs.LIST_SUPPORTED_YARD_IDENTIFIERS,
        {
          searchString,
          searchScope,
          exclusiveStartKey,
          pageSize,
        }
      );

      dispatch(fetchSearchSuggestionsSuccess(responseJson));
    } catch (exception) {
      dispatch(fetchSearchSuggestionsFailure(exception.message));
    }
  };
};

export const clearSearchSuggestions = () => {
  return {
    type: CLEAR_SEARCH_SUGGESTIONS,
  };
};

const fetchSearchSuggestionsRequest = () => {
  return {
    type: FETCH_SEARCH_SUGGESTIONS,
  };
};

const fetchSearchSuggestionsSuccess = (searchSuggestions) => {
  return {
    type: FETCH_SEARCH_SUGGESTIONS_SUCCESS,
    payload: searchSuggestions,
  };
};

const fetchSearchSuggestionsFailure = (error) => {
  return {
    type: FETCH_SEARCH_SUGGESTIONS_FAILURE,
    payload: error,
  };
};
