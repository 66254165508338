import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { isEqual } from "lodash";

import { colorBlue500, colorGray0 } from "@amzn/meridian-tokens/base/color";
import blueDarkTokens from "@amzn/meridian-tokens/theme/blue-dark";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Input from "@amzn/meridian/input";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Theme from "@amzn/meridian/theme";

import BaseTable from "component/Common/BaseTable";
import { GATE_LANE_KEYS, GATE_LANE_PURPOSE, GATE_TABLE } from "app-constants";
import { keys } from "i18n";
import { parsePhoneNumber } from "libphonenumber-js";
import { gateLane } from "types";

class GateTable extends React.PureComponent {
  state = {
    labelFilter: "",
    selectedIds: [],
    sortColumn: GATE_TABLE.keys[0],
    sortDirection: "ascending",
  };

  // sortState is an object with sortColumn and sortDirection keys
  onSort = (sortState) => this.setState(sortState);

  toggleAll = (selected) => {
    if (!this.props.canEdit) {
      return;
    }
    this.setState({
      selectedIds: selected ? this.props.gateLanes.map((gateLane) => gateLane.id) : [],
    });
  };

  toggleOne = (id, selected) => {
    if (!this.props.canEdit) {
      return;
    }
    this.setState({
      selectedIds: selected
        ? [...this.state.selectedIds, id]
        : this.state.selectedIds.filter((selectedId) => selectedId !== id),
    });
  };

  isGateModified = (gateLane) => {
    const originalGateLane = this.props.idToOriginalGateLaneMap[gateLane.id];
    return originalGateLane && !isEqual(originalGateLane, gateLane);
  };

  formatTableCellValue = (value, key) => {
    const { t } = this.props;
    if (key === GATE_LANE_KEYS.PURPOSE) {
      return t(value === GATE_LANE_PURPOSE.CHECK_IN ? keys.ENTRY_GATE : keys.EXIT_GATE);
    }
    if (key === GATE_LANE_KEYS.R4G_MODE) {
      return t(value);
    }
    if (value && key === GATE_LANE_KEYS.INTERCOM_PHONE_NUMBER) {
      return parsePhoneNumber(value).formatInternational();
    }
    return value;
  };

  renderActionBar = () => {
    const { t, gateLanes, onEdit, onRemove, onRestore } = this.props;
    const { labelFilter, selectedIds } = this.state;

    const selectedCount = selectedIds.length;
    if (selectedCount) {
      const selectedItems = gateLanes.filter(({ id }) => selectedIds.includes(id));
      return (
        <Box type="fill" backgroundColor={colorBlue500} spacingInset="small medium">
          <Theme tokens={blueDarkTokens}>
            <Row alignmentHorizontal="justify">
              <Text>{`${selectedCount} ${t(keys.GATES_LOWER_CASE)} ${t(keys.SELECTED)}`}</Text>
              <Row spacing="small">
                {selectedCount === 1 && this.isGateModified(selectedItems[0]) && (
                  <Button type="icon" onClick={() => onRestore(selectedItems)}>
                    {t(keys.RESTORE)}
                  </Button>
                )}
                {selectedCount === 1 && (
                  <Button type="icon" onClick={() => onEdit(selectedItems)}>
                    {t(keys.EDIT)}
                  </Button>
                )}
                <Button type="icon" onClick={() => onRemove(selectedItems)}>
                  {t(keys.REMOVE)}
                </Button>
              </Row>
            </Row>
          </Theme>
        </Box>
      );
    }
    return (
      <Box type="fill" backgroundColor={colorGray0} spacingInset="small medium">
        <Row>
          <Input
            width="200px"
            type="text"
            placeholder={t(keys.FILTER_BY_LABEL)}
            value={labelFilter}
            onChange={(labelFilter) => {
              this.props.setPageNumber(1);
              this.setState({ labelFilter });
            }}
          />
        </Row>
      </Box>
    );
  };

  render() {
    return (
      <Column>
        {this.renderActionBar()}

        <BaseTable
          tableColumns={GATE_TABLE}
          items={this.props.gateLanes}
          idToOriginalItemMap={this.props.idToOriginalGateLaneMap}
          itemsLabelLowerCase={keys.GATES_LOWER_CASE}
          formatTableCellValue={this.formatTableCellValue}
          currentPage={this.props.currentPage}
          setPageNumber={this.props.setPageNumber}
          labelFilter={this.state.labelFilter}
          isItemModified={this.isGateModified}
          selectedIds={this.state.selectedIds}
          toggleAll={this.toggleAll}
          toggleOne={this.toggleOne}
          sortColumn={this.state.sortColumn}
          sortDirection={this.state.sortDirection}
          onSort={this.onSort}
        />
      </Column>
    );
  }
}

export default withTranslation()(GateTable);

GateTable.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  gateLanes: PropTypes.arrayOf(gateLane).isRequired,
  idToOriginalGateLaneMap: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
};
