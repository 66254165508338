import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";
import { compose } from "lodash/fp";
import { connect } from "react-redux";
import Modal from "@amzn/meridian/modal";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Select, { SelectOption } from "@amzn/meridian/select";
import { isEmpty, isEqual, omit, trim } from "lodash";

import {
  SUBSCRIPTION_KEYS,
  SUBSCRIPTION_TABLE,
  SUBSCRIPTION_MESSAGE_TYPE_LIST,
  SUBSCRIPTION_PROTOCOL_LIST,
  SUBSCRIPTION_STATUS_LIST,
} from "app-constants";
import { keys } from "i18n";
import { ableToSendTestMessage, zipWithFlattenedKeys, getTempId } from "helpers";

const fields = zipWithFlattenedKeys(SUBSCRIPTION_TABLE.keys);

class SubscriptionDialog extends React.PureComponent {
  state = {
    isEndpointInvalid: false,
  };

  getInitialValues = (yardId, buildingCode) => ({
    // Give temp id for subscriptions added directly with the UI (https://sim.amazon.com/issues/Yard-3453)
    id: getTempId(),
    messageType: "",
    protocol: "",
    endpoint: "",
    status: "",
    yardId,
    buildingCode,
  });

  validate = (values) => {
    const { t, subscription } = this.props;
    const messageTypeError = !values.messageType;
    const protocolError = !values.protocol;
    const endpointError = !values.endpoint;
    const statusError = !values.status;
    const subscriptionUsedError = this.isSubscriptionUsed(values, subscription);
    const endpointInvalidError = this.state.isEndpointInvalid;

    return {
      ...(messageTypeError && {
        messageType: t(keys.REQUIRED_ERROR_MESSAGE),
      }),
      ...(protocolError && {
        protocol: t(keys.REQUIRED_ERROR_MESSAGE),
      }),
      ...(endpointError && {
        endpoint: t(keys.REQUIRED_ERROR_MESSAGE),
      }),
      ...(statusError && {
        status: t(keys.REQUIRED_ERROR_MESSAGE),
      }),
      ...(subscriptionUsedError && {
        subscriptionUsed: t(keys.SUBSCRIPTION_USED_ERROR_MESSAGE),
      }),
      ...(endpointInvalidError && {
        endpointInvalid: t(keys.ENDPOINT_INVALID_ERROR_MESSAGE),
      }),
    };
  };

  isSubscriptionUsed = (inputSub, initialSub) => {
    return this.props.subscriptions.some(
      (s) =>
        isEqual(omit(inputSub, ["status", "id"]), omit(s, ["status", "id"])) &&
        !isEqual(omit(inputSub, ["status", "id"]), omit(initialSub, ["status", "id"]))
    );
  };

  handleChange = (setFieldValue, fieldName, isEdit) => (fieldValue) => {
    setFieldValue(
      fieldName,
      isEqual(fieldName, SUBSCRIPTION_KEYS.SUBSCRIPTION_ENDPOINT) ? trim(fieldValue) : fieldValue
    );

    if (isEqual(fieldName, SUBSCRIPTION_KEYS.SUBSCRIPTION_ENDPOINT)) {
      this.setState({ isEndpointInvalid: false });
    }

    if (isEqual(fieldName, SUBSCRIPTION_KEYS.SUBSCRIPTION_PROTOCOL)) {
      this.setState({ isEndpointInvalid: false });
    }

    if (isEqual(fieldName, SUBSCRIPTION_KEYS.SUBSCRIPTION_STATUS) && isEdit) {
      this.setState({ isEndpointInvalid: false });
    }
  };

  handleAddOrClose = (handleSubmit, isEdit, values) => async () => {
    const isStatusActive = isEqual(values.status, keys.ACTIVE);
    if (!isEdit || isStatusActive) {
      const isEndpointWorking = await ableToSendTestMessage(values, this.props.accountInfo);
      if (!isEndpointWorking) {
        this.setState({ isEndpointInvalid: true });
      }
    }

    handleSubmit();
  };

  onSubmit = (event) => {
    event.preventDefault();
  };

  shouldDisable = (errors) => {
    const { isEndpointInvalid } = this.state;
    return !isEmpty(omit(errors, ["endpointInvalid"])) || isEndpointInvalid;
  };

  render() {
    const { t, subscription, add, edit, dismiss, yardId, buildingCode } = this.props;
    const initialValues = subscription || this.getInitialValues(yardId, buildingCode);
    const isEdit = !!subscription;

    return (
      <Modal
        width="400px"
        bodySpacingInset="medium"
        data-testid="modalInSubscriptionDialog"
        title={subscription ? t(keys.EDIT_SUBSCRIPTION) : t(keys.ADD_SUBSCRIPTION)}
        open={true}
      >
        <Formik
          initialValues={initialValues}
          initialErrors={!subscription && this.validate(initialValues)}
          validate={this.validate}
          onSubmit={(values) => (subscription ? edit(values) : add(values))}
        >
          {({ values, errors, setFieldValue, handleSubmit }) => (
            <form onSubmit={this.onSubmit}>
              <Column spacing="small" spacingInset="none none small none">
                <Row>
                  <Select
                    width="100%"
                    label={`${t(keys.SUBSCRIPTION_MESSAGE_TYPE)}*`}
                    placeholder={t(keys.SELECT_SUBSCRIPTION_MESSAGE_TYPE)}
                    value={values.messageType}
                    onChange={this.handleChange(setFieldValue, fields.messageType, isEdit)}
                  >
                    {SUBSCRIPTION_MESSAGE_TYPE_LIST.map((messageType) => (
                      <SelectOption key={messageType} value={messageType} label={t(messageType)} />
                    ))}
                  </Select>
                </Row>
                <Row>
                  <Select
                    width="100%"
                    label={`${t(keys.SUBSCRIPTION_PROTOCOL)}*`}
                    placeholder={t(keys.SUBSCRIPTION_PROTOCOL)}
                    value={values.protocol}
                    onChange={this.handleChange(setFieldValue, fields.protocol, isEdit)}
                  >
                    {SUBSCRIPTION_PROTOCOL_LIST.map((protocol) => (
                      <SelectOption key={protocol} value={protocol} label={t(protocol)} />
                    ))}
                  </Select>
                </Row>
                <Row>
                  <Input
                    width="100%"
                    name={fields.endpoint}
                    data-testid="endpointInput"
                    type="text"
                    label={`${t(keys.SUBSCRIPTION_ENDPOINT)}`}
                    value={values.endpoint}
                    onChange={this.handleChange(setFieldValue, fields.endpoint, isEdit)}
                    error={!!errors.subscriptionUsed || !!errors.endpointInvalid}
                    errorMessage={errors.subscriptionUsed || errors.endpointInvalid}
                  />
                </Row>
                <Row>
                  <Select
                    width="100%"
                    label={`${t(keys.SUBSCRIPTION_STATUS)}*`}
                    placeholder={t(keys.SUBSCRIPTION_STATUS)}
                    value={values.status}
                    onChange={this.handleChange(setFieldValue, fields.status, isEdit)}
                  >
                    {SUBSCRIPTION_STATUS_LIST.map((status) => (
                      <SelectOption key={status} value={status} label={t(status)} />
                    ))}
                  </Select>
                </Row>
              </Column>

              <Row alignmentHorizontal="right">
                <Button type="secondary" minWidth="80px" onClick={dismiss}>
                  {t(keys.CANCEL)}
                </Button>
                <Button
                  data-testid="submitButtonInSubscriptionDialog"
                  type="primary"
                  minWidth="80px"
                  disabled={this.shouldDisable(errors)}
                  onClick={this.handleAddOrClose(handleSubmit, isEdit, values)}
                >
                  {subscription ? t(keys.CLOSE) : t(keys.ADD)}
                </Button>
              </Row>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export { SubscriptionDialog };

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo,
});

const withTranslationsAndMapStateToProps = compose(withTranslation(), connect(mapStateToProps));

export default withTranslationsAndMapStateToProps(SubscriptionDialog);

SubscriptionDialog.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  subscription: PropTypes.object,
  add: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
};
