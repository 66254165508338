import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Menu, { MenuItem } from "@amzn/meridian/menu";
import globeIcon from "@amzn/meridian-tokens/base/icon/globe";

import { SUPPORTED_LANGUAGES } from "app-constants";

export default function LanguagePicker() {
  const [shouldOpenLanguageMenu, setShouldOpenLanguageMenu] = useState(false);

  const buttonRef = useRef();

  const { i18n } = useTranslation();

  const openLanguageMenu = () => setShouldOpenLanguageMenu(true);
  const closeLanguageMenu = () => setShouldOpenLanguageMenu(false);

  return (
    <>
      <Button data-testid="languageButton" ref={buttonRef} type="icon" onClick={openLanguageMenu}>
        <Icon tokens={globeIcon} />
      </Button>

      <Menu
        anchorNode={buttonRef.current}
        position="bottom"
        open={shouldOpenLanguageMenu}
        onClose={closeLanguageMenu}
      >
        {Object.keys(SUPPORTED_LANGUAGES).map((locale) => (
          <MenuItem
            data-testid={locale}
            key={locale}
            onClick={() => {
              const i18nStyledLocale = locale.replace("_", "-");
              if (i18n.language !== i18nStyledLocale) {
                i18n.changeLanguage(i18nStyledLocale);
              }
            }}
          >
            {SUPPORTED_LANGUAGES[locale]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

LanguagePicker.propTypes = {};
