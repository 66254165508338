import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import Row from "@amzn/meridian/row";

import LocationMappingInfo from "component/YardMapDialog/LocationMappingInfo";
import YardMap from "component/YardMapDialog/YardMap";
import { clearErrorEditingYardMap, editYardMap } from "store";

export default function MapContainer({ yardId }) {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const secrets = useSelector((state) => state.secrets);
  const { label, parkingLocations } = useSelector((state) => state.yardConfigData)[yardId];
  const yardMapData = useSelector((state) => state.yardMapData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLocation) {
      setSelectedLocation(
        yardMapData.yardMap.locations.find(
          ({ locationCode }) => locationCode === selectedLocation.locationCode
        )
      );
    }
  }, [yardMapData.yardMap]);

  const selectLocation = (selectedLocation) => setSelectedLocation(selectedLocation);
  const autoSelectLocationWithChangedCode = (locationCode) => setSelectedLocation({ locationCode });

  const handleEditYardMap = (edits, callback) => dispatch(editYardMap(edits, callback));
  const handleClearErrorEditingYardMap = () => dispatch(clearErrorEditingYardMap());

  // Same height as the map defined in styles.scss: 100vh - 270px
  // The 270px offset is to account for TCP header and footer and Yard Designer header
  // If there's a need to change, need to change both together
  const dialogHeight = window.innerHeight - 270;

  return (
    <Row widths={["fill", "400px"]} alignmentVertical="top" spacing="none">
      <YardMap
        secrets={secrets}
        dialogHeight={dialogHeight}
        yardLabel={label}
        yardMapData={yardMapData}
        selectedLocation={selectedLocation}
        selectLocation={selectLocation}
        editYardMap={handleEditYardMap}
      />

      <LocationMappingInfo
        dialogHeight={dialogHeight}
        yardMapData={yardMapData}
        selectedLocation={selectedLocation}
        locations={parkingLocations}
        editYardMap={handleEditYardMap}
        autoSelectLocationWithChangedCode={autoSelectLocationWithChangedCode}
        clearErrorEditingYardMap={handleClearErrorEditingYardMap}
      />
    </Row>
  );
}

MapContainer.propTypes = {
  yardId: PropTypes.string.isRequired,
};
