import PropTypes from "prop-types";

import HighlightSubstring from "@amzn/meridian/highlight-substring";

export default function SearchSuggestionCellContent({ searchString, items }) {
  return items
    .map((item) => <HighlightSubstring match={searchString}>{item}</HighlightSubstring>)
    .map((node, i) => (
      <span data-testid={node.props.children} key={node.props.children}>
        {node}
        {i !== items.length - 1 ? ", " : ""}
      </span>
    ));
}

SearchSuggestionCellContent.propTypes = {
  searchString: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};
