import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { get, isEqual } from "lodash";

import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import Checkbox from "@amzn/meridian/checkbox";
import minusIconTokens from "@amzn/meridian-tokens/base/icon/minus";
import checkIconTokens from "@amzn/meridian-tokens/base/icon/check-large";
import Pagination from "@amzn/meridian/pagination";
import Row from "@amzn/meridian/row";
import Tag from "@amzn/meridian/tag";
import Text from "@amzn/meridian/text";

import { DEFAULT_NUM_ITEMS_PER_PAGE } from "app-constants";

/**
 * A component for displaying yard configurations of a certain type.
 * This component allows users to perform batch modification of objects before
 * saving them in the database.
 */
class BaseTable extends React.PureComponent {
  getLabel = (item) => {
    return get(item, this.props.tableColumns.keys[0]);
  };

  areAllSelected = () => this.props.selectedIds.length === this.props.items.length;

  areSomeSelected = () => !!this.props.selectedIds.length;

  getHeaderCheckboxIconTokens = () =>
    this.areSomeSelected() && !this.areAllSelected() ? minusIconTokens : checkIconTokens;

  isItemNew = (item) => {
    const originalItem = this.props.idToOriginalItemMap[item.id];
    return !originalItem;
  };

  getTableCellClassname = (item, key) => {
    const originalItem = this.props.idToOriginalItemMap[item.id];
    const isNew = this.isItemNew(item);
    const modified = this.props.isItemModified(item);
    if (isNew) {
      return "base-table-cell-new";
    }
    return modified
      ? isEqual(get(originalItem, key), get(item, key))
        ? "base-table-cell-modified-row"
        : "base-table-cell-modified-cell"
      : "";
  };

  renderItemNewOrModifiedTag = (item, key) => {
    const itemModifiedOrNew = this.props.isItemModified(item) || this.isItemNew(item);
    if (key === this.props.tableColumns.keys[0] && itemModifiedOrNew) {
      return <Tag type="theme" />;
    }
    return null;
  };

  renderHeader = () => {
    const { t, tableColumns } = this.props;
    return (
      <TableRow>
        <TableCell>
          <Checkbox
            checked={this.areSomeSelected()}
            iconTokens={this.getHeaderCheckboxIconTokens()}
            onChange={() => this.props.toggleAll(!this.areAllSelected())}
          />
        </TableCell>
        {tableColumns.labels.map((header, index) => (
          <TableCell key={header} sortColumn={tableColumns.keys[index]}>
            {t(header)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  renderRows = () => {
    const {
      sortColumn,
      sortDirection,
      items,
      formatTableCellValue,
      tableColumns,
      labelFilter,
      selectedIds,
    } = this.props;
    const filteredAndSortedAndPaginatedItems = _(items)
      .filter((item) => this.getLabel(item).toLowerCase().includes(labelFilter.toLowerCase()))
      .orderBy([sortColumn], [sortDirection === "ascending" ? "asc" : "desc"])
      .filter(
        (_, index) => Math.floor(index / DEFAULT_NUM_ITEMS_PER_PAGE) === this.props.currentPage - 1
      )
      .value();
    return filteredAndSortedAndPaginatedItems.map((item) => {
      const selected = selectedIds.includes(item.id);

      return (
        <TableRow key={item.id || this.getLabel(item)} highlightOnHover={true}>
          <TableCell>
            <Checkbox
              checked={selected}
              onChange={() => this.props.toggleOne(item.id, !selected)}
            />
          </TableCell>
          {tableColumns.keys.map((key) => {
            // Handles nested values safely.
            const value = get(item, key);

            return (
              <TableCell key={key}>
                <span className={this.getTableCellClassname(item, key)}>
                  {formatTableCellValue(value, key, item)}
                </span>
                {this.renderItemNewOrModifiedTag(item, key)}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  renderPagination = () => {
    const { t, items, itemsLabelLowerCase, labelFilter, currentPage, setPageNumber } = this.props;
    const filteredItems = items.filter((item) =>
      this.getLabel(item).toLowerCase().includes(labelFilter.toLowerCase())
    );
    const numberOfPages = Math.ceil(filteredItems.length / DEFAULT_NUM_ITEMS_PER_PAGE);
    return (
      <Row alignmentHorizontal="right">
        <Text>
          {filteredItems.length} {t(itemsLabelLowerCase)}
        </Text>
        <Pagination
          numberOfPages={numberOfPages}
          currentPage={currentPage}
          showSkipArrows={true}
          onChange={(page) => setPageNumber(page)}
        />
      </Row>
    );
  };

  render() {
    const { sortColumn, sortDirection } = this.props;
    return (
      <>
        <div style={{ marginTop: "10px", overflowX: "auto", maxWidth: "100%" }}>
          <Table
            spacing="small"
            headerRows={1}
            showDividers={true}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={this.props.onSort}
          >
            {this.renderHeader()}
            {this.renderRows()}
          </Table>
        </div>
        {this.renderPagination()}
      </>
    );
  }
}

export default withTranslation()(BaseTable);

BaseTable.propTypes = {
  /**
   * A structure with metadata about the columns of the table.
   * It specifies column labels and keys to extract the corresponding values.
   */
  tableColumns: PropTypes.object.isRequired,

  /**
   * A list of objects to be displayed in the table.
   */
  items: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * A map between object identifiers and their original values.
   */
  idToOriginalItemMap: PropTypes.object.isRequired,

  /**
   * The label for objects in the table in lowercase.
   * It will be displayed in the pagination section.
   */
  itemsLabelLowerCase: PropTypes.string.isRequired,

  /**
   * A function that should format the value of a cell to display in the table.
   */
  formatTableCellValue: PropTypes.func.isRequired,

  /**
   * The number of the currently selected page.
   */
  currentPage: PropTypes.number.isRequired,

  /**
   * A function that will be called when the user attempts to change the current
   * page.
   */
  setPageNumber: PropTypes.func.isRequired,

  /**
   * A value that should be used to filter objects in the table by labels.
   */
  labelFilter: PropTypes.string.isRequired,

  /**
   * Is the object different from the saved version?
   */
  isItemModified: PropTypes.func.isRequired,

  /**
   * Identifiers of all selected objects in the table.
   */
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * Specify selection for all rows in the table.
   */
  toggleAll: PropTypes.func.isRequired,

  /**
   * Change selection for one row in the table.
   */
  toggleOne: PropTypes.func.isRequired,

  /**
   * This identifies which column is currently sorted.
   */
  sortColumn: PropTypes.string.isRequired,

  /**
   * This identifies which direction the data column identified by "sortColumn"
   * is sorted.
   */
  sortDirection: PropTypes.string.isRequired,

  /**
   * When the user clicks on the header of a sortable column this is called
   * with an object containing two keys: "sortColumn" and "sortDirection".
   */
  onSort: PropTypes.func.isRequired,
};
