import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";

import ActionBar from "componentsV2/DocumentPage/ActionBar";
import DocumentTable from "componentsV2/DocumentPage/DocumentTable";

export default function DocumentPageBody({ yardId }) {
  const yardDocuments = useSelector((state) => state.yardDocumentsData)[yardId];

  if (!yardDocuments) {
    return (
      <Row
        data-testid="fetchYardDocumentsLoader"
        spacingInset="medium"
        alignmentHorizontal="center"
      >
        <Loader />
      </Row>
    );
  }

  return (
    <Column>
      <ActionBar yardId={yardId} />
      <DocumentTable yardId={yardId} />
    </Column>
  );
}

DocumentPageBody.propTypes = {
  yardId: PropTypes.string.isRequired,
};
