import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Link from "@amzn/meridian/link";
import Row from "@amzn/meridian/row";
import { SearchSuggestionFooter } from "@amzn/meridian/search-field";
import Text from "@amzn/meridian/text";

import { clearYardConfigData, fetchYardIdentifiers } from "store";
import { keys } from "i18n";

export default function SearchFooter({ searchString, searchScope }) {
  const { fetchingSearchResults, searchResultsCount } = useSelector(
    (state) => state.searchSuggestions
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const submitSearch = () => {
    dispatch(
      fetchYardIdentifiers({
        refresh: true,
        searchString,
        searchScope,
      })
    );

    dispatch(clearYardConfigData());
  };

  if (fetchingSearchResults) {
    return null;
  }

  return (
    <SearchSuggestionFooter>
      <Row spacingInset="small medium" alignmentHorizontal="justify">
        <Text data-testid="searchResultsCountText">
          {searchResultsCount} {t(keys.RESULTS)}
        </Text>

        <Link data-testid="searchFooterLink" type="secondary" onClick={submitSearch}>
          {t(keys.SEE_ALL_RESULTS)}
        </Link>
      </Row>
    </SearchSuggestionFooter>
  );
}

SearchFooter.propTypes = {
  searchString: PropTypes.string.isRequired,
  searchScope: PropTypes.string.isRequired,
};
