import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { cloneDeep, isEqual } from "lodash";

import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Theme from "@amzn/meridian/theme";
import blueDarkTokens from "@amzn/meridian-tokens/theme/blue-dark";
import { colorBlue500 } from "@amzn/meridian-tokens/base/color";

import LocationDialog from "component/LocationDisplayer/LocationDialog";
import { location } from "types";
import { keys } from "i18n";
import { ENABLE_DISABLE_LOCATION_FEATURE } from "app-constants";

export default function OverlayActionBar({
  yardId,
  locations,
  selectedIds,
  setLocations,
  setSelectedIds,
}) {
  const [shouldShowEditLocationDialog, setShouldShowEditLocationDialog] = useState(false);

  const featureFlags = useSelector((state) => state.featureFlags);
  const { parkingLocations, buildings } = useSelector((state) => state.yardConfigData)[yardId];

  const { t } = useTranslation();

  const selectedCount = selectedIds.length;
  const selectedLocations = locations.filter(({ id }) => selectedIds.includes(id));
  const enableDisableLocationsFeature = featureFlags[ENABLE_DISABLE_LOCATION_FEATURE]?.enabled;
  const showEnableLocations =
    enableDisableLocationsFeature &&
    locations.some(({ id, disabled }) => selectedIds.includes(id) && disabled);
  const showDisableLocations =
    enableDisableLocationsFeature &&
    locations.some(({ id, disabled }) => selectedIds.includes(id) && !disabled);

  const isSelectedLocationModified = () => {
    const selectedLocation = selectedLocations[0];
    const originalLocation =
      selectedLocation && parkingLocations.find(({ id }) => id === selectedLocation.id);
    return originalLocation && !isEqual(originalLocation, selectedLocation);
  };

  const restoreSelectedLocation = () => {
    const selectedId = selectedIds[0];
    const originalLocation = parkingLocations.find(({ id }) => id === selectedId);
    setLocations(
      locations.map((location) =>
        location.id === selectedId ? cloneDeep(originalLocation) : location
      )
    );
  };

  const editLocation = (location) =>
    setLocations(locations.map((current) => (current.id === location.id ? location : current)));

  const removeSelectedLocations = () => {
    setLocations(locations.filter(({ id }) => !selectedIds.includes(id)));
    setSelectedIds([]);
  };

  const disableSelectedLocations = () => {
    setLocations(
      locations.map((location) => {
        if (selectedIds.includes(location.id)) {
          return { ...location, disabled: true };
        }
        return location;
      })
    );
    setSelectedIds([]);
  };

  const enableSelectedLocations = () => {
    setLocations(
      locations.map((location) => {
        if (selectedIds.includes(location.id)) {
          return { ...location, disabled: false };
        }
        return location;
      })
    );
    setSelectedIds([]);
  };

  const openEditLocationDialog = () => setShouldShowEditLocationDialog(true);
  const closeEditLocationDialog = () => setShouldShowEditLocationDialog(false);

  return (
    <>
      <Box type="fill" backgroundColor={colorBlue500} spacingInset="small medium">
        <Theme tokens={blueDarkTokens}>
          <Row alignmentHorizontal="justify">
            <Text data-testid={`selectedCountText-${selectedCount}`}>
              {`${selectedCount} ${t(keys.LOCATIONS_LOWER_CASE)} ${t(keys.SELECTED)}`}
            </Text>

            <Row spacing="small">
              {selectedCount === 1 && isSelectedLocationModified() && (
                <Button
                  data-testid="restoreLocationButton"
                  type="icon"
                  onClick={restoreSelectedLocation}
                >
                  {t(keys.RESTORE)}
                </Button>
              )}

              {selectedCount === 1 && (
                <Button
                  data-testid="editLocationButton"
                  type="icon"
                  onClick={openEditLocationDialog}
                >
                  {t(keys.EDIT)}
                </Button>
              )}

              <Button
                data-testid="removeLocationButton"
                type="icon"
                onClick={removeSelectedLocations}
              >
                {t(keys.REMOVE)}
              </Button>

              {showDisableLocations && (
                <Button
                  data-testid="disableLocationButton"
                  type="icon"
                  onClick={disableSelectedLocations}
                >
                  {t(keys.DISABLE)}
                </Button>
              )}

              {showEnableLocations && (
                <Button
                  data-testid="enableLocationButton"
                  type="icon"
                  onClick={enableSelectedLocations}
                >
                  {t(keys.ENABLE)}
                </Button>
              )}
            </Row>
          </Row>
        </Theme>
      </Box>

      {shouldShowEditLocationDialog && (
        <LocationDialog
          location={selectedLocations[0]}
          locations={locations}
          buildings={buildings}
          featureFlags={featureFlags}
          add={() => {}}
          edit={editLocation}
          dismiss={closeEditLocationDialog}
        />
      )}
    </>
  );
}

OverlayActionBar.propTypes = {
  yardId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(location).isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setLocations: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
};
