import PropTypes from "prop-types";
import { SortableContainer } from "react-sortable-hoc";

import Column from "@amzn/meridian/column";

import SortableItem from "componentsV2/Common/SortableItemList/SortableItem";

const SortableItemList = SortableContainer(({ items, formatItem, removeItem }) => {
  return (
    <Column spacing="200">
      {items.map((item, index) => (
        <SortableItem
          key={item}
          index={index}
          item={item}
          formatItem={formatItem}
          removeItem={removeItem}
        />
      ))}
    </Column>
  );
});

export default SortableItemList;

SortableItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  formatItem: PropTypes.func,
  removeItem: PropTypes.func.isRequired,
};
