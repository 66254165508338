import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Checkbox from "@amzn/meridian/checkbox";
import Column from "@amzn/meridian/column";
import Modal from "@amzn/meridian/modal";
import Text from "@amzn/meridian/text";

import DialogFooter from "componentsV2/Common/Dialogs/DialogFooter";
import LocationCSVDownload from "componentsV2/Common/LocationCSVDownload";
import { deleteYard } from "store";
import { generateErrorMessage } from "helpers";
import { keys } from "i18n";

export default function SelfRemoveYardDialog({ mainYardId, yardId, dismiss, successCallback }) {
  const [offsitesRemoved, setOffsitesRemoved] = useState(false);
  const [buildingsRemoved, setBuildingsRemoved] = useState(false);
  const [devicesRemoved, setDevicesRemoved] = useState(false);
  const [equipmentRemoved, setEquipmentRemoved] = useState(false);
  const [locationsDownloaded, setLocationsDownloaded] = useState(false);
  const [deletingYard, setDeletingYard] = useState(false);
  const [errorDeletingYard, setErrorDeletingYard] = useState(null);

  const { parkingLocations, version } = useSelector((state) => state.yardConfigData)[yardId];

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const submit = () => {
    setDeletingYard(true);
    setErrorDeletingYard(null);

    dispatch(
      deleteYard({
        mainYardId,
        yardId,
        version,
        successCallback,
        failureCallback: (errorMessage) => {
          setDeletingYard(false);
          setErrorDeletingYard(errorMessage);
        },
      })
    );
  };

  const submittable =
    (mainYardId || offsitesRemoved) &&
    buildingsRemoved &&
    devicesRemoved &&
    equipmentRemoved &&
    locationsDownloaded;
  const error =
    errorDeletingYard && generateErrorMessage(t(keys.REMOVE_YARD_ERROR_MESSAGE), errorDeletingYard);

  return (
    <Modal data-testid="dialogModal" width="600px" title={t(keys.REMOVE_YARD)} open={true}>
      <Column spacing="large">
        <Column>
          <Text>{t(keys.REMOVE_YARD_SELF_INSTRUCTION)}</Text>

          <Column spacingInset="none none none medium" spacing="small">
            {!mainYardId && (
              <Checkbox
                data-testid="offsitesRemovedCheckbox"
                checked={offsitesRemoved}
                onChange={setOffsitesRemoved}
              >
                {t(keys.REMOVE_YARD_SELF_INSTRUCTION_OFFSITES)}
              </Checkbox>
            )}
            <Checkbox
              data-testid="buildingsRemovedCheckbox"
              checked={buildingsRemoved}
              onChange={setBuildingsRemoved}
            >
              {t(keys.REMOVE_YARD_SELF_INSTRUCTION_BUILDINGS)}
            </Checkbox>
            <Checkbox
              data-testid="devicesRemovedCheckbox"
              checked={devicesRemoved}
              onChange={setDevicesRemoved}
            >
              {t(keys.REMOVE_YARD_SELF_INSTRUCTION_DEVICES)}
            </Checkbox>
            <Checkbox
              data-testid="equipmentRemovedCheckbox"
              checked={equipmentRemoved}
              onChange={setEquipmentRemoved}
            >
              {t(keys.REMOVE_YARD_SELF_INSTRUCTION_EQUIPMENT)}
            </Checkbox>
          </Column>

          <Text>{t(keys.REMOVE_YARD_VERIFICATION)}</Text>
        </Column>

        <DialogFooter
          commitButtonText={t(keys.REMOVE_YARD)}
          inProgress={deletingYard}
          error={error}
          disabled={!submittable}
          commit={submit}
          dismiss={dismiss}
        >
          <LocationCSVDownload
            buttonType="primary"
            locations={parkingLocations}
            sortColumn="label"
            sortDirection="ascending"
            downloadCallback={() => setLocationsDownloaded(true)}
          />
        </DialogFooter>
      </Column>
    </Modal>
  );
}

SelfRemoveYardDialog.propTypes = {
  mainYardId: PropTypes.string,
  yardId: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired,
};
