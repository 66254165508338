import PropTypes from "prop-types";

import Row from "@amzn/meridian/row";

import AddYardEntityActions from "componentsV2/ConfigPage/ActionBar/AddYardEntityActions";
import YardEntityTabs from "componentsV2/ConfigPage/ActionBar/YardEntityTabs";

export default function ActionBar({ yardId, tab, setTab }) {
  return (
    <Row alignmentHorizontal="justify" alignmentVertical="bottom">
      <YardEntityTabs yardId={yardId} tab={tab} setTab={setTab} />
      <AddYardEntityActions yardId={yardId} />
    </Row>
  );
}

ActionBar.propTypes = {
  yardId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
};
