import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";

import ItemList from "componentsV2/Common/ItemList";
import { keys } from "i18n";

export default function LocationSelect({ sourceYardId, locationIdsToMove, setLocationIdsToMove }) {
  const { parkingLocations } = useSelector((state) => state.yardConfigData)[sourceYardId];

  const offsiteLocations = parkingLocations.filter(({ offsiteLocation }) => offsiteLocation);

  const selectLocation = (id) => setLocationIdsToMove([...locationIdsToMove, id]);
  const removeLocation = (idToRemove) =>
    setLocationIdsToMove(locationIdsToMove.filter((id) => id !== idToRemove));

  const selectAll = () => setLocationIdsToMove(offsiteLocations.map(({ id }) => id));
  const unselectAll = () => setLocationIdsToMove([]);

  const getLabel = (id) => {
    const location = parkingLocations.find((location) => location.id === id);
    if (location) {
      return location.label;
    }
    // After locations have been moved, the whole dialog will be unmounted, but this component will
    // still be re-rendered in the meantime
    // `location` will no longer be found then, so returning something here so that ItemList still
    // has some item content to render
    return id;
  };

  const isAllSelected = offsiteLocations.length === locationIdsToMove.length;

  const { t } = useTranslation();

  return (
    <Column>
      <Row widths={["fill"]}>
        <Select
          data-testid="locationSelect"
          label={t(keys.LOCATIONS_TO_MOVE)}
          placeholder={t(keys.SELECT_LOCATIONS_TO_MOVE)}
          value={""}
          onChange={selectLocation}
        >
          {offsiteLocations
            .filter(({ id }) => !locationIdsToMove.includes(id))
            .map(({ id, label }) => (
              <SelectOption data-testid={id} key={id} value={id} label={label} />
            ))}
        </Select>

        {isAllSelected && (
          <Button data-testid="unselectAllButton" type="secondary" onClick={unselectAll}>
            {t(keys.UNSELECT_ALL)}
          </Button>
        )}
        {!isAllSelected && (
          <Button data-testid="selectAllButton" type="secondary" onClick={selectAll}>
            {t(keys.SELECT_ALL)}
          </Button>
        )}
      </Row>

      <ItemList items={locationIdsToMove} formatItem={getLabel} removeItem={removeLocation} />
    </Column>
  );
}

LocationSelect.propTypes = {
  sourceYardId: PropTypes.string.isRequired,
  locationIdsToMove: PropTypes.arrayOf(PropTypes.string).isRequired,
  setLocationIdsToMove: PropTypes.func.isRequired,
};
