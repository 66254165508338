import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import YardDialog from "componentsV2/Common/Dialogs/YardDialog";
import { editYard } from "store";
import { keys } from "i18n";

export default function EditYardDialog({ yardId, dismiss }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const submit = ({ yardConfig, successCallback, failureCallback }) =>
    dispatch(editYard({ yardId, yardConfig, successCallback, failureCallback }));

  return (
    <YardDialog
      title={t(keys.EDIT_YARD)}
      genericErrorMessage={t(keys.EDIT_YARD_ERROR_MESSAGE)}
      yardId={yardId}
      submit={submit}
      dismiss={dismiss}
    />
  );
}

EditYardDialog.propTypes = {
  yardId: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
};
