import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";

import LocationDialog from "component/LocationDisplayer/LocationDialog";
import SaveLocationsDialog from "component/LocationDisplayer/SaveLocationsDialog";
import { getRemovedLocations, getOffsiteBuildingCodeChanges } from "helpers";
import { location } from "types";
import { keys } from "i18n";

export default function TopActionBar({ yardId, locations, setLocations }) {
  const [shouldShowAddLocationDialog, setShouldShowAddLocationDialog] = useState(false);
  const [shouldShowSaveLocationsDialog, setShouldShowSaveLocationsDialog] = useState(false);

  const { permissions } = useSelector((state) => state.permissionsData);
  const featureFlags = useSelector((state) => state.featureFlags);
  const {
    parkingLocations: originalLocations,
    buildings,
    version,
  } = useSelector((state) => state.yardConfigData)[yardId];

  const { t } = useTranslation();

  const openAddLocationDialog = () => setShouldShowAddLocationDialog(true);
  const closeAddLocationDialog = () => setShouldShowAddLocationDialog(false);
  const openSaveLocationsDialog = () => setShouldShowSaveLocationsDialog(true);
  const closeSaveLocationsDialog = () => setShouldShowSaveLocationsDialog(false);

  const addLocation = (location) => setLocations(locations.concat(location));

  const canEdit = !!permissions.canEdit;

  return (
    <>
      <Row data-testid="actionRow" alignmentHorizontal="justify">
        <Heading level={6} color="secondary">
          {t(keys.LOCATIONS)}
        </Heading>

        <Row>
          <Button
            data-testid="addButton"
            type="tertiary"
            disabled={!canEdit}
            onClick={openAddLocationDialog}
          >
            {t(keys.NEW_LOCATION)}
          </Button>
          <Button
            data-testid="saveButton"
            type="tertiary"
            disabled={!canEdit}
            onClick={openSaveLocationsDialog}
          >
            {t(keys.SAVE)}
          </Button>
        </Row>
      </Row>

      {shouldShowAddLocationDialog && (
        <LocationDialog
          locations={locations}
          buildings={buildings}
          featureFlags={featureFlags}
          add={addLocation}
          edit={() => {}}
          dismiss={closeAddLocationDialog}
        />
      )}

      {shouldShowSaveLocationsDialog && (
        <SaveLocationsDialog
          yardId={yardId}
          locations={locations}
          version={version}
          removedLocations={getRemovedLocations(locations, originalLocations)}
          clearRemovedLocations={() => {}}
          listOffsiteBuildingCodesChanged={getOffsiteBuildingCodeChanges(
            locations,
            originalLocations
          )}
          dismiss={closeSaveLocationsDialog}
        />
      )}
    </>
  );
}

TopActionBar.propTypes = {
  yardId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(location).isRequired,
  setLocations: PropTypes.func.isRequired,
};
