import PropTypes from "prop-types";
import { SortableElement } from "react-sortable-hoc";

import Button from "@amzn/meridian/button";
import Card from "@amzn/meridian/card";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import closeSmallTokens from "@amzn/meridian-tokens/base/icon/close-small";

import DragHandle from "componentsV2/Common/SortableItemList/DragHandle";

const SortableItem = SortableElement(({ item, formatItem, removeItem }) => {
  return (
    <div style={{ zIndex: 99 }}>
      <Card spacingInset="200 400" onClick={() => {}}>
        <Row alignmentHorizontal="justify">
          <Row>
            <DragHandle />
            <Text data-testid="itemText" className="unselectable">
              {formatItem ? formatItem(item) : item}
            </Text>
          </Row>

          <Button
            data-testid="removeItemButton"
            type="icon"
            size="small"
            onClick={() => removeItem(item)}
          >
            <Icon tokens={closeSmallTokens}>Close</Icon>
          </Button>
        </Row>
      </Card>
    </div>
  );
});

export default SortableItem;

SortableItem.propTypes = {
  item: PropTypes.string.isRequired,
  formatItem: PropTypes.func,
  removeItem: PropTypes.func.isRequired,
};
