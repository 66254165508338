import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";
import syncIcon from "@amzn/meridian-tokens/base/icon/sync";

import LocaleDialog from "componentsV2/Common/Dialogs/LocaleDialog";
import { fetchYardDocuments } from "store";
import { keys } from "i18n";

export default function ActionBar({ yardId }) {
  const [shouldShowLocaleDialog, setShouldShowLocaleDialog] = useState(false);

  const { languages } = useSelector((state) => state.yardConfigData)[yardId];
  const yardDocuments = useSelector((state) => state.yardDocumentsData)[yardId];

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const openLocaleDialog = () => setShouldShowLocaleDialog(true);
  const closeLocaleDialog = () => setShouldShowLocaleDialog(false);

  const refresh = () => {
    languages.forEach((documentLocale) => dispatch(fetchYardDocuments({ yardId, documentLocale })));
  };

  // Yard documents are fetched one locale at a time
  // If it's still fetching for any locale, do not enable the global fetch button
  const fetching = languages.reduce(
    (accumulator, language) => accumulator || yardDocuments[language]?.fetching,
    false
  );

  return (
    <>
      <Row data-testid="actionBar" alignmentHorizontal="end">
        <Button
          data-testid="refreshButton"
          type="icon"
          label={t(keys.REFRESH)}
          disabled={fetching}
          onClick={refresh}
        >
          <Icon tokens={syncIcon} />
        </Button>

        <Button data-testid="manageLocalesButton" disabled={fetching} onClick={openLocaleDialog}>
          {t(keys.MANAGE_LOCALES)}
        </Button>
      </Row>

      {shouldShowLocaleDialog && <LocaleDialog yardId={yardId} dismiss={closeLocaleDialog} />}
    </>
  );
}

ActionBar.propTypes = {
  yardId: PropTypes.string.isRequired,
};
