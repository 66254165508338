import { keys } from "./i18n";

export const APP_NAME = "Yard Designer";

export const SYSTEM = "YardDesigner";

export const AMAZON_ACCOUNT_NAME = "Amazon";
export const YARD_DESIGNER_ACCOUNT_NAME = "YardDesigner";

export const POST = "POST";
export const PUT = "PUT";

export const APIs = {
  GET_YARD_DESIGNER_PERMISSIONS: "GET_YARD_DESIGNER_PERMISSIONS",
  IS_FEATURE_ENABLED: "IS_FEATURE_ENABLED",
  LIST_SUPPORTED_YARD_FEATURES: "LIST_SUPPORTED_YARD_FEATURES",
  LIST_SUPPORTED_YARD_IDENTIFIERS: "LIST_SUPPORTED_YARD_IDENTIFIERS",
  QUERY_YARD_GRAPH: "QUERY_YARD_GRAPH",
  CREATE_YARD: "CREATE_YARD",
  EDIT_YARD_LAYOUT: "EDIT_YARD_LAYOUT",
  DELETE_YARD: "DELETE_YARD",
  CREATE_BUILDING: "CREATE_BUILDING",
  EDIT_BUILDING_LAYOUT: "EDIT_BUILDING_LAYOUT",
  DELETE_BUILDING: "DELETE_BUILDING",
  SET_PARKING_LOCATIONS_FOR_YARD: "SET_PARKING_LOCATIONS_FOR_YARD",
  MOVE_LOCATIONS_TO_RELATED_YARD: "MOVE_LOCATIONS_TO_RELATED_YARD",
  GET_YARD_MAP: "GET_YARD_MAP",
  EDIT_YARD_MAP: "EDIT_YARD_MAP",
  SET_YARD_GATES: "SET_YARD_GATES",
  VALIDATE_GATE_CONFIGURATION: "VALIDATE_GATE_CONFIGURATION",
  BATCH_CREATE_3P_YARDS: "BATCH_CREATE_3P_YARDS",
  GET_ACN_SUBSCRIPTIONS: "GET_ACN_SUBSCRIPTIONS",
  SET_ACN_SUBSCRIPTIONS: "SET_ACN_SUBSCRIPTIONS",
  TEST_ACN_SUBSCRIPTION: "TEST_ACN_SUBSCRIPTION",
  GET_YARD_DOCUMENTS: "GET_YARD_DOCUMENTS",
  UPLOAD_YARD_DOCUMENT: "UPLOAD_YARD_DOCUMENT",
  LIST_SUPPORTED_DEVICES: "LIST_SUPPORTED_DEVICES",
  ADD_DEVICE: "ADD_DEVICE",
  UPDATE_DEVICE: "UPDATE_DEVICE",
  DELETE_DEVICE: "DELETE_DEVICE",
  LIST_SIGNALLING_CHANNELS_BY_GATE_LANE: "LIST_SIGNALLING_CHANNELS_BY_GATE_LANE",
  GET_KVS_PRESIGNED_URL: "GET_KVS_PRESIGNED_URL",
  GET_GATE_AREA_DEFINITION_MASK_PRESIGNED_URL: "GET_GATE_AREA_DEFINITION_MASK_PRESIGNED_URL",
  LIST_SUPPORTED_BUILDING_FEATURES: "LIST_SUPPORTED_BUILDING_FEATURES",
};

export const SHOW_AUDIT_MODE_SEARCH_FEATURE = "showAuditModeSearch";
export const ENABLE_DISABLE_LOCATION_FEATURE = "enableDisableLocation";
export const SHOW_YARD_TYPE = "showYardType";
export const ENABLE_MULTIZONE_MAPPING = "enableMultiZoneMapping";
export const ENABLE_SELF_DELETION_FEATURE = "enableSelfDeletionFeature";
export const SHOW_OFFSITE_BUILDING_CODE = "showOffsiteBuildingCode";
export const ENABLE_MULTILANE_GATES_MANAGEMENT = "enableMultiLaneGatesManagement";
export const ENABLE_BATCH_3P_YARD_CREATION = "enableBatch3PYardCreation";
export const ENABLE_SUBSCRIPTIONS_MANAGEMENT = "enableSubscriptionsManagement";
export const ENABLE_YARD_DOCUMENTS_MANAGEMENT = "enableYardDocumentsManagement";
export const SUPPORT_OFFSITE_MODELING = "supportOffsiteModeling";
export const ENABLE_YARD_MAP_MANAGEMENT = "enableYardMapManagement";
export const ENABLE_YARD_FEATURES_MANAGEMENT = "enableYardFeaturesManagement";
export const ENABLE_YARD_DEVICES_MANAGEMENT = "enableYardDevicesManagement";
export const ENABLE_VALIDATE_GATE_CONFIGURATION = "enableValidateGateConfiguration";
export const ENABLE_SKIP_VALIDATING_UNCHANGED_GATE_LANE = "enableSkipValidatingUnchangedGateLane";
export const ENABLE_GAS_MASKING_TOOL = "enableGASMaskingTool";
export const ENABLE_BUILDING_FEATURES_MANAGEMENT = "enableBuildingFeaturesManagement";
export const ENABLE_GATE_BADGE_SCANNER_MANAGEMENT = "enableGateBadgeScannerManagement";

export const FEATURES = [
  SHOW_AUDIT_MODE_SEARCH_FEATURE,
  SHOW_YARD_TYPE,
  ENABLE_MULTIZONE_MAPPING,
  ENABLE_SELF_DELETION_FEATURE,
  SHOW_OFFSITE_BUILDING_CODE,
  ENABLE_MULTILANE_GATES_MANAGEMENT,
  ENABLE_BATCH_3P_YARD_CREATION,
  ENABLE_SUBSCRIPTIONS_MANAGEMENT,
  ENABLE_YARD_DOCUMENTS_MANAGEMENT,
  SUPPORT_OFFSITE_MODELING,
  ENABLE_YARD_MAP_MANAGEMENT,
  ENABLE_YARD_FEATURES_MANAGEMENT,
  ENABLE_YARD_DEVICES_MANAGEMENT,
  ENABLE_VALIDATE_GATE_CONFIGURATION,
  ENABLE_SKIP_VALIDATING_UNCHANGED_GATE_LANE,
  ENABLE_DISABLE_LOCATION_FEATURE,
  ENABLE_GAS_MASKING_TOOL,
  ENABLE_BUILDING_FEATURES_MANAGEMENT,
  ENABLE_GATE_BADGE_SCANNER_MANAGEMENT,
];

export const STATUS_TYPES = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

export const PIXELS_ABOVE_PAGE_BOTTOM = 3000;

export const TICKET_TEMPLATE_DELETE_YARD = "https://tt.amazon.com/quicklink/Q001167650";
export const TICKET_TEMPLATE_DELETE_BUILDING = "https://tt.amazon.com/quicklink/Q001167808";
export const SELF_YARD_BUILDING_DELETION_INSTRUCTIONS =
  "https://w.amazon.com/bin/view/TransportationByAmazon/YMS/Operations/FrontlineSupport/#HHowtodeletebuilding2FyardinYardDesigner3F";
export const REQUEST_PERMISSION_SIM =
  "https://sim.amazon.com/issues/create?template=3e185960-834e-4a1e-b288-40dcccc5f192";
export const REQUEST_CHANGE_SIM =
  "https://sim.amazon.com/issues/create?template=3d5ef3d6-0cc3-43a2-bc45-a32ba27f3257";
export const REQUEST_CHANGE_LINK =
  "https://amazonprojects.corp.amazon.com/sites/TOMWORK/Projects/YMSTickets/BrightWork%20Pages/Welcome.aspx";
export const HELP_WIKI =
  "https://w.amazon.com/bin/view/TransportationByAmazon/YMS/Operations/FAQs/#HYMSLaunch26UpdateFAQs";

export const MAX_SEARCH_SUGGESTIONS = 4;
export const SEARCH_SCOPE_YARD = "yard";
export const SEARCH_SCOPE_BUILDING = "building";
export const SEARCH_SCOPE_YARD_AND_BUILDING = "yard_and_building";
export const DEFAULT_NUM_YARD_IDENTIFIERS_PER_PAGE = 100;

// relation between yard and building, used for traversing yard graph in YDLR
export const ADJACENT = "ADJACENT";
// offsite relation between two yards
export const OFFSITE = "OFFSITE";

export const SCROLL_THROTTLE_WAIT = 1000; // in milliseconds
export const SEARCH_DEBOUNCE_WAIT = 300; // in milliseconds
export const YARD_LABEL_AVAILABILITY_CHECK_DEBOUNCE_WAIT = 300; // in milliseconds
export const BUILDING_CODE_AVAILABILITY_CHECK_DEBOUNCE_WAIT = 300; // in milliseconds

export const DEFAULT_NUM_ITEMS_PER_PAGE = 10;

export const BATCH_CREATE_3P_YARDS_LIMIT = 80;

export const YARD_TYPE_LIST = [keys.AMAZON, keys.AMAZON_TOM_MANAGED, keys.THIRD_PARTY];

export const ASSET_TYPE_LIST = [
  keys.TRAILER,
  keys.TRACTOR,
  keys.BOX_TRUCK,
  keys.BOX_TRUCK_2T,
  keys.BOX_TRUCK_4T,
  keys.BOX_TRUCK_10T,
  keys.BOX_SPRINTER_VAN,
  keys.TRAILER_DOUBLE,
  keys.SPRINTER_VAN,
  keys.TRAILER_SOFT,
  keys.SWAP_BODY,
  keys.CAR,
  keys.MOTORCYCLE,
  keys.TRACTOR_FLATBED,
  keys.TRAILER_FLATBED,
  keys.TRAILER_PUP,
  keys.TRAILER_BOX_TRUCK,
  keys.THREE_WHEELER,
  keys.PERSON,
  keys.TRAILER_SKIRTED,
  keys.TRAILER_INTERMODAL,
  keys.TRAILER_REFRIGERATED,
  keys.AIRPLANE,
  keys.DOLLY,
  keys.TRAILER_PUP_SKIRT,
  keys.TRAILER_PUP_INTERMODAL,
];

export const SUPPORTED_LANGUAGES = {
  en_US: "English",
  pl_PL: "Polski",
  pt_BR: "Português (BR)",
  es_ES: "Español",
  zh_CN: "中文 (简)",
  cs_CZ: "Čeština",
  de_DE: "Deutsch",
  en_GB: "English (GB)",
  fr_FR: "Français (FR)",
  fr_CA: "Français (CA)",
  it_IT: "Italiano",
  hi_IN: "हिन्दी",
  ja_JP: "日本語",
  sk_SK: "Slovenský",
  tr_TR: "Türkçe",
  pa_IN: "ਪੰਜਾਬੀ",
};

export const BUILDING_PROPERTY_KEYS = [
  "buildingCode",
  "description",
  "express",
  "inboundExpress",
  "enableSSP",
  "enableInboundSSP",
  "buildingFeatures",
];

export const LOCATION_FILE_SUPPORTED_EXTENSIONS = ["csv"];
export const BATCH_ACTION_FILE_SUPPORTED_EXTENSIONS = ["csv"];

export const DataType = {
  String,
  Boolean,
  Number,
};

export const LOCATION_KEY_DATA_TYPES = {
  id: DataType.String,
  label: DataType.String,
  hasDockDoor: DataType.Boolean,
  inbound: DataType.Boolean,
  outbound: DataType.Boolean,
  offsiteLocation: DataType.Boolean,
  screenOrder: DataType.String,
  auditOrder: DataType.String,
  zone: DataType.String,
  zoneList: DataType.String,
  nonInventory: DataType.Boolean,
  sortable: DataType.Boolean,
  capacity: DataType.Number,
  multiCapacity: DataType.Boolean,
  auditModeSearch: DataType.Boolean,
  disabled: DataType.Boolean,
};

export const LOCATION_TABLE = {
  // labels are user-friendly UI text used in table header. keys are system-friendly fields used in backend communication.
  // order of labels and keys must match.
  labels: [
    keys.LOCATION_LABEL,
    keys.LOCATION_DOCK_DOOR,
    keys.LOCATION_INBOUND,
    keys.LOCATION_OUTBOUND,
    keys.LOCATION_OFFSITE,
    keys.LOCATION_SCREEN_ORDER,
    keys.LOCATION_AUDIT_ORDER,
    keys.LOCATION_ZONE,
    keys.LOCATION_NON_INVENTORY,
    keys.LOCATION_SORTABLE,
    keys.LOCATION_CAPACITY,
    keys.LOCATION_MULTI_CAPACITY,
  ],
  keys: [
    "label",
    "hasDockDoor",
    "inbound",
    "outbound",
    "offsiteLocation",
    "screenOrder",
    "auditOrder",
    "zone",
    "nonInventory",
    "sortable",
    "capacity",
    "multiCapacity",
  ],
};

// Location table in CSV file contains extra columns invisible in Yard Designer UI (e.g. location ID)
export const CSV_LOCATION_TABLE = {
  // labels are user-friendly UI text used in table header. keys are system-friendly fields used in backend communication.
  // order of labels and keys must match.
  labels: [
    "ID",
    "Label",
    "Dock door",
    "Inbound",
    "Outbound",
    "Offsite",
    "Screen order",
    "Audit order",
    "Zone",
    "Non-inventory",
    "Sortable",
    "Capacity",
    "Multi-capacity",
  ],
  keys: [
    "id",
    "label",
    "hasDockDoor",
    "inbound",
    "outbound",
    "offsiteLocation",
    "screenOrder",
    "auditOrder",
    "zone",
    "nonInventory",
    "sortable",
    "capacity",
    "multiCapacity",
  ],
};

export const TEMP_ID_PREFIX = "Temp";

export const LOCATION_TYPES = ["PARKING_SLIP", "DOCK_DOOR", "GATE", "NO_GO_AREA"];

export const EDIT_YARD_MAP_TYPES = {
  SAVE_LOCATION_DETAILS: "saveLocationDetails",
  REQUEST_REMAPPING: "requestRemapping",
  DELETE_LOCATION_MAPPING: "deleteLocationMapping",
  SAVE_OFFSET: "saveOffset",
};

export const OFFSET_UNIT = 0.00001;

export const GATE_LANE_KEYS = {
  LABEL: "label",
  PURPOSE: "purpose",
  LANE_IDENTIFIER: "laneIdentifier",
  KFB_ACCESS_POINT_ID: "kfbAccessPointId",
  R4G_MODE: "r4gMode",
  INTERCOM_PHONE_NUMBER: "intercomPhoneNumber",
};

export const GATE_TABLE = {
  // Labels are user-friendly UI text used in table header.
  // Keys are system-friendly fields used in backend communication.
  // Order of labels and keys must match.
  labels: [
    keys.GATE_LABEL,
    keys.GATE_TYPE,
    keys.GATE_LANE_IDENTIFIER,
    keys.GATE_KFB_ACCESS_POINT_ID,
    keys.GATE_R4G_MODE,
    keys.INTERCOM_PHONE_NUMBER,
  ],
  keys: [
    GATE_LANE_KEYS.LABEL,
    GATE_LANE_KEYS.PURPOSE,
    GATE_LANE_KEYS.LANE_IDENTIFIER,
    GATE_LANE_KEYS.KFB_ACCESS_POINT_ID,
    GATE_LANE_KEYS.R4G_MODE,
    GATE_LANE_KEYS.INTERCOM_PHONE_NUMBER,
  ],
};

export const GATE_LANE_PURPOSE = {
  CHECK_IN: "CHECK_IN",
  CHECK_OUT: "CHECK_OUT",
};

export const GATE_LANE_PURPOSE_LIST = Object.values(GATE_LANE_PURPOSE);
export const LANE_IDENTIFIER_LIST = ["LANE1", "LANE2", "LANE3", "LANE4"];

export const R4G_MODE_LIST = [keys.DISABLED, keys.SANDBOX, keys.LOCAL, keys.NETWORK];

export const SUBSCRIPTION_KEYS = {
  SUBSCRIPTION_MESSAGE_TYPE: "messageType",
  SUBSCRIPTION_PROTOCOL: "protocol",
  SUBSCRIPTION_STATUS: "status",
  SUBSCRIPTION_ENDPOINT: "endpoint",
};

export const SUBSCRIPTION_TABLE = {
  // Labels are user-friendly UI text used in table header.
  // Keys are system-friendly fields used in backend communication.
  // Order of labels and keys must match.
  labels: [
    keys.SUBSCRIPTION_MESSAGE_TYPE,
    keys.SUBSCRIPTION_PROTOCOL,
    keys.SUBSCRIPTION_STATUS,
    keys.SUBSCRIPTION_ENDPOINT,
  ],
  keys: [
    SUBSCRIPTION_KEYS.SUBSCRIPTION_MESSAGE_TYPE,
    SUBSCRIPTION_KEYS.SUBSCRIPTION_PROTOCOL,
    SUBSCRIPTION_KEYS.SUBSCRIPTION_STATUS,
    SUBSCRIPTION_KEYS.SUBSCRIPTION_ENDPOINT,
  ],
};

export const SUBSCRIPTION_MESSAGE_TYPE_LIST = [
  keys.INBOUND_ARRIVAL,
  keys.NON_INVENTORY_ARRIVAL,
  keys.OUTBOUND_ARRIVAL,
  keys.TOM_ARRIVAL,
  keys.VENDOR_RETURN_ARRIVAL,
];

export const SUBSCRIPTION_PROTOCOL_LIST = [keys.CHIME, keys.SLACK];

export const SUBSCRIPTION_STATUS_LIST = [keys.ACTIVE, keys.INACTIVE];

export const SAFETY_INSTRUCTIONS = "SAFETY_INSTRUCTIONS";
export const YARD_MAP = "YARD_MAP";
export const YARD_DOCUMENT_TYPES = [SAFETY_INSTRUCTIONS, YARD_MAP];

export const YARD_LINKS = {
  config: { path: "config", text: keys.CONFIG },
  devices: { path: "devices", text: keys.DEVICES },
  documents: { path: "documents", text: keys.DOCUMENTS },
  map: { path: "map", text: keys.MAP },
};

export const YARD_ENTITY_PROPS_TABLE_HEADER_COL_WIDTH = 200;

export const PEER_CONNECTION_STATES = {
  NEW: "new",
  CLOSED: "closed",
  FAILED: "failed",
  DISCONNECTED: "disconnected",
  CONNECTED: "connected",
};

export const CAMERA_TYPES = ["FRONT", "REAR", "BOX_TRUCK", "OVERVIEW_FRONT", "OVERVIEW_REAR"];

export const STREAM_QUALITY = {
  LO: "LO",
  HI: "HI",
};

// yard features
export const ENABLE_DSP_SUPPORT = "ENABLE_DSP_SUPPORT";

// building features
export const ENABLE_DDU_SUPPORT = "ENABLE_DDU_SUPPORT";
