import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import syncIcon from "@amzn/meridian-tokens/base/icon/sync";

import AddDeviceDialog from "componentsV2/Common/Dialogs/AddDeviceDialog";
import SingleMessageToaster from "componentsV2/Common/SingleMessageToaster";
import { fetchYardDevices } from "store";
import { keys } from "i18n";
import { STATUS_TYPES } from "app-constants";

export default function ActionBar({ yardId, refreshingYardDevices, setRefreshingYardDevices }) {
  const [errorRefreshingDevices, setErrorRefreshingDevices] = useState(null);
  const [shouldShowAddDeviceDialog, setShouldShowAddDeviceDialog] = useState(false);

  const dispatch = useDispatch();

  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];

  const { buildings } = yardConfig || {};

  const { t } = useTranslation();

  const openAddDeviceDialog = () => setShouldShowAddDeviceDialog(true);
  const closeAddDeviceDialog = () => setShouldShowAddDeviceDialog(false);

  const refresh = () => {
    setRefreshingYardDevices(true);

    dispatch(
      fetchYardDevices(
        {
          yardIdentifier: {
            yardId,
            buildingCodes: buildings.map(({ buildingCode }) => buildingCode),
          },
        },
        () => setRefreshingYardDevices(false),
        (error) => {
          setRefreshingYardDevices(false);
          setErrorRefreshingDevices(error);
        }
      )
    );
  };

  return (
    <>
      <Row data-testid="actionBar" alignmentHorizontal="end">
        <Button
          data-testid="refreshButton"
          type="icon"
          label={t(keys.REFRESH)}
          disabled={refreshingYardDevices}
          onClick={refresh}
        >
          <Icon tokens={syncIcon} />
        </Button>

        <Button
          data-testid="addDeviceButton"
          disabled={refreshingYardDevices}
          onClick={openAddDeviceDialog}
        >
          {t(keys.ADD_DEVICE)}
        </Button>
      </Row>

      {shouldShowAddDeviceDialog && (
        <AddDeviceDialog yardId={yardId} dismiss={closeAddDeviceDialog} />
      )}

      <SingleMessageToaster
        message={errorRefreshingDevices}
        type={STATUS_TYPES.ERROR}
        onClose={() => setErrorRefreshingDevices(null)}
      />
    </>
  );
}

ActionBar.propTypes = {
  yardId: PropTypes.string.isRequired,
  refreshingYardDevices: PropTypes.bool.isRequired,
  setRefreshingYardDevices: PropTypes.func.isRequired,
};
