import { callAPI } from "store";
import { BATCH_CREATE_3P_YARDS_SUCCESS } from "./batchCreate3PYardsActionTypes";
import { APIs } from "app-constants";

export const batchCreate3PYards = ({ yardLabels, onSuccess, onFailure }) => {
  return async (dispatch, getState) => {
    try {
      const responseJson = await callAPI(getState().accountInfo, APIs.BATCH_CREATE_3P_YARDS, {
        yardLabels,
      });

      dispatch(batchCreate3PYardsSuccess(responseJson));
      onSuccess();
    } catch (exception) {
      onFailure(JSON.parse(exception.message));
    }
  };
};

export const batchCreate3PYardsSuccess = (payload) => {
  return {
    type: BATCH_CREATE_3P_YARDS_SUCCESS,
    payload,
  };
};
