export const CREATE_BUILDING = "CREATE_BUILDING";
export const CREATE_BUILDING_SUCCESS = "CREATE_BUILDING_SUCCESS";
export const CREATE_BUILDING_FAILURE = "CREATE_BUILDING_FAILURE";
export const CLEAR_ERROR_CREATING_BUILDING = "CLEAR_ERROR_CREATING_BUILDING";
export const EDIT_BUILDING = "EDIT_BUILDING";
export const EDIT_BUILDING_SUCCESS = "EDIT_BUILDING_SUCCESS";
export const EDIT_BUILDING_FAILURE = "EDIT_BUILDING_FAILURE";
export const CLEAR_ERROR_EDITING_BUILDING = "CLEAR_ERROR_EDITING_BUILDING";
export const DELETE_BUILDING = "DELETE_BUILDING";
export const DELETE_BUILDING_SUCCESS = "DELETE_BUILDING_SUCCESS";
export const DELETE_BUILDING_FAILURE = "DELETE_BUILDING_FAILURE";
export const CLEAR_DELETE_BUILDING_DIALOG = "CLEAR_DELETE_BUILDING_DIALOG";
