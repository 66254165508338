import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Column from "@amzn/meridian/column";

import BuildingPropsTable from "componentsV2/ConfigPage/YardEntity/BuildingInfo/BuildingPropsTable";
import RemoveBuildingAction from "componentsV2/ConfigPage/YardEntity/BuildingInfo/RemoveBuildingAction";
import SubscriptionTable from "componentsV2/ConfigPage/YardEntity/BuildingInfo/SubscriptionTable";
import { ENABLE_SELF_DELETION_FEATURE } from "app-constants";

export default function BuildingInfo({ yardId, buildingCode }) {
  const featureFlags = useSelector((state) => state.featureFlags);

  return (
    <Column>
      {featureFlags[ENABLE_SELF_DELETION_FEATURE]?.enabled && (
        <RemoveBuildingAction yardId={yardId} buildingCode={buildingCode} />
      )}
      <BuildingPropsTable yardId={yardId} buildingCode={buildingCode} />
      <SubscriptionTable yardId={yardId} buildingCode={buildingCode} />
    </Column>
  );
}

BuildingInfo.propTypes = {
  yardId: PropTypes.string.isRequired,
  buildingCode: PropTypes.string.isRequired,
};
