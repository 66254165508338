import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Routes, Route } from "react-router-dom";

import ConfigPage from "componentsV2/ConfigPage";
import DevicePage from "componentsV2/DevicePage";
import DocumentPage from "componentsV2/DocumentPage";
import HomePage from "componentsV2/HomePage";
import MapPage from "componentsV2/MapPage";
import { fetchFeatureFlags, fetchPermissions, saveAccountInfo, saveSecrets } from "store";
import { parseWithoutThrowingException } from "utils";
import { FEATURES } from "app-constants";
import CameraMaskingPage from "./CameraMaskingPage";

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    window.postMessage("Component mounted", "*");

    // Dev setup to inject dev-provided accountInfo for testing
    if (process.env.NODE_ENV === "development") {
      const {
        REACT_APP_SECURITY_TOKEN,
        REACT_APP_DOMAIN,
        REACT_APP_REALM,
        REACT_APP_ACTIVE_ACCOUNT_NAME,
      } = process.env;
      const json = {
        securityToken: REACT_APP_SECURITY_TOKEN,
        realm: REACT_APP_REALM,
        domain: REACT_APP_DOMAIN,
        activeAccountName: REACT_APP_ACTIVE_ACCOUNT_NAME,
      };
      window.postMessage(JSON.stringify(json), "*");
    }
  }, []);

  const handleMessage = (event) => {
    const json = typeof event.data === "string" && parseWithoutThrowingException(event.data);
    if (json && json.securityToken) {
      window.securityToken = json.securityToken;

      dispatch(
        saveAccountInfo({
          activeAccountName: json.activeAccountName,
          realm: json.realm,
          domain: json.domain,
        })
      );

      // Before saving secret, replace ' by " to make it a legal JSON string
      // ' instead of " is used in the first place when passing the secret to the UI because doing so allows for passing nested JSON string
      json.mapApiKeys && dispatch(saveSecrets(json.mapApiKeys.replace(/'/g, '"')));

      dispatch(fetchPermissions());

      FEATURES.forEach((feature) => dispatch(fetchFeatureFlags(feature)));
    }
  };

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/yards/:yardId/config" element={<ConfigPage />} />
      <Route path="/yards/:yardId/config/:gateId/cameraMasking" element={<CameraMaskingPage />} />
      <Route path="/yards/:yardId/devices" element={<DevicePage />} />
      <Route path="/yards/:yardId/documents" element={<DocumentPage />} />
      <Route path="/yards/:yardId/map" element={<MapPage />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

App.propTypes = {};
