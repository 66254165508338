import { callAPI } from "store";
import {
  FETCH_YARD_CONFIG_INITIALIZATION,
  FETCH_YARD_CONFIG_SUCCESS,
  FETCH_YARD_CONFIG_FAILURE,
  CLEAR_YARD_CONFIG_DATA,
} from "./yardConfigDataActionTypes";
import { APIs, ADJACENT, OFFSITE, SUPPORT_OFFSITE_MODELING } from "app-constants";

export const fetchYardConfig = (yardIdentifier, yardGraphVersion) => {
  return async (dispatch, getState) => {
    const { yardId } = yardIdentifier;

    dispatch(fetchYardConfigInitialization(yardId));

    try {
      const responseJson = await callAPI(getState().accountInfo, APIs.QUERY_YARD_GRAPH, {
        yardIds: [yardId],
        followRelations: getState().featureFlags[SUPPORT_OFFSITE_MODELING]?.enabled
          ? [ADJACENT, OFFSITE]
          : [ADJACENT],
        minVersion: yardGraphVersion,
      });

      dispatch(fetchYardConfigSuccess({ yardId, yardConfig: responseJson }));
    } catch (exception) {
      dispatch(fetchYardConfigFailure({ yardId, error: exception.message }));
    }
  };
};

export const clearYardConfigData = () => {
  return {
    type: CLEAR_YARD_CONFIG_DATA,
  };
};

export const fetchYardConfigInitialization = (yardId) => {
  return {
    type: FETCH_YARD_CONFIG_INITIALIZATION,
    payload: yardId,
  };
};

// payload includes yardId and yardConfig
export const fetchYardConfigSuccess = (payload) => {
  return {
    type: FETCH_YARD_CONFIG_SUCCESS,
    payload,
  };
};

// payload includes yardId and error
export const fetchYardConfigFailure = (payload) => {
  return {
    type: FETCH_YARD_CONFIG_FAILURE,
    payload,
  };
};
