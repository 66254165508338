import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import AddYardActions from "componentsV2/HomePage/ActionBar/AddYardActions";
import Search from "componentsV2/HomePage/ActionBar/Search";
import { keys } from "i18n";

export default function ActionBar() {
  const { fetchingRefresh, allMatchedYardIdentifiersCount } = useSelector(
    (state) => state.yardIdentifiersData
  );

  const { t } = useTranslation();

  return (
    <Row alignmentHorizontal="justify" alignmentVertical="bottom">
      <Text data-testid="yardIdentifiersCountText">
        {fetchingRefresh ? t(keys.LOADING) : `${allMatchedYardIdentifiersCount} ${t(keys.RESULTS)}`}
      </Text>

      <Row>
        <Search />
        <AddYardActions />
      </Row>
    </Row>
  );
}

ActionBar.propTypes = {};
