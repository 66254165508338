import React from "react";
import PropTypes from "prop-types";
import { Popup } from "react-leaflet";

import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Toggle from "@amzn/meridian/toggle";

export default class YardMapOffsetPopup extends React.PureComponent {
  render() {
    const { yardMapData, offsetKeysEnabled, toggleOffsetKeysEnabled, saveOffset, cancelOffset } =
      this.props;
    return (
      <Popup>
        <Column>
          <Text>
            {offsetKeysEnabled
              ? "Use the AWSD or arrow keys to adjust the offset of all yard locations at this site."
              : "To adjust the offset of the yard locations of this site, enable the offset toggle."}
          </Text>

          <Toggle checked={offsetKeysEnabled} onChange={toggleOffsetKeysEnabled}>
            Offset Keys Enabled
          </Toggle>

          <Row spacing="small">
            {yardMapData.editingYardMap.saveOffset ? (
              <Loader size="medium" />
            ) : (
              <Button onClick={saveOffset}>Save Offset</Button>
            )}
            <Button type="secondary" onClick={cancelOffset}>
              Cancel Offset
            </Button>
          </Row>
        </Column>
      </Popup>
    );
  }
}

YardMapOffsetPopup.propTypes = {
  yardMapData: PropTypes.object,
  offsetKeysEnabled: PropTypes.bool.isRequired,
  toggleOffsetKeysEnabled: PropTypes.func.isRequired,
  saveOffset: PropTypes.func.isRequired,
  cancelOffset: PropTypes.func.isRequired,
};
