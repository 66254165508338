import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Toaster from "@amzn/meridian/toaster";

import { callAPI, fetchYardDocuments } from "store";
import { keys } from "i18n";
import { APIs, PUT, STATUS_TYPES } from "app-constants";

export default function FileUpload({ yardId, documentLocale, documentType }) {
  const [file, setFile] = useState(null);
  const [toasts, setToasts] = useState([]);
  const [uploading, setUploading] = useState(false);

  const accountInfo = useSelector((state) => state.accountInfo);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const fetchUploadUrl = async () => {
    setUploading(true);

    try {
      const { url } = await callAPI(accountInfo, APIs.UPLOAD_YARD_DOCUMENT, {
        yardId,
        documentLocale,
        documentType,
      });

      await fetch(url, {
        method: PUT,
        body: file,
      });

      setUploading(false);
      setToasts(
        toasts.concat({
          id: "upload-success",
          type: STATUS_TYPES.SUCCESS,
          message: t(keys.FILE_UPLOADED),
        })
      );
      setFile(null);

      // Re-fetch documents for locale so users can verify upload immediately
      dispatch(fetchYardDocuments({ yardId, documentLocale }));
    } catch (e) {
      setUploading(false);
      setToasts(
        toasts.concat({
          id: "upload-failure",
          type: STATUS_TYPES.ERROR,
          message: t(keys.FILE_UPLOAD_FAILURE_MESSAGE),
          error: e.message,
        })
      );
    }
  };

  const validateFileFormat = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const [fileExtension] = file.name.split(".").slice(-1);
    if (fileExtension !== "pdf") {
      file.error = true;
      file.errorMessage = t(keys.FILE_EXTENSION_NOT_SUPPORTED_MESSAGE_YARD_DOCUMENT);
    }
    setFile(file);
  };

  const reset = () => setFile(null);

  const closeToast = (id) => {
    setToasts(toasts.filter((toast) => toast.id !== id));
  };

  const renderSubmitButton = () =>
    uploading ? (
      <Loader data-testid="loader" size="medium" />
    ) : (
      <Button data-testid="sumbitButton" onClick={fetchUploadUrl}>
        {t(keys.SUBMIT)}
      </Button>
    );

  return (
    <>
      <FileInput
        data-testid="fileUpload"
        type="single"
        uploadButtonLabel={t(keys.UPLOAD_NEW_FILE)}
        uploadButtonSize="medium"
        uploadButtonType="link"
        onFileAttached={validateFileFormat}
      >
        <Row>
          {file && (
            <FileDetails
              data-testid="fileDetails"
              file={file}
              uploadComplete={true}
              error={file.error}
              errorMessage={file.errorMessage}
              onClickCancel={reset}
              onClickRemoveFile={reset}
            />
          )}

          {file && !file.error && renderSubmitButton()}
        </Row>
      </FileInput>

      <Toaster toasts={toasts} onCloseToast={closeToast}>
        {({ id, type, message, error, onClose }) => (
          <Alert data-testid={id} toast={true} type={type} onClose={onClose}>
            {message}
            {error && <Text>{error}</Text>}
          </Alert>
        )}
      </Toaster>
    </>
  );
}

FileUpload.propTypes = {
  yardId: PropTypes.string.isRequired,
  documentLocale: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
};
