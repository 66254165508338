import PropTypes from "prop-types";

import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import { colorGray0 } from "@amzn/meridian-tokens/base/color";

import CSVDownload from "componentsV2/Common/LocationCSVDownload";
import CSVUpload from "componentsV2/ConfigPage/YardEntity/YardInfo/LocationTable/ActionBar/BaseActionBar/CSVUpload";
import LabelFilter from "componentsV2/ConfigPage/YardEntity/YardInfo/LocationTable/ActionBar/BaseActionBar/LabelFilter";
import { location } from "types";

export default function BaseActionBar({
  locations,
  filterString,
  sortColumn,
  sortDirection,
  setLocations,
  setFilterString,
  setPage,
}) {
  return (
    <Box type="fill" backgroundColor={colorGray0} spacingInset="small medium">
      <Row alignmentHorizontal="justify">
        <LabelFilter
          filterString={filterString}
          setFilterString={setFilterString}
          setPage={setPage}
        />

        <Row spacing="small">
          <CSVDownload
            locations={locations}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />

          <CSVUpload locations={locations} setLocations={setLocations} />
        </Row>
      </Row>
    </Box>
  );
}

BaseActionBar.propTypes = {
  locations: PropTypes.arrayOf(location).isRequired,
  filterString: PropTypes.string.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  setLocations: PropTypes.func.isRequired,
  setFilterString: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};
