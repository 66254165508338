import {
  FETCH_SEARCH_SUGGESTIONS,
  FETCH_SEARCH_SUGGESTIONS_SUCCESS,
  FETCH_SEARCH_SUGGESTIONS_FAILURE,
  CLEAR_SEARCH_SUGGESTIONS,
} from "./searchSuggestionsActionTypes";

const INITIAL_SEARCH_SUGGESTIONS = {
  fetchingSearchResults: false,
  searchResultsCount: 0,
  searchSuggestions: [],
  error: null,
};

export const searchSuggestionsReducer = (state = INITIAL_SEARCH_SUGGESTIONS, action) => {
  switch (action.type) {
    case FETCH_SEARCH_SUGGESTIONS:
      return {
        ...state,
        fetchingSearchResults: true,
        searchResultsCount: 0,
        searchSuggestions: [],
        error: null,
      };

    case FETCH_SEARCH_SUGGESTIONS_SUCCESS:
      const { allMatchedYardIdentifiersCount, yardIdentifiers } = action.payload;
      return {
        ...state,
        fetchingSearchResults: false,
        searchResultsCount: allMatchedYardIdentifiersCount,
        searchSuggestions: yardIdentifiers,
        error: null,
      };

    case FETCH_SEARCH_SUGGESTIONS_FAILURE:
      return {
        ...state,
        fetchingSearchResults: false,
        error: action.payload,
      };

    case CLEAR_SEARCH_SUGGESTIONS:
      return {
        ...state,
        fetchingSearchResults: false,
        searchResultsCount: 0,
        searchSuggestions: [],
        error: null,
      };

    default:
      return state;
  }
};
