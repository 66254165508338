import PropTypes from "prop-types";

import Alert from "@amzn/meridian/alert";
import Toaster from "@amzn/meridian/toaster";

export default function SingleMessageToaster({ message, type, onClose }) {
  if (message) {
    return (
      <Toaster toasts={[{ id: message, message }]} onCloseToast={onClose}>
        {({ message, onClose }) => (
          <Alert data-testid={message} toast={true} type={type} onClose={onClose}>
            {message}
          </Alert>
        )}
      </Toaster>
    );
  }

  return null;
}

SingleMessageToaster.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
