import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Alert from "@amzn/meridian/alert";
import Column from "@amzn/meridian/column";

import Breadcrumb from "componentsV2/Common/Headers/Breadcrumb";
import DevicePageBody from "componentsV2/DevicePage/DevicePageBody";
import Headers from "componentsV2/Common/Headers";
import PageBody from "componentsV2/Common/PageBody";
import { useYardConfigInit } from "hooks";
import { fetchYardDevices } from "store";
import { keys } from "i18n";
import { STATUS_TYPES, YARD_LINKS } from "app-constants";

export default function DevicePage() {
  const [errorFetchingYardDevices, setErrorFetchingYardDevices] = useState(null);

  const { yardId } = useParams();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];
  const yardDevices = useSelector((state) => state.yardDevicesData)[yardId];

  const { label, buildings, version } = yardConfig || {};

  useEffect(() => {
    if (!yardDevices && version) {
      dispatch(
        fetchYardDevices(
          {
            yardIdentifier: {
              yardId,
              buildingCodes: buildings.map(({ buildingCode }) => buildingCode),
            },
          },
          null,
          (error) => setErrorFetchingYardDevices(error)
        )
      );
    }
  }, [yardDevices, version]);

  useYardConfigInit(yardId);

  return (
    <Column spacing="large" spacingInset="large">
      <Headers>
        <Breadcrumb yardId={yardId} yardLabel={label} yardLink={YARD_LINKS.devices} />
      </Headers>

      <PageBody yardId={yardId}>
        {errorFetchingYardDevices && (
          <Alert data-testid="fetchYardDevicesErrorMessage" type={STATUS_TYPES.ERROR}>
            {t(keys.FETCH_YARD_DEVICES_ERROR_MESSAGE)}
          </Alert>
        )}
        {!errorFetchingYardDevices && <DevicePageBody yardId={yardId} />}
      </PageBody>
    </Column>
  );
}

DevicePage.propTypes = {};
