import { computeDestinationPoint } from "geolib";
import { isEqual, zipObject } from "lodash";
import { keys } from "i18n";
import { callAPI } from "store";
import {
  APIs,
  CSV_LOCATION_TABLE,
  ENABLE_MULTIZONE_MAPPING,
  SHOW_AUDIT_MODE_SEARCH_FEATURE,
  SHOW_OFFSITE_BUILDING_CODE,
  ENABLE_DISABLE_LOCATION_FEATURE,
  LOCATION_TABLE,
  POST,
} from "app-constants";

export const equalsIgnoreCase = (string1, string2) =>
  string1.toUpperCase() === string2.toUpperCase();

export const includesIgnoreCase = (stringArray, stringToMatch) =>
  stringArray.some((string) => equalsIgnoreCase(string, stringToMatch));

// Supports lodash path syntax values by keying with only the final part of the path
// e.g. auditModeSearch: "auditFeatures.auditModeSearch"
export const zipWithFlattenedKeys = (stringArray) =>
  Object.fromEntries(stringArray.map((key) => [key.split(".").pop(), key]));

export const getLatLonFromCartesianDisplacement = (origin, x, y) => {
  // When no displacement
  if (x === 0 && y === 0) {
    return origin;
  }

  const distance = Math.sqrt(x * x + y * y);
  const bearing = getBearing(x, y);

  return computeDestinationPoint(origin, distance, bearing);
};

// Bearing is the angle clockwise from the positive y-axis
// x and y will not equal 0 at the same time
const getBearing = (x, y) => {
  if (x === 0) return y > 0 ? 0 : 180;
  if (y === 0) return x > 0 ? 90 : 270;

  const atan = (Math.atan(x / y) * 180) / Math.PI;

  // Destination point in first/third quadrant
  if (atan > 0) return x > 0 ? atan : 180 + atan;
  // Destination point in second/fourth quadrant
  return x > 0 ? 180 + atan : 360 + atan;
};

export const getUniqueZoneSet = (parkinglocations) => {
  const uniqueZones = new Set();
  parkinglocations.forEach((location) => {
    if (location.zone) {
      uniqueZones.add(location.zone);
    }
    if (location.zoneList) {
      location.zoneList.forEach((zone) => {
        uniqueZones.add(zone);
      });
    }
  });
  return uniqueZones;
};

export const getNewZones = (zones) => {
  const newZones = [];
  zones.forEach((zone) => {
    if (zone) {
      newZones.push(zone);
    }
  });
  return newZones;
};

export const getZoneList = (location) => {
  if (location) {
    if (location.zoneList) {
      return location.zoneList;
    }
    if (location.zone) {
      return [location.zone];
    }
  }
  return [];
};

export const prepareTableColumns = (featureFlags) => {
  let newLabels = [...LOCATION_TABLE.labels];
  let newKeys = [...LOCATION_TABLE.keys];

  if (featureFlags[ENABLE_MULTIZONE_MAPPING]?.enabled) {
    const locationZoneIndex = newLabels.indexOf(keys.LOCATION_ZONE);

    newLabels = [
      //Replacing zone column with zones.
      ...newLabels.slice(0, locationZoneIndex),
      `${keys.LOCATION_ZONES}`,
      ...newLabels.slice(locationZoneIndex + 1),
    ];
    newKeys = [
      //Replacing zone column with zones.
      ...newKeys.slice(0, locationZoneIndex),
      "zoneList",
      ...newKeys.slice(locationZoneIndex + 1),
    ];
  }
  // Push any FF-driven labels and keys into newLabels and newKeys

  return {
    labels: newLabels,
    keys: newKeys,
  };
};

export const prepareCSVColumns = (featureFlags) => {
  let newLabels = [...CSV_LOCATION_TABLE.labels];
  let newKeys = [...CSV_LOCATION_TABLE.keys];

  if (featureFlags[SHOW_OFFSITE_BUILDING_CODE]?.enabled) {
    newLabels.push("Offsite BuildingCode");
    newKeys.push("offsiteBuildingCode");
  }

  if (featureFlags[SHOW_AUDIT_MODE_SEARCH_FEATURE]?.enabled) {
    newLabels.push("Audit mode search");
    newKeys.push("auditModeSearch");
  }

  if (featureFlags[ENABLE_DISABLE_LOCATION_FEATURE]?.enabled) {
    newLabels.push("Disabled");
    newKeys.push("disabled");
  }

  if (featureFlags[ENABLE_MULTIZONE_MAPPING]?.enabled) {
    const zoneLabelIndex = newLabels.indexOf("Zone");

    newLabels = [
      //Replacing zone column with zones.
      ...newLabels.slice(0, zoneLabelIndex),
      "Zones",
      ...newLabels.slice(zoneLabelIndex + 1),
    ];
    newKeys = [
      //Replacing zone column with zones.
      ...newKeys.slice(0, zoneLabelIndex),
      "zoneList",
      ...newKeys.slice(zoneLabelIndex + 1),
    ];
  }
  // Push any FF-driven labels and keys into newLabels and newKeys
  return {
    labels: newLabels,
    keys: newKeys,
  };
};

export const convertToMapById = (gates) =>
  zipObject(
    gates.map((gate) => gate.id),
    gates
  );

export const ableToSendTestMessage = async (subscription, accountInfo) => {
  // The endpoint must belong to the protocol. e.g. Chime webhook to CHIME protocol
  if (!subscription.endpoint.toLowerCase().includes(subscription.protocol.toLowerCase())) {
    return false;
  }

  // CORS is not currently enabled for Chime webhooks.
  // That's why we call API to validate the endpoint.
  // https://docs.aws.amazon.com/chime/latest/ag/webhooks.html
  if (isEqual(subscription.protocol, keys.CHIME)) {
    try {
      const requestBody = {
        webhook: subscription.endpoint,
      };
      const responseJson = await callAPI(accountInfo, APIs.TEST_ACN_SUBSCRIPTION, requestBody);
      return responseJson.isACNSubscriptionValid;
    } catch (exception) {
      console.error("Failed to validate endpoint:", subscription.endpoint, exception.message);
      return false;
    }
  }

  try {
    const payload = {
      Content: "This is a test message.",
    };
    const response = await fetch(subscription.endpoint, {
      method: POST,
      body: JSON.stringify(payload),
    });

    return response.ok;
  } catch (exception) {
    console.error("Failed to validate endpoint:", subscription.endpoint, exception.message);
    return false;
  }
};

export const uploadMaskImageToS3 = async (accountInfo, imageDataUrl, cameraInfo) => {
  const responseJson = await callAPI(
    accountInfo,
    APIs.GET_GATE_AREA_DEFINITION_MASK_PRESIGNED_URL,
    {
      cameraList: [cameraInfo],
      presignMethod: "PUT",
    }
  );
  const s3PresignedUrl = responseJson.cameraMaskUrls?.[0]?.presignedUrl;
  const blob = await (await fetch(imageDataUrl)).blob();
  // Make a PUT request to the presigned URL
  const response = await fetch(s3PresignedUrl, {
    method: "PUT",
    headers: {
      "Content-Type": blob.type,
    },
    body: blob,
  });
  return response;
};

export const isValidInteger = (str) => {
  const num = parseInt(str, 10);
  return !Number.isNaN(num) && num.toString() === str;
};
