import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import YardDialog from "componentsV2/Common/Dialogs/YardDialog";
import { createYard } from "store";
import { keys } from "i18n";

export default function AddYardDialog({ dismiss }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const submit = ({ yardConfig, successCallback, failureCallback }) =>
    dispatch(createYard({ yardConfig, successCallback, failureCallback }));

  return (
    <YardDialog
      title={t(keys.ADD_YARD)}
      genericErrorMessage={t(keys.CREATE_YARD_ERROR_MESSAGE)}
      submit={submit}
      dismiss={dismiss}
    />
  );
}

AddYardDialog.propTypes = {
  dismiss: PropTypes.func.isRequired,
};
