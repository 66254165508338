import PropTypes from "prop-types";
import { GridLayer, withLeaflet } from "react-leaflet";
import "leaflet-plugins/layer/tile/Bing.js";

class BingTileLayer extends GridLayer {
  createLeafletElement(props) {
    return L.bingLayer(props.bingKey, this.getOptions(props));
  }
}

export default withLeaflet(BingTileLayer);

BingTileLayer.propTypes = {
  bingKey: PropTypes.string.isRequired,
  imagerySet: PropTypes.string.isRequired,
  maxZoom: PropTypes.number.isRequired,
};
