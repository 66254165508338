import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Column from "@amzn/meridian/column";

import GateTable from "componentsV2/ConfigPage/YardEntity/YardInfo/GateTable";
import LocationTable from "componentsV2/ConfigPage/YardEntity/YardInfo/LocationTable";
import YardPropsTable from "componentsV2/ConfigPage/YardEntity/YardInfo/YardPropsTable";
import RemoveYardAction from "componentsV2/ConfigPage/YardEntity/YardInfo/RemoveYardAction";
import { ENABLE_SELF_DELETION_FEATURE } from "app-constants";

export default function YardInfo({ mainYardId, yardId }) {
  const featureFlags = useSelector((state) => state.featureFlags);

  return (
    <Column>
      {featureFlags[ENABLE_SELF_DELETION_FEATURE]?.enabled && (
        <RemoveYardAction mainYardId={mainYardId} yardId={yardId} />
      )}
      <YardPropsTable yardId={yardId} />
      <LocationTable mainYardId={mainYardId} yardId={yardId} />
      <GateTable yardId={yardId} />
    </Column>
  );
}

YardInfo.propTypes = {
  mainYardId: PropTypes.string,
  yardId: PropTypes.string.isRequired,
};
