import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Box from "@amzn/meridian/box";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";

import FileDownload from "componentsV2/DocumentPage/DocumentTable/FileDownload";
import FileUpload from "componentsV2/DocumentPage/DocumentTable/FileUpload";
import { fetchYardDocuments } from "store";
import { getNewLocales, processYardDocuments } from "helpers";
import { keys } from "i18n";

export default function DocumentTable({ yardId }) {
  const { languages } = useSelector((state) => state.yardConfigData)[yardId];
  const yardDocuments = useSelector((state) => state.yardDocumentsData)[yardId];

  const dispatch = useDispatch();

  useEffect(() => {
    getNewLocales(languages, yardDocuments).forEach((documentLocale) =>
      dispatch(fetchYardDocuments({ yardId, documentLocale }))
    );
  }, [languages]);

  const { t } = useTranslation();

  return (
    <Box overflowX="scroll">
      <Table headerRows={1} showDividers={true}>
        <TableRow>
          <TableCell>{t(keys.LOCALE)}</TableCell>
          <TableCell>{t(keys.DOCUMENT_TYPE)}</TableCell>
          <TableCell>{t(keys.DOWNLOAD)}</TableCell>
          <TableCell>{t(keys.UPLOAD)}</TableCell>
        </TableRow>

        {processYardDocuments(languages, yardDocuments).map(
          ({ documentLocale, documentType, fetching, error, url }, index) => (
            <TableRow key={`${documentLocale}-${documentType}`} highlightOnHover={true}>
              <TableCell data-testid={`${index}-${documentLocale}`}>{documentLocale}</TableCell>
              <TableCell data-testid={`${index}-${documentType}`}>{documentType}</TableCell>
              <TableCell>
                <FileDownload fetching={fetching} error={error} url={url} />
              </TableCell>
              <TableCell>
                <FileUpload
                  yardId={yardId}
                  documentLocale={documentLocale}
                  documentType={documentType}
                />
              </TableCell>
            </TableRow>
          )
        )}
      </Table>
    </Box>
  );
}

DocumentTable.propTypes = {
  yardId: PropTypes.string.isRequired,
};
