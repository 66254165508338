import Papa from "papaparse";
import _, { isEqual, mapValues } from "lodash";

import { getTempId } from "helpers";
import { keys } from "i18n";
import { DataType, LOCATION_KEY_DATA_TYPES } from "app-constants";

export const formatLocationsForCSV = (locations, sortColumn, sortDirection, t) => {
  return _(locations)
    .map((location) =>
      mapValues(
        {
          ...location,
          // Internal location props don't have auditModeSearch but rather auditFeatures: { auditModeSearch: boolean }
          // CSV expects a flattened auditModeSearch, so creating one here
          auditModeSearch: null,
        },
        (value, key, location) => formatValueForCSV(value, key, location, t)
      )
    )
    .orderBy([sortColumn], [sortDirection === "ascending" ? "asc" : "desc"])
    .value();
};

const formatValueForCSV = (value, key, location, t) => {
  if (typeof value === "boolean") {
    return value ? t(keys.YES) : t(keys.NO);
  }

  if (key === "zoneList") {
    return value ? value.join(",") : location.zone;
  }

  if (key === "auditModeSearch") {
    return location.auditFeatures?.auditModeSearch ? t(keys.YES) : t(keys.NO);
  }

  return value;
};

export const extractLocationsFromCSV = (csvString, expectedColumns, t) => {
  const parsedCSV = Papa.parse(csvString, {
    header: true,
    delimiter: ",",
    skipEmptyLines: true,
    quoteChar: '"',
  });

  validateParsedCSV(parsedCSV, expectedColumns);

  const headers = parsedCSV.meta.fields;

  return parsedCSV.data.map((row) => {
    let location = {};

    headers.forEach((header, index) => {
      const key = expectedColumns.keys[index];
      location[key] = parseCSVValue(key, row[header].trim(), t);
    });

    // Generate temp id for uploaded locations without id (https://sim.amazon.com/issues/Yard-3453)
    // DO NOT change the format of the temp id, these ids need to be found later and removed before calling back-end to save
    if (!location.id) {
      location.id = getTempId();
    }

    // Convert comma-delimited string to array, default to empty array
    // Also, create a null zone internal prop to preserve that prop
    location.zoneList = location.zoneList?.split(",") || [];
    location.zone = null;

    // Create internal auditFeatures props from flattened auditModeSearch
    // Also, delete flattened auditModeSearch from internal props
    location.auditFeatures = {
      auditModeSearch: !!location.auditModeSearch,
    };
    delete location.auditModeSearch;

    return location;
  });
};

const validateParsedCSV = (parsedCSV, expectedColumns) => {
  const { errors, meta } = parsedCSV;

  if (errors?.length) {
    throw new Error(JSON.stringify(errors), { cause: "invalid-csv" });
  }

  const headers = meta.fields;
  const expectedHeaders = expectedColumns.labels;

  if (headers?.length !== expectedHeaders.length) {
    throw new Error(
      // prettier-ignore
      `Location CSV file doesn't have the expected number of headers. Expected headers are: ${expectedHeaders.join(", ")}`,
      { cause: "csv-invalid-header" }
    );
  }

  if (!isEqual(headers, expectedHeaders)) {
    throw new Error(
      // prettier-ignore
      `Location CSV file doesn't have the expected headers in the expected order. Expected headers are: ${expectedHeaders.join(", ")}, but actual headers are: ${headers.join(", ")}`,
      { cause: "csv-invalid-header" }
    );
  }
};

const parseCSVValue = (key, value, t) => {
  switch (LOCATION_KEY_DATA_TYPES[key]) {
    case DataType.Boolean:
      return (
        value.toUpperCase() === "YES" || // to make CSV boolean inputs case insensitive
        value === t(keys.YES)
      );
    case DataType.Number: {
      const parsed = parseInt(value);
      return isNaN(parsed) ? null : parsed;
    }
    default:
      return value || null;
  }
};
