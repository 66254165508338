import { last } from "lodash";

import {
  FETCH_YARD_IDENTIFIERS_REFRESH,
  FETCH_YARD_IDENTIFIERS_CONTINUE,
  FETCH_YARD_IDENTIFIERS_SUCCESS,
  FETCH_YARD_IDENTIFIERS_FAILURE,
  SET_YARD_IDENTIFIERS,
} from "./yardIdentifiersActionTypes";
import { BATCH_CREATE_3P_YARDS_SUCCESS } from "./batchCreate3PYardsActionTypes";
import { CREATE_YARD_SUCCESS } from "../yardConfigData/editYardActionTypes";

export const INITIAL_YARD_IDENTIFIERS = {
  fetchingRefresh: false,
  fetchingContinue: false,
  searchString: null,
  searchScope: null,
  lastUpdate: null,
  allMatchedYardIdentifiersCount: 0,
  yardIdentifiers: [],
  exclusiveStartKey: null,
  error: null,
};

export const yardIdentifiersReducer = (state = INITIAL_YARD_IDENTIFIERS, action) => {
  switch (action.type) {
    // ---------------------------------------------------
    //                   Create yard
    // ---------------------------------------------------

    // TODO: Instead of adding yard identifier of newly created yard to
    // the top of the yard identifier list, provide a link in create yard dialog
    // for users to navigate to config page
    case CREATE_YARD_SUCCESS: {
      const { version, yard } = action.payload;
      const newYard = {
        buildingCodes: [],
        yardId: yard.yardId,
        yardLabel: yard.label,
      };

      return {
        ...state,
        yardGraphVersion: version,
        lastUpdate: new Date(),
        allMatchedYardIdentifiersCount: state.allMatchedYardIdentifiersCount + 1,
        yardIdentifiers: [newYard, ...state.yardIdentifiers],
      };
    }

    // ---------------------------------------------------
    //                Batch create 3P yards
    // ---------------------------------------------------

    // TODO: Similar to CREATE_YARD_SUCCESS, provide links to config page of newly
    // created yards. Also, add all returned created yards to store to save a call
    // to backend to fetch data
    case BATCH_CREATE_3P_YARDS_SUCCESS: {
      const { version, yards } = action.payload;
      const updatedIdentifiers = [
        ...yards.map(({ yardId, label }) => ({
          yardId,
          yardLabel: label,
          buildingCodes: [label],
        })),
        ...state.yardIdentifiers,
      ];
      return {
        ...state,
        ...(yards.length && {
          yardGraphVersion: version,
          lastUpdate: new Date(),
          allMatchedYardIdentifiersCount: state.allMatchedYardIdentifiersCount + yards.length,
          yardIdentifiers: updatedIdentifiers,
        }),
      };
    }

    // ---------------------------------------------------
    //                Fetch yard identifiers
    // ---------------------------------------------------

    case FETCH_YARD_IDENTIFIERS_REFRESH:
      const { searchString, searchScope } = action.payload;
      return {
        ...state,
        fetchingRefresh: true,
        fetchingContinue: false,
        searchString,
        searchScope,
        allMatchedYardIdentifiersCount: 0,
        yardIdentifiers: [],
        exclusiveStartKey: null,
        error: null,
      };

    case FETCH_YARD_IDENTIFIERS_CONTINUE:
      return {
        ...state,
        fetchingRefresh: false,
        fetchingContinue: true,
        error: null,
      };

    case FETCH_YARD_IDENTIFIERS_SUCCESS:
      const { allMatchedYardIdentifiersCount, yardIdentifiers } = action.payload;
      // existingYardIds to filter out identifiers that have already been added when new yards
      // are created and consequently their identifiers are added to the top of the list
      const existingYardIds = new Set(state.yardIdentifiers.map(({ yardId }) => yardId));
      return {
        ...state,
        fetchingRefresh: false,
        fetchingContinue: false,
        lastUpdate: new Date(),
        allMatchedYardIdentifiersCount,
        yardIdentifiers: [
          ...state.yardIdentifiers,
          ...yardIdentifiers.filter(({ yardId }) => !existingYardIds.has(yardId)),
        ],
        exclusiveStartKey: last(yardIdentifiers) ? last(yardIdentifiers).yardId : null,
        error: null,
      };

    case FETCH_YARD_IDENTIFIERS_FAILURE:
      return {
        ...state,
        fetchingRefresh: false,
        fetchingContinue: false,
        lastUpdate: new Date(),
        error: action.payload,
      };

    case SET_YARD_IDENTIFIERS:
      return {
        ...state,
        fetchingRefresh: false,
        fetchingContinue: false,
        lastUpdate: new Date(),
        allMatchedYardIdentifiersCount: 1,
        yardIdentifiers: action.payload,
        exclusiveStartKey: null,
        error: null,
      };

    default:
      return state;
  }
};
