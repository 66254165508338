import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { throttle } from "lodash";

import Column from "@amzn/meridian/column";

import ActionBar from "componentsV2/HomePage/ActionBar";
import Headers from "componentsV2/Common/Headers";
import PageBody from "componentsV2/Common/PageBody";
import YardIdentifierTable from "componentsV2/HomePage/YardIdentifierTable";
import { fetchYardIdentifiers } from "store";
import { PIXELS_ABOVE_PAGE_BOTTOM, SCROLL_THROTTLE_WAIT } from "app-constants";

export default function HomePage() {
  const { activeAccountName } = useSelector((state) => state.accountInfo);
  const { fetchingContinue, allMatchedYardIdentifiersCount, yardIdentifiers } = useSelector(
    (state) => state.yardIdentifiersData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (activeAccountName) {
      dispatch(fetchYardIdentifiers({ refresh: true }));
    }
  }, [activeAccountName]);

  useEffect(() => {
    if (allMatchedYardIdentifiersCount) {
      // Retrieve the next page of yard identifiers upon scrolling to the bottom of the last page
      window.addEventListener("scroll", throttledHandleScroll);
    }

    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [allMatchedYardIdentifiersCount, yardIdentifiers.length]);

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target.scrollingElement;
    // Pre-load next page before user has reached page bottom for better UX
    const shouldLoadNextPage =
      Math.abs(scrollHeight - scrollTop - clientHeight) < PIXELS_ABOVE_PAGE_BOTTOM;
    const hasMoreYardIdentifiers = allMatchedYardIdentifiersCount > yardIdentifiers.length;

    if (shouldLoadNextPage && !fetchingContinue && hasMoreYardIdentifiers) {
      // UI gets the next page of yard identifiers when scrolled to the bottom of the last page
      // This is for both cases whether the yard identifiers are filtered or not
      dispatch(fetchYardIdentifiers({}));
    }
  };

  const throttledHandleScroll = throttle(handleScroll, SCROLL_THROTTLE_WAIT);

  return (
    <Column spacing="large" spacingInset="large">
      <Headers />

      <PageBody shouldSkipLinkedPageChecks={true}>
        <ActionBar />
        <YardIdentifierTable />
      </PageBody>
    </Column>
  );
}

HomePage.propTypes = {};
