import React from "react";
import iconTokenRefresh from "@amzn/meridian-tokens/base/icon/refresh";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import Button from "@amzn/meridian/button";
import PropTypes from "prop-types";
import { keys } from "i18n";
import { useTranslation } from "react-i18next";

export default function CameraReload({ reloadFunction }) {
  const { t } = useTranslation();

  return (
    <div className="button-section centered-button-section">
      <Button
        data-testid="reloadCameraButton"
        className="button-controls"
        type="secondary"
        onClick={reloadFunction}
      >
        <Icon className="button-control-icon" tokens={iconTokenRefresh} />
        <Text className="button-controls-text">{t(keys.RELOAD_CAMERA)}</Text>
      </Button>
    </div>
  );
}

CameraReload.propTypes = {
  reloadFunction: PropTypes.func.isRequired,
};
