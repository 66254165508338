import { callAPI } from "store";
import {
  EDIT_YARD_MAP,
  EDIT_YARD_MAP_SUCCESS,
  EDIT_YARD_MAP_FAILURE,
  CLEAR_ERROR_EDITING_YARD_MAP,
} from "./editYardMapActionTypes";
import { APIs } from "app-constants";

export const editYardMap = (
  { editType, yardId, version, offset, deleteLocationCodes = [], createOrUpdateLocations = [] },
  callback = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch(editYardMapRequest(editType));

    try {
      const responseJson = await callAPI(getState().accountInfo, APIs.EDIT_YARD_MAP, {
        yardId,
        version,
        ...(offset && { offset }),
        deleteLocationCodes,
        createOrUpdateLocations,
      });

      callback();
      dispatch(editYardMapSuccess({ editType, yardMap: responseJson.yardMap }));
    } catch (exception) {
      dispatch(editYardMapFailure({ editType, error: exception.message }));
    }
  };
};

const editYardMapRequest = (editType) => {
  return {
    type: EDIT_YARD_MAP,
    payload: editType,
  };
};

// payload includes editType and yardMap
const editYardMapSuccess = (payload) => {
  return {
    type: EDIT_YARD_MAP_SUCCESS,
    payload,
  };
};

// payload includes editType and error
const editYardMapFailure = (payload) => {
  return {
    type: EDIT_YARD_MAP_FAILURE,
    payload,
  };
};

export const clearErrorEditingYardMap = () => {
  return {
    type: CLEAR_ERROR_EDITING_YARD_MAP,
  };
};
