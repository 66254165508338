import {
  FETCH_YARD_DOCUMENTS,
  FETCH_YARD_DOCUMENTS_SUCCESS,
  FETCH_YARD_DOCUMENTS_FAILURE,
  SAVE_LOCALES,
  SAVE_LOCALES_SUCCESS,
  SAVE_LOCALES_FAILURE,
  CLEAR_ERROR_SAVING_LOCALES,
} from "./documentsActionTypes";

export const INITIAL_YARD_DOCUMENTS = {};

export const yardDocumentsReducer = (state = INITIAL_YARD_DOCUMENTS, action) => {
  switch (action.type) {
    case FETCH_YARD_DOCUMENTS: {
      const { yardId, documentLocale } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          [documentLocale]: {
            fetching: true,
          },
        },
      };
    }

    case FETCH_YARD_DOCUMENTS_SUCCESS: {
      const { yardId, documentLocale, yardDocuments } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          [documentLocale]: {
            yardDocuments,
          },
        },
      };
    }

    case FETCH_YARD_DOCUMENTS_FAILURE: {
      const { yardId, documentLocale, error } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          [documentLocale]: {
            error,
          },
        },
      };
    }

    case SAVE_LOCALES: {
      const { yardId } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          savingLocales: true,
          errorSavingLocales: null,
        },
      };
    }

    case SAVE_LOCALES_SUCCESS: {
      const { yardId } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          savingLocales: false,
        },
      };
    }

    case SAVE_LOCALES_FAILURE: {
      const { yardId, error } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          savingLocales: false,
          errorSavingLocales: error,
        },
      };
    }

    case CLEAR_ERROR_SAVING_LOCALES: {
      const { yardId } = action.payload;
      return {
        ...state,
        [yardId]: {
          ...state[yardId],
          errorSavingLocales: null,
        },
      };
    }

    default:
      return state;
  }
};
