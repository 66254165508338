import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Column from "@amzn/meridian/column";
import Modal from "@amzn/meridian/modal";
import Text from "@amzn/meridian/text";

import DialogFooter from "componentsV2/Common/Dialogs/DialogFooter";
import { removeYardDevice } from "store";
import { generateErrorMessage } from "helpers";
import { keys } from "i18n";

export default function RemoveDeviceDialog({ yardId, imei, dismiss }) {
  const [removingDevice, setRemovingDevice] = useState(false);
  const [errorRemovingDevice, setErrorRemovingDevice] = useState(null);

  const dispatch = useDispatch();

  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];

  const { buildings } = yardConfig || {};

  const { t } = useTranslation();

  const handleRemoveDevice = () => {
    setRemovingDevice(true);
    setErrorRemovingDevice(null);

    dispatch(
      removeYardDevice(
        {
          yardIdentifier: {
            yardId,
            buildingCodes: buildings.map(({ buildingCode }) => buildingCode),
          },
          imei,
        },
        dismiss,
        (error) => {
          setRemovingDevice(false);
          setErrorRemovingDevice(error);
        }
      )
    );
  };

  const error = errorRemovingDevice
    ? generateErrorMessage(t(keys.REMOVE_DEVICE_ERROR_MESSAGE), errorRemovingDevice)
    : null;

  return (
    <Modal width="400px" title={t(keys.REMOVE_DEVICE)} open={true}>
      <Column spacing="500">
        <Text>{`${t(keys.DEVICE_TO_REMOVE)}: ${imei}. ${t(keys.REMOVE_DEVICE_WARNING)}`}</Text>

        <DialogFooter
          inProgress={removingDevice}
          error={error}
          commit={handleRemoveDevice}
          dismiss={dismiss}
        />
      </Column>
    </Modal>
  );
}

RemoveDeviceDialog.propTypes = {
  yardId: PropTypes.string.isRequired,
  imei: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
};
