import {
  FETCH_FEATURE_FLAGS,
  FETCH_FEATURE_FLAGS_SUCCESS,
  FETCH_FEATURE_FLAGS_FAILURE,
} from "./featureFlagsActionTypes";

const INITIAL_FEATURE_FLAGS = {};

export const featureFlagsReducer = (state = INITIAL_FEATURE_FLAGS, action) => {
  switch (action.type) {
    case FETCH_FEATURE_FLAGS: {
      const { feature } = action.payload;
      return {
        ...state,
        [feature]: {
          fetching: true,
        },
      };
    }

    case FETCH_FEATURE_FLAGS_SUCCESS: {
      const { feature, enabled } = action.payload;
      return {
        ...state,
        [feature]: {
          fetching: false,
          enabled,
        },
      };
    }

    case FETCH_FEATURE_FLAGS_FAILURE: {
      const { feature, error } = action.payload;
      return {
        ...state,
        [feature]: {
          fetching: false,
          error,
        },
      };
    }

    default:
      return state;
  }
};
