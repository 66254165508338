export const CREATE_YARD_SUCCESS = "CREATE_YARD_SUCCESS";
export const CREATE_OFFSITE_YARD_SUCCESS = "CREATE_OFFSITE_YARD_SUCCESS";
export const EDIT_YARD = "EDIT_YARD";
export const EDIT_YARD_SUCCESS = "EDIT_YARD_SUCCESS";
export const EDIT_YARD_FAILURE = "EDIT_YARD_FAILURE";
export const DELETE_YARD = "DELETE_YARD";
export const DELETE_YARD_SUCCESS = "DELETE_YARD_SUCCESS";
export const DELETE_YARD_FAILURE = "DELETE_YARD_FAILURE";
export const CLEAR_DELETE_YARD_DIALOG = "CLEAR_DELETE_YARD_DIALOG";
export const CLEAR_DELETE_YARD_DIALOG_SUCCESS = "CLEAR_DELETE_YARD_DIALOG_SUCCESS";
export const CLEAR_ERROR_EDITING_YARD = "CLEAR_ERROR_EDITING_YARD";
