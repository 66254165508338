import { zipObject } from "lodash";

export const getRemovedLocations = (locations, originalLocations) => {
  const locationIds = locations.map(({ id }) => id);
  return originalLocations.filter(({ id }) => !locationIds.includes(id));
};

export const getOffsiteBuildingCodeChanges = (locations, originalLocations) => {
  const idToOriginalLocationMap = zipObject(
    originalLocations.map(({ id }) => id),
    originalLocations
  );

  return [
    ...locations.filter(({ id, offsiteLocation, offsiteBuildingCode }) => {
      const originalLocation = idToOriginalLocationMap[id];
      return (
        originalLocation?.offsiteBuildingCode &&
        // Offsite building code changes, or location no longer offsite
        (originalLocation.offsiteBuildingCode !== offsiteBuildingCode || !offsiteLocation)
      );
    }),
    ...getRemovedLocations(locations, originalLocations).filter(
      ({ offsiteBuildingCode }) => offsiteBuildingCode
    ),
  ].map(({ label }) => label);
};
