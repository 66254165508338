import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";

import YardLabelAvailabilityCheck from "componentsV2/Common/Dialogs/YardDialog/YardLabelInput/YardLabelAvailabilityCheck";
import { keys } from "i18n";

export default function YardLabelInput({ yardId, yardConfig, setYardConfig, setSubmittable }) {
  const { label } = yardConfig;

  const handleChange = (label) => setYardConfig({ ...yardConfig, label });

  const { t } = useTranslation();

  return (
    <Row>
      <Input
        data-testid="yardLabelInput"
        width="250px"
        label={t(keys.YARD_LABEL)}
        placeholder={t(keys.ENTER_YARD_LABEL)}
        value={label}
        disabled={!!yardId}
        type="text"
        onChange={handleChange}
      />

      {!yardId && <YardLabelAvailabilityCheck label={label} setSubmittable={setSubmittable} />}
    </Row>
  );
}

YardLabelInput.propTypes = {
  yardId: PropTypes.string,
  yardConfig: PropTypes.object.isRequired,
  setYardConfig: PropTypes.func.isRequired,
  setSubmittable: PropTypes.func.isRequired,
};
