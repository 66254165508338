import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { arrayMoveImmutable } from "array-move";

import Column from "@amzn/meridian/column";
import Select, { SelectOption } from "@amzn/meridian/select";

import SortableItemList from "componentsV2/Common/SortableItemList";
import { keys } from "i18n";
import { SUPPORTED_LANGUAGES } from "app-constants";

export default function LanguageSelect({ yardConfig, setYardConfig, setSubmittable }) {
  const languages = yardConfig.languages || [];

  useEffect(() => {
    setSubmittable((submittable) => ({ ...submittable, isLanguagesValid: !!languages.length }));
  }, [languages.length]);

  const selectLanguage = (language) =>
    setYardConfig({ ...yardConfig, languages: [...languages, language] });
  const removeLanguage = (languageToRemove) =>
    setYardConfig({
      ...yardConfig,
      languages: languages.filter((language) => language !== languageToRemove),
    });

  const { t } = useTranslation();

  const startSort = () => {
    // https://github.com/clauderic/react-sortable-hoc/issues/328#issuecomment-1005835670
    document.body.classList.add("cursor-grabbing");
  };
  const endSort = ({ oldIndex, newIndex }) => {
    document.body.classList.remove("cursor-grabbing");
    setYardConfig({ ...yardConfig, languages: arrayMoveImmutable(languages, oldIndex, newIndex) });
  };

  return (
    <Column>
      <Select
        data-testid="languageSelect"
        label={t(keys.LANGUAGE)}
        placeholder={t(keys.SELECT_LANGUAGE)}
        value={""}
        onChange={selectLanguage}
      >
        {Object.keys(SUPPORTED_LANGUAGES)
          .filter((language) => !languages.includes(language))
          .map((language) => (
            <SelectOption
              data-testid={language}
              key={language}
              value={language}
              label={SUPPORTED_LANGUAGES[language]}
            />
          ))}
      </Select>

      <SortableItemList
        lockAxis="y"
        useDragHandle
        items={languages}
        formatItem={(language) => SUPPORTED_LANGUAGES[language]}
        removeItem={removeLanguage}
        onSortStart={startSort}
        onSortEnd={endSort}
      />
    </Column>
  );
}

LanguageSelect.propTypes = {
  yardConfig: PropTypes.object.isRequired,
  setYardConfig: PropTypes.func.isRequired,
  setSubmittable: PropTypes.func.isRequired,
};
