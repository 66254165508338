import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { isEmpty } from "lodash";
import { withTranslation } from "react-i18next";

import Table, { TableRow, TableCell, TableActionBar } from "@amzn/meridian/table";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

import { keys } from "i18n";

class YardLabelTable extends React.PureComponent {
  csvLink = React.createRef();

  downloadCSV = () => {
    this.csvLink.current.link.click();
  };

  renderYardLabelStatus = (i) => {
    const { yardLabelsWithStatus } = this.props;
    if (!isEmpty(yardLabelsWithStatus[i].errorsFrontEnd)) {
      return yardLabelsWithStatus[i].errorsFrontEnd.map((error) => (
        <Text key={error} color="error">
          {error}
        </Text>
      ));
    }
    if (!isEmpty(yardLabelsWithStatus[i].errorsBackEnd)) {
      return yardLabelsWithStatus[i].errorsBackEnd.map((error) => (
        <Text key={error} color="error">
          {error}
        </Text>
      ));
    }
    if (yardLabelsWithStatus[i].successMessage) {
      return <Text>{yardLabelsWithStatus[i].successMessage}</Text>;
    }
    return "";
  };

  render() {
    const { t, yardLabelsWithStatus, batchCreateSuccessMessage } = this.props;
    const hasErrorsFrontEnd = yardLabelsWithStatus?.some(
      ({ errorsFrontEnd }) => !isEmpty(errorsFrontEnd)
    );
    const hasErrorsBackEnd = yardLabelsWithStatus?.some(
      ({ errorsBackEnd }) => !isEmpty(errorsBackEnd)
    );

    if (!yardLabelsWithStatus) {
      return null;
    }

    return (
      <Table data-testid="yardLabelTable" headerRows={1} spacing="small" showDividers={true}>
        <TableActionBar>
          <Row width="100%" alignmentHorizontal="justify">
            {!hasErrorsFrontEnd && !hasErrorsBackEnd && !batchCreateSuccessMessage && (
              <Text data-testid="goodToSubmitText">{t(keys.THIRD_PARTY_YARDS_READY_TO_ADD)}</Text>
            )}
            {hasErrorsFrontEnd && (
              <Text data-testid="errorsFrontEndText">
                {t(keys.THIRD_PARTY_YARDS_ERRORS_BEFORE_ADD_ATTEMPT)}
              </Text>
            )}
            {hasErrorsBackEnd && (
              <Text data-testid="errorsBackEndText">
                {t(keys.THIRD_PARTY_YARDS_ERRORS_AFTER_ADD_ATTEMPT)}
              </Text>
            )}
            {batchCreateSuccessMessage && (
              <Text data-testid="successText">{batchCreateSuccessMessage}</Text>
            )}
            <Row spacing="none">
              <Button
                data-testid="downloadButton"
                type="link"
                disabled={!hasErrorsFrontEnd && !hasErrorsBackEnd}
                onClick={this.downloadCSV}
              >
                {t(keys.DOWNLOAD)}
              </Button>
              <CSVLink
                data={yardLabelsWithStatus.map(({ yardLabel, errorsFrontEnd, errorsBackEnd }) => ({
                  yardLabel,
                  errors: [...(errorsFrontEnd || []), ...(errorsBackEnd || [])],
                }))}
                headers={[
                  { label: "Yard label", key: "yardLabel" },
                  { label: "Errors", key: "errors" },
                ]}
                filename="3p-yards-with-errors.csv"
                enclosingCharacter=""
                ref={this.csvLink}
                target="_blank"
                rel="noopener noreferrer"
              />
            </Row>
          </Row>
        </TableActionBar>

        <TableRow highlightOnHover={true}>
          <TableCell></TableCell>
          <TableCell>{t(keys.YARD_LABEL)}</TableCell>
          <TableCell>{t(keys.STATUS)}</TableCell>
        </TableRow>

        {yardLabelsWithStatus.map(({ yardLabel }, i) => (
          <TableRow key={`${yardLabel}-${i}`} highlightOnHover={true}>
            <TableCell>{i + 1}</TableCell>
            <TableCell data-testid={`yardLabel${yardLabel}`}>{yardLabel}</TableCell>
            <TableCell data-testid={`yardLabelStatus${yardLabel}`}>
              {this.renderYardLabelStatus(i)}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    );
  }
}

export { YardLabelTable };
export default withTranslation()(YardLabelTable);

YardLabelTable.propTypes = {
  yardLabelsWithStatus: PropTypes.arrayOf(
    PropTypes.shape({
      yardLabel: PropTypes.string.isRequired,
      successMessage: PropTypes.string,
      errorsFrontEnd: PropTypes.arrayOf(PropTypes.string),
      errorsBackEnd: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  batchCreateSuccessMessage: PropTypes.string,
};
