import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { validate as isValidUUID, version as getUUIDVersion } from "uuid";
import { withTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import Modal from "@amzn/meridian/modal";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Alert from "@amzn/meridian/alert";
import Button from "@amzn/meridian/button";
import Select, { SelectOption } from "@amzn/meridian/select";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

import {
  GATE_LANE_KEYS,
  GATE_LANE_PURPOSE,
  GATE_LANE_PURPOSE_LIST,
  GATE_TABLE,
  LANE_IDENTIFIER_LIST,
  R4G_MODE_LIST,
} from "app-constants";
import { keys } from "i18n";
import {
  equalsIgnoreCase,
  zipWithFlattenedKeys,
  getTempId,
  getGateIdentifierFromGate,
  isValidInteger,
} from "helpers";
import { gateLane } from "types";
import Divider from "@amzn/meridian/divider";
import Heading from "@amzn/meridian/heading";

const prepareFields = () => {
  const fields = zipWithFlattenedKeys(GATE_TABLE.keys);
  fields.parentDeviceId = "parentDeviceId";
  fields.childDeviceId = "childDeviceId";
  fields.subchildDeviceId = "subchildDeviceId";
  return fields;
};

/**
 * TODO: Change LaneDialog to function instead of using component and write unit tests.
 */
class LaneDialog extends React.PureComponent {
  state = {
    isLabelUsed: false,
    fields: prepareFields(),
  };

  getInitialValues = (gateLane) => {
    const { currentGate } = this.props;
    if (gateLane) {
      // displaying the formatted phone number after opening the dialog
      const intercomPhoneNumber = !isEmpty(gateLane.intercomPhoneNumber)
        ? parsePhoneNumber(gateLane.intercomPhoneNumber).formatInternational()
        : null;
      return {
        ...gateLane,
        intercomPhoneNumber: intercomPhoneNumber,
        parentDeviceId: `${gateLane.badgeScannerInfo?.parentDeviceId ?? ""}`,
        childDeviceId: `${gateLane.badgeScannerInfo?.childDeviceId ?? ""}`,
        subchildDeviceId: `${gateLane.badgeScannerInfo?.subchildDeviceId ?? ""}`,
      };
    }
    return {
      // Give temporary id for objects added directly with the UI
      id: getTempId(),
      laneIdentifier: "",
      gateId: currentGate.id || getTempId(),
      gateIdentifier: currentGate.gateIdentifier,
      label: "",
      purpose: "",
      r4gMode: "",
      kfbAccessPointId: "",
      intercomPhoneNumber: "",
      parentDeviceId: "",
      childDeviceId: "",
      subchildDeviceId: "",
    };
  };

  validate = (values) => {
    const { t, currentGate } = this.props;
    const currentGateIdentifier = getGateIdentifierFromGate(currentGate);
    const gateLanesForCurrentGate = this.props.gateLanes?.filter(
      (gateLane) => gateLane.gateIdentifier === currentGateIdentifier
    );

    const labelError = !values.label;
    const purposeRequiredError = !values.purpose;
    const laneIdentifierRequiredError = !values.laneIdentifier;
    const purposeAndLaneUsedError =
      (this.props.gateLane?.laneIdentifier !== values.laneIdentifier ||
        this.props.gateLane?.purpose !== values.purpose) &&
      gateLanesForCurrentGate.some(
        (gateLane) =>
          equalsIgnoreCase(gateLane.purpose, values.purpose) &&
          equalsIgnoreCase(gateLane.laneIdentifier, values.laneIdentifier)
      );
    const kfbAccessPointIdError = !this.isKfBAccessPointIdValid(values.kfbAccessPointId);
    const r4gModeError = !values.r4gMode;
    const intercomPhoneNumberError = !this.isIntercomPhoneNumberValid(values.intercomPhoneNumber);
    const parentDeviceIdError = !this.isDeviceIdValid(values.parentDeviceId);
    const childDeviceIdError = !this.isDeviceIdValid(values.childDeviceId);
    const subchildDeviceIdError = !this.isDeviceIdValid(values.subchildDeviceId);
    return {
      ...(labelError && { label: t(keys.REQUIRED_ERROR_MESSAGE) }),
      ...(purposeRequiredError && {
        purposeRequired: t(keys.REQUIRED_ERROR_MESSAGE),
      }),
      ...(laneIdentifierRequiredError && {
        laneIdentifierRequired: t(keys.REQUIRED_ERROR_MESSAGE),
      }),
      ...(purposeAndLaneUsedError && {
        purposeAndLaneUsed: t(keys.LANE_IDENTIFIER_USED),
      }),
      ...(kfbAccessPointIdError && {
        kfbAccessPointId: t(keys.KFB_ACCESS_POINT_ID_INVALID),
      }),
      ...(r4gModeError && { r4gMode: t(keys.REQUIRED_ERROR_MESSAGE) }),
      ...(intercomPhoneNumberError && {
        intercomPhoneNumber: t(keys.INTERCOM_PHONE_NUMBER_INVALID),
      }),
      ...(parentDeviceIdError && {
        parentDeviceId: t(keys.DEVICE_ID_INVALID),
      }),
      ...(childDeviceIdError && {
        childDeviceId: t(keys.DEVICE_ID_INVALID),
      }),
      ...(subchildDeviceIdError && {
        subchildDeviceId: t(keys.DEVICE_ID_INVALID),
      }),
    };
  };

  handleChange = (setFieldValue, fieldName) => (fieldValue) => {
    setFieldValue(fieldName, fieldValue);
    if (fieldName === GATE_LANE_KEYS.LABEL && fieldValue) {
      const isLabelUsed = this.props.gateLanes?.some((gateLane) =>
        equalsIgnoreCase(gateLane.label, fieldValue)
      );
      this.setState({ isLabelUsed });
    }
  };

  format = (values) => {
    // KfB access point id must be valid or not present at all
    const kfbAccessPointId = !isEmpty(values.kfbAccessPointId) ? values.kfbAccessPointId : null;
    // intercom phone number must be valid or not present at all
    const intercomPhoneNumber = !isEmpty(values.intercomPhoneNumber)
      ? parsePhoneNumber(values.intercomPhoneNumber).number
      : null;
    const parentDeviceId = !isEmpty(values.parentDeviceId) ? parseInt(values.parentDeviceId) : null;
    const childDeviceId = !isEmpty(values.childDeviceId) ? parseInt(values.childDeviceId) : null;
    const subchildDeviceId = !isEmpty(values.subchildDeviceId)
      ? parseInt(values.subchildDeviceId)
      : null;
    let badgeScannerInfo;
    if (parentDeviceId || childDeviceId || subchildDeviceId) {
      badgeScannerInfo = {
        parentDeviceId,
        childDeviceId,
        subchildDeviceId,
      };
    } else {
      badgeScannerInfo = null;
    }
    return {
      ...values,
      kfbAccessPointId: kfbAccessPointId,
      intercomPhoneNumber: intercomPhoneNumber,
      badgeScannerInfo: badgeScannerInfo,
    };
  };

  renderLabelAvailabilityCheck = (currentLabel) => {
    const { t } = this.props;
    const { isLabelUsed } = this.state;

    const previousLabel = this.props.gateLane?.label;
    const isLabelUnchanged = previousLabel && equalsIgnoreCase(currentLabel, previousLabel);

    if (!currentLabel || isLabelUnchanged) {
      return null;
    }

    return isLabelUsed ? (
      <Alert size="small" type="error">
        {t(keys.LABEL_USED)}
      </Alert>
    ) : (
      <Alert size="small" type="success">
        {t(keys.LABEL_AVAILABLE)}
      </Alert>
    );
  };

  isKfBAccessPointIdValid = (kfbAccessPointId) => {
    return (
      isEmpty(kfbAccessPointId) ||
      (isValidUUID(kfbAccessPointId) && getUUIDVersion(kfbAccessPointId) === 4)
    );
  };

  isIntercomPhoneNumberValid = (intercomPhoneNumber) => {
    return isEmpty(intercomPhoneNumber) || isValidPhoneNumber(intercomPhoneNumber);
  };

  isDeviceIdValid = (deviceId) => {
    if (isEmpty(deviceId)) {
      return true;
    }
    if (isValidInteger(deviceId)) {
      const num = parseInt(deviceId);
      // device IDs can be any positive INT value, the upper bound for this data type is 2^31 - 1
      return num > 0 && num <= 2147483647;
    }
    return false;
  };

  render() {
    const { t, gateLane, add, edit, dismiss, showBadgeScannerInfo } = this.props;
    const { fields } = this.state;
    const initialValues = this.getInitialValues(gateLane);

    return (
      <Modal
        width="400px"
        bodySpacingInset="medium"
        title={gateLane ? t(keys.EDIT_LANE) : t(keys.ADD_LANE)}
        open={true}
      >
        <Formik
          initialValues={initialValues}
          // invalidate form on initialization for adding gate
          initialErrors={!gateLane && this.validate(initialValues)}
          validate={this.validate}
          onSubmit={(values) => {
            gateLane ? edit(this.format(values)) : add(this.format(values));
            dismiss();
          }}
        >
          {({ values, errors, isValid, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Column spacing="small" spacingInset="none none small none">
                <Row>
                  <Input
                    width="250px"
                    name={fields.label}
                    type="text"
                    label={`${t(keys.GATE_LABEL)}*`}
                    value={values.label}
                    onChange={this.handleChange(setFieldValue, fields.label)}
                  />
                  {this.renderLabelAvailabilityCheck(values.label)}
                </Row>
                <Row>
                  <Select
                    width="100%"
                    label={`${t(keys.GATE_TYPE)}*`}
                    placeholder={t(keys.SELECT_GATE_TYPE)}
                    value={values.purpose}
                    onChange={this.handleChange(setFieldValue, fields.purpose)}
                  >
                    {GATE_LANE_PURPOSE_LIST.map((purpose) => (
                      <SelectOption
                        key={purpose}
                        value={purpose}
                        label={t(
                          purpose === GATE_LANE_PURPOSE.CHECK_IN ? keys.ENTRY_GATE : keys.EXIT_GATE
                        )}
                      />
                    ))}
                  </Select>
                </Row>
                <Row>
                  <Select
                    width="100%"
                    label={`${t(keys.LANE)}*`}
                    placeholder="Select lanes..."
                    value={values.laneIdentifier}
                    onChange={this.handleChange(setFieldValue, fields.laneIdentifier)}
                    error={!!errors.purposeAndLaneUsedError}
                    errorMessage={errors.purposeAndLaneUsed}
                  >
                    {LANE_IDENTIFIER_LIST.map((laneIdentifier) => (
                      <SelectOption
                        key={laneIdentifier}
                        label={laneIdentifier}
                        value={laneIdentifier}
                      />
                    ))}
                  </Select>
                </Row>
                <Row>
                  <Input
                    width="100%"
                    name={fields.kfbAccessPointId}
                    type="text"
                    label={`${t(keys.GATE_KFB_ACCESS_POINT_ID)}`}
                    value={values.kfbAccessPointId}
                    onChange={this.handleChange(setFieldValue, fields.kfbAccessPointId)}
                    error={!!errors.kfbAccessPointId}
                    errorMessage={errors.kfbAccessPointId}
                  />
                </Row>
                <Row>
                  <Select
                    width="100%"
                    label={`${t(keys.GATE_R4G_MODE)}*`}
                    placeholder={t(keys.SELECT_R4G_MODE)}
                    value={values.r4gMode}
                    onChange={this.handleChange(setFieldValue, fields.r4gMode)}
                  >
                    {R4G_MODE_LIST.map((mode) => (
                      <SelectOption key={mode} value={mode} label={t(mode)} />
                    ))}
                  </Select>
                </Row>
                <Row>
                  <Input
                    data-testid="intercomPhoneNumberField"
                    width="100%"
                    name={fields.intercomPhoneNumber}
                    type="text"
                    label={`${t(keys.INTERCOM_PHONE_NUMBER)}`}
                    placeholder={"+X XXX XXX XXXX"}
                    value={values.intercomPhoneNumber}
                    onChange={this.handleChange(setFieldValue, fields.intercomPhoneNumber)}
                    error={!!errors.intercomPhoneNumber}
                    errorMessage={errors.intercomPhoneNumber}
                  />
                </Row>
                {showBadgeScannerInfo && (
                  <>
                    <Divider />
                    <Heading level={6} type={"b300"}>
                      {t(keys.BADGE_SCANNER)}
                    </Heading>
                    <Row>
                      <Input
                        data-testid="parentDeviceIdField"
                        width="100%"
                        name={fields.parentDeviceId}
                        type="text"
                        label={t(keys.PARENT_DEVICE_ID)}
                        placeholder={t(keys.PARENT_DEVICE_ID)}
                        value={values.parentDeviceId}
                        onChange={this.handleChange(setFieldValue, fields.parentDeviceId)}
                        error={!!errors.parentDeviceId}
                        errorMessage={errors.parentDeviceId}
                      />
                    </Row>
                    <Row>
                      <Input
                        data-testid="childDeviceIdField"
                        width="100%"
                        name={fields.childDeviceId}
                        type="text"
                        label={t(keys.CHILD_DEVICE_ID)}
                        placeholder={t(keys.CHILD_DEVICE_ID)}
                        value={values.childDeviceId}
                        onChange={this.handleChange(setFieldValue, fields.childDeviceId)}
                        error={!!errors.childDeviceId}
                        errorMessage={errors.childDeviceId}
                      />
                    </Row>
                    <Row>
                      <Input
                        data-testid="subchildDeviceIdField"
                        width="100%"
                        name={fields.subchildDeviceId}
                        type="text"
                        label={t(keys.SUBCHILD_DEVICE_ID)}
                        placeholder={t(keys.SUBCHILD_DEVICE_ID)}
                        value={values.subchildDeviceId}
                        onChange={this.handleChange(setFieldValue, fields.subchildDeviceId)}
                        error={!!errors.subchildDeviceId}
                        errorMessage={errors.subchildDeviceId}
                      />
                    </Row>
                  </>
                )}
              </Column>
              <Row alignmentHorizontal="right">
                <Button type="secondary" minWidth="80px" onClick={dismiss}>
                  {t(keys.CANCEL)}
                </Button>
                <Button
                  data-testid="closeButton"
                  type="primary"
                  minWidth="80px"
                  disabled={!isValid || this.state.isLabelUsed}
                  onClick={handleSubmit}
                >
                  {gateLane ? t(keys.CLOSE) : t(keys.ADD)}
                </Button>
              </Row>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export { LaneDialog };
export default withTranslation()(LaneDialog);

LaneDialog.propTypes = {
  gateLanes: PropTypes.arrayOf(gateLane).isRequired,
  gateLane: gateLane, // not required as is null when creating new object
  currentGate: PropTypes.object.isRequired, // Required for new lane's gate identifier
  add: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
  showBadgeScannerInfo: PropTypes.bool.isRequired,
};
