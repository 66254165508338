import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";

import MapContainer from "componentsV2/MapPage/MapContainer";

export default function MapPageBody({ yardId }) {
  const { yardMap } = useSelector((state) => state.yardMapData);

  if (isEmpty(yardMap)) {
    return (
      <Row data-testid="fetchYardMapLoader" spacingInset="medium" alignmentHorizontal="center">
        <Loader />
      </Row>
    );
  }

  return <MapContainer yardId={yardId} />;
}

MapPageBody.propTypes = {
  yardId: PropTypes.string.isRequired,
};
