import { v1 as uuidv1 } from "uuid";
import { isEqual } from "lodash";

import { parseWithoutThrowingException } from "utils";
import { AMAZON_ACCOUNT_NAME, TEMP_ID_PREFIX, YARD_DESIGNER_ACCOUNT_NAME } from "app-constants";

// buildings must be an array
export const hasPermissionToAccessPage = (activeAccountName, buildings) =>
  activeAccountName === YARD_DESIGNER_ACCOUNT_NAME ||
  activeAccountName === AMAZON_ACCOUNT_NAME ||
  buildings.map(({ buildingCode }) => buildingCode).includes(activeAccountName);

export const generateErrorMessage = (genericMessage, rawExceptionInString) => {
  if (!rawExceptionInString) {
    return genericMessage;
  }

  const rawException = parseWithoutThrowingException(rawExceptionInString, false);
  return `${genericMessage} ${rawException ? rawException.message : rawExceptionInString}`;
};

export const doListsContainSameElements = (list1, list2) => {
  const sortById = ({ id: id1 }, { id: id2 }) => id1 - id2;
  const sortedList1 = [...list1].sort(sortById);
  const sortedList2 = [...list2].sort(sortById);
  return isEqual(sortedList1, sortedList2);
};

export const replaceUnknownIdsByTempIds = (originalList, newList) => {
  const originalIds = originalList.map(({ id }) => id);
  return newList.map((item) =>
    originalIds.includes(item.id) || hasTempId(item.id) ? item : { ...item, id: getTempId() }
  );
};

// Format below has to match what hasTempId expects
export const getTempId = () => `${TEMP_ID_PREFIX}-${uuidv1()}`;

export const hasTempId = (id) => id.startsWith(`${TEMP_ID_PREFIX}-`);

export const generateRandomFloat = () => {
  // A buffer with just the right size to convert to Float64
  const buffer = new ArrayBuffer(8);

  // View it as an Int8Array and fill it with 8 random ints
  const ints = new Int8Array(buffer);
  window.crypto.getRandomValues(ints);

  // Set the sign (ints[7][7]) to 0 and the
  // exponent (ints[7][6]-[6][5]) to just the right size
  // (all ones except for the highest bit)
  ints[7] = 63;
  // eslint-disable-next-line no-bitwise
  ints[6] |= 0xf0;

  // Now view it as a Float64Array, and read the one float from it
  return new DataView(buffer).getFloat64(0, true) - 1;
};
