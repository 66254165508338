import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Modal from "@amzn/meridian/modal";

import DialogFooter from "componentsV2/Common/Dialogs/DialogFooter";
import { addYardDevice } from "store";
import { generateErrorMessage } from "helpers";
import { keys } from "i18n";

export default function AddDeviceDialog({ yardId, dismiss }) {
  const [imei, setImei] = useState(null);
  const [addingDevice, setAddingDevice] = useState(false);
  const [errorAddingDevice, setErrorAddingDevice] = useState(null);

  const dispatch = useDispatch();

  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];

  const { buildings } = yardConfig || {};

  const { t } = useTranslation();

  const handleAddDevice = () => {
    setAddingDevice(true);
    setErrorAddingDevice(null);

    dispatch(
      addYardDevice(
        {
          yardIdentifier: {
            yardId,
            buildingCodes: buildings.map(({ buildingCode }) => buildingCode),
          },
          imei,
        },
        dismiss,
        (error) => {
          setAddingDevice(false);
          setErrorAddingDevice(error);
        }
      )
    );
  };

  const error = errorAddingDevice
    ? generateErrorMessage(t(keys.ADD_DEVICE_ERROR_MESSAGE), errorAddingDevice)
    : null;

  return (
    <Modal width="400px" title={t(keys.ADD_DEVICE)} open={true}>
      <Column spacing="500">
        <Input
          data-testid="imeiField"
          type="number"
          label={t(keys.IMEI)}
          value={imei}
          onChange={setImei}
        />

        <DialogFooter
          inProgress={addingDevice}
          error={error}
          disabled={!imei}
          commit={handleAddDevice}
          dismiss={dismiss}
        />
      </Column>
    </Modal>
  );
}

AddDeviceDialog.propTypes = {
  yardId: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
};
