// For use when testing on cloud desktop, with logging of store activities to console

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import { rootReducer } from "./rootReducer";

export const storeDev = createStore(
  rootReducer,
  composeWithDevTools({ trace: true, traceLimit: 25 })(applyMiddleware(thunk, logger))
);
