import { combineReducers } from "redux";

import { accountInfoReducer } from "./accountInfo/accountInfoReducer";
import { secretsReducer } from "./secrets/secretsReducer";
import { permissionsReducer } from "./permissions/permissionsReducer";
import { featureFlagsReducer } from "./featureFlags/featureFlagsReducer";
import { yardFeaturesReducer } from "./yardFeatures/yardFeaturesReducer";
import { buildingFeaturesReducer } from "./buildingFeatures/buildingFeaturesReducer";
import { searchSuggestionsReducer } from "./searchSuggestions/searchSuggestionsReducer";
import { yardIdentifiersReducer } from "./yardIdentifiers/yardIdentifiersReducer";
import { yardConfigDataReducer } from "./yardConfigData/yardConfigDataReducer";
import { yardMapReducer } from "./yardMap/yardMapReducer";
import { subscriptionsReducer } from "./subscriptions/subscriptionsReducer";
import { yardDocumentsReducer } from "./documents/documentsReducer";
import { yardDevicesReducer } from "./devices/devicesReducer";

export const rootReducer = combineReducers({
  accountInfo: accountInfoReducer,
  secrets: secretsReducer,
  permissionsData: permissionsReducer,
  yardFeaturesData: yardFeaturesReducer,
  buildingFeaturesData: buildingFeaturesReducer,
  featureFlags: featureFlagsReducer,
  searchSuggestions: searchSuggestionsReducer,
  yardIdentifiersData: yardIdentifiersReducer,
  yardConfigData: yardConfigDataReducer,
  yardMapData: yardMapReducer,
  subscriptionsData: subscriptionsReducer,
  yardDocumentsData: yardDocumentsReducer,
  yardDevicesData: yardDevicesReducer,
});
