import { callAPI } from "store";
import {
  FETCH_FEATURE_FLAGS,
  FETCH_FEATURE_FLAGS_SUCCESS,
  FETCH_FEATURE_FLAGS_FAILURE,
} from "./featureFlagsActionTypes";
import { SYSTEM, AMAZON_ACCOUNT_NAME, YARD_DESIGNER_ACCOUNT_NAME, APIs } from "app-constants";

export const fetchFeatureFlags = (feature) => {
  return async (dispatch, getState) => {
    const accountInfo = getState().accountInfo;

    dispatch(fetchFeatureFlagsRequest({ feature }));

    try {
      const responseJson = await callAPI(accountInfo, APIs.IS_FEATURE_ENABLED, {
        feature,
        // When user is logged in Amazon account, call IsFeatureEnabled API with override for YardDesigner account
        // See https://quip-amazon.com/KGkdAqjsCWS4/Yard-Designer-FF for context
        ...(accountInfo.activeAccountName === AMAZON_ACCOUNT_NAME
          ? {
              yardId: { buildingCode: YARD_DESIGNER_ACCOUNT_NAME },
              requester: { system: SYSTEM },
            }
          : {}),
      });

      dispatch(fetchFeatureFlagsSuccess(responseJson));
    } catch (exception) {
      dispatch(fetchFeatureFlagsFailure({ feature, error: exception.message }));
    }
  };
};

const fetchFeatureFlagsRequest = (feature) => {
  return {
    type: FETCH_FEATURE_FLAGS,
    payload: feature,
  };
};

const fetchFeatureFlagsSuccess = (featureFlags) => {
  return {
    type: FETCH_FEATURE_FLAGS_SUCCESS,
    payload: featureFlags,
  };
};

// payload includes feature and error
const fetchFeatureFlagsFailure = (payload) => {
  return {
    type: FETCH_FEATURE_FLAGS_FAILURE,
    payload,
  };
};
