import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SaveChangesDialog from "component/Common/SaveChangesDialog";
import { hasTempId } from "helpers";
import { APIs } from "app-constants";
import {
  clearErrorSavingGates,
  saveGatesFailure,
  saveGatesRequest,
  saveGatesSuccess,
  callAPI,
} from "store";
import { gateLane } from "types";

class SaveGatesDialog extends React.PureComponent {
  saveGates = async () => {
    const { accountInfo, version, yardId, gateLanes } = this.props;

    // Remove temp ids added to gates created directly with the UI
    // These ids are added because of https://sim.amazon.com/issues/Yard-3453
    // Removing these ids is needed because back-end needs new gates not to have ids
    const gateId = gateLanes[0]?.gateId;
    const formattedLanes = gateLanes.map((gateLane) => {
      return {
        id: hasTempId(gateLane.id) ? null : gateLane.id,
        laneIdentifier: gateLane.laneIdentifier || "LANE1", // assign default laneIdentifier value
        label: gateLane.label,
        purpose: gateLane.purpose,
        kfbAccessPointId: gateLane.kfbAccessPointId,
        r4gMode: gateLane.r4gMode,
        intercomPhoneNumber: gateLane.intercomPhoneNumber,
      };
    });

    const formattedGates = gateId
      ? [
          {
            id: hasTempId(gateId) ? null : gateId,
            gateIdentifier: gateLanes[0]?.gateIdentifier || "GATE1", // assign default gateIdentifier value
            lanes: formattedLanes,
          },
        ]
      : [];

    this.props.saveGatesRequest(yardId);

    try {
      const responseJson = await callAPI(accountInfo, APIs.SET_YARD_GATES, {
        version,
        yardId,
        gates: formattedGates,
      });

      this.props.saveGatesSuccess({ yardId, yardConfig: responseJson });
      this.props.dismiss();
    } catch (exception) {
      this.props.saveGatesFailure({ yardId, error: exception.message });
    }
  };

  clearErrorAndDismiss = async () => {
    const { yardId } = this.props;
    this.props.clearErrorSavingGates(yardId);
    this.props.dismiss();
  };

  render() {
    const { yardConfigData, yardId } = this.props;
    const { savingGates, errorSavingGates } = yardConfigData[yardId];

    return (
      <SaveChangesDialog
        title={"SAVE_GATES"}
        saveChanges={this.saveGates}
        dismiss={this.clearErrorAndDismiss}
        savingChanges={savingGates}
        genericSavingErrorMessage={"SAVE_GATES_ERROR_MESSAGE"}
        serverErrorMessage={errorSavingGates}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo,
  yardConfigData: state.yardConfigData,
});

const mapDispatchToProps = (dispatch) => ({
  saveGatesRequest: (yardId) => {
    dispatch(saveGatesRequest(yardId));
  },
  saveGatesSuccess: (payload) => {
    dispatch(saveGatesSuccess(payload));
  },
  saveGatesFailure: (payload) => {
    dispatch(saveGatesFailure(payload));
  },
  clearErrorSavingGates: (yardId) => {
    dispatch(clearErrorSavingGates(yardId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveGatesDialog);

SaveGatesDialog.propTypes = {
  version: PropTypes.number.isRequired,
  yardId: PropTypes.string.isRequired,
  gateLanes: PropTypes.arrayOf(gateLane).isRequired,
  dismiss: PropTypes.func.isRequired,
};
