import { callAPI } from "store";
import {
  FETCH_PERMISSIONS,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAILURE,
} from "./permissionsActionTypes";
import { APIs } from "app-constants";

export const fetchPermissions = () => {
  return async (dispatch, getState) => {
    dispatch(fetchPermissionsRequest());

    try {
      const responseJson = await callAPI(
        getState().accountInfo,
        APIs.GET_YARD_DESIGNER_PERMISSIONS
      );

      dispatch(fetchPermissionsSuccess({ permissions: responseJson }));
    } catch (exception) {
      dispatch(fetchPermissionsFailure(exception.message));
    }
  };
};

const fetchPermissionsRequest = () => {
  return {
    type: FETCH_PERMISSIONS,
  };
};

const fetchPermissionsSuccess = (permissions) => {
  return {
    type: FETCH_PERMISSIONS_SUCCESS,
    payload: permissions,
  };
};

const fetchPermissionsFailure = (error) => {
  return {
    type: FETCH_PERMISSIONS_FAILURE,
    payload: error,
  };
};
