import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "@amzn/meridian/input";

import { keys } from "i18n";

export default function LabelFilter({ filterString, setFilterString, setPage }) {
  const { t } = useTranslation();

  const handleFilterStringChange = (filterString) => {
    setPage(1);
    setFilterString(filterString);
  };

  return (
    <Input
      data-testid="labelFilterInput"
      width="200px"
      type="text"
      placeholder={t(keys.FILTER_BY_LABEL)}
      value={filterString}
      onChange={handleFilterStringChange}
    />
  );
}

LabelFilter.propTypes = {
  filterString: PropTypes.string.isRequired,
  setFilterString: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};
