import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import HighlightSubstring from "@amzn/meridian/highlight-substring";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";

import SearchSuggestionCellContent from "componentsV2/HomePage/ActionBar/Search/SearchSuggestions/SearchSuggestionCellContent";
import { setYardIdentifiers } from "store";
import { keys } from "i18n";
import { SUPPORT_OFFSITE_MODELING } from "app-constants";

export default function SearchSuggestions({ searchString, blurSearchField }) {
  const featureFlags = useSelector((state) => state.featureFlags);
  const { fetchingSearchResults, searchResultsCount, searchSuggestions } = useSelector(
    (state) => state.searchSuggestions
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const selectSearchSuggestion = (yardId) => {
    const yardIdentifiers = searchSuggestions.filter(
      (yardIdentifier) => yardIdentifier.yardId === yardId
    );
    dispatch(setYardIdentifiers(yardIdentifiers));
    blurSearchField();
  };

  if (fetchingSearchResults) {
    return (
      <Row data-testid="loader" spacingInset="medium" alignmentHorizontal="center">
        <Loader />
      </Row>
    );
  }

  if (!searchResultsCount) {
    return null;
  }

  return (
    <Table data-testid="searchSuggestionsTable" headerRows={1}>
      <TableRow>
        <TableCell>{t(keys.YARD)}</TableCell>
        {featureFlags[SUPPORT_OFFSITE_MODELING]?.enabled && (
          <TableCell>{t(keys.OFFSITE_YARDS)}</TableCell>
        )}
        <TableCell>{t(keys.BUILDINGS)}</TableCell>
      </TableRow>

      {searchSuggestions.map(({ yardId, yardLabel, offsiteYardIdentifiers, buildingCodes }) => (
        <TableRow
          data-testid={`searchSuggestion-${yardId}`}
          key={yardId}
          highlightOnHover={true}
          onClick={() => selectSearchSuggestion(yardId)}
        >
          <TableCell data-testid={`yardLabel-${yardLabel}`}>
            <HighlightSubstring match={searchString}>{yardLabel}</HighlightSubstring>
          </TableCell>

          {featureFlags[SUPPORT_OFFSITE_MODELING]?.enabled && (
            <TableCell
              // prettier-ignore
              data-testid={`offsiteYards-${offsiteYardIdentifiers.map(({ yardLabel }) => yardLabel).join(",")}`}
            >
              <SearchSuggestionCellContent
                searchString={searchString}
                items={offsiteYardIdentifiers.map(({ yardLabel }) => yardLabel)}
              />
            </TableCell>
          )}

          <TableCell data-testid={`buildingCodes-${buildingCodes.join(",")}`}>
            <SearchSuggestionCellContent searchString={searchString} items={buildingCodes} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
}

SearchSuggestions.propTypes = {
  searchString: PropTypes.string.isRequired,
  blurSearchField: PropTypes.func.isRequired,
};
