import { callAPI } from "store";
import {
  FETCH_YARD_MAP,
  FETCH_YARD_MAP_SUCCESS,
  FETCH_YARD_MAP_FAILURE,
} from "./yardMapActionTypes";
import { APIs } from "app-constants";

export const fetchYardMap = (yardId) => {
  return async (dispatch, getState) => {
    dispatch(fetchYardMapRequest(yardId));

    try {
      const responseJson = await callAPI(getState().accountInfo, APIs.GET_YARD_MAP, { yardId });

      dispatch(fetchYardMapSuccess(responseJson));
    } catch (exception) {
      dispatch(fetchYardMapFailure(exception.message));
    }
  };
};

const fetchYardMapRequest = (yardId) => {
  return {
    type: FETCH_YARD_MAP,
    payload: yardId,
  };
};

const fetchYardMapSuccess = (yardMap) => {
  return {
    type: FETCH_YARD_MAP_SUCCESS,
    payload: yardMap,
  };
};

const fetchYardMapFailure = (error) => {
  return {
    type: FETCH_YARD_MAP_FAILURE,
    payload: error,
  };
};
