import PropTypes from "prop-types";

export const building = PropTypes.shape({
  buildingCode: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  express: PropTypes.bool.isRequired,
  inboundExpress: PropTypes.bool.isRequired,
  enableSSP: PropTypes.bool.isRequired,
  enableInboundSSP: PropTypes.bool.isRequired,
});
