import PropTypes from "prop-types";
import { gateLane } from "types";
import BaseTable from "componentsV2/Common/BaseTable";
import { keys } from "i18n";
import { extractGateLanes, getGateIdentifierFromGate } from "helpers";
import { GATE_LANE_KEYS, GATE_LANE_PURPOSE, GATE_TABLE } from "app-constants";
import { zipObject } from "lodash";
import { useSelector } from "react-redux";
import { parsePhoneNumber } from "libphonenumber-js";

export default function TableContent({
  yardId,
  gateLanes,
  currentGate,
  sortColumn,
  sortDirection,
  selectedIds,
  page,
  setSortColumn,
  setSortDirection,
  setSelectedIds,
  setPage,
}) {
  const { gates } = useSelector((state) => state.yardConfigData)[yardId];
  const originalGateLanes = extractGateLanes(gates);

  const handleSort = ({ sortColumn, sortDirection }) => {
    setSortColumn(sortColumn);
    setSortDirection(sortDirection);
  };

  const idToOriginalGateLaneMap = zipObject(
    originalGateLanes.map((originalGateLane) => originalGateLane.id),
    originalGateLanes
  );

  // get selected ids only within current gate
  const currentGateIdentifier = getGateIdentifierFromGate(currentGate);
  const gateLaneIdsInCurrentGate = gateLanes
    .filter((gateLane) => gateLane.gateIdentifier === currentGateIdentifier)
    .map((gateLane) => gateLane.id);
  const selectedIdsInCurrentGate = selectedIds.filter((selectedId) =>
    gateLaneIdsInCurrentGate.includes(selectedId)
  );

  const gateLanesInCurrentGate = gateLanes.filter(
    (gateLane) => gateLane.gateIdentifier === currentGateIdentifier
  );

  const formatGateTableCellValue = (key, gateLane, featureFlags, t) => {
    const value = gateLane[key];
    if (key === GATE_LANE_KEYS.PURPOSE) {
      return t(value === GATE_LANE_PURPOSE.CHECK_IN ? keys.ENTRY_GATE : keys.EXIT_GATE);
    }
    if (key === GATE_LANE_KEYS.R4G_MODE) {
      return t(value);
    }
    if (value && key === GATE_LANE_KEYS.INTERCOM_PHONE_NUMBER) {
      return parsePhoneNumber(value).formatInternational();
    }
    return value;
  };

  return (
    <BaseTable
      tableColumns={GATE_TABLE}
      idToOriginalItemMap={idToOriginalGateLaneMap}
      itemsLabelLowerCase={keys.LANES_LOWER_CASE}
      items={gateLanesInCurrentGate}
      filterString="" // the gate content table doesn't need the filter function.
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      selectedIds={selectedIdsInCurrentGate}
      page={page}
      handleSort={handleSort}
      setSelectedIds={setSelectedIds}
      formatTableCellContent={formatGateTableCellValue}
      setPage={setPage}
    />
  );
}

TableContent.propTypes = {
  yardId: PropTypes.string.isRequired,
  gateLanes: PropTypes.arrayOf(gateLane).isRequired,
  currentGate: PropTypes.object.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  page: PropTypes.number.isRequired,
  setSortColumn: PropTypes.func.isRequired,
  setSortDirection: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};
