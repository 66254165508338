import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Column from "@amzn/meridian/column";
import Select, { SelectOption } from "@amzn/meridian/select";

import ItemList from "componentsV2/Common/ItemList";
import { keys } from "i18n";
import { ASSET_TYPE_LIST } from "app-constants";

export default function AssetTypeSelect({ yardId, yardConfig, setYardConfig }) {
  const assetTypes = yardConfig.assetTypes || [];

  useEffect(() => {
    // Set default asset types when creating a new yard
    if (!yardId) {
      setYardConfig({ ...yardConfig, assetTypes: [keys.TRAILER, keys.TRACTOR, keys.BOX_TRUCK] });
    }
  }, [yardId]);

  const selectAssetType = (assetType) =>
    setYardConfig({ ...yardConfig, assetTypes: [...assetTypes, assetType] });
  const removeAssetType = (assetTypeToRemove) =>
    setYardConfig({
      ...yardConfig,
      assetTypes: assetTypes.filter((assetType) => assetType !== assetTypeToRemove),
    });

  const { t } = useTranslation();

  return (
    <Column>
      <Select
        data-testid="assetTypeSelect"
        label={t(keys.ASSET_TYPE)}
        placeholder={t(keys.SELECT_ASSET_TYPE)}
        value={""}
        onChange={selectAssetType}
      >
        {ASSET_TYPE_LIST.filter((assetType) => !assetTypes.includes(assetType)).map((assetType) => (
          <SelectOption
            data-testid={assetType}
            key={assetType}
            value={assetType}
            label={t(assetType)}
          />
        ))}
      </Select>

      <ItemList items={assetTypes} formatItem={t} removeItem={removeAssetType} />
    </Column>
  );
}

AssetTypeSelect.propTypes = {
  yardId: PropTypes.string,
  yardConfig: PropTypes.object.isRequired,
  setYardConfig: PropTypes.func.isRequired,
};
