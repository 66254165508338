import { callAPI } from "store";
import { FETCH_BUILDING_FEATURES_SUCCESS } from "./buildingFeaturesActionTypes";
import { APIs } from "app-constants";

export const fetchBuildingFeatures = ({ failureCallback }) => {
  return async (dispatch, getState) => {
    try {
      const responseJson = await callAPI(
        getState().accountInfo,
        APIs.LIST_SUPPORTED_BUILDING_FEATURES
      );

      dispatch(fetchBuildingFeaturesSuccess(responseJson));
    } catch (exception) {
      failureCallback(exception.message);
    }
  };
};

export const fetchBuildingFeaturesSuccess = (payload) => {
  return {
    type: FETCH_BUILDING_FEATURES_SUCCESS,
    payload,
  };
};
