import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Menu, { MenuItem } from "@amzn/meridian/menu";

import AddYardDialog from "componentsV2/Common/Dialogs/AddYardDialog";
import BatchCreate3PYardsDialog from "component/BatchActionDialogs/BatchCreate3PYardsDialog";
import { keys } from "i18n";
import { ENABLE_BATCH_3P_YARD_CREATION } from "app-constants";

export default function AddYardActions() {
  const [shouldOpenActionMenu, setShouldOpenActionMenu] = useState(false);
  const [shouldShowAddYardDialog, setShouldShowAddYardDialog] = useState(false);
  const [shouldShowBatchCreate3PYardsDialog, setShouldShowBatchCreate3PYardsDialog] =
    useState(false);

  const accountInfo = useSelector((state) => state.accountInfo);
  const { permissions } = useSelector((state) => state.permissionsData);
  const featureFlags = useSelector((state) => state.featureFlags);
  const { fetchingRefresh } = useSelector((state) => state.yardIdentifiersData);

  const buttonRef = useRef();

  const { t } = useTranslation();

  const openActionMenu = () => setShouldOpenActionMenu(true);
  const closeActionMenu = () => setShouldOpenActionMenu(false);
  const openAddYardDialog = () => setShouldShowAddYardDialog(true);
  const closeAddYardDialog = () => setShouldShowAddYardDialog(false);
  const openBatchCreate3PYardsDialog = () => setShouldShowBatchCreate3PYardsDialog(true);
  const closeBatchCreate3PYardsDialog = () => setShouldShowBatchCreate3PYardsDialog(false);

  const canEdit = !!permissions.canEdit;

  return (
    <>
      <Button
        data-testid="addYardActionsButton"
        ref={buttonRef}
        type="primary"
        disabled={fetchingRefresh || !canEdit}
        onClick={openActionMenu}
      >
        {t(keys.ADD_YARD_ACTIONS)}
      </Button>

      <Menu
        anchorNode={buttonRef.current}
        position="bottom"
        open={shouldOpenActionMenu}
        onClose={closeActionMenu}
      >
        <MenuItem data-testid="addYardMenuItem" onClick={openAddYardDialog}>
          {t(keys.ADD_YARD)}
        </MenuItem>
        <MenuItem
          data-testid="batchAdd3PYardMenuItem"
          disabled={!featureFlags[ENABLE_BATCH_3P_YARD_CREATION]?.enabled}
          onClick={openBatchCreate3PYardsDialog}
        >
          {t(keys.BATCH_ADD_3P_YARDS)}
        </MenuItem>
      </Menu>

      {shouldShowAddYardDialog && <AddYardDialog dismiss={closeAddYardDialog} />}

      {shouldShowBatchCreate3PYardsDialog && (
        <BatchCreate3PYardsDialog
          accountInfo={accountInfo}
          dismiss={closeBatchCreate3PYardsDialog}
        />
      )}
    </>
  );
}

AddYardActions.propTypes = {};
