import PropTypes from "prop-types";

import BaseActionBar from "componentsV2/ConfigPage/YardEntity/YardInfo/LocationTable/ActionBar/BaseActionBar";
import OverlayActionBar from "componentsV2/ConfigPage/YardEntity/YardInfo/LocationTable/ActionBar/OverlayActionBar";
import { location } from "types";

export default function ActionBar({
  yardId,
  locations,
  filterString,
  sortColumn,
  sortDirection,
  selectedIds,
  setLocations,
  setFilterString,
  setSelectedIds,
  setPage,
}) {
  if (selectedIds.length) {
    return (
      <OverlayActionBar
        yardId={yardId}
        locations={locations}
        selectedIds={selectedIds}
        setLocations={setLocations}
        setSelectedIds={setSelectedIds}
      />
    );
  }

  return (
    <BaseActionBar
      locations={locations}
      filterString={filterString}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      setLocations={setLocations}
      setFilterString={setFilterString}
      setPage={setPage}
    />
  );
}

ActionBar.propTypes = {
  yardId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(location).isRequired,
  filterString: PropTypes.string.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setLocations: PropTypes.func.isRequired,
  setFilterString: PropTypes.func.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};
