import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Column from "@amzn/meridian/column";

import Breadcrumb from "componentsV2/Common/Headers/Breadcrumb";
import DocumentPageBody from "componentsV2/DocumentPage/DocumentPageBody";
import Headers from "componentsV2/Common/Headers";
import PageBody from "componentsV2/Common/PageBody";
import { useYardConfigInit } from "hooks";
import { fetchYardDocuments } from "store";
import { YARD_LINKS } from "app-constants";

export default function DocumentPage() {
  const { yardId } = useParams();

  const dispatch = useDispatch();

  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];
  const yardDocuments = useSelector((state) => state.yardDocumentsData)[yardId];

  const { label, languages } = yardConfig || {};

  useEffect(() => {
    if (languages && !yardDocuments) {
      languages.forEach((documentLocale) =>
        dispatch(fetchYardDocuments({ yardId, documentLocale }))
      );
    }
  }, [languages]);

  useYardConfigInit(yardId);

  return (
    <Column spacing="large" spacingInset="large">
      <Headers>
        <Breadcrumb yardId={yardId} yardLabel={label} yardLink={YARD_LINKS.documents} />
      </Headers>

      <PageBody yardId={yardId}>
        <DocumentPageBody yardId={yardId} />
      </PageBody>
    </Column>
  );
}

DocumentPage.propTypes = {};
