import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { isEqual } from "lodash";

import { colorBlue500 } from "@amzn/meridian-tokens/base/color";
import blueDarkTokens from "@amzn/meridian-tokens/theme/blue-dark";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Theme from "@amzn/meridian/theme";

import BaseTable from "component/Common/BaseTable";
import { SUBSCRIPTION_KEYS, SUBSCRIPTION_TABLE } from "app-constants";
import { keys } from "i18n";

class SubscriptionTable extends React.PureComponent {
  state = {
    labelFilter: "",
    selectedIds: [],
    sortColumn: SUBSCRIPTION_TABLE.keys[0],
    sortDirection: "ascending",
    subscriptions: this.props.subscriptions,
  };

  onSort = (sortState) => {
    this.setState(sortState);
  };

  toggleAll = (selected) => {
    if (!this.props.canEdit) {
      return;
    }
    this.setState({
      selectedIds: selected ? this.props.subscriptions.map((subscription) => subscription.id) : [],
    });
  };

  toggleOne = (id, selected) => {
    if (!this.props.canEdit) {
      return;
    }
    this.setState({
      selectedIds: selected
        ? [...this.state.selectedIds, id]
        : this.state.selectedIds.filter((selectedId) => selectedId !== id),
    });
  };

  isSubscriptionModified = (subscription) => {
    const originalSubscription = this.props.idToOriginalSubscriptionMap[subscription.id];
    return originalSubscription && !isEqual(originalSubscription, subscription);
  };

  formatTableCellValue = (value, key) => {
    const { t } = this.props;
    if (key === SUBSCRIPTION_KEYS.SUBSCRIPTION_MESSAGE_TYPE) {
      return t(value);
    }
    if (key === SUBSCRIPTION_KEYS.SUBSCRIPTION_PROTOCOL) {
      return t(value);
    }
    if (key === SUBSCRIPTION_KEYS.SUBSCRIPTION_STATUS) {
      return t(value);
    }
    return value;
  };

  renderActionBar = () => {
    const { t, subscriptions, onEdit, onRemove, onRestore } = this.props;
    const { selectedIds } = this.state;

    const selectedCount = selectedIds.length;
    if (selectedCount) {
      const selectedItems = subscriptions.filter(({ id }) => selectedIds.includes(id));
      return (
        <Box type="fill" backgroundColor={colorBlue500} spacingInset="small medium">
          <Theme tokens={blueDarkTokens}>
            <Row alignmentHorizontal="justify">
              <Text>
                {`${selectedCount} ${t(keys.SUBSCRIPTIONS_LOWER_CASE)} ${t(keys.SELECTED)}`}
              </Text>
              <Row spacing="small">
                {selectedCount === 1 && this.isSubscriptionModified(selectedItems[0]) && (
                  <Button type="icon" onClick={() => onRestore(selectedItems)}>
                    {t(keys.RESTORE)}
                  </Button>
                )}
                {selectedCount === 1 && (
                  <Button
                    type="icon"
                    onClick={() => onEdit(selectedItems)}
                    data-testid="editSubscriptionButton"
                  >
                    {t(keys.EDIT)}
                  </Button>
                )}
                {
                  <Button
                    type="icon"
                    onClick={() => onRemove(selectedItems)}
                    data-testid="removeSubscriptionButton"
                  >
                    {t(keys.REMOVE)}
                  </Button>
                }
              </Row>
            </Row>
          </Theme>
        </Box>
      );
    }
  };

  render() {
    return (
      <Column>
        {this.renderActionBar()}

        <BaseTable
          tableColumns={SUBSCRIPTION_TABLE}
          items={this.props.subscriptions}
          idToOriginalItemMap={this.props.idToOriginalSubscriptionMap}
          itemsLabelLowerCase={keys.SUBSCRIPTIONS_LOWER_CASE}
          labelFilter={this.state.labelFilter}
          formatTableCellValue={this.formatTableCellValue}
          currentPage={this.props.currentPage}
          setPageNumber={this.props.setPageNumber}
          isItemModified={this.isSubscriptionModified}
          selectedIds={this.state.selectedIds}
          toggleAll={this.toggleAll}
          toggleOne={this.toggleOne}
          sortColumn={this.state.sortColumn}
          sortDirection={this.state.sortDirection}
          onSort={this.onSort}
        />
      </Column>
    );
  }
}

export { SubscriptionTable };

export default withTranslation()(SubscriptionTable);

SubscriptionTable.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  idToOriginalSubscriptionMap: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
};
