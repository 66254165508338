import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";

import SelfRemoveYardDialog from "componentsV2/Common/Dialogs/SelfRemoveYardDialog";
import { keys } from "i18n";

export default function RemoveYardAction({ mainYardId, yardId }) {
  const [shouldShowSelfRemoveYardDialog, setShouldShowSelfRemoveYardDialog] = useState(false);

  const { permissions } = useSelector((state) => state.permissionsData);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const navigateToHomePage = () => !mainYardId && navigate("/");

  const openSelfRemoveYardDialog = () => setShouldShowSelfRemoveYardDialog(true);
  const closeSelfRemoveYardDialog = () => setShouldShowSelfRemoveYardDialog(false);

  const canEdit = !!permissions.canEdit;

  return (
    <>
      <Row alignmentHorizontal="end">
        <Button
          data-testid="removeYardButton"
          type="secondary"
          disabled={!canEdit}
          onClick={openSelfRemoveYardDialog}
        >
          {t(keys.REMOVE_YARD)}
        </Button>
      </Row>

      {shouldShowSelfRemoveYardDialog && (
        <SelfRemoveYardDialog
          mainYardId={mainYardId}
          yardId={yardId}
          dismiss={closeSelfRemoveYardDialog}
          successCallback={navigateToHomePage}
        />
      )}
    </>
  );
}

RemoveYardAction.propTypes = {
  mainYardId: PropTypes.string,
  yardId: PropTypes.string.isRequired,
};
