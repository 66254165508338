import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import { keys } from "i18n";
import PropTypes from "prop-types";
import { getNewGateIdentifier, getTempId } from "helpers";

export default function NewGateAction({ yardId, setGates }) {
  const { permissions } = useSelector((state) => state.permissionsData);
  const { gates } = useSelector((state) => state.yardConfigData)[yardId];
  const { t } = useTranslation();

  const canEdit = !!permissions.canEdit;

  const createNewGate = () => {
    const newGateIdentifier = getNewGateIdentifier(gates);
    const newGate = {
      id: getTempId(),
      gateIdentifier: newGateIdentifier,
      lanes: [],
    };
    setGates(gates.concat(newGate));
  };

  return (
    <Row alignmentHorizontal="right">
      <Button
        data-testid="newGateButton"
        type="secondary"
        disabled={!canEdit}
        onClick={createNewGate}
      >
        {t(keys.NEW_GUARD_SHACK)}
      </Button>
    </Row>
  );
}

NewGateAction.propTypes = {
  yardId: PropTypes.string.isRequired,
  setGates: PropTypes.func.isRequired,
};
