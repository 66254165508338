import { Role, SignalingClient } from "amazon-kinesis-video-streams-webrtc";
import "webrtc-adapter";
import PropTypes from "prop-types";
import { PEER_CONNECTION_STATES } from "app-constants";

export class KVSWebRTCViewer {
  constructor(config) {
    this.signalingClient = null;
    this.peerConnection = null;
    this.dataChannel = null;
    this.peerConnectionStatsInterval = null;
    this.localStream = null;
    this.remoteStream = null;
    this.config = {
      forceTURN: false,
      openDataChannel: false,
      useTrickleICE: true,
      ...config,
    };
    this.cameraType = config.cameraType;
    this.clientId = config.clientId;
    this.siteId = config.siteId;
  }

  async startViewer() {
    // Create Signaling Client
    this.signalingClient = new SignalingClient({
      channelARN: this.config.channelARN,
      channelEndpoint: this.config.channelEndpoint,
      clientId: this.config.clientId,
      role: Role.VIEWER,
      region: this.config.region,
      credentials: this.config.credentials,
      requestSigner: this.config.requestSigner,
    });

    const configuration = {
      iceServers: this.config.iceServers,
      iceTransportPolicy: this.config.forceTURN ? "relay" : "all",
    };
    this.peerConnection = new RTCPeerConnection(configuration);

    if (this.config.openDataChannel) {
      this.dataChannel = this.peerConnection.createDataChannel("kvsDataChannel");
      this.peerConnection.ondatachannel = (event) => {
        if (this.config.onRemoteDataMessage !== undefined) {
          event.channel.onmessage = this.config.onRemoteDataMessage;
        }
      };
    }
    this.signalingClient.on("open", async () => {
      // Create an SDP offer to send to the master
      if (this.peerConnection) {
        await this.peerConnection.setLocalDescription(
          await this.peerConnection.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          })
        );
      }

      /*
       * When trickle ICE is enabled, send the offer now and then
       * send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
       */
      if (
        this.config.useTrickleICE &&
        this.peerConnection &&
        this.peerConnection.localDescription &&
        this.signalingClient
      ) {
        this.signalingClient.sendSdpOffer(this.peerConnection.localDescription);
      }
    });

    this.signalingClient.on("sdpAnswer", async (answer) => {
      // Add the SDP answer to the peer connection
      if (this.peerConnection) {
        await this.peerConnection.setRemoteDescription(answer);
      }
    });

    this.signalingClient.on("iceCandidate", (candidate) => {
      // Add the ICE candidate received from the MASTER to the peer connection
      if (this.peerConnection) {
        this.peerConnection.addIceCandidate(candidate);
      }
    });

    this.signalingClient.on("close", () => {
      // no-op; TODO: add logging
    });

    this.signalingClient.on("error", (error) => {
      // no-op; TODO: add logging
    });

    // Send any ICE candidates to the other peer
    this.peerConnection.addEventListener("icecandidate", ({ candidate }) => {
      if (candidate) {
        // When trickle ICE is enabled, send the ICE candidates as they are generated.
        if (this.config.useTrickleICE && this.signalingClient) {
          this.signalingClient.sendIceCandidate(candidate);
        }
      } else if (
        !this.config.useTrickleICE &&
        this.peerConnection &&
        this.peerConnection.localDescription &&
        this.signalingClient
      ) {
        /*
         * When trickle ICE is disabled,
         * send the offer now that all the ICE candidates have been generated.
         */
        this.signalingClient.sendSdpOffer(this.peerConnection.localDescription);
      }
    });

    // As remote tracks are received, add them to the remote view
    this.peerConnection.addEventListener("track", (event) => {
      if (!this.config.onRemoteStream) {
        return;
      }

      const [receivedStream] = event.streams;
      if (this.remoteStream) {
        receivedStream.getTracks().forEach((track) => this.remoteStream?.addTrack(track));
      } else {
        this.remoteStream = receivedStream;
        this.config.onRemoteStream(this.remoteStream);
      }
    });

    this.peerConnection.addEventListener(
      "connectionstatechange",
      () => {
        switch (this.peerConnection?.connectionState) {
          case PEER_CONNECTION_STATES.NEW:
            break;
          case PEER_CONNECTION_STATES.CONNECTED:
            break;
          case PEER_CONNECTION_STATES.DISCONNECTED:
            this.config.onPeerConnectionDisconnected(this.config.cameraType);
            break;
          case PEER_CONNECTION_STATES.CLOSED:
            break;
          case PEER_CONNECTION_STATES.FAILED:
            this.config.onPeerConnectionFailed(this.config.cameraType);
            break;
          default:
            break;
        }
      },
      false
    );
    this.signalingClient.open();
  }

  stopViewer() {
    if (this.signalingClient) {
      this.signalingClient.close();
      this.signalingClient = null;
    }

    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
    }

    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => track.stop());
      this.localStream = null;
    }

    if (this.remoteStream) {
      this.remoteStream.getTracks().forEach((track) => track.stop());
      this.remoteStream = null;
    }

    if (this.peerConnectionStatsInterval) {
      window.clearInterval(this.peerConnectionStatsInterval);
      this.peerConnectionStatsInterval = null;
    }

    if (this.dataChannel) {
      this.dataChannel = null;
      this.peerConnectionStatsInterval = null;
    }
  }

  sendViewerMessage(message) {
    if (this.dataChannel) {
      try {
        this.dataChannel.send(message);
      } catch (e) {
        // no-op; TODO: add logging
      }
    }
  }
}

KVSWebRTCViewer.propTypes = {
  config: PropTypes.object.isRequired,
};
