import { keys } from "i18n";
import { SHOW_OFFSITE_BUILDING_CODE, STATUS_TYPES } from "app-constants";
import Tag from "@amzn/meridian/tag";

export const formatTableCellContent = (key, location, featureFlags, t) => {
  const value = location[key];

  if (typeof value === "boolean") {
    return value ? t(keys.YES) + overloadBooleanValue(key, location, featureFlags) : t(keys.NO);
  }

  if (key === "zoneList") {
    return value ? value.join(", ") : location.zone;
  }

  if (key === "label" && location.disabled) {
    return (
      <div>
        <div>{value}</div>
        <Tag data-testid="disabledTag" type={STATUS_TYPES.ERROR}>
          {t(keys.DISABLED)}
        </Tag>
      </div>
    );
  }

  return value;
};

const overloadBooleanValue = (key, location, featureFlags) => {
  if (
    key === "offsiteLocation" &&
    location.offsiteBuildingCode &&
    featureFlags[SHOW_OFFSITE_BUILDING_CODE]?.enabled
  ) {
    return ` (${location.offsiteBuildingCode})`;
  }

  return "";
};
