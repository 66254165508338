import { SAVE_SECRETS } from "./secretsActionTypes";

const INITIAL_SECRETS = {
  hereApiKey: "dummy-here-api-key",
  mapboxApiKey: "dummy-mapbox-api-key",
  bingApiKey: "dummy-bing-api-key",
};

export const secretsReducer = (state = INITIAL_SECRETS, action) => {
  switch (action.type) {
    case SAVE_SECRETS: {
      const secret = JSON.parse(action.payload);
      return {
        ...state,
        ...(!secret.error && secret),
      };
    }

    default:
      return state;
  }
};
