import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";

import ActionBar from "componentsV2/DevicePage/ActionBar";
import DeviceTable from "componentsV2/DevicePage/DeviceTable";

export default function DevicePageBody({ yardId }) {
  const [refreshingYardDevices, setRefreshingYardDevices] = useState(false);

  const yardDevices = useSelector((state) => state.yardDevicesData)[yardId];

  if (!yardDevices) {
    return (
      <Row data-testid="fetchYardDevicesLoader" spacingInset="medium" alignmentHorizontal="center">
        <Loader />
      </Row>
    );
  }

  return (
    <Column>
      <ActionBar
        yardId={yardId}
        refreshingYardDevices={refreshingYardDevices}
        setRefreshingYardDevices={setRefreshingYardDevices}
      />
      <DeviceTable yardId={yardId} refreshingYardDevices={refreshingYardDevices} />
    </Column>
  );
}

DevicePageBody.propTypes = {
  yardId: PropTypes.string.isRequired,
};
