import i18n from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import keys from "./keys";

// Documentation: https://react.i18next.com

const defaultLng = "en-US";
const initLng = window.localStorage.getItem("i18nextLng") || defaultLng;

i18n
  // Lazy load in memory translations
  // https://www.i18next.com/how-to/add-or-load-translations#lazy-load-in-memory-translations
  // If for some reason, you change the name of "strings.puff.json", you need to change the import path below too
  .use(
    resourcesToBackend((lng, namespace, callback) => {
      import(`../../translations/strings-${lng}.puff.json`)
        .then(({ resources }) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  // Store user language selection in browser
  .use(LanguageDetector)
  // Pass i18n down to react-i18next
  .use(initReactI18next)
  .init({
    lng: initLng,
    fallbackLng: "en-US",
    load: "currentOnly", // prevent double loading e.g. both "en" and "en-US" when "en-US" is selected
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false, // something to do with xss, actually not needed for react
    },
  });

export default i18n;

export { keys };
