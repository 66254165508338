import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";

import MoveLocationsDialog from "componentsV2/Common/Dialogs/MoveLocationsDialog";
import { keys } from "i18n";

export default function MoveLocationsAction({ mainYardId, yardId }) {
  const [shouldShowMoveLocationsDialog, setShouldShowMoveLocationsDialog] = useState(false);

  const { permissions } = useSelector((state) => state.permissionsData);

  const { t } = useTranslation();

  const openMoveLocationsDialog = () => setShouldShowMoveLocationsDialog(true);
  const closeMoveLocationsDialog = () => setShouldShowMoveLocationsDialog(false);

  const canEdit = !!permissions.canEdit;

  return (
    <>
      <Row alignmentHorizontal="right">
        <Button
          data-testid="moveLocationsButton"
          type="secondary"
          disabled={!canEdit}
          onClick={openMoveLocationsDialog}
        >
          {t(keys.MOVE_LOCATIONS)}
        </Button>
      </Row>

      {shouldShowMoveLocationsDialog && (
        <MoveLocationsDialog
          mainYardId={mainYardId}
          sourceYardId={yardId}
          dismiss={closeMoveLocationsDialog}
        />
      )}
    </>
  );
}

MoveLocationsAction.propTypes = {
  mainYardId: PropTypes.string,
  yardId: PropTypes.string.isRequired,
};
