import { callAPI } from "store";
import { CREATE_YARD_SUCCESS, EDIT_YARD_SUCCESS, DELETE_YARD_SUCCESS } from "./editYardActionTypes";
import { APIs, OFFSITE } from "app-constants";

/**
 * If there's mainYardId, we're creating an offsite yard
 */
export const createYard = ({ mainYardId, yardConfig, successCallback, failureCallback }) => {
  return async (dispatch, getState) => {
    const {
      label,
      type,
      assetTypes,
      languages = [],
      yardFeatures = [],
      enableHostler = false,
    } = yardConfig;

    try {
      const responseJson = await callAPI(getState().accountInfo, APIs.CREATE_YARD, {
        deDuplicationKey: label,
        label,
        type,
        assetTypes,
        languages,
        yardFeatures,
        enableHostler,
        enableRecordDrivers: true, // TODO: Remove?
        ...(mainYardId && {
          yardRelations: [{ relationType: OFFSITE, yardId: mainYardId }],
        }),
      });

      dispatch(createYardSuccess({ mainYardId, ...responseJson }));
      successCallback();
    } catch (e) {
      failureCallback(e.message);
    }
  };
};

export const editYard = ({ yardId, yardConfig, successCallback, failureCallback }) => {
  return async (dispatch, getState) => {
    const { label, type, assetTypes, languages, yardFeatures, enableHostler, version } = yardConfig;

    try {
      const responseJson = await callAPI(getState().accountInfo, APIs.EDIT_YARD_LAYOUT, {
        yardId,
        label,
        type,
        assetTypes,
        languages,
        yardFeatures,
        enableHostler,
        enableRecordDrivers: true, // TODO: Remove?
        version,
      });

      dispatch(editYardSuccess({ yardId, ...responseJson }));
      successCallback();
    } catch (e) {
      failureCallback(e.message);
    }
  };
};

/**
 * If there's mainYardId, we're deleting an offsite yard
 */
export const deleteYard = ({ mainYardId, yardId, version, successCallback, failureCallback }) => {
  return async (dispatch, getState) => {
    try {
      const responseJson = await callAPI(getState().accountInfo, APIs.DELETE_YARD, {
        yardId,
        version,
      });

      dispatch(deleteYardSuccess({ mainYardId, yardId, ...responseJson }));
      successCallback();
    } catch (e) {
      failureCallback(e.message);
    }
  };
};

export const createYardSuccess = (payload) => {
  return {
    type: CREATE_YARD_SUCCESS,
    payload,
  };
};

export const editYardSuccess = (payload) => {
  return {
    type: EDIT_YARD_SUCCESS,
    payload,
  };
};

export const deleteYardSuccess = (payload) => {
  return {
    type: DELETE_YARD_SUCCESS,
    payload,
  };
};
