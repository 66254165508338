import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

import Alert from "@amzn/meridian/alert";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";

import { hasPermissionToAccessPage } from "helpers";
import { keys } from "i18n";
import { FEATURES, STATUS_TYPES } from "app-constants";

export default function PageBody({ yardId, shouldSkipLinkedPageChecks, children }) {
  const { activeAccountName } = useSelector((state) => state.accountInfo);
  const { permissions, error: errorFetchingPermissions } = useSelector(
    (state) => state.permissionsData
  );
  const featureFlags = useSelector((state) => state.featureFlags);
  const yardConfig = useSelector((state) => state.yardConfigData)[yardId];

  const { t } = useTranslation();

  const fetchingPermissions = isEmpty(permissions) && !errorFetchingPermissions;
  const fetchingFeatureFlags = FEATURES.some(
    (feature) => isEmpty(featureFlags[feature]) || featureFlags[feature].fetching
  );
  const { label, buildings, version } = yardConfig || {};

  if (fetchingPermissions || fetchingFeatureFlags) {
    return (
      <Row
        data-testid="fetchPermissionsAndFeatureFlagsLoader"
        spacingInset="medium"
        alignmentHorizontal="center"
      >
        <Loader />
      </Row>
    );
  }

  if (!shouldSkipLinkedPageChecks && !version) {
    return (
      <Row data-testid="fetchYardConfigLoader" spacingInset="medium" alignmentHorizontal="center">
        <Loader />
      </Row>
    );
  }

  if (!shouldSkipLinkedPageChecks && !label) {
    return (
      <Alert data-testid="fetchYardConfigErrorMessage" type={STATUS_TYPES.ERROR}>
        {t(keys.FETCH_YARD_CONFIG_ERROR_MESSAGE)}
      </Alert>
    );
  }

  if (!shouldSkipLinkedPageChecks && !hasPermissionToAccessPage(activeAccountName, buildings)) {
    return (
      <Alert data-testid="noPermissionErrorMessage" type={STATUS_TYPES.WARNING}>
        {t(keys.NO_PERMISSION_ERROR_MESSAGE)}
      </Alert>
    );
  }

  return children;
}

PageBody.propTypes = {
  yardId: PropTypes.string,
};
