import {
  CLEAR_ERROR_SAVING_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  SAVE_SUBSCRIPTIONS,
  SAVE_SUBSCRIPTIONS_FAILURE,
  SAVE_SUBSCRIPTIONS_SUCCESS,
} from "./subscriptionsActionTypes";
import { callAPI } from "store";
import { convertToMapById, hasTempId } from "helpers";
import { isEqual } from "lodash";
import { APIs } from "app-constants";

export const fetchSubscriptions = (yardId) => {
  return async (dispatch, getState) => {
    dispatch(fetchSubscriptionsRequest());

    try {
      const responseJson = await callAPI(getState().accountInfo, APIs.GET_ACN_SUBSCRIPTIONS, {
        yardId,
      });

      dispatch(fetchSubscriptionsSuccess({ yardId, subscriptions: responseJson.subscriptions }));
    } catch (exception) {
      dispatch(fetchSubscriptionsFailure(exception.message));
    }
  };
};

export const saveSubscriptions = (
  { yardId, buildingCode, subscriptions, originalSubscriptions },
  successCallback
) => {
  return async (dispatch, getState) => {
    const idToSubscriptionsMap = convertToMapById(subscriptions);
    const idToOriginalSubscriptionsMap = convertToMapById(originalSubscriptions);
    const updatedSubscriptions = subscriptions.filter(
      (s) => !isEqual(s, idToOriginalSubscriptionsMap[s.id])
    );
    const unchangedSubscriptions = subscriptions.filter((s) =>
      isEqual(s, idToOriginalSubscriptionsMap[s.id])
    );
    const deletedSubscriptions = originalSubscriptions.filter((s) => !idToSubscriptionsMap[s.id]);

    // Remove temp subscription ids created directly with the UI.
    // These temp ids are added because of https://sim.amazon.com/issues/Yard-3453
    // They should not be sent to backend service.
    const formattedUpdatedSubscriptions = updatedSubscriptions.map((subscription) => {
      if (hasTempId(subscription.id)) {
        return { ...subscription, id: null };
      }

      return subscription;
    });

    const requestBody = {
      subscriptionsToUpdate: formattedUpdatedSubscriptions,
      subscriptionsToDelete: deletedSubscriptions,
    };

    dispatch(saveSubscriptionsRequest());

    try {
      const responseJson = await callAPI(
        getState().accountInfo,
        APIs.SET_ACN_SUBSCRIPTIONS,
        requestBody
      );

      dispatch(
        saveSubscriptionsSuccess({
          yardId,
          buildingCode,
          subscriptions: [...responseJson.updatedSubscriptions, ...unchangedSubscriptions],
        })
      );
      successCallback && successCallback();
    } catch (exception) {
      dispatch(saveSubscriptionsFailure(exception.message));
    }
  };
};

export const fetchSubscriptionsRequest = () => {
  return {
    type: FETCH_SUBSCRIPTIONS,
  };
};

export const fetchSubscriptionsSuccess = ({ yardId, subscriptions }) => {
  return {
    type: FETCH_SUBSCRIPTIONS_SUCCESS,
    payload: { yardId, subscriptions },
  };
};

export const fetchSubscriptionsFailure = (error) => {
  return {
    type: FETCH_SUBSCRIPTIONS_FAILURE,
    payload: error,
  };
};

export const saveSubscriptionsRequest = () => {
  return {
    type: SAVE_SUBSCRIPTIONS,
  };
};

export const saveSubscriptionsSuccess = ({ yardId, buildingCode, subscriptions }) => {
  return {
    type: SAVE_SUBSCRIPTIONS_SUCCESS,
    payload: { yardId, buildingCode, subscriptions },
  };
};

export const saveSubscriptionsFailure = (error) => {
  return {
    type: SAVE_SUBSCRIPTIONS_FAILURE,
    payload: error,
  };
};

export const clearErrorSavingSubscriptions = (callback) => {
  callback && callback();
  return {
    type: CLEAR_ERROR_SAVING_SUBSCRIPTIONS,
  };
};
