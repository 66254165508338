import {
  FETCH_PERMISSIONS,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAILURE,
} from "./permissionsActionTypes";

export const INITIAL_PERMISSIONS = {
  fetchingPermissions: false,
  permissions: {},
  error: null,
};

export const permissionsReducer = (state = INITIAL_PERMISSIONS, action) => {
  switch (action.type) {
    case FETCH_PERMISSIONS:
      return {
        ...state,
        fetchingPermissions: true,
      };

    case FETCH_PERMISSIONS_SUCCESS:
      const { permissions } = action.payload;
      return {
        ...state,
        fetchingPermissions: false,
        permissions,
      };

    case FETCH_PERMISSIONS_FAILURE:
      return {
        ...state,
        fetchingPermissions: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
