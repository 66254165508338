import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Select, { SelectOption } from "@amzn/meridian/select";

import { keys } from "i18n";

export default function DestinationYardSelect({
  mainYardId,
  sourceYardId,
  destinationYardId,
  setDestinationYardId,
}) {
  const mainYardConfig = useSelector((state) => state.yardConfigData)[mainYardId];
  const { offsiteYards } = useSelector((state) => state.yardConfigData)[sourceYardId];

  const { t } = useTranslation();

  return (
    <Select
      data-testid="destinationYardSelect"
      label={t(keys.DESTINATION_YARD)}
      placeholder={t(keys.SELECT_DESTINATION_YARD)}
      value={destinationYardId}
      onChange={setDestinationYardId}
    >
      {mainYardId && (
        <SelectOption
          data-testid={mainYardId}
          key={mainYardId}
          value={mainYardId}
          label={mainYardConfig.label}
        />
      )}
      {!mainYardId &&
        offsiteYards.map(({ yardId, label }) => (
          <SelectOption data-testid={yardId} key={yardId} value={yardId} label={label} />
        ))}
    </Select>
  );
}

DestinationYardSelect.propTypes = {
  mainYardId: PropTypes.string,
  sourceYardId: PropTypes.string.isRequired,
  destinationYardId: PropTypes.string,
  setDestinationYardId: PropTypes.func.isRequired,
};
