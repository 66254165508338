import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Column from "@amzn/meridian/column";
import Modal from "@amzn/meridian/modal";

import DestinationYardSelect from "componentsV2/Common/Dialogs/MoveLocationsDialog/DestinationYardSelect";
import DialogFooter from "componentsV2/Common/Dialogs/DialogFooter";
import LocationSelect from "componentsV2/Common/Dialogs/MoveLocationsDialog/LocationSelect";
import { moveLocations } from "store";
import { generateErrorMessage } from "helpers";
import { keys } from "i18n";

export default function MoveLocationsDialog({ mainYardId, sourceYardId, dismiss }) {
  const { version } = useSelector((state) => state.yardConfigData)[sourceYardId];

  const [destinationYardId, setDestinationYardId] = useState(null);
  const [locationIdsToMove, setLocationIdsToMove] = useState([]);
  const [movingLocations, setMovingLocations] = useState(false);
  const [errorMovingLocations, setErrorMovingLocations] = useState(null);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const submit = () => {
    setMovingLocations(true);
    setErrorMovingLocations(null);

    dispatch(
      moveLocations({
        sourceYardId,
        destinationYardId,
        locationIdsToMove,
        version,
        successCallback: dismiss,
        failureCallback: (errorMessage) => {
          setMovingLocations(false);
          setErrorMovingLocations(errorMessage);
        },
      })
    );
  };

  const submittable = destinationYardId && locationIdsToMove.length;
  const error =
    errorMovingLocations &&
    generateErrorMessage(t(keys.MOVE_LOCATIONS_ERROR_MESSAGE), errorMovingLocations);

  return (
    <Modal data-testid="dialogModal" width="400px" title={t(keys.MOVE_LOCATIONS)} open={true}>
      <Column spacing="large">
        <Column>
          <DestinationYardSelect
            mainYardId={mainYardId}
            sourceYardId={sourceYardId}
            destinationYardId={destinationYardId}
            setDestinationYardId={setDestinationYardId}
          />

          <LocationSelect
            sourceYardId={sourceYardId}
            locationIdsToMove={locationIdsToMove}
            setLocationIdsToMove={setLocationIdsToMove}
          />
        </Column>

        <DialogFooter
          commitButtonText={t(keys.MOVE_LOCATIONS)}
          inProgress={movingLocations}
          error={error}
          disabled={!submittable}
          commit={submit}
          dismiss={dismiss}
        />
      </Column>
    </Modal>
  );
}

MoveLocationsDialog.propTypes = {
  mainYardId: PropTypes.string,
  sourceYardId: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
};
