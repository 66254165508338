import { callAPI } from "store";
import {
  FETCH_YARD_DOCUMENTS,
  FETCH_YARD_DOCUMENTS_SUCCESS,
  FETCH_YARD_DOCUMENTS_FAILURE,
  SAVE_LOCALES,
  SAVE_LOCALES_SUCCESS,
  SAVE_LOCALES_FAILURE,
  CLEAR_ERROR_SAVING_LOCALES,
} from "./documentsActionTypes";
import { APIs } from "app-constants";

export const fetchYardDocuments = ({ yardId, documentLocale }) => {
  return async (dispatch, getState) => {
    const accountInfo = getState().accountInfo;

    dispatch(fetchYardDocumentsRequest({ yardId, documentLocale }));

    try {
      const responseJson = await callAPI(accountInfo, APIs.GET_YARD_DOCUMENTS, {
        yardId,
        documentLocale,
      });

      dispatch(fetchYardDocumentsSuccess({ yardId, documentLocale, ...responseJson }));
    } catch (exception) {
      dispatch(fetchYardDocumentsFailure({ yardId, documentLocale, error: exception.message }));
    }
  };
};

export const saveLocales = ({ yardId, locales }, successCallback) => {
  return async (dispatch, getState) => {
    const accountInfo = getState().accountInfo;
    const { label, type, assetTypes, enableHostler, version } = getState().yardConfigData[yardId];

    dispatch(saveLocalesRequest({ yardId }));

    try {
      const responseJson = await callAPI(accountInfo, APIs.EDIT_YARD_LAYOUT, {
        yardId,
        label,
        type,
        assetTypes,
        languages: locales,
        enableHostler,
        enableRecordDrivers: true, // should remove already?
        version,
      });

      dispatch(saveLocalesSuccess({ yardId, ...responseJson }));
      successCallback && successCallback();
    } catch (exception) {
      dispatch(saveLocalesFailure({ yardId, error: exception.message }));
    }
  };
};

export const clearErrorSavingLocales = (payload) => {
  return {
    type: CLEAR_ERROR_SAVING_LOCALES,
    payload,
  };
};

export const fetchYardDocumentsRequest = (payload) => {
  return {
    type: FETCH_YARD_DOCUMENTS,
    payload,
  };
};

export const fetchYardDocumentsSuccess = (payload) => {
  return {
    type: FETCH_YARD_DOCUMENTS_SUCCESS,
    payload,
  };
};

export const fetchYardDocumentsFailure = (payload) => {
  return {
    type: FETCH_YARD_DOCUMENTS_FAILURE,
    payload,
  };
};

export const saveLocalesRequest = (payload) => {
  return {
    type: SAVE_LOCALES,
    payload,
  };
};

export const saveLocalesSuccess = (payload) => {
  return {
    type: SAVE_LOCALES_SUCCESS,
    payload,
  };
};

export const saveLocalesFailure = (payload) => {
  return {
    type: SAVE_LOCALES_FAILURE,
    payload,
  };
};
