import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Box from "@amzn/meridian/box";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";

import ControlledExpander from "componentsV2/Common/ControlledExpander";
import SubscriptionDisplayer from "component/SubscriptionDisplayer";
import { fetchSubscriptions } from "store";
import { keys } from "i18n";
import { ENABLE_SUBSCRIPTIONS_MANAGEMENT } from "app-constants";

/**
 * TODO: Deprecate since this component uses a different backend than YDLR,
 * hence should not be here in Yard Designer
 */
export default function SubscriptionTable({ yardId, buildingCode }) {
  const { permissions } = useSelector((state) => state.permissionsData);
  const featureFlags = useSelector((state) => state.featureFlags);
  const subscriptions = useSelector((state) => state.subscriptionsData)[yardId];

  const featureEnabled = featureFlags[ENABLE_SUBSCRIPTIONS_MANAGEMENT]?.enabled;

  const dispatch = useDispatch();

  useEffect(() => {
    if (featureEnabled && !subscriptions) {
      dispatch(fetchSubscriptions(yardId));
    }
  }, [featureEnabled]);

  const { t } = useTranslation();

  const canEdit = !!permissions.canEdit;

  if (!featureEnabled) {
    return null;
  }

  if (!subscriptions) {
    return (
      <ControlledExpander title={t(keys.SUBSCRIPTIONS)} type="inline" openOnMount={true}>
        <Row data-testid="loader" spacingInset="medium" alignmentHorizontal="center">
          <Loader />
        </Row>
      </ControlledExpander>
    );
  }

  return (
    <ControlledExpander title={t(keys.SUBSCRIPTIONS)} type="inline" openOnMount={true}>
      <Box type="fill" spacingInset="medium">
        <SubscriptionDisplayer
          canEdit={canEdit}
          yardId={yardId}
          buildingCode={buildingCode}
          subscriptions={subscriptions?.[buildingCode] || []}
        />
      </Box>
    </ControlledExpander>
  );
}

SubscriptionTable.propTypes = {
  yardId: PropTypes.string.isRequired,
  buildingCode: PropTypes.string.isRequired,
};
