import { YARD_DOCUMENT_TYPES } from "app-constants";

export const getNewLocales = (locales, yardDocuments) => {
  return locales.filter((locale) => !yardDocuments[locale]);
};

export const processYardDocuments = (locales, yardDocumentsByLocale) =>
  locales
    .map((documentLocale) => {
      const { fetching, yardDocuments, error } = yardDocumentsByLocale[documentLocale] || {};
      if (fetching) {
        return YARD_DOCUMENT_TYPES.map((documentType) => ({
          documentLocale,
          documentType,
          fetching,
        }));
      }

      if (error) {
        return YARD_DOCUMENT_TYPES.map((documentType) => ({
          documentLocale,
          documentType,
          error,
        }));
      }

      if (!yardDocuments) {
        return null;
      }

      if (yardDocuments.length < YARD_DOCUMENT_TYPES.length) {
        return YARD_DOCUMENT_TYPES.map((documentType) => {
          const yardDocument = yardDocuments.find(
            (yardDocument) => yardDocument.documentType === documentType
          );
          return yardDocument || { documentLocale, documentType };
        });
      }

      return yardDocuments;
    })
    .filter((yardDocuments) => yardDocuments)
    .flatMap((yardDocuments) => yardDocuments);
