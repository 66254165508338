import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Checkbox from "@amzn/meridian/checkbox";
import Pagination from "@amzn/meridian/pagination";
import Row from "@amzn/meridian/row";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import checkIconTokens from "@amzn/meridian-tokens/base/icon/check-large";
import minusIconTokens from "@amzn/meridian-tokens/base/icon/minus";

import TableCellContent from "componentsV2/Common/BaseTable/TableCellContent";
import { tableColumns } from "types";
import { DEFAULT_NUM_ITEMS_PER_PAGE } from "app-constants";

export default function BaseTable({
  tableColumns,
  idToOriginalItemMap,
  itemsLabelLowerCase,
  items,
  filterString,
  sortColumn,
  sortDirection,
  selectedIds,
  page,
  handleSort,
  setSelectedIds,
  formatTableCellContent,
  setPage,
}) {
  const { permissions } = useSelector((state) => state.permissionsData);

  const { t } = useTranslation();

  const canEdit = !!permissions.canEdit;

  const areSomeSelected = !!selectedIds.length;
  const areAllSelected = selectedIds.length === items.length;
  const headerCheckboxInconToken = areAllSelected ? checkIconTokens : minusIconTokens;

  const filteredSortedItems = _(items)
    .filter(({ label }) => label.toLowerCase().includes(filterString.toLowerCase()))
    .orderBy([sortColumn], [sortDirection === "ascending" ? "asc" : "desc"])
    .value();
  const filteredSortedPaginatedItems = filteredSortedItems.filter(
    (_, index) => Math.floor(index / DEFAULT_NUM_ITEMS_PER_PAGE) === page - 1
  );

  const numberOfPages = Math.ceil(filteredSortedItems.length / DEFAULT_NUM_ITEMS_PER_PAGE);

  const isItemSelected = (id) => selectedIds.includes(id);

  const handleHeaderCheckboxToggle = () =>
    setSelectedIds(areAllSelected ? [] : items.map(({ id }) => id));

  const toggleItem = (id) =>
    setSelectedIds(
      isItemSelected(id)
        ? selectedIds.filter((selectedId) => selectedId !== id)
        : selectedIds.concat(id)
    );

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <Table
          data-testid="table"
          spacing="small"
          headerRows={1}
          showDividers={true}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={handleSort}
        >
          <TableRow>
            <TableCell>
              <Checkbox
                data-testid="headerCheckbox"
                iconTokens={headerCheckboxInconToken}
                checked={areSomeSelected}
                disabled={!canEdit}
                onChange={handleHeaderCheckboxToggle}
              />
            </TableCell>

            {tableColumns.labels.map((header, index) => (
              <TableCell key={header} sortColumn={tableColumns.keys[index]}>
                {t(header)}
              </TableCell>
            ))}
          </TableRow>

          {filteredSortedPaginatedItems.map((item) => (
            <TableRow key={item.id || item.label} highlightOnHover={true}>
              <TableCell>
                <Checkbox
                  data-testid={`rowCheckbox-${item.id}`}
                  checked={isItemSelected(item.id)}
                  disabled={!canEdit}
                  onChange={() => toggleItem(item.id)}
                />
              </TableCell>

              {tableColumns.keys.map((key) => (
                <TableCell data-testid={`rowCell-${item.id}-${key}-${item[key]}`} key={key}>
                  <TableCellContent
                    columnKey={key}
                    item={item}
                    originalItem={idToOriginalItemMap[item.id]}
                    formatTableCellContent={formatTableCellContent}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </Table>
      </div>

      <Row alignmentHorizontal="right">
        <Text data-testid="paginationItemsCountText">
          {filteredSortedItems.length} {t(itemsLabelLowerCase)}
        </Text>
        <Pagination
          showSkipArrows={true}
          numberOfPages={numberOfPages}
          currentPage={page}
          onChange={setPage}
        />
      </Row>
    </>
  );
}

BaseTable.propTypes = {
  /**
   * Columns of table, an object with labels and keys arrays
   * First column must be "Label" (as label) and "label" (as key)
   */
  tableColumns: tableColumns.isRequired,

  /**
   * Mapping of item id to its original value
   */
  idToOriginalItemMap: PropTypes.object.isRequired,

  /**
   * To be used to show item count in pagination section (e.g. 20 location(s))
   */
  itemsLabelLowerCase: PropTypes.string.isRequired,

  /**
   * Items to be displayed in table, each must have id and label props
   */
  items: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * To filter items in table by label
   */
  filterString: PropTypes.string.isRequired,

  /**
   * A column key to sort items in table
   */
  sortColumn: PropTypes.string.isRequired,

  /**
   * Directon to sort items by, either "ascending" or "descending"
   */
  sortDirection: PropTypes.string.isRequired,

  /**
   * ids of the items selected
   */
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * Current page
   */
  page: PropTypes.number.isRequired,

  /**
   * Sort function that set sortColumn and sortDirection
   */
  handleSort: PropTypes.func.isRequired,

  /**
   * Function to record ids of selected items
   */
  setSelectedIds: PropTypes.func.isRequired,

  /**
   * Function to format table cell content, with params (columnKey, item, featureFlags, t)
   */
  formatTableCellContent: PropTypes.func.isRequired,

  /**
   * Function to set current page
   */
  setPage: PropTypes.func.isRequired,
};
