import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@amzn/meridian/button";
import Menu, { MenuItem } from "@amzn/meridian/menu";

import AddOffsiteYardDialog from "componentsV2/Common/Dialogs/AddOffsiteYardDialog";
import BuildingDialog from "component/BuildingDialogs/BuildingDialog";
import { keys } from "i18n";
import { SUPPORT_OFFSITE_MODELING } from "app-constants";

export default function AddYardEntityActions({ yardId }) {
  const [shouldOpenActionMenu, setShouldOpenActionMenu] = useState(false);
  const [shouldShowAddBuildingDialog, setShouldShowAddBuildingDialog] = useState(false);
  const [shouldShowAddOffsiteYardDialog, setShouldShowAddOffsiteYardDialog] = useState(false);

  const { permissions } = useSelector((state) => state.permissionsData);
  const featureFlags = useSelector((state) => state.featureFlags);
  const { version } = useSelector((state) => state.yardConfigData)[yardId];

  const buttonRef = useRef();

  const { t } = useTranslation();

  const openActionMenu = () => setShouldOpenActionMenu(true);
  const closeActionMenu = () => setShouldOpenActionMenu(false);
  const openAddBuildingDialog = () => setShouldShowAddBuildingDialog(true);
  const closeAddBuildingDialog = () => setShouldShowAddBuildingDialog(false);
  const openAddOffsiteYardDialog = () => setShouldShowAddOffsiteYardDialog(true);
  const closeAddOffsiteYardDialog = () => setShouldShowAddOffsiteYardDialog(false);

  const canEdit = !!permissions.canEdit;

  return (
    <>
      <Button
        data-testid="addYardEntityActionsButton"
        ref={buttonRef}
        type="primary"
        disabled={!canEdit}
        onClick={openActionMenu}
      >
        {t(keys.ADD_YARD_ENTITY_ACTIONS)}
      </Button>

      <Menu
        anchorNode={buttonRef.current}
        position="bottom"
        open={shouldOpenActionMenu}
        onClose={closeActionMenu}
      >
        <MenuItem data-testid="addBuildingMenuItem" onClick={openAddBuildingDialog}>
          {t(keys.ADD_BUILDING)}
        </MenuItem>
        {featureFlags[SUPPORT_OFFSITE_MODELING]?.enabled && (
          <MenuItem data-testid="addOffsiteYardMenuItem" onClick={openAddOffsiteYardDialog}>
            {t(keys.ADD_OFFSITE_YARD)}
          </MenuItem>
        )}
      </Menu>

      {shouldShowAddBuildingDialog && (
        <BuildingDialog yardId={yardId} version={version} dismiss={closeAddBuildingDialog} />
      )}
      {shouldShowAddOffsiteYardDialog && (
        <AddOffsiteYardDialog mainYardId={yardId} dismiss={closeAddOffsiteYardDialog} />
      )}
    </>
  );
}

AddYardEntityActions.propTypes = {
  yardId: PropTypes.string.isRequired,
};
