import PropTypes from "prop-types";

import { auditFeatures } from "types";

export const location = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hasDockDoor: PropTypes.bool.isRequired,
  inbound: PropTypes.bool.isRequired,
  outbound: PropTypes.bool.isRequired,
  offsiteLocation: PropTypes.bool.isRequired,
  offsiteBuildingCode: PropTypes.string,
  screenOrder: PropTypes.string.isRequired,
  auditOrder: PropTypes.string.isRequired,
  auditFeatures: auditFeatures,
  zone: PropTypes.string,
  zoneList: PropTypes.arrayOf(PropTypes.string),
  nonInventory: PropTypes.bool.isRequired,
  sortable: PropTypes.bool.isRequired,
  capacity: PropTypes.number,
  multiCapacity: PropTypes.bool.isRequired,
});
