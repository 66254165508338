import React from "react";
import PropTypes from "prop-types";
import { compose } from "lodash/fp";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import SaveChangesDialog from "component/Common/SaveChangesDialog";
import { hasTempId } from "helpers";
import {
  clearErrorSavingLocations,
  saveLocationsFailure,
  saveLocationsRequest,
  saveLocationsSuccess,
  callAPI,
} from "store";
import { APIs } from "app-constants";
import { keys } from "i18n";

class SaveLocationsDialog extends React.PureComponent {
  saveLocations = async () => {
    const { accountInfo, version, yardId, locations, removedLocations } = this.props;

    const formattedLocations = locations.map((location) => {
      const formattedLocation = { ...location };

      // Remove temp ids added to locations created directly with the UI or uploaded locations without id
      // These ids are added because of https://sim.amazon.com/issues/Yard-3453
      // Removing these ids is needed because back-end needs new locations not to have ids
      if (hasTempId(formattedLocation.id)) {
        formattedLocation.id = null;
      }

      // Clear empty zone
      if (!formattedLocation.zone) {
        formattedLocation.zone = null;
      }

      if (!formattedLocation.offsiteLocation) {
        formattedLocation.offsiteBuildingCode = null;
      }

      return formattedLocation;
    });

    this.props.saveLocationsRequest(yardId);

    try {
      const responseJson = await callAPI(accountInfo, APIs.SET_PARKING_LOCATIONS_FOR_YARD, {
        version,
        yardId,
        parkingLocations: formattedLocations,
        locationsToDelete: removedLocations,
      });

      this.props.saveLocationsSuccess({ yardId, yardConfig: responseJson });
      this.props.clearRemovedLocations();
      this.props.dismiss();
    } catch (exception) {
      this.props.saveLocationsFailure({ yardId, error: exception.message });
    }
  };

  getWarningMessage = () => {
    const { t, listOffsiteBuildingCodesChanged } = this.props;

    if (listOffsiteBuildingCodesChanged.length < 1) {
      return null;
    }

    let offsiteBuildingCodesAffected = listOffsiteBuildingCodesChanged.join(", ");

    // prettier-ignore
    return `${t(keys.OFFSITE_BUILDING_CODE_CHANGE_WARNING)} ${offsiteBuildingCodesAffected}`;
  };

  clearErrorAndDismiss = async () => {
    const { yardId } = this.props;
    this.props.clearErrorSavingLocations(yardId);
    this.props.dismiss();
  };

  render() {
    const { yardConfigData, yardId } = this.props;
    const { savingLocations, errorSavingLocations } = yardConfigData[yardId];
    return (
      <SaveChangesDialog
        title={"SAVE_LOCATIONS"}
        saveChanges={this.saveLocations}
        dismiss={this.clearErrorAndDismiss}
        savingChanges={savingLocations}
        warningMessage={this.getWarningMessage()}
        genericSavingErrorMessage={"SAVE_LOCATIONS_ERROR_MESSAGE"}
        serverErrorMessage={errorSavingLocations}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo,
  yardConfigData: state.yardConfigData,
});

const mapDispatchToProps = (dispatch) => ({
  saveLocationsRequest: (yardId) => {
    dispatch(saveLocationsRequest(yardId));
  },
  saveLocationsSuccess: (payload) => {
    dispatch(saveLocationsSuccess(payload));
  },
  saveLocationsFailure: (payload) => {
    dispatch(saveLocationsFailure(payload));
  },
  clearErrorSavingLocations: (yardId) => {
    dispatch(clearErrorSavingLocations(yardId));
  },
});

const withTranslationsAndMapStateAndDispatchToProps = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
);

export default withTranslationsAndMapStateAndDispatchToProps(SaveLocationsDialog);

SaveLocationsDialog.propTypes = {
  version: PropTypes.number.isRequired,
  yardId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  removedLocations: PropTypes.arrayOf(PropTypes.object),
  clearRemovedLocations: PropTypes.func.isRequired,
  listOffsiteBuildingCodesChanged: PropTypes.arrayOf(PropTypes.string),
  dismiss: PropTypes.func.isRequired,
};
