import {
  FETCH_YARD_MAP,
  FETCH_YARD_MAP_SUCCESS,
  FETCH_YARD_MAP_FAILURE,
} from "./yardMapActionTypes";
import {
  EDIT_YARD_MAP,
  EDIT_YARD_MAP_SUCCESS,
  EDIT_YARD_MAP_FAILURE,
  CLEAR_ERROR_EDITING_YARD_MAP,
} from "./editYardMapActionTypes";
import { EDIT_YARD_MAP_TYPES } from "app-constants";

const { SAVE_LOCATION_DETAILS, REQUEST_REMAPPING, DELETE_LOCATION_MAPPING, SAVE_OFFSET } =
  EDIT_YARD_MAP_TYPES;

const INITIAL_YARD_MAP_DATA = {
  fetchingYardMap: false,
  editingYardMap: {
    [SAVE_LOCATION_DETAILS]: false,
    [REQUEST_REMAPPING]: false,
    [DELETE_LOCATION_MAPPING]: false,
    [SAVE_OFFSET]: false,
  },
  yardMap: {},
  errorFetchingYardMap: null,
  errorEditingYardMap: null,
};

export const yardMapReducer = (state = INITIAL_YARD_MAP_DATA, action) => {
  switch (action.type) {
    case FETCH_YARD_MAP:
      return {
        ...state,
        fetchingYardMap: true,
        errorFetchingYardMap: null,
      };

    case FETCH_YARD_MAP_SUCCESS: {
      const { yardMap } = action.payload;
      return {
        ...state,
        fetchingYardMap: false,
        yardMap,
        errorFetchingYardMap: null,
      };
    }

    case FETCH_YARD_MAP_FAILURE:
      return {
        ...state,
        fetchingYardMap: false,
        errorFetchingYardMap: action.payload,
      };

    case EDIT_YARD_MAP:
      return {
        ...state,
        editingYardMap: {
          ...state.editingYardMap,
          [action.payload]: true,
        },
        errorEditingYardMap: null,
      };

    case EDIT_YARD_MAP_SUCCESS: {
      const { editType, yardMap } = action.payload;
      return {
        ...state,
        editingYardMap: {
          ...state.editingYardMap,
          [editType]: false,
        },
        yardMap: yardMap,
        errorEditingYardMap: null,
      };
    }

    case EDIT_YARD_MAP_FAILURE: {
      const { editType, error } = action.payload;
      return {
        ...state,
        editingYardMap: {
          ...state.editingYardMap,
          [editType]: false,
        },
        errorEditingYardMap: error,
      };
    }

    case CLEAR_ERROR_EDITING_YARD_MAP:
      return {
        ...state,
        errorEditingYardMap: null,
      };

    default:
      return state;
  }
};
