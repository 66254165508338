import React, { useEffect, useRef, useState } from "react";
import { KVSWebRTCViewer } from "./KVSWebRTCViewer";
import CameraReload from "./CameraReload";
import YardConsoleRequestSigner from "./YardConsoleRequestSigner";
import { useSelector } from "react-redux";
import { APIs } from "app-constants";
import { callAPI } from "store";
import PropTypes from "prop-types";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import { keys } from "i18n";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";

export default function KVSCamera({
  configuration,
  cameraType,
  siteId,
  onRemoteStream,
  remoteStream,
  manuallyReloadKVSVideo = () => {
    /* do nothing */
  },
  autoReloadKVSVideo = () => {
    /* do nothing */
  },
  showReload = true,
  displayMask,
  setDisplayMask,
  setshowAnnotationPanel,
  setCameraInfo,
  setAnnotationImageUrl,
}) {
  const video = useRef();
  const viewerVideo = useRef();
  const [currentLocalStream, setCurrentLocalStream] = useState();
  const accountInfo = useSelector((state) => state.accountInfo);

  const [isLoading, setIsLoading] = useState(true);
  const [overlayImage, setOverlayImage] = useState();
  const [openMaskNotFoundModal, setOpenMaskNotFoundModal] = useState(false);
  const [capturedImageUrl, setCapturedImageUrl] = useState("");
  const [openCapturedImageModal, setOpenCapturedImageModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setOverlayImage(null);
    async function getOverlayImage() {
      if (!configuration || !displayMask) {
        return;
      }
      const responseJson = await callAPI(
        accountInfo,
        APIs.GET_GATE_AREA_DEFINITION_MASK_PRESIGNED_URL,
        {
          cameraList: [
            {
              gateLaneInfo: {
                site: configuration.siteId,
                gateId: configuration.gateId,
                purpose: configuration.purpose,
                laneId: configuration.laneNumber,
              },
              cameraLocation: configuration.cameraType,
              cameraId: configuration.cameraNumber,
            },
          ],
          presignMethod: "GET",
        }
      );

      setOverlayImage(responseJson.cameraMaskUrls?.[0]?.presignedUrl);
    }
    getOverlayImage();
  }, [displayMask, cameraType]);

  useEffect(() => {
    const currentVideo = video.current;
    const currentViewerVideo = viewerVideo.current;
    setIsLoading(true);
    if (remoteStream) {
      if (currentVideo) {
        currentVideo.srcObject = remoteStream;
      }
      return () => {
        if (currentVideo) {
          currentVideo.srcObject = null;
        }
      };
    }
    if (configuration) {
      const viewer = new KVSWebRTCViewer({
        channelARN: configuration.channelARN || "",
        channelEndpoint: configuration.channelEndpoint || "",
        clientId: configuration.clientId || "",
        region: configuration.region || "",
        requestSigner: new YardConsoleRequestSigner(accountInfo),
        iceServers: configuration.iceServers || [],
        cameraType,
        siteId,
        onPeerConnectionFailed: autoReloadKVSVideo,
        onPeerConnectionDisconnected: autoReloadKVSVideo,
        onRemoteStream: (newRemoteStream) => {
          if (currentVideo) {
            currentVideo.srcObject = newRemoteStream;
          }
          if (onRemoteStream) {
            onRemoteStream(cameraType, newRemoteStream);
          }
        },
        onLocalStream: (newLocalStream) => {
          if (currentViewerVideo) {
            currentViewerVideo.srcObject = newLocalStream;
            setCurrentLocalStream(newLocalStream);
          }
        },
      });
      viewer.startViewer();
      const handleBeforeUnload = () => {
        viewer.stopViewer();
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        viewer.stopViewer();
        if (currentVideo) {
          currentVideo.srcObject = null;
        }
        if (currentViewerVideo) {
          currentViewerVideo.srcObject = null;
        }

        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [configuration, cameraType, onRemoteStream, remoteStream, siteId, autoReloadKVSVideo]);

  useEffect(() => {
    const currentVideo = video.current;
    if (currentVideo) {
      currentVideo.muted = true;
    }
  });

  useEffect(() => {
    if (currentLocalStream) {
      const currentViewerVideo = viewerVideo.current;
      if (currentViewerVideo && currentViewerVideo.srcObject) {
        const mediaStream = currentViewerVideo.srcObject;
        const audioTracks = mediaStream.getAudioTracks();
        audioTracks.forEach((audioTrack) => {
          // eslint-disable-next-line no-param-reassign
          audioTrack.enabled = false;
        });
      }
    }
  }, [currentLocalStream, configuration, cameraType]);

  const onMaskLoadError = () => {
    setDisplayMask(false);
    setOpenMaskNotFoundModal(true);
  };

  const takeVideoScreenShot = async () => {
    const canvas = await html2canvas(video.current);
    const resizedCanvas = document.createElement("canvas");
    resizedCanvas.width = 1280;
    resizedCanvas.height = 720;

    // Draw the captured image onto the new canvas with scaling
    const ctx = resizedCanvas.getContext("2d");
    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, 1280, 720);

    const resizedImageUrl = resizedCanvas.toDataURL("image/png", 1.0);
    setCapturedImageUrl(resizedImageUrl);
    setOpenCapturedImageModal(true);
  };

  const startAnnotation = () => {
    setOpenCapturedImageModal(false);
    setAnnotationImageUrl(capturedImageUrl);
    setCameraInfo({
      gateLaneInfo: {
        site: configuration.siteId,
        gateId: configuration.gateId,
        purpose: configuration.purpose,
        laneId: configuration.laneNumber,
      },
      cameraLocation: configuration.cameraType,
      cameraId: configuration.cameraNumber,
    });
    setshowAnnotationPanel(true);
  };

  return (
    <div
      className="camera"
      data-testid={`${siteId}${cameraType}`}
      data-showreload={showReload}
      data-isloading={isLoading}
    >
      <div className="camera-selected">
        <Modal
          title={t(keys.MASK_NOT_FOUND)}
          open={openMaskNotFoundModal}
          scrollContainer="viewport"
          aria-describedby="modal-description"
        >
          <Text id="modal-description">{t(keys.GAS_MASK_NOT_FOUND)}</Text>
          <ModalFooter>
            <Row alignmentHorizontal="end" widths="fit">
              <Button type="primary" onClick={() => setOpenMaskNotFoundModal(false)}>
                Ok
              </Button>
            </Row>
          </ModalFooter>
        </Modal>

        <Modal
          title={t(keys.IMAGE_CAPTURED)}
          open={openCapturedImageModal}
          scrollContainer="viewport"
          aria-describedby="modal-description"
        >
          <img src={capturedImageUrl} alt="captured image" />
          <Text id="modal-description">{t(keys.IMAGE_CAPTURED_NOTE)}</Text>
          <ModalFooter>
            <Row alignmentHorizontal="end" widths="fit">
              <Button type="secondary" onClick={() => setOpenCapturedImageModal(false)}>
                {t(keys.CANCEL)}
              </Button>
              <Button type="primary" onClick={startAnnotation}>
                {t(keys.PROCEED_TO_ANNOTATION)}
              </Button>
            </Row>
          </ModalFooter>
        </Modal>

        <div className="video-mask-container">
          <video
            ref={video}
            className="masking-video"
            autoPlay
            playsInline
            muted
            controls={true}
            onPlaying={() => setIsLoading(false)}
            onWaiting={() => setIsLoading(true)}
          />
          {displayMask && overlayImage && (
            <img className="video-mask-overlay" src={overlayImage} onError={onMaskLoadError} />
          )}
          {showReload && isLoading && (
            <CameraReload reloadFunction={() => manuallyReloadKVSVideo(cameraType)} />
          )}
        </div>

        <Button type="primary" onClick={takeVideoScreenShot}>
          {t(keys.CAPTURE_IMAGE)}
        </Button>
      </div>
    </div>
  );
}

KVSCamera.propTypes = {
  configuration: PropTypes.object,
  onRemoteStream: PropTypes.func,
  remoteStream: PropTypes.object,
  showReload: PropTypes.bool,
  autoReloadKVSVideo: PropTypes.func.isRequired,
  manuallyReloadKVSVideo: PropTypes.func.isRequired,
  displayMask: PropTypes.bool.isRequired,
  setDisplayMask: PropTypes.func.isRequired,
};
