import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { arrayMoveImmutable } from "array-move";

import Column from "@amzn/meridian/column";
import Modal from "@amzn/meridian/modal";

import DialogFooter from "componentsV2/Common/Dialogs/DialogFooter";
import LocaleSelect from "componentsV2/Common/Dialogs/LocaleDialog/LocaleSelect";
import SortableItemList from "componentsV2/Common/SortableItemList";
import { clearErrorSavingLocales, saveLocales } from "store";
import { generateErrorMessage } from "helpers";
import { keys } from "i18n";

export default function LocaleDialog({ yardId, dismiss }) {
  const { languages } = useSelector((state) => state.yardConfigData)[yardId];
  const { savingLocales, errorSavingLocales } = useSelector((state) => state.yardDocumentsData)[
    yardId
  ];

  const [locales, setLocales] = useState(languages);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const addLocale = (locale) => setLocales(locales.concat(locale));
  const removeLocale = (localeToRemove) =>
    setLocales(locales.filter((locale) => locale !== localeToRemove));

  const handleSaveLocales = () => dispatch(saveLocales({ yardId, locales }, dismiss));
  const handleDismiss = () => {
    dispatch(clearErrorSavingLocales({ yardId }));
    dismiss();
  };

  const startSort = () => {
    // https://github.com/clauderic/react-sortable-hoc/issues/328#issuecomment-1005835670
    document.body.classList.add("cursor-grabbing");
  };
  const endSort = ({ oldIndex, newIndex }) => {
    document.body.classList.remove("cursor-grabbing");
    setLocales(arrayMoveImmutable(locales, oldIndex, newIndex));
  };

  const error = errorSavingLocales
    ? generateErrorMessage(t(keys.SAVE_LOCALES_ERROR_MESSAGE), errorSavingLocales)
    : null;

  return (
    <Modal width="400px" title={t(keys.MANAGE_LOCALES)} open={true}>
      <Column spacing="500">
        <Column>
          <LocaleSelect locales={locales} selectLocale={addLocale} />

          <SortableItemList
            lockAxis="y"
            useDragHandle
            items={locales}
            removeItem={removeLocale}
            onSortStart={startSort}
            onSortEnd={endSort}
          />
        </Column>

        <DialogFooter
          inProgress={savingLocales}
          error={error}
          commit={handleSaveLocales}
          dismiss={handleDismiss}
        />
      </Column>
    </Modal>
  );
}

LocaleDialog.propTypes = {
  yardId: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
};
