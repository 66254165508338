import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Checkbox from "@amzn/meridian/checkbox";

import { keys } from "i18n";

export default function HostlerSettings({ yardConfig, setYardConfig }) {
  const { enableHostler } = yardConfig;

  const handleChange = (enableHostler) => setYardConfig({ ...yardConfig, enableHostler });

  const { t } = useTranslation();

  return (
    <Checkbox data-testid="hostlerCheckbox" checked={enableHostler} onChange={handleChange}>
      {t(keys.HOSTLER)}
    </Checkbox>
  );
}

HostlerSettings.propTypes = {
  yardConfig: PropTypes.object.isRequired,
  setYardConfig: PropTypes.func.isRequired,
};
