import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Select, { SelectOption } from "@amzn/meridian/select";

import { keys } from "i18n";
import { SUPPORTED_LANGUAGES } from "app-constants";

export default function LocaleSelect({ locales, selectLocale }) {
  const { t } = useTranslation();

  return (
    <Select
      data-testid="localeSelect"
      label={t(keys.LOCALE)}
      placeholder={t(keys.SELECT_LOCALE)}
      value={""}
      onChange={selectLocale}
    >
      {Object.keys(SUPPORTED_LANGUAGES)
        .filter((locale) => !locales.includes(locale))
        .map((locale) => (
          <SelectOption data-testid={locale} key={locale} label={locale} value={locale} />
        ))}
    </Select>
  );
}

LocaleSelect.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectLocale: PropTypes.func.isRequired,
};
