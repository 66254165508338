import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import BuildingInfo from "componentsV2/ConfigPage/YardEntity/BuildingInfo";
import YardInfo from "componentsV2/ConfigPage/YardEntity/YardInfo";

export default function YardEntity({ yardId, tab, setTab }) {
  const { offsiteYards, buildings } = useSelector((state) => state.yardConfigData)[yardId];

  const offsiteYardId = offsiteYards.find(({ yardId }) => tab === yardId)?.yardId;
  const buildingCode = buildings.find(({ buildingCode }) => tab === buildingCode)?.buildingCode;

  useEffect(() => {
    if (tab !== yardId && !offsiteYardId && !buildingCode) {
      // Navigate to yard tab when an offsite or building is deleted
      setTab(yardId);
    }
  }, [offsiteYardId, buildingCode]);

  if (tab === yardId) {
    return <YardInfo yardId={yardId} />;
  }

  if (offsiteYardId) {
    return <YardInfo mainYardId={yardId} yardId={offsiteYardId} />;
  }

  if (buildingCode) {
    return <BuildingInfo yardId={yardId} buildingCode={buildingCode} />;
  }

  return null;
}

YardEntity.propTypes = {
  yardId: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
};
