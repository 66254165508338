import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Select, { SelectOption } from "@amzn/meridian/select";
import Loader from "@amzn/meridian/loader";
import Theme from "@amzn/meridian/theme";
import orangeLightTokens from "@amzn/meridian-tokens/theme/orange-light";
import loaderTokens from "@amzn/meridian-tokens/component/loader";
import { colorGray400 } from "@amzn/meridian-tokens/base/color";

import { keys } from "i18n";
import { LOCATION_TYPES, EDIT_YARD_MAP_TYPES } from "app-constants";

class LocationMappingInfo extends React.PureComponent {
  state = { isEditButtonClicked: false };

  componentDidUpdate(prevProps) {
    if (this.props.selectedLocation && prevProps.selectedLocation !== this.props.selectedLocation) {
      this.initLocationDetails();
    }
  }

  toggleEditCancelButtons = () => {
    if (this.state.isEditButtonClicked) {
      this.initLocationDetails();
    } else {
      this.setState({ isEditButtonClicked: true });
    }
  };

  initLocationDetails = () => {
    const { yardMapData, selectedLocation, clearErrorEditingYardMap } = this.props;
    this.setState(
      {
        isEditButtonClicked: false,
        locationCode: selectedLocation.locationCode,
        locationType: selectedLocation.locationType,
      },
      () => yardMapData.errorEditingYardMap && clearErrorEditingYardMap()
    );
  };

  setLocationCode = (locationCode) => this.setState({ locationCode });

  setLocationType = (locationType) => this.setState({ locationType });

  saveLocationDetails = () => {
    const { yardMapData, selectedLocation, editYardMap, autoSelectLocationWithChangedCode } =
      this.props;
    const { locationCode } = this.state;
    const { yardId, version } = yardMapData.yardMap;
    const isLocationCodeChanged = locationCode !== selectedLocation.locationCode;
    const deleteLocationCodes = isLocationCodeChanged ? [selectedLocation.locationCode] : [];
    const createOrUpdateLocations = this.getCreateOrUpdateLocations();

    editYardMap(
      {
        editType: EDIT_YARD_MAP_TYPES.SAVE_LOCATION_DETAILS,
        yardId,
        version,
        deleteLocationCodes,
        createOrUpdateLocations,
      },
      () => isLocationCodeChanged && autoSelectLocationWithChangedCode(locationCode)
    );
  };

  requestOrConfimRemapping = () => {
    const { yardMapData, editYardMap } = this.props;
    const { yardId, version } = yardMapData.yardMap;
    const createOrUpdateLocations = this.getCreateOrUpdateLocations(true);

    editYardMap({
      editType: EDIT_YARD_MAP_TYPES.REQUEST_REMAPPING,
      yardId,
      version,
      createOrUpdateLocations,
    });
  };

  deleteLocationMapping = () => {
    const { yardMapData, selectedLocation, editYardMap } = this.props;
    const { yardId, version } = yardMapData.yardMap;
    const deleteLocationCodes = [selectedLocation.locationCode];

    editYardMap({
      editType: EDIT_YARD_MAP_TYPES.DELETE_LOCATION_MAPPING,
      yardId,
      version,
      deleteLocationCodes,
    });
  };

  getCreateOrUpdateLocations = (shouldFlipNeedsRemapping) => {
    const { selectedLocation } = this.props;
    const { locationCode, locationType } = this.state;
    return [
      {
        locationCode,
        locationType,
        // ydlrPolygon when it's selected from YardMap, polygon when it's refreshed from redux
        // Regardless, it's back-end Cartesian coordinates
        polygon: selectedLocation.ydlrPolygon || selectedLocation.polygon,
        parkingDirection: selectedLocation.parkingDirection,
        needsRemapping: shouldFlipNeedsRemapping
          ? !selectedLocation.needsRemapping
          : selectedLocation.needsRemapping,
      },
    ];
  };

  areLocationDetailsChanged = () => {
    const { selectedLocation } = this.props;
    const { locationCode, locationType } = this.state;
    return (
      locationCode !== selectedLocation.locationCode ||
      locationType !== selectedLocation.locationType
    );
  };

  render() {
    const { t, dialogHeight, yardMapData, selectedLocation, locations } = this.props;
    const { isEditButtonClicked, locationCode, locationType } = this.state;
    return selectedLocation ? (
      <Column
        height={dialogHeight}
        maxHeight={dialogHeight}
        overflowY="scroll"
        alignmentVertical="justify"
        spacingInset="450"
      >
        <Column>
          <Row alignmentHorizontal="justify">
            <Text type="b500">{t(keys.LOCATION_DETAILS)}</Text>
            <Button type="link" onClick={this.toggleEditCancelButtons}>
              {isEditButtonClicked ? t(keys.CANCEL) : t(keys.EDIT)}
            </Button>
          </Row>

          <Column spacing="small">
            <Text type="h100">{t(keys.LOCATION_CODE)}</Text>
            {isEditButtonClicked ? (
              <Select popoverMaxHeight={500} value={locationCode} onChange={this.setLocationCode}>
                {locations.map(({ label }) => (
                  <SelectOption key={label} value={label} label={label} />
                ))}
              </Select>
            ) : (
              <Text>{selectedLocation.locationCode}</Text>
            )}
          </Column>

          <Column spacing="small">
            <Text type="h100">{t(keys.LOCATION_TYPE)}</Text>
            {isEditButtonClicked ? (
              <Select value={locationType} onChange={this.setLocationType}>
                {LOCATION_TYPES.map((locationType) => (
                  <SelectOption key={locationType} value={locationType} label={t(locationType)} />
                ))}
              </Select>
            ) : (
              <Text>{t(selectedLocation.locationType)}</Text>
            )}
          </Column>

          {selectedLocation.needsRemapping && <Text>Re-mapping requested.</Text>}

          {yardMapData.errorEditingYardMap && (
            <>
              <Text color="error">{t(keys.EDIT_YARD_MAP_ERROR_MESSAGE)}</Text>
              <Text color="error" breakWord>
                {yardMapData.errorEditingYardMap}
              </Text>
            </>
          )}
        </Column>

        {isEditButtonClicked && (
          <Column spacing="small">
            {yardMapData.editingYardMap.saveLocationDetails ? (
              <Row alignmentHorizontal="center">
                <Loader size="medium" />
              </Row>
            ) : (
              <Button
                disabled={!this.areLocationDetailsChanged()}
                onClick={this.saveLocationDetails}
              >
                {t(keys.SAVE_LOCATION_DETAILS)}
              </Button>
            )}

            {yardMapData.editingYardMap.requestRemapping ? (
              <Row alignmentHorizontal="center">
                <Theme
                  tokens={{
                    ...loaderTokens,
                    loaderIndicatorBackgroundColor: colorGray400,
                  }}
                >
                  <Loader size="medium" />
                </Theme>
              </Row>
            ) : (
              <Button type="secondary" onClick={this.requestOrConfimRemapping}>
                {selectedLocation.needsRemapping
                  ? t(keys.CONFIRM_REMAPPING)
                  : t(keys.REQUEST_REMAPPING)}
              </Button>
            )}

            <Theme tokens={orangeLightTokens}>
              {yardMapData.editingYardMap.deleteLocationMapping ? (
                <Row alignmentHorizontal="center">
                  <Loader size="medium" />
                </Row>
              ) : (
                <Button onClick={this.deleteLocationMapping}>
                  {t(keys.DELETE_LOCATION_MAPPING)}
                </Button>
              )}
            </Theme>
          </Column>
        )}
      </Column>
    ) : (
      <Column spacing="small" spacingInset="450">
        {yardMapData.fetchingYardMap ? (
          <Text>{t(keys.FETCHING_YARD_MAP)}</Text>
        ) : yardMapData.errorFetchingYardMap ? (
          <>
            <Text color="error">{t(keys.FETCH_YARD_MAP_ERROR_MESSAGE)}</Text>
            <Text color="error" breakWord>
              {yardMapData.errorFetchingYardMap}
            </Text>
          </>
        ) : (
          <>
            <Text>
              {yardMapData.yardMap.locations?.length} {t(keys.LOCATION_MAPPING_COUNT_TEXT)}
            </Text>
            <Text>{t(keys.SELECT_LOCATION_INSTRUCTION)}</Text>
          </>
        )}
      </Column>
    );
  }
}

export default withTranslation()(LocationMappingInfo);

LocationMappingInfo.propTypes = {
  dialogHeight: PropTypes.number.isRequired,
  yardMapData: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  editYardMap: PropTypes.func.isRequired,
  autoSelectLocationWithChangedCode: PropTypes.func.isRequired,
  clearErrorEditingYardMap: PropTypes.func.isRequired,
};
